import {Component} from '@angular/core';
import {IdentityControllerService} from "../../../services/api/identity-controller.service";
import {ErrorMessageService} from "../../../services/error-message-service";
import {DataSubscriptionService} from "../../../services/data-subscription.service";
import {ClaimControllerService} from "../../../services/api/claim-controller.service";
import {CryptoWalletService} from "../../../services/crypto-wallet.service";
import {ClaimPayload} from "../../../services/api/models/claim-payload";
import {sha256} from "js-sha256";
import {IdentityView} from "../../../services/api/models/identity-view";
import {VisibilityScopeEnum} from "../../../services/api/models/visibility-scope";
import {VerifiedClaimView} from "../../../services/api/models/verification-request-result-view";
import {SignedData} from "../../../services/api/models/signed-data";
import {LandingComponent} from "../landing/landing.component";
import {NavigatorService} from "../../common/navigator.service";

@Component({
  selector: 'app-process',
  standalone: true,
  imports: [
    LandingComponent
  ],
  templateUrl: './process.component.html',
  styleUrl: './process.component.scss'
})
export class ProcessComponent {
  
  constructor(private errorMessageService: ErrorMessageService,
              private navigatorService: NavigatorService,
              private dataSubscriptionService: DataSubscriptionService,
              private cryptoWalletService: CryptoWalletService,
              private claimControllerService: ClaimControllerService,
              private identityControllerService: IdentityControllerService) {
    this.dataSubscriptionService.publish('showMenu', false)
    console.log("Retrieving last process params value...")
    this.dataSubscriptionService.onFirst<{ correlationId: string, origin: string }>('processParams').subscribe({
      next: processParams => {
        console.log("Found last process params value")
        const correlationId = processParams.correlationId
        const origin = processParams.origin
        switch (origin) {
          case "payment": {
            console.log("Continuing verification process")
            this.identityControllerService.continueVerificationProcess(correlationId!).subscribe({
              next: actionView => {
                console.log("Verification process continuation result:", actionView)
                if (actionView.metadata.errorMessage != undefined) {
                  this.errorMessageService.setMessage(actionView.metadata.errorMessage)
                }
                if (actionView.metadata.redirectUrl) {
                  window.location.href = actionView.metadata.redirectUrl;
                }
              },
              error: error => {
                this.navigatorService.navigateTo('/my-identity', true);
              }
            })
          }
            break;
          case "verification": {
            console.log("Finalizing verification process")
            this.identityControllerService.finalizeVerificationProcess(correlationId!).subscribe({
              next: verificationResult => {
                console.log("Verification process finalization result:", verificationResult)
                if (verificationResult.errorMessage != undefined) {
                  this.errorMessageService.setMessage(verificationResult.errorMessage)
                } else {
                  Promise.all(verificationResult.claims!.map(verificationClaimView => this.createSignedClaimPayload(verificationClaimView)))
                    .then(claimPayloads => {
                      this.claimControllerService.makeClaims(claimPayloads).subscribe({
                        next: claimViews => {
                          this.dataSubscriptionService.onFirst<IdentityView>('identity').subscribe({
                            next: identity => {
                              identity.claims?.push(...claimViews)
                              this.dataSubscriptionService.publish('identity', identity)
                              this.navigatorService.navigateTo('/my-identity', true);
                            }
                          })
                        }
                      })
                    })
                }
              },
              error: error => {
                this.navigatorService.navigateTo('/my-identity', true);
              }
            })
          }
        }
      }, error: error => {
        this.navigatorService.navigateTo('/my-identity', true);
      }
    })
  }
  
  async createSignedClaimPayload(verifiedClaimView: VerifiedClaimView): Promise<SignedData<ClaimPayload>> {
    const claimPayload: ClaimPayload = {
      claimVisibilityScope: VisibilityScopeEnum.Protected
    }
    claimPayload.claimScope = verifiedClaimView.scope
    const nonEncryptedValue = verifiedClaimView.value!
    const encryptedValue = await this.cryptoWalletService.encrypt(nonEncryptedValue)
    const signatureResult = await this.cryptoWalletService.sign(nonEncryptedValue)
    claimPayload.thumbprint = sha256.hex(nonEncryptedValue)
    claimPayload.encryptedFactValue = encryptedValue
    claimPayload.attestations = verifiedClaimView.attestations
    return {
      data: claimPayload,
      signature: signatureResult.signature
    }
  }
  
}
