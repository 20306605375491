import {Injectable} from '@angular/core';
import {Observable} from 'rxjs';

import {DoaToaHttpService} from "./doa-toa-http.service";
import {environment} from "../../environments/environment";


@Injectable({
  providedIn: 'root'
})
export class ApiStatusControllerService {
  
  constructor(private client: DoaToaHttpService) {}
  
  checkApiStatus(): Observable<any> {
    let headers: Map<string, string> = new Map<string, string>();
    headers.set('Accept', 'application/json')
    headers.set('Content-Type', 'application/json')
    let localVarPath = `/public/api-status/health`;
    return this.client.get(`${environment.idpApiBaseUrl}${localVarPath}`, headers);
  }
  
  
}
