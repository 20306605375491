/**
 * DoaToa
 * Manage your data and your digital identities, your data your choice.
 *
 * The version of the OpenAPI document: 1.0.0-alpha
 * Contact: info@compilit.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
/* tslint:disable:no-unused-variable member-ordering */

import {Injectable} from '@angular/core';
import {HttpContext, HttpParams} from '@angular/common/http';
import {Observable} from 'rxjs';
import {AuthorizationRequestView} from "../model/authorization-request-view";
import {AbstractHttpService} from "./abstract-http-service";
import {TokenSet} from "../model/token-set";
import {AuthenticationResultView} from "../model/authentication-result-view";
import {CredentialsPayload} from "../model/credentials-payload";
import {environment} from "../../environments/environment";

@Injectable({
    providedIn: 'root'
})
export class OauthControllerService extends AbstractHttpService {

    public initiateOAuthAuthorizationCodeFlow(params: any): Observable<AuthorizationRequestView> {

        let localVarQueryParameters = new HttpParams({encoder: this.encoder});
        let localVarHeaders = this.defaultHeaders;
        localVarHeaders = localVarHeaders.append('Content-Type', 'application/json')
        localVarHeaders = localVarHeaders.append('Accept', 'application/json')

        const state = params.state
        const clientId = params.client_id
        const redirectUri = encodeURIComponent(params.redirect_uri)
        const nonce = params.nonce
        const scope = params.scope
        const responseType = params.response_type
        const codeChallenge = params.code_challenge
        const responseMode = params.response_mode

        let localVarPath = `/oauth2/authorization-codes/?state=${state}&client_id=${clientId}&redirect_uri=${redirectUri}&response_type=${responseType}&response_mode=${responseMode}&scope=${scope}&nonce=${nonce}&code_challenge=${codeChallenge}`;
        return this.httpClient.request<AuthorizationRequestView>('get', `${this.configuration.basePath}${localVarPath}`,
            {
                params: localVarQueryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: localVarHeaders
            }
        );
    }

    public requestChallenge(credentialsPayload: CredentialsPayload, params: any): Observable<AuthenticationResultView> {
        if (credentialsPayload === null || credentialsPayload === undefined) {
            throw new Error('Required parameter credentialsPayload was null or undefined when calling createSession.');
        }

        let localVarHeaders = this.defaultHeaders;
        localVarHeaders = localVarHeaders.append('Content-Type', 'application/json')
        localVarHeaders = localVarHeaders.append('Accept', 'application/json')
        
        const state = params.state
        let localVarPath = `/oauth2/challenge/?state=${state}&client_id=${environment.webappClientId}`;
        return this.httpClient.request<AuthenticationResultView>('post', `${this.configuration.basePath}${localVarPath}`,
            {
                body: credentialsPayload,
                withCredentials: this.configuration.withCredentials,
                headers: localVarHeaders
            }
        );
    }

    public signIn(params: any, signature: string): Observable<AuthenticationResultView> {

        let localVarHeaders = this.defaultHeaders;
        localVarHeaders = localVarHeaders.append('Content-Type', 'application/json')
        localVarHeaders = localVarHeaders.append('Accept', 'application/json')
        
        const state = params.state
        let localVarPath = `/oauth2/sign-in/?state=${state}&client_id=${environment.webappClientId}`;
        return this.httpClient.request<AuthenticationResultView>('post', `${this.configuration.basePath}${localVarPath}`,
            {
                body: {signature: signature},
                withCredentials: this.configuration.withCredentials,
                headers: localVarHeaders
            }
        );
    }
    
    public refreshToken(refreshToken: string): Observable<TokenSet> {

        let localVarHeaders = this.defaultHeaders;
        localVarHeaders = localVarHeaders.append('Content-Type', "application/x-www-form-urlencoded");
        localVarHeaders = localVarHeaders.append('Accept', 'application/json')
        
        const clientId = environment.webappClientId

        const httpParams: HttpParams = new HttpParams()
            .set('grant_type', 'refresh_token')
            .set('client_id', clientId)
            .set('refresh_token', refreshToken);
        let localVarPath = `/oauth2/tokens/`;
        return this.httpClient.request<TokenSet>('post', `${this.configuration.basePath}${localVarPath}`,
            {
                body: httpParams,
                withCredentials: this.configuration.withCredentials,
                headers: localVarHeaders
            }
        );
    }
    
    public signOut(idToken: string): Observable<any> {

        let localVarHeaders = this.defaultHeaders;
        localVarHeaders = localVarHeaders.append('Content-Type', 'application/json')
        localVarHeaders = localVarHeaders.append('Accept', 'application/json')

        let localVarPath = `/oauth2/sign-out/?id_token_hint=` + idToken!;
        return this.httpClient.request<any>('get', `${this.configuration.basePath}${localVarPath}`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: localVarHeaders
            }
        );
    }
    
    public process(consentGiven?: boolean, id?: string, code?: string, options?: {
        httpHeaderAccept?: '*/*',
        context?: HttpContext
    }): Observable<AuthorizationRequestView> {

        let localVarHeaders = this.defaultHeaders;
        localVarHeaders = localVarHeaders.append('Content-Type', 'application/json')
        localVarHeaders = localVarHeaders.append('Accept', 'application/json')
        
        let localVarPath = `/oauth2/authorization-requests/${id}`
        return this.httpClient.request<AuthorizationRequestView>('put', `${this.configuration.basePath}${localVarPath}`,
            {
                body: {code: code, approved: consentGiven},
                responseType: 'json',
                withCredentials: this.configuration.withCredentials,
                headers: localVarHeaders
            }
        );
    }
    

    public exchangeAuthorizationCodeForToken(code: string, codeVerifier: string, clientId?: string, redirectUri?: string): Observable<TokenSet> {

        let localVarHeaders = this.defaultHeaders;
        localVarHeaders = localVarHeaders.append('Content-Type', "application/x-www-form-urlencoded");
        localVarHeaders = localVarHeaders.append('Accept', 'application/json')
        
        const grantType = "authorization_code"
        const usedClientId: string = clientId || environment.webappClientId
        const responseType = 'query'
        const actualRedirectUri = redirectUri || environment.webappBaseUrl + '/#/oauth-landing'
        const httpParams: HttpParams = new HttpParams()
            .set('grant_type', grantType)
            .set('code', code)
            .set('client_id', usedClientId)
            .set('response_type', responseType)
            .set('redirect_uri', actualRedirectUri)
            .set('code_verifier', codeVerifier);
        let localVarPath = `/oauth2/tokens/`;
        return this.httpClient.request<TokenSet>('post', `${this.configuration.basePath}${localVarPath}`,
            {
                body: httpParams,
                withCredentials: this.configuration.withCredentials,
                headers: localVarHeaders
            }
        );
    }

}
