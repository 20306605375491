import {Component, OnInit} from '@angular/core';
import {GenericButtonComponent} from "../../common/generic-button/generic-button.component";
import {LoadingPlaceholderComponent} from "../../common/loading-placeholder/loading-placeholder.component";
import {NgForOf, NgIf} from "@angular/common";
import {ReactiveFormsModule} from "@angular/forms";
import {DataSubscriptionService} from "../../../services/data-subscription.service";
import {ToCapitalizedTextPipe} from "../../common/pipes/ToCapitalizedTextPipe";
import {IdentityControllerService} from "../../../services/api/identity-controller.service";
import {SpinnerComponent} from "../../common/spinner/spinner.component";
import {Router} from "@angular/router";
import {VerificationMethodView} from "../../../services/api/models/verification-method-view";
import {ToScopeExplanationPipe} from "../../common/pipes/ToScopeExplanation";
import {NavigatorService} from "../../common/navigator.service";

@Component({
  selector: 'app-claim-verification-method',
  standalone: true,
  imports: [
    GenericButtonComponent,
    LoadingPlaceholderComponent,
    NgIf,
    ReactiveFormsModule,
    NgForOf,
    ToScopeExplanationPipe,
    SpinnerComponent,
    ToCapitalizedTextPipe,
  ],
  templateUrl: './claim-verification-method.component.html',
  styleUrl: './claim-verification-method.component.scss'
})
export class ClaimVerificationMethodComponent implements OnInit {
  
  protected loading: Map<string, boolean> = new Map<string, boolean>()
  protected chosenVerificationMethod?: string
  protected classes: string = ''
  protected errorMessage: string = ''
  protected errorOccurred: boolean = false
  protected verificationMethods: Array<VerificationMethodView> = []
  protected scopes: Array<string> = []
  protected scope?: string = undefined
  
  constructor(protected navigator: NavigatorService, private dataSubscriptionService: DataSubscriptionService) {
  }
  
  ngOnInit(): void {
    this.dataSubscriptionService.onFirst<VerificationMethodView[]>('verificationMethods').subscribe({
      next: verificationMethods => this.verificationMethods = verificationMethods
    })
  }
  
  componentLoading() {
    return this.loading.has('component') && this.loading.get('component')!;
  }
  
  startVerificationProcess() {
    if (this.chosenVerificationMethod) {
      this.dataSubscriptionService.publish('chosenVerificationMethod', this.chosenVerificationMethod)
      this.navigator.navigateTo(`/landing/verification`, false);
    }
  }
  
  flipMethod(method: string) {
    if (this.chosenVerificationMethod == method) {
      this.chosenVerificationMethod = undefined
    } else {
      this.chosenVerificationMethod = method
    }
  }
}
