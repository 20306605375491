"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.VerifyMessage = exports.SignMessage = void 0;
const params_1 = require("../../params");
const schema_utils_1 = require("@trezor/schema-utils");
exports.SignMessage = schema_utils_1.Type.Object({
    path: params_1.DerivationPath,
    coin: schema_utils_1.Type.Optional(schema_utils_1.Type.String()),
    message: schema_utils_1.Type.String(),
    hex: schema_utils_1.Type.Optional(schema_utils_1.Type.Boolean()),
    no_script_type: schema_utils_1.Type.Optional(schema_utils_1.Type.Boolean()),
});
exports.VerifyMessage = schema_utils_1.Type.Object({
    address: schema_utils_1.Type.String(),
    signature: schema_utils_1.Type.String(),
    message: schema_utils_1.Type.String(),
    coin: schema_utils_1.Type.String(),
    hex: schema_utils_1.Type.Optional(schema_utils_1.Type.Boolean()),
});
