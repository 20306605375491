<div class="m-3 card p-3 d-flex justify-content-center">
    <div class="">
        <h5>Please enter the type of the claim ({{ claimToDelete?.type || '' }}) to
            confirm.</h5>
        <button aria-label="Close"
                class="btn-close float-end m-1"
                (click)="navigator.navigateTo('/my-claims', true)">
        </button>
    </div>
    <div class="mb-3">
        <input [(ngModel)]="claimType"
               class="form-control rounded-5 height-40"
               id="value"
               name="value"
               type="text"
               datatype="text"
               placeholder="Value">
    </div>
    <div class="row">
        <app-generic-button
                class="col"
                [type]="'submit'"
                [buttonText]="'My mistake!'"
                [btnClass]="'btn-regular'">
        </app-generic-button>
        <app-generic-button
                class="col"
                (click)="deleteClaim()"
                [disabled]="(loading.get(claimToDelete?.cid!) || false) || !nameMatches()"
                [loading]="loading.get(claimToDelete?.cid!) || false"
                [buttonText]="'Delete claim'"
                [btnClass]="'btn-alert'">
        </app-generic-button>
    </div>
</div>