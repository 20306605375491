"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.getSystemInfo = exports.getInstallerPackage = void 0;
const env_utils_1 = require("@trezor/env-utils");
const getInstallerPackage = () => {
    const agent = (0, env_utils_1.getUserAgent)();
    switch ((0, env_utils_1.getOsFamily)()) {
        case 'MacOS':
            return 'mac';
        case 'Windows': {
            const arch = agent.match(/(Win64|WOW64)/) ? '64' : '32';
            return `win${arch}`;
        }
        case 'Linux': {
            const isRpm = agent.match(/CentOS|Fedora|Mandriva|Mageia|Red Hat|Scientific|SUSE/)
                ? 'rpm'
                : 'deb';
            const is64x = agent.match(/Linux i[3456]86/) ? '32' : '64';
            return `${isRpm}${is64x}`;
        }
        default:
    }
};
exports.getInstallerPackage = getInstallerPackage;
const getSystemInfo = (supportedBrowsers) => {
    const browserName = (0, env_utils_1.getBrowserName)();
    const browserVersion = (0, env_utils_1.getBrowserVersion)();
    const supportedBrowser = browserName ? supportedBrowsers[browserName.toLowerCase()] : undefined;
    const outdatedBrowser = supportedBrowser
        ? supportedBrowser.version > parseInt(browserVersion, 10)
        : false;
    const mobile = (0, env_utils_1.getDeviceType)() === 'mobile';
    const supportedMobile = mobile ? 'usb' in navigator : true;
    const supported = !!(supportedBrowser && !outdatedBrowser && supportedMobile);
    return {
        os: {
            family: (0, env_utils_1.getOsFamily)(),
            mobile,
        },
        browser: {
            supported,
            outdated: outdatedBrowser,
        },
    };
};
exports.getSystemInfo = getSystemInfo;
