import {Component, OnInit} from '@angular/core';

import {ActivatedRoute, Router, RouterLink} from '@angular/router';
import {AuthorizationRequestParams} from "../../services/model/authorization-request-params";
import {AuthorizationRequestView} from "../../services/model/authorization-request-view";
import {ErrorMessageService} from "../../services/error-message-service";
import {CryptographyService} from "../../services/cryptography.service";
import {RedirectService} from "../../services/redirect-service";
import {OauthControllerService} from "../../services/api/oauth-controller.service";
import {DataService} from "../../services/data.service";
import {CryptoWalletService} from "../../services/crypto-wallet.service";
import {CredentialsPayload} from "../../services/";
import {environment} from "../../environments/environment";
import {LogoComponent} from "../common/logo/logo.component";
import {FormsModule} from "@angular/forms";
import {GenericButtonComponent} from "../common/generic-button/generic-button.component";
import {ErrorModalComponent} from "../common/error-modal/error-modal.component";
import {NgClass, NgIf, NgOptimizedImage} from "@angular/common";
import {SpinnerComponent} from "../common/spinner/spinner.component";
import {switchMap, take} from "rxjs";
import {oneHour} from "../../app.config";


@Component({
  selector: 'app-sign-in',
  templateUrl: './sign-in.component.html',
  standalone: true,
  imports: [
    LogoComponent,
    FormsModule,
    GenericButtonComponent,
    ErrorModalComponent,
    NgIf,
    NgOptimizedImage,
    RouterLink,
    SpinnerComponent,
    NgClass
  ],
  styleUrls: ['./sign-in.component.scss']
})
export class SignInComponent implements OnInit {
  protected email?: string = undefined;
  protected code?: string = undefined;
  protected loading: boolean = false;
  protected showEmailInfo: boolean = false;
  protected twoStepAuthenticationEnabled: boolean = false;
  protected errorOccurred: boolean = false
  protected errorMessage: string = ''
  protected method: string = ''
  protected queryParams?: AuthorizationRequestParams = undefined
  protected authorizationRequest?: AuthorizationRequestView = undefined
  
  constructor(private errorMessageService: ErrorMessageService, private cryptographyService: CryptographyService, private redirectService: RedirectService, private oauthControllerService: OauthControllerService, private activatedRoute: ActivatedRoute, private router: Router, private dataService: DataService, private cryptoWalletService: CryptoWalletService) {
  }
  
  ngOnInit(): void {
    // this.activatedRoute.queryParamMap.subscribe((p: any) => {
    //   this.queryParams = p.params
    //   if (this.queryParams?.client_id == undefined) {
    //     //if no client_id is in place we assume people want to sign-in into DoaToa itself, so we populate all query parameters through this redirect
    //     this.redirectService.redirectToSignInPage()
    //   } else {
    //     setTimeout(() => {
    //       this.redirectService.redirectToGateway()
    //     }, 240000);
    //   }
    // });
    if (this.dataService.has('jwt') && this.dataService.has('identity')) {
      this.router.navigate(['/my-identity'])
    }
  }
  
  protected authenticate() {
    this.loading = true
    this.dataService.publish('email', this.email, true)
    const doatoaDID = environment.did
    
    return this.dataService.last<string>('did').subscribe({
      next: did => {
        const header = {
          "alg": "EdDSA",
          "typ": "JWT"
        }
        const base64Header = btoa(JSON.stringify(header))
        const payload = {
          iss: did,
          aud: doatoaDID,
          exp: Date.now() + oneHour,
          att: [
            {
              with: environment.idpApiBaseUrl,
              can: "read",
            },
            {
              with: environment.idpApiBaseUrl,
              can: "write",
            }
          ]
        }
        const base64Payload = btoa(JSON.stringify(payload))
        this.cryptoWalletService.signData(`${base64Header}.${base64Payload}`, 'doatoa').then(signatureResult => {
          const signature = signatureResult.signature
          const completeJwt = `${base64Header}.${base64Payload}.${signature}`
          this.dataService.publish('jwt', completeJwt, true)
          this.dataService.publish('showMenu', true)
          this.dataService.init()
          this.router.navigate(['/my-identity'])
        })
      }
    })
    // let credentialsPayload: CredentialsPayload = {emailAddress: this.email}
    // if (this.authorizationRequest == undefined) {
    //     this.oauthControllerService.initiateOAuthAuthorizationCodeFlow(this.queryParams)
    //         .subscribe({
    //             next: (response) => {
    //                 this.authorizationRequest = response
    //                 this.oauthControllerService.requestChallenge(credentialsPayload, this.queryParams)
    //                     .subscribe({
    //                         next: async (response) => {
    //                             this.dataService.publish('did', response.did)
    //                             const challenge = response.challenge
    //                             //todo: change into requestLogin(params), see https://docs.trezor.io/trezor-suite/packages/connect/methods/requestLogin.html
    //                             const signatureResult = await this.cryptoWalletService.signData(challenge!, this.method);
    //                             if (signatureResult.signature) {
    //                                 this.startSignInProcess(signatureResult.signature)
    //                             } else {
    //                                 this.errorOccurred = true;
    //                                 this.errorMessageService.setMessage("Failed to get a signature.")
    //                             }
    //                         },
    //                         error: (error) => {
    //                             this.errorOccurred = true
    //                             setTimeout(() => {
    //                                 this.loading = false
    //                                 this.signingIn = false
    //                                 // if (this.queryParams?.client_id == environment.webappClientId) {
    //                                 //     this.redirectService.redirectToSignInPage();
    //                                 // } else window.location.href = this.authorizationRequest!!.redirectUri!!;
    //                             }, 5000);
    //                         }
    //                     });
    //             },
    //             error: (error) => {
    //                 this.errorOccurred = true
    //                 this.loading = false
    //                 this.signingIn = false
    //                 setTimeout(() => {
    //                     this.redirectService.redirectToSignInPage()
    //                 }, 5000);
    //             }
    //         });
    // }
    
  }
  
  private startSignInProcess(signature ?: string) {
    if (signature) {
      this.oauthControllerService.signIn(this.queryParams, signature)
        .subscribe({
          next: (response_2) => {
            this.continueAuthorizationRequest();
          },
          error: (error_1) => {
            this.errorOccurred = true;
            setTimeout(() => {
              if (this.queryParams?.client_id == environment.webappClientId) {
                this.redirectService.redirectToSignInPage();
              } else window.location.href = this.authorizationRequest!!.redirectUri!!;
            }, 5000);
          }
        });
    } else {
      if (this.queryParams?.client_id == environment.webappClientId) {
        this.redirectService.redirectToSignInPage();
      } else window.location.href = this.authorizationRequest!!.redirectUri!!;
    }
  }
  
  private continueAuthorizationRequest() {
    if (this.authorizationRequest?.approved) {
      this.oauthControllerService.process(true, this.authorizationRequest?.state!)
        .subscribe({
          next: (response) => {
            window.location.href = decodeURIComponent(response!.redirectUri!)
          },
          error: (error) => {
            this.errorOccurred = true
            setTimeout(() => {
              this.redirectService.redirectToSignInPage()
            }, 5000);
          }
        });
    } else {
      this.router.navigate(['/consent'], {
        queryParams: {
          state: this.authorizationRequest?.state,
          scopes: this.authorizationRequest?.openIdScopes,
          client_name: this.authorizationRequest?.client?.name
        }
      })
    }
  }
  
  isValidEmail = (email?: string) => {
    return String(email)
      .toLowerCase()
      .match(
        /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|.(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
      );
  };
  
  flipShowEmailInfo() {
    this.showEmailInfo = !this.showEmailInfo
  }
}
