<div class="vh-100 d-flex flex-column align-items-center justify-content-start">
    <div class="logo-container mt-4">
        <app-logo
                [classes]="'mx-auto d-block'"
                [height]="'200'"
                [image]="'/doatoa-logo-full.svg'"
                [width]="'300'">
        </app-logo>
    </div>
    <div class="flex-grow-1 d-flex flex-column align-items-center justify-content-center w-100">
        <ng-container>
            <!-- Authentication Form -->
            <ng-container *ngIf="!loading">
                <form (submit)="authenticate()" class="row justify-content-center w-100">
                    <div class="card p-3 max-width-300">
                        <h2 class="text-center">Sign in</h2>
                        <div class="position-relative mb-2">
                            <input [(ngModel)]="email"
                                   id="email"
                                   name="email"
                                   class="form-control rounded-5"
                                   [ngClass]="{ 'is-valid': email && isValidEmail(email), 'is-invalid': email === '' || email === undefined || !isValidEmail(email) }"
                                   placeholder="E-mail address"
                                   type="email"
                                   autocapitalize="off"
                                   autocomplete="new-email">
                            <i class="secondary-blue fa-solid fa-circle-question pe-4"
                               style="position: absolute; right: 10px; top: 50%; transform: translateY(-50%);"
                               (click)="flipShowEmailInfo()"></i>
                        </div>
                        <div class="p-3" *ngIf="showEmailInfo">
                            The mandatory e-mail address used for all DoaToa correspondence.
                        </div>
                        <app-generic-button
                                id="sign-in"
                                class="mb-2"
                                (click)="method = 'doatoa'"
                                [type]="'submit'"
                                [disabled]="loading || !email"
                                [loading]="loading"
                                [buttonText]="'Sign in'"
                                [btnClass]="'btn-basic-info'"
                                [icon]="'fa-solid fa-right-to-bracket'">
                        </app-generic-button>
<!--                        <app-generic-button-->
<!--                                id="sign-in-trezor"-->
<!--                                class="mb-2"-->
<!--                                (click)="method = 'trezor'"-->
<!--                                [type]="'submit'"-->
<!--                                [disabled]="loading || !email"-->
<!--                                [loading]="loading"-->
<!--                                [buttonText]="'Sign in with Trezor'"-->
<!--                                [icon]="'fa-solid fa-right-to-bracket'">-->
<!--                        </app-generic-button>-->
                    </div>
                </form>

                <div class="card text-center max-width-300 mt-3">
                    <p class="m-auto p-2">New to DoaToa? <a routerLink="/sign-up">Sign up</a></p>
                </div>
                <div class="card text-center max-width-300 mt-3">
                    <p class="m-auto p-2">Lost keys? <a routerLink="/recover-keys">Recover keys</a></p>
                </div>

                <app-error-modal
                        [showModal]="errorOccurred"
                        [extraClasses]="'m-3'"
                        [message]="errorMessage">
                </app-error-modal>

                <img ngSrc="/sign-in.png" class="max-width-400 mx-auto mt-3" alt="sign-in" height="400" width="400">
            </ng-container>

            <ng-container *ngIf="loading && !errorOccurred">
                <app-spinner [loading]="true" class="m-auto mt-5" [style]="'width: 183px; height: 183px;'"></app-spinner>
                <div class="mt-3">Signing in...</div>
            </ng-container>

            <app-error-modal [showModal]="errorOccurred"></app-error-modal>
        </ng-container>
    </div>
</div>