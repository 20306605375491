import {NgModule} from '@angular/core';
import {IdentitiesComponent} from "./identities.component";
import {IdentityReferenceComponent} from "./identity-reference/identity-reference.component";
import {SubscriptionsComponent} from "./subscriptions/subscriptions.component";
import {InfiniteScrollModule} from "ngx-infinite-scroll";
import {IdentityComponent} from "./identity/identity.component";
import {NgbCollapse} from "@ng-bootstrap/ng-bootstrap";
import {ToReadableStringPipe} from "../common/pipes/ToReadableStringPipe";
import {ActionButtonComponent} from "../common/action-button/action-button.component";
import {SubscriptionComponent} from "../common/subscription/subscription.component";
import {ToClaimScopeIconPipe} from "../common/pipes/ToClaimTypeIconPipe";
import {ToVisibilityScopeIconPipe} from "../common/pipes/ToVisibilityScopeIconPipe";
import {ClaimComponent} from "../common/claim/claim.component";
import {NgClass, NgForOf, NgIf, NgOptimizedImage, NgStyle} from "@angular/common";
import {NewClaimModalComponent} from "../common/new-claim-modal/new-claim-modal.component";
import {NewSubscriptionModalComponent} from "../common/new-request-modal/new-subscription-modal.component";
import {ExportModalComponent} from "../common/export-modal/export-modal.component";
import {SpinnerComponent} from "../common/spinner/spinner.component";
import {LoadingPlaceholderComponent} from "../common/loading-placeholder/loading-placeholder.component";
import {FormsModule} from "@angular/forms";
import {GenericButtonComponent} from "../common/generic-button/generic-button.component";


@NgModule({
    imports: [
        InfiniteScrollModule,
        NgbCollapse,
        ToReadableStringPipe,
        ActionButtonComponent,
        SubscriptionComponent,
        ToClaimScopeIconPipe,
        ToVisibilityScopeIconPipe,
        ClaimComponent,
        NgIf,
        NgForOf,
        NewClaimModalComponent,
        NewSubscriptionModalComponent,
        ExportModalComponent,
        NgOptimizedImage,
        NgClass,
        NgStyle,
        SpinnerComponent,
        LoadingPlaceholderComponent,
        FormsModule,
        GenericButtonComponent
    ],
    exports: [
    ],
    declarations: [
        IdentitiesComponent,
        IdentityComponent,
        IdentityReferenceComponent,
        SubscriptionsComponent
    ]
})
export class IdentitiesModule {
}