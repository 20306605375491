"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.TrezorConnectDynamicImpl = void 0;
const tslib_1 = require("tslib");
const factory_1 = require("@trezor/connect/lib/factory");
const core_in_iframe_1 = require("./impl/core-in-iframe");
const core_in_popup_1 = require("./impl/core-in-popup");
class ProxyEventEmitter {
    constructor(eventEmitters) {
        this.eventEmitters = eventEmitters;
    }
    emit(eventName, ...args) {
        this.eventEmitters.forEach(emitter => emitter.emit(eventName, ...args));
        return true;
    }
    on(eventName, listener) {
        this.eventEmitters.forEach(emitter => emitter.on(eventName, listener));
        return this;
    }
    off(eventName, listener) {
        this.eventEmitters.forEach(emitter => emitter.off(eventName, listener));
        return this;
    }
    once(eventName, listener) {
        this.eventEmitters.forEach(emitter => emitter.once(eventName, listener));
        return this;
    }
    addListener(eventName, listener) {
        this.eventEmitters.forEach(emitter => emitter.addListener(eventName, listener));
        return this;
    }
    prependListener(eventName, listener) {
        this.eventEmitters.forEach(emitter => emitter.prependListener(eventName, listener));
        return this;
    }
    prependOnceListener(eventName, listener) {
        this.eventEmitters.forEach(emitter => emitter.prependOnceListener(eventName, listener));
        return this;
    }
    removeAllListeners(event) {
        this.eventEmitters.forEach(emitter => emitter.removeAllListeners(event));
        return this;
    }
    removeListener(eventName, listener) {
        this.eventEmitters.forEach(emitter => emitter.removeListener(eventName, listener));
        return this;
    }
    setMaxListeners(n) {
        this.eventEmitters.forEach(emitter => emitter.setMaxListeners(n));
        return this;
    }
    eventNames() {
        return this.eventEmitters[0].eventNames();
    }
    getMaxListeners() {
        return this.eventEmitters[0].getMaxListeners();
    }
    listenerCount(eventName, listener) {
        return this.eventEmitters[0].listenerCount(eventName, listener);
    }
    rawListeners(eventName) {
        return this.eventEmitters[0].rawListeners(eventName);
    }
    listeners(eventName) {
        return this.eventEmitters[0].listeners(eventName);
    }
}
class TrezorConnectDynamicImpl {
    constructor() {
        this.currentTarget = 'iframe';
        this.coreInIframeImpl = new core_in_iframe_1.CoreInIframe();
        this.coreInPopupImpl = new core_in_popup_1.CoreInPopup();
        this.eventEmitter = new ProxyEventEmitter([
            this.coreInIframeImpl.eventEmitter,
            this.coreInPopupImpl.eventEmitter,
        ]);
    }
    getTarget() {
        return this.currentTarget === 'iframe' ? this.coreInIframeImpl : this.coreInPopupImpl;
    }
    manifest(data) {
        this.getTarget().manifest(data);
    }
    init(settings = {}) {
        if (settings.useCoreInPopup) {
            this.currentTarget = 'core-in-popup';
        }
        else {
            this.currentTarget = 'iframe';
        }
        return this.getTarget().init(settings);
    }
    call(params) {
        return this.getTarget().call(params);
    }
    requestLogin(params) {
        return this.getTarget().requestLogin(params);
    }
    uiResponse(params) {
        return this.getTarget().uiResponse(params);
    }
    renderWebUSBButton() {
        return this.getTarget().renderWebUSBButton();
    }
    disableWebUSB() {
        return this.getTarget().disableWebUSB();
    }
    requestWebUSBDevice() {
        return this.getTarget().requestWebUSBDevice();
    }
    cancel(error) {
        return this.getTarget().cancel(error);
    }
    dispose() {
        this.eventEmitter.removeAllListeners();
        return this.getTarget().dispose();
    }
}
exports.TrezorConnectDynamicImpl = TrezorConnectDynamicImpl;
const methods = new TrezorConnectDynamicImpl();
const TrezorConnect = (0, factory_1.factory)({
    eventEmitter: methods.eventEmitter,
    init: methods.init.bind(methods),
    call: methods.call.bind(methods),
    manifest: methods.manifest.bind(methods),
    requestLogin: methods.requestLogin.bind(methods),
    uiResponse: methods.uiResponse.bind(methods),
    renderWebUSBButton: methods.renderWebUSBButton.bind(methods),
    disableWebUSB: methods.disableWebUSB.bind(methods),
    requestWebUSBDevice: methods.requestWebUSBDevice.bind(methods),
    cancel: methods.cancel.bind(methods),
    dispose: methods.dispose.bind(methods),
});
exports.default = TrezorConnect;
tslib_1.__exportStar(require("@trezor/connect/lib/exports"), exports);
