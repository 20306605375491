<div aria-hidden="true"
     aria-labelledby="exportModalLabel"
     class="modal fade"
     [id]="customId"
     role="dialog"
     tabindex="-1">
    <div class="modal-dialog"
         role="document">
        <div class="modal-content">
            <div class="modal-header">
                <h5 class="modal-title"
                    >Export claims.</h5>
                <button aria-label="Close"
                        class="btn-close float-end m-1"
                        data-bs-dismiss="modal"
                        type="button">
                </button>
            </div>
            <div class="modal-body">
                <select (change)="setScopeToDownload($event)"
                        class="rounded-5 mb-2 col form-select"
                        id="#"
                        name="type">
                    <option disabled
                            [selected]="scope == undefined"
                            value="">Select a scope...
                    </option>
                    <option *ngFor="let scope of scopes"
                            value="{{scope}}">{{ scope | toReadableString }}
                    </option>
                </select>
            </div>
            <div class="modal-footer justify-content-center">
                <app-generic-button
                        (click)="download()"
                        [icon]="'fa fa-download'"
                        [buttonText]="'Download'"
                        [disabled]="exportLoading || scope == undefined"
                        [loading]="exportLoading">
                </app-generic-button>
                <app-error-modal [message]="errorMessage" [showModal]="errorOccurred"></app-error-modal>
            </div>
        </div>
    </div>
</div>