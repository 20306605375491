import {Component, EventEmitter, OnInit, Output} from '@angular/core';

import {SafeResourceUrl} from "@angular/platform-browser";
import * as uuid from "uuid";
import {DataSubscriptionService} from "../../services/data-subscription.service";
import {AuthorisedComponent} from "../common/authorised-component";
import {SignOutService} from "../../services/signout-service";
import {LoadingPlaceholderComponent} from "../common/loading-placeholder/loading-placeholder.component";
import {NgIf} from "@angular/common";
import {IdentityCardComponent} from "./identity-card/identity-card.component";
import {CryptoWalletService} from "../../services/crypto-wallet.service";
import {ClaimView} from "../../services/api/models/claim-reference-view";
import {IdentityView} from "../../services/api/models/identity-view";
import {ErrorMessageComponent} from "../common/error-message/error-message.component";
import {ErrorMessageService} from "../../services/error-message-service";
import {NavigatorService} from "../common/navigator.service";
import {DataStorageService} from "../../services/data-storage.service";


@Component({
  selector: 'app-my-identity',
  templateUrl: './my-identity.component.html',
  standalone: true,
  imports: [
    LoadingPlaceholderComponent,
    NgIf,
    IdentityCardComponent,
    ErrorMessageComponent
  ],
  styleUrls: ['./my-identity.component.scss']
})
export class MyIdentityComponent extends AuthorisedComponent implements OnInit {
  
  @Output() claimViewEmitter: EventEmitter<ClaimView> = new EventEmitter<ClaimView>()
  protected myProfileId: string = 'my-claims-reference-' + uuid.v4()
  protected loading: boolean = true
  protected identity?: IdentityView
  protected did?: string = undefined
  protected queryParams?: any = undefined
  protected loadingAvatar: boolean = false
  protected errorOccurred: boolean = false
  protected errorMessage: string = ''
  protected flipOpenForRequestsLoading: boolean = false
  protected imageToUpload?: Promise<ArrayBuffer> = undefined
  protected imagePath?: SafeResourceUrl = undefined
  
  constructor(protected errorMessageService: ErrorMessageService,
              navigatorService: NavigatorService,
              dataSubscriptionService: DataSubscriptionService,
              signOutService: SignOutService,
              cryptoWalletService: CryptoWalletService,
              dataStorageService: DataStorageService) {
    super(dataSubscriptionService, signOutService, cryptoWalletService, navigatorService, dataStorageService)
  }
  ngOnInit() {
    this.shouldAuthenticate = true
    this.dataSubscriptionService.onNext<boolean>('dataLoading').subscribe(dataLoading => {
      this.loading = dataLoading
    })
    this.dataSubscriptionService.onNext<IdentityView>('identity').subscribe({
      next: identity => {
        this.identity = identity;
      }
    })
  }
  
}
