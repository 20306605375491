import {BiometryType, NativeBiometric} from "capacitor-native-biometric";

  
  export async function performBiometricVerification() : Promise<boolean> {
    const result = await NativeBiometric.isAvailable();
    
    if(!result.isAvailable)
      return false;
    const isFaceID = result.biometryType == BiometryType.FACE_ID;
    console.log(result)
    // const credentials = await NativeBiometric.getCredentials({
    //   server: "www.example.com",
    // });
    
    return await NativeBiometric.verifyIdentity({
      reason: "To access DoaToa",
      title: "Authenticate yourself"
    })
      .then(() => true)
      .catch(() => false);
  }

// // Save user's credentials
// NativeBiometric.setCredentials({
//   username: "username",
//   password: "password",
//   server: "www.example.com",
// }).then();
//
// // Delete user's credentials
// NativeBiometric.deleteCredentials({
//   server: "www.example.com",
// }).then();
