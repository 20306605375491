import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {NgIf} from "@angular/common";
import {FactView} from "../../../services/api/models/fact-view";
import {ClipboardService} from "../../../services/clipboard.service";

@Component({
  selector: 'app-fact',
  templateUrl: './fact.component.html',
  standalone: true,
  imports: [
    NgIf
  ],
  styleUrls: ['./fact.component.scss']
})
export class FactComponent implements OnInit {
  
  @Input() fact!: FactView
  @Input() style: string = 'min-width: 300px; max-width: 100%;'
  
  constructor(protected clipboardService: ClipboardService) {
  }
  
  ngOnInit(): void {
  }
  
  copyToClipboard() {
    const element = document.getElementById('fact-value-' + this.fact?.value);
    this.clipboardService.copyToClipboard(element!.textContent!)
  };
}
