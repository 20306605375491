<div class="min-width-300 overflow-x-hidden">
    <div class="w-100">
        <div class="m-3 card p-1 z-max">
            <div class="d-flex justify-content-between p-2">
                <div class="ps-3 d-flex justify-content-between align-items-center"
                     aria-expanded="true"
                     aria-controls="settings"
                     data-bs-target="#settings"
                     data-bs-toggle="collapse">
                    <div class="border-end">
                        <i class="pointer fa-solid fa-bars fa-2x color-secondary-blue me-3 mt-auto mb-auto"></i>
                    </div>
                    <i class="fa-solid fa-list-check fa-2x color-secondary-blue ms-3 me-2 mt-auto mb-auto"></i>
                </div>
                <div class="">
                    <div class="d-flex color-secondary-blue border rounded-pill">
                        <app-action-button
                                class="p-1"
                                (click)="navigator.navigateTo('/verification-method', false)"
                                [useSpinner]="false"
                                [showPrimary]="true"
                                [btnClass]="'btn-regular'"
                                [primaryIconClass]="'fa-solid fa-check-double'">
                        </app-action-button>
<!--                        <app-action-button-->
<!--                                class="p-1"-->
<!--                                [useSpinner]="false"-->
<!--                                [showPrimary]="true"-->
<!--                                [btnClass]="'btn-regular'"-->
<!--                                [primaryIconClass]="'fa-solid fa-share-nodes'"-->
<!--                                (click)="shareClaims()">-->
<!--                        </app-action-button>-->
                        <app-action-button
                                class="p-1"
                                [useSpinner]="false"
                                [showPrimary]="true"
                                [btnClass]="'btn-regular'"
                                [primaryIconClass]="'fa fa-cloud-arrow-down'"
                                (click)="navigator.navigateTo('/export-claims', false)">
                        </app-action-button>
                    </div>
                </div>
            </div>
            <div id="settings" class="collapse">
                <app-settings></app-settings>
            </div>
        </div>
    </div>
    <div class="">
        <div class="m-3 card p-3">
            <div class="row text-dark">
                <app-generic-button [buttonText]="'Informal claim'"
                                    [primaryIcon]="'fa fa-plus'"
                                    [attr.data-bs-target]="'#'+newClaimId"
                                    data-bs-toggle="modal"></app-generic-button>
            </div>
            <div *ngFor="let claim of claims; index as i" class="text-dark mb-3 mt-3">
                <div (click)="openCloseClaim(claim)"
                     [attr.aria-controls]="'claim-'+i"
                     [attr.data-bs-target]="'#claim-'+i"
                     [id]="'claim-row-'+i"
                     aria-expanded="false"
                     data-bs-toggle="collapse"
                     class="p-0 m-0 btn btn-regular min-width-220 w-100">
                    <div class="ps-3 d-flex align-items-center justify-content-between height-40">
                        <div class="d-flex align-items-center">
                            <i class="color-primary-blue"
                               [ngClass]="{'fa fa-fw fa-check color-nominal': claim.attestations!.length > 0, 'fa fa-fw fa-question': claim.attestations!.length == 0}"></i>
                            <div class="ms-2 text-start truncate-text">{{ claim.type | toReadableString }}</div>
                            <i class="ms-2 fa fa-fw color-secondary-blue {{claim.visibilityScope | toVisibilityScopeIcon}}"></i>
                        </div>
                        <div class="pe-3 d-flex align-items-center justify-content-center height-40">
                            <i *ngIf="open.get(claim.cid!) || false" class="fa fa-chevron-up"></i>
                            <i *ngIf="!open.get(claim.cid!) || false" class="fa fa-chevron-down"></i>
                        </div>
                    </div>
                </div>
                <div [id]="'claim-'+i" class="collapse">
                    <app-claim [myProfile]="true"
                               [loading]="isLoading(claim.cid!) || false"
                               (claimChanged)="updateClaimClass($event)"
                               [claim]=claim></app-claim>
                </div>
            </div>
        </div>
    </div>
</div>