import {Component, OnInit} from '@angular/core';
import {GenericButtonComponent} from "../../common/generic-button/generic-button.component";
import {NgForOf, NgIf} from "@angular/common";
import {LogoComponent} from "../../common/logo/logo.component";
import {ErrorMessageService} from "../../../services/error-message-service";
import {DataSubscriptionService} from "../../../services/data-subscription.service";

import {CryptoWalletService} from "../../../services/crypto-wallet.service";
import {Router} from "@angular/router";
import {environment} from "../../../environments/environment";
import {ErrorMessageComponent} from "../../common/error-message/error-message.component";
import {IdentityControllerService} from "../../../services/api/identity-controller.service";
import {ClipboardService} from "../../../services/clipboard.service";
import {IdentityPayload} from "../../../services/api/models/identity-payload";


@Component({
  selector: 'app-mnemonic-phrase',
  standalone: true,
  imports: [
    GenericButtonComponent,
    NgForOf,
    NgIf,
    LogoComponent,
    ErrorMessageComponent
  ],
  templateUrl: './mnemonic-phrase.component.html',
  styleUrl: './mnemonic-phrase.component.scss'
})
export class MnemonicPhraseComponent implements OnInit {
  
  protected termsAndConditionsAccepted: boolean = false
  protected loading: boolean = false;
  protected noEmail: boolean = false;
  protected errorOccurred: boolean = false
  protected authenticationMethod: string = 'doatoa';
  protected mnemonic: string = '';
  protected mnemonicParts: Array<string> = [];
  protected showCopyConfirmation: boolean = false;
  
  constructor(protected clipboardService: ClipboardService, private errorMessageService: ErrorMessageService, private dataSubscriptionService: DataSubscriptionService, private identityControllerService: IdentityControllerService, private cryptoWalletService: CryptoWalletService, private router: Router) {
  }
  
  ngOnInit(): void {
    // this.dataSubscriptionService.onFirst<string>('authenticationMethod').subscribe({
    //   next: async authenticationMethod => {
    //     this.authenticationMethod = authenticationMethod;
    //     if (this.authenticationMethod == 'doatoa') {
    this.dataSubscriptionService.onFirst<string>('mnemonic').subscribe({
      next: mnemonic => {
        this.mnemonic = mnemonic;
        this.mnemonicParts = mnemonic.split(" ")
      }
    })
    //     }
    //   }
    // })
  }
  
  copyToClipboard = async () => {
    try {
      await navigator.clipboard.writeText(this.mnemonic);
      this.showCopyConfirmation = true
      setTimeout(() => {
        this.showCopyConfirmation = false
      }, 1000);
    } catch (error) {
      console.error("Failed to copy to clipboard:", error);
    }
  };
  
  switchTermsAndConditionsAccepted() {
    this.termsAndConditionsAccepted = !this.termsAndConditionsAccepted
  }
  
  async createIdentity() {
    this.loading = true
    const email = await this.cryptoWalletService.getStored('email')
    const publicKey: string | undefined = await this.resolvePublicKey(this.authenticationMethod)
    if (!!publicKey) {
      const did: string = `did:key:${publicKey!}`
      this.cryptoWalletService.rollSalt().then(async salt => {
        await this.cryptoWalletService.store('did', did)
        let identityPayload: IdentityPayload = {
          did: did!,
          salt: salt,
          emailAddress: email,
          locale: navigator.languages[0]
        }
        this.cryptoWalletService.setupJWT([
          {
            with: environment.idpApiBaseUrl,
            can: "create-account",
          }
        ], environment.did).then(async jwt => {
          await this.cryptoWalletService.store('jwt', jwt)
          this.identityControllerService.register(identityPayload)
            .subscribe({
              next: async (response) => {
                await this.cryptoWalletService.removeStored('jwt')
                await this.router.navigate(['/sign-in'])
                this.loading = false
              },
              error: (error) => {
                this.loading = false
                this.errorMessageService.setMessage(error.message)
                this.router.navigate(['/sign-in'])
              }
            });
        })
      })
    } else {
      throw new Error(`No public key found for email: ${email}`)
    }
  }
  
  private async resolvePublicKey(method: string): Promise<string | undefined> {
    switch (method) {
      case 'doatoa' :
        return this.cryptoWalletService.getSigningPublicKey()
      default:
        throw new Error(`Unknown method: ${method}`)
    }
  }
  

}
