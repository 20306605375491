<app-new-subscription-modal (submittedRequest)="addRequest($event)"
                            [customId]="newRequestIdentifier"
                            [subject]="identityReference.did"
                            [title]="'Request subscription'"></app-new-subscription-modal>
<app-new-claim-modal [proposed]="true"
                     [subject]="identityReference.did"
                     [customId]="newClaimIdentifier"
                     (claimMade)="addClaim($event)"></app-new-claim-modal>
<app-export-modal [subject]="identityReference.did" [customId]="exportIdentifier"></app-export-modal>
<div class="container {{classList}}"
     aria-expanded="false"
     data-bs-toggle="collapse"
     (click)="getIdentity()"
     [attr.aria-controls]="identityIdentifier"
     [attr.data-bs-target]="'#'+identityIdentifier">
    <div class="card">
        <div class="btn {{resolveClass()}} text-dark">
            <img *ngIf="filePath == '' && !loading && !loadingAvatar"
                 alt="avatar"
                 class="col-md float-start"
                 height="100"
                 ngSrc="{{resolveSource()}}"
                 width="100">
            <div *ngIf="filePath != '' && !loading && !loadingAvatar"
                 [ngClass]="{'box-shadow-avatar': !!filePath}"
                 [ngStyle]="{backgroundImage: 'url(' + filePath + ')', backgroundSize: 'cover', width: '100px', height: '100px', border: '3px solid rgb(58,134,255)'}"
                 class="float-start center-container rounded-circle">
            </div>
            <app-spinner [loading]="loadingAvatar || loading"
                         [spinnerClasses]="'col-md float-start'"
                         [style]="'width: 100px; height: 100px;'"></app-spinner>
            <div class="p-3">
                <h5 class="truncate-text">
                    <i class="ps-3 fa {{identityReference.visibilityScope | toVisibilityScopeIcon}} secondary-blue me-2"></i>
                    {{ identityReference.did }}
                </h5>
                <div class="col-md-auto align-self-center">
                    <div *ngIf="!!identityReference.introduction"
                         class="truncate-text max-height-50 p-2">{{ shortSummary }}
                    </div>
                    <div *ngIf="!identityReference.introduction"
                         class="greyed-out truncate-text">No introduction written.
                    </div>
                </div>
            </div>
        </div>
        <div class="collapse" [id]="identityIdentifier">
            <div class="container">
                <div class="row p-3" *ngIf="!dataLoaded">
                    <div class="col-xl-6  height-90 mb-3">
                        <lib-loading-placeholder [show]="true"></lib-loading-placeholder>
                    </div>
                    <div class="col-xl-6  height-90 mb-3">
                        <lib-loading-placeholder [show]="true"></lib-loading-placeholder>
                    </div>
                </div>
            </div>
            <div class="container" *ngIf="dataLoaded">
                <div *ngIf="!loading">
                    <div *ngIf="identityReference.introduction"
                         class="m-3 overflow-y-scroll max-height-200 p-2">{{ identityReference.introduction }}
                    </div>
                    <div class="row text-dark">
                        <div class="col-xl-6 mt-4">
                            <div class="container justify-content-center position-relative">
                                <div class="d-flex align-items-center justify-content-end">
                                    <div class="h5-container">
                                        <h5 class="w-100 text-center mt-2 position-absolute start-50 translate-middle">Claims</h5>
                                    </div>
                                    <app-action-button
                                            class="me-1"
                                            [useSpinner]="true"
                                            [loading]="loading"
                                            [disabled]="loading"
                                            [showPrimary]="true"
                                            [btnClass]="'btn-basic-info'"
                                            [primaryIconClass]="'fa-solid fa-bell'"
                                            [attr.data-bs-toggle]="'modal'"
                                            [attr.data-bs-target]="'#'+newRequestIdentifier">
                                    </app-action-button>
                                    <app-action-button
                                            [useSpinner]="true"
                                            [loading]="loading"
                                            [disabled]="loading || noClaimsMade()"
                                            [showPrimary]="true"
                                            [btnClass]="'btn-basic-info'"
                                            [primaryIconClass]="'fa fa-cloud-arrow-down'"
                                            [attr.data-bs-target]="'#'+exportIdentifier"
                                            [attr.data-bs-toggle]="noClaimsMade()? null : 'modal'">
                                    </app-action-button>
                                </div>
                            </div>
                            <app-identity></app-identity>
                        </div>
                        <div class="col-xl-6 mt-4">
                            <div class="container justify-content-center">
                                <div class="align-items-center">
                                    <div class="h5-container">
                                        <h5 class="w-100 text-center mt-3">Your subscriptions</h5>
                                    </div>
                                </div>
                            </div>
                            <app-subscriptions [identity]="this.identity"></app-subscriptions>
                        </div>
                    </div>
                </div>
                <div class="m-4"></div>
            </div>
        </div>
    </div>
</div>

