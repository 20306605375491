import {Component, OnInit} from '@angular/core';
import {HttpErrorResponse} from "@angular/common/http";
import {DataService} from "../common/services/data-storage.service";
import {RedirectService} from "../common/services/redirect-service";
import {IdentityControllerService} from "../common/services";
import {OauthControllerService} from "../common/services/api/oauth-controller.service";
import {GenericButtonComponent} from "../common/generic-button/generic-button.component";


@Component({
    selector: 'app-close-account',
    templateUrl: './close-account.component.html',
    standalone: true,
    imports: [
        GenericButtonComponent
    ],
    styleUrls: ['./close-account.component.scss']
})
export class CloseAccountComponent implements OnInit {

    loading: boolean = false
    closeAccountAccepted: boolean = false
    errorOccurred: boolean = false
    errorMessage: string = ''

    constructor(protected dataService: DataService, private redirectService: RedirectService, private identityControllerService: IdentityControllerService, private oauthControllerService: OauthControllerService) {
    }

    ngOnInit(): void {
    }

    flipAcceptCloseAccount() {
        this.closeAccountAccepted = !this.closeAccountAccepted
    }

    closeAccount() {
        if (this.closeAccountAccepted) {
            this.loading = true
            this.dataService.last<string>('did')
                .subscribe({
                    next: did => {
                        this.identityControllerService.closeAccount(did)
                            .subscribe({
                                next: (response) => {
                                    this.oauthControllerService.signOut(did!)
                                        .subscribe({
                                            next: (response) => {
                                                sessionStorage.clear()
                                                const redirectUri = response.redirectUri
                                                if (!!redirectUri) {
                                                    window.location.href = redirectUri
                                                } else {
                                                    this.redirectService.redirectToGateway()
                                                }
                                                this.loading = false
                                            },
                                            error: (error) => {
                                                this.loading = false
                                                if (error instanceof HttpErrorResponse) {
                                                    this.errorOccurred = true
                                                }
                                            }
                                        });
                                },
                                error: (error) => {
                                    this.loading = false
                                    if (error instanceof HttpErrorResponse) {
                                        this.errorOccurred = true
                                    }
                                }
                            });
                    }
                })
        }
    }
}
