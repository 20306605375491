import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {HttpErrorResponse} from "@angular/common/http";

import {ActionButtonComponent} from "../action-button/action-button.component";
import {ToVisibilityScopeIconPipe} from "../pipes/ToVisibilityScopeIconPipe";
import {ToOppositeVisibilityScopeIconPipe} from "../pipes/ToOppositeClaimAspectIconPipe";
import {FactComponent} from "../fact/fact.component";
import {ToReadableStringPipe} from "../pipes/ToReadableStringPipe";
import {NgForOf, NgIf} from "@angular/common";
import {VisibilityScopeEnum} from '../../../services/api/models/visibility-scope';
import {ErrorMessageService} from "../../../services/error-message-service";
import {CryptographyService} from "../../../services/cryptography.service";
import {DataSubscriptionService} from "../../../services/data-subscription.service";
import {CryptoWalletService} from "../../../services/crypto-wallet.service";
import {CacheService} from "../../../services/cache-service";
import {IpfsService} from "../../../services/api/ipfs.service";
import {SpinnerComponent} from "../spinner/spinner.component";
import {ClaimView} from "../../../services/api/models/claim-reference-view";
import {FactView} from "../../../services/api/models/fact-view";
import {ClaimControllerService} from "../../../services/api/claim-controller.service";
import {IdentityView} from "../../../services/api/models/identity-view";
import {NavigatorService} from "../navigator.service";


@Component({
  selector: 'app-claim',
  templateUrl: './claim.component.html',
  standalone: true,
  imports: [
    ActionButtonComponent,
    ToVisibilityScopeIconPipe,
    ToOppositeVisibilityScopeIconPipe,
    FactComponent,
    ToReadableStringPipe,
    NgForOf,
    NgIf,
    SpinnerComponent
  ],
  styleUrls: ['./claim.component.scss']
})
export class ClaimComponent implements OnInit {
  @Input() claim!: ClaimView
  @Input() loading: boolean = false
  @Input() myProfile: boolean = false
  @Input() identity?: IdentityView
  @Input() inMySubscriptionsOverview: boolean = false
  @Output() deletedSubscriptionId: EventEmitter<string> = new EventEmitter<string>();
  @Output() claimChanged: EventEmitter<string> = new EventEmitter<string>();
  protected fact?: FactView
  protected loadingMap: Map<string, boolean> = new Map<string, boolean>()
  protected deleteClaimClicked: boolean = false
  protected encrypted: boolean = true
  protected errorOccurred: boolean = false
  protected type: string = ''
  protected password?: string;
  protected readonly VisibilityScopeEnum = VisibilityScopeEnum;
  protected showCopyConfirmation: boolean = false;
  
  constructor(private factsService: IpfsService, private navigatorService: NavigatorService,  private dataSubscriptionService: DataSubscriptionService, private cryptoWalletService: CryptoWalletService, private cacheService: CacheService, private claimControllerService: ClaimControllerService) {
  }
  
  ngOnInit(): void {
    this.factsService.getFact(this.claim?.cid).subscribe({
      next: fact => {
        this.fact = fact
      }
    })
  }
  
  protected tryDeleteClaim(claim?: ClaimView) {
    if (claim) {
      this.deleteClaimClicked = true
      this.dataSubscriptionService.publish<ClaimView>('claimToDelete', claim)
      this.navigatorService.navigateTo('/delete-claim', false)
    }
  }
  
  protected alterVisibility(id: string, visibilityScope?: VisibilityScopeEnum) {
    this.loadingMap.set(id + visibilityScope, true)
    this.cacheService.bypassCache = true
    this.claimControllerService.alterClaim([{
      op: "replace",
      path: `${id}/visibilityScope`,
      value: visibilityScope?.toLowerCase()
    }]).subscribe({
      next: (claim) => {
        this.claim.visibilityScope = visibilityScope
        this.loadingMap.set(id + visibilityScope, false)
      },
      error: (error) => {
        this.loadingMap.set(id + visibilityScope, false)
        if (error instanceof HttpErrorResponse) {
          this.errorOccurred = true
        }
      }
    });
  }
  
  protected async encryptDecryptClaim() {
    if (this.myProfile) {
      if (this.encrypted) {
        const decryptedValue = await this.cryptoWalletService.decrypt(this.fact?.value!)
        this.fact = {
          ...this.fact,
          value: decryptedValue
        }
        this.encrypted = false
      } else {
        const encryptedValue = await this.cryptoWalletService.encrypt(this.fact?.value!)
        this.fact = {
          ...this.fact,
          value: encryptedValue
        }
        this.encrypted = true
      }
    } else {
      this.cryptoWalletService.getStored('did')
        .then(async did => {
          if (this.encrypted) {
            const subscription = this.identity!.subscribers?.find(subscription => subscription.subscriber == did && subscription.status == "approved")
            if (subscription) {
              const providedEncryptedAESPrivateKey = subscription.encryptedEncryptionKey
              const myEncryptedRSAPrivateKey = subscription.encryptedSecretSharingPrivateKey
              this.cryptoWalletService.decryptRSAToAESToValue(this.fact?.value, providedEncryptedAESPrivateKey!, myEncryptedRSAPrivateKey!).then(decryptedValue => {
                this.fact = {
                  ...this.fact,
                  value: decryptedValue
                }
                this.encrypted = false
              })
            }
          }
        })
    }
  }
  
}