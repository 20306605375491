import {Component, OnInit} from '@angular/core';
import {SignOutService} from "../../../services/signout-service";
import {DataService} from "../../../services/data.service";


@Component({
  selector: 'app-account',
  templateUrl: './account.component.html',
  styleUrls: ['./account.component.scss']
})
export class AccountComponent {
  
  loading: boolean = false;
  patchLoading: boolean = false
  errorOccurred: boolean = false
  signoutLoading: boolean = false;
  
  constructor(private signoutService: SignOutService, protected dataService: DataService) {
  }
  
  signOut() {
    this.signoutService.signOut()
  }
}
