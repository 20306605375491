import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';

import {FormsModule} from "@angular/forms";
import {ToReadableStringPipe} from "../../common/pipes/ToReadableStringPipe";
import {NgForOf, NgIf} from "@angular/common";
import {ErrorMessageComponent} from "../../common/error-message/error-message.component";
import {GenericMessageComponent} from "../../common/generic-message/generic-message.component";
import {GenericButtonComponent} from "../../common/generic-button/generic-button.component";
import {DataSubscriptionService} from "../../../services/data-subscription.service";
import {CryptographyService} from "../../../services/cryptography.service";
import {SubscriptionView} from "../../../services/api/models/subscription-view";
import {Subscriptions} from "../../../services/api/models/subscription-payload";
import {SubscriptionControllerService} from "../../../services/api/subscription-controller.service";
import {ScopeControllerService} from "../../../services/api/scope-controller.service";
import {ScrollingModule} from "@angular/cdk/scrolling";
import {CryptoWalletService} from "../../../services/crypto-wallet.service";
import {NavigatorService} from "../../common/navigator.service";

@Component({
  selector: 'app-new-subscription',
  templateUrl: './new-subscription.component.html',
  standalone: true,
  imports: [
    FormsModule,
    ToReadableStringPipe,
    NgIf,
    ErrorMessageComponent,
    GenericMessageComponent,
    GenericButtonComponent,
    ScrollingModule,
    NgForOf
  ],
  styleUrls: ['./new-subscription.component.scss']
})
export class NewSubscriptionComponent implements OnInit {
  
  @Output() submittedSubscription: EventEmitter<SubscriptionView> = new EventEmitter<SubscriptionView>()
  protected subscriptionRequest?: SubscriptionView = undefined
  protected shareModal: boolean = false
  protected topicOwner?: string;
  protected subscriber?: string;
  protected scopes: Array<string> = []
  protected filteredScopes: Array<string> = []
  protected password?: string
  protected selectedClaimScopes: string[] = []
  protected showMessage: boolean = false
  protected loading: boolean = false
  protected success: boolean = false
  protected value?: string
  protected message?: string
  protected defaultScopeMessage: string = "Select a scope..."
  protected todayDate: Date = new Date()
  protected startingMoment: Date = new Date();
  protected duration: number = 1
  protected durationUnit: Subscriptions.DurationUnitEnum = Subscriptions.DurationUnitEnum.Days
  protected durations: Subscriptions.DurationUnitEnum[] = [
    Subscriptions.DurationUnitEnum.Hours,
    Subscriptions.DurationUnitEnum.Days,
    Subscriptions.DurationUnitEnum.Months,
    Subscriptions.DurationUnitEnum.Years
  ]
  protected description: string = ''
  protected DurationUnitEnum = Subscriptions.DurationUnitEnum;
  protected readonly Date = Date;
  protected readonly parent = parent;
  
  constructor(protected navigator: NavigatorService, protected dataSubscriptionService: DataSubscriptionService, private cryptoWalletService: CryptoWalletService, private requestCommandControllerService: SubscriptionControllerService) {
  }
  
  ngOnInit(): void {
    this.dataSubscriptionService.onFirst<Array<string>>('scopes').subscribe({
      next: scopes => {
        this.scopes = scopes.sort()
        this.filteredScopes = this.scopes
      }
    })
    if (!this.topicOwner) {
      this.cryptoWalletService.getStored('did').then(did => {
        this.topicOwner = did
      })
    }
    this.cryptoWalletService.getStored('did').then(did => {
      this.subscriber = did
    })
  }
  
  async requestSubscription() {
    this.loading = true
    const rsaKeyPair = await this.cryptoWalletService.generateRSAKeyPair()
    const publicKey = rsaKeyPair.publicKey
    const privateKey = rsaKeyPair.privateKey
    const encryptedSecretSharingPrivateKey = await this.cryptoWalletService.encrypt(privateKey)
    this.requestCommandControllerService.requestSubscription({
        topicOwner: this.topicOwner!,
        subscriber: this.subscriber,
        scopes: this.selectedClaimScopes!,
        startingMoment: this.startingMoment?.toISOString(),
        description: this.description,
        duration: this.duration,
        durationUnit: this.durationUnit,
        secretSharingPublicKey: publicKey,
        encryptedSecretSharingPrivateKey: encryptedSecretSharingPrivateKey
      }
    ).subscribe({
      next: (response) => {
        this.loading = false
        this.message = 'Successfully submitted the subscription'
        this.showMessage = true
        setTimeout(() => {
          this.showMessage = false
        }, 3000)
        this.submittedSubscription.emit(response)
      },
      error: (error) => {
        this.loading = false
      }
    });
  }
  
  setUnit(event: any) {
    this.durationUnit = event.target.value as Subscriptions.DurationUnitEnum
  }
  
  setScope(scope: string) {
    this.selectedClaimScopes.push(scope)
  }
  
  filterScopes($event: any) {
    const searchedValue = $event.target.value;
    if (!searchedValue || searchedValue == "") {
      this.filteredScopes = this.scopes;
    } else {
      this.filteredScopes = this.scopes.filter((scope) =>
        scope.toLowerCase().includes(searchedValue.toLowerCase())
      )
    }
    
  }
  
  setDate($event: any) {
    this.startingMoment = new Date(Date.parse($event))
  }
}
