"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.parseConnectSettings = exports.getEnv = void 0;
const connectSettings_1 = require("@trezor/connect/lib/data/connectSettings");
const getEnv = () => {
    var _a;
    if (typeof chrome !== 'undefined' && typeof ((_a = chrome.runtime) === null || _a === void 0 ? void 0 : _a.onConnect) !== 'undefined') {
        return 'webextension';
    }
    if (typeof navigator !== 'undefined') {
        if (typeof navigator.product === 'string' &&
            navigator.product.toLowerCase() === 'reactnative') {
            return 'react-native';
        }
        const userAgent = navigator.userAgent.toLowerCase();
        if (userAgent.indexOf(' electron/') > -1) {
            return 'electron';
        }
    }
    return 'web';
};
exports.getEnv = getEnv;
const processQueryString = (url, keys) => {
    const searchParams = new URLSearchParams(url);
    const result = {};
    const paramArray = Array.from(searchParams.entries());
    paramArray.forEach(([key, value]) => {
        if (keys.includes(key)) {
            result[key] = decodeURIComponent(value);
        }
    });
    return result;
};
const parseConnectSettings = (input = {}) => {
    var _a;
    const settings = Object.assign({ popup: true }, input);
    let globalSrc;
    if (typeof window !== 'undefined') {
        globalSrc = window.__TREZOR_CONNECT_SRC;
    }
    else if (typeof global !== 'undefined') {
        globalSrc = global.__TREZOR_CONNECT_SRC;
    }
    if (typeof globalSrc === 'string') {
        settings.connectSrc = globalSrc;
        settings.debug = true;
    }
    if (typeof window !== 'undefined' && typeof ((_a = window.location) === null || _a === void 0 ? void 0 : _a.search) === 'string') {
        const query = processQueryString(window.location.search, ['trezor-connect-src']);
        if (query['trezor-connect-src']) {
            settings.debug = true;
            settings.connectSrc = query['trezor-connect-src'];
        }
    }
    if (typeof input.env !== 'string') {
        settings.env = (0, exports.getEnv)();
    }
    return (0, connectSettings_1.parseConnectSettings)(settings);
};
exports.parseConnectSettings = parseConnectSettings;
