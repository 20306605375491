import {Pipe, PipeTransform} from '@angular/core';

@Pipe({
    name: 'toClaimScopeIcon',
    standalone: true
})
export class ToClaimScopeIconPipe implements PipeTransform {
    constructor() {
    }

    public transform(value?: string): any {
        switch (value) {
            case "commercial_contact_data":
                return 'fa-building'
            case "commercial_financial_data":
                return 'fa-money'
            case "private_contact_data":
                return 'fa-home'
            case "private_financial_data":
                return 'fa-money'
            case "private_identity_data":
                return 'fa-fingerprint'
            case "professional_data":
                return 'fa-suitcase'
            case "personality_data":
                return 'fa-identity'
            case "physical_data":
                return 'fa-heart'
            case "health_data":
                return 'fa-file-medical'
            case "possessions_data":
                return 'fa-crown'
            case "relationship_data":
                return 'fa-handshake'
            default:
                return 'fa-fingerprint'
        }
    }
}