import {DataService} from "../../services/data.service";
import {RedirectService} from "../../services/redirect-service";
import {Component} from "@angular/core";

const nonAuthorizedPaths = ['sign-in', 'sign-up', 'consent', 'error'];
@Component({template: ``})
export abstract class AuthorisedComponent {

    protected constructor(protected dataService: DataService, redirectService: RedirectService) {
        if (!nonAuthorizedPaths.some(path => window.location.href.includes(path))) {
            if (!dataService.has('jwt')) {
                this.dataService.publish('showMenu', false)
                redirectService.redirectToSignInPage()
            } else {
                this.dataService.publish('showMenu', true)
            }
        }

    }
}