import {Pipe, PipeTransform} from '@angular/core';

@Pipe({
  name: 'ToScopeExplanation',
  standalone: true
})
export class ToScopeExplanationPipe implements PipeTransform {
  constructor() {
  }
  
  public transform(value?: string): string {
    console.log(value);
    if (value == undefined)
      return 'No further details'
    switch (value.toLowerCase()) {
      case 'idin': return 'Sign-in at your bank and let your bank provide the known claims'
      default: return 'No further details'
    }
  }
}