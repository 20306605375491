import {Component, EventEmitter, OnInit, Output} from '@angular/core';

import {SafeResourceUrl} from "@angular/platform-browser";
import * as uuid from "uuid";
import {ClaimView, IdentityView} from "../../services/";
import {DataService} from "../../services/data.service";
import {AuthorisedComponent} from "../common/authorised-component";
import {RedirectService} from "../../services/redirect-service";


@Component({
  selector: 'app-my-identity',
  templateUrl: './my-identity.component.html',
  styleUrls: ['./my-identity.component.scss']
})
export class MyIdentityComponent extends AuthorisedComponent implements OnInit {
  
  @Output() claimViewEmitter: EventEmitter<ClaimView> = new EventEmitter<ClaimView>()
  protected myProfileId: string = 'my-identity-reference-' + uuid.v4()
  protected loading: boolean = !this.dataService.has('identity')
  protected did?: string = undefined
  protected queryParams?: any = undefined
  protected loadingAvatar: boolean = false
  protected errorOccurred: boolean = false
  protected errorMessage: string = ''
  protected flipOpenForRequestsLoading: boolean = false
  protected imageToUpload?: Promise<ArrayBuffer> = undefined
  protected imagePath?: SafeResourceUrl = undefined
  protected identity?: IdentityView
  
  constructor(dataService: DataService, redirectService: RedirectService) {
    super(dataService, redirectService)
  }
  
  ngOnInit() {
    this.dataService.continuousChanges<boolean>('dataLoading').subscribe(currentLoadingState => {
      this.loading = currentLoadingState
    })
    this.dataService.continuousChanges<IdentityView>('identity').subscribe(identity => {
      this.identity = identity;
    })
  }
  
  //
  // protected removeRequest($event: SubscriptionView) {
  //     const currentIdentity = this.dataService.last<IdentityView>('identity') as IdentityView
  //     currentIdentity?.subscriptions!.forEach((element, index) => {
  //         if (currentIdentity?.subscriptions![index].id == $event.id) {
  //             currentIdentity?.subscriptions!.splice(index, 1);
  //         }
  //     });
  //     this.dataService.publish('identity', currentIdentity)
  // }
  
  // protected updateRequest($event: SubscriptionView) {
  //     const currentIdentity = this.dataService.last<IdentityView>('identity') as IdentityView
  //     currentIdentity?.subscriptions!.forEach((element, index) => {
  //         if (currentIdentity?.subscriptions![index].id == $event.id) {
  //             currentIdentity!.subscriptions![index] = $event
  //         }
  //     });
  //     this.dataService.publish('identity', currentIdentity)
  // }
  
  // protected updateMyClaims(claimView: ClaimView) {
  //     const currentIdentity = this.dataService.last<IdentityView>('identity') as IdentityView
  //     currentIdentity?.claims?.push(claimView)
  //     this.dataService.publish('identity', currentIdentity)
  // }
  
}
