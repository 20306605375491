import {Component, OnInit} from '@angular/core';

import {Router} from '@angular/router';
import {DataSubscriptionService} from "../../services/data-subscription.service";
import {CryptoWalletService} from "../../services/crypto-wallet.service";
import {environment} from "../../environments/environment";
import {LogoComponent} from "../common/logo/logo.component";
import {FormsModule} from "@angular/forms";
import {GenericButtonComponent} from "../common/generic-button/generic-button.component";
import {ErrorMessageComponent} from "../common/error-message/error-message.component";
import {NgClass, NgIf} from "@angular/common";
import {SpinnerComponent} from "../common/spinner/spinner.component";
import {PushNotifications, PushNotificationSchema} from "@capacitor/push-notifications";
import {Device} from "@capacitor/device";
import {IdentityControllerService} from "../../services/api/identity-controller.service";
import {SignOutService} from "../../services/signout-service";
import {DataStorageService} from "../../services/data-storage.service";
import {oneHour} from "../../app.config";
import {Attenuation} from "../../models/app.models";
import {hideSplashScreen, showSplashScreen} from "../../framework";
import {IdentityView} from "../../services/api/models/identity-view";
import {ErrorMessageService} from "../../services/error-message-service";
import {BootstrapService} from "../../services/bootstrap.service";


@Component({
  selector: 'app-sign-in',
  templateUrl: './sign-in.component.html',
  standalone: true,
  imports: [
    LogoComponent,
    FormsModule,
    GenericButtonComponent,
    ErrorMessageComponent,
    NgIf,
    SpinnerComponent,
    NgClass
  ],
  styleUrls: ['./sign-in.component.scss']
})
export class SignInComponent implements OnInit {
  protected email?: string = undefined;
  protected code?: string = undefined;
  protected loading: boolean = false;
  protected showSignIn: boolean = false;
  protected showOptions: boolean = false;
  protected twoStepAuthenticationEnabled: boolean = false;
  protected method: string = ''
  
  constructor(protected errorMessageService: ErrorMessageService, private router: Router, private boostrapService: BootstrapService, private dataSubscriptionService: DataSubscriptionService, private cryptoWalletService: CryptoWalletService, private sqLiteService: DataStorageService, private identityCommandController: IdentityControllerService) {
  }
  
  ngOnInit(): void {
    this.cryptoWalletService.hasStored('email').then(hasEmail => {
      if (hasEmail) {
        Promise.all([this.sqLiteService.getValue<IdentityView>('identity'), this.cryptoWalletService.hasStored('jwt')])
          .then((identityJwt) => {
            if (identityJwt.at(0) && identityJwt.at(1)) {
              this.boostrapService.init().then(() => {
                this.dataSubscriptionService.publish('dataLoading', false)
                this.dataSubscriptionService.publish('showMenu', true)
                this.router.navigate(['/my-identity'])
              })
            } else {
              this.cryptoWalletService.getStored('email').then(email => {
                  this.createAuthenticationJWT(email!)
                }
              )
            }
          })
      } else {
        hideSplashScreen()
        this.showSignIn = true
      }
    })
  }
  
  protected async process() {
    showSplashScreen()
    console.log("Checking JWT...");
    if (this.email == undefined || !this.isValidEmail(this.email)) {
      throw Error('Please enter a valid email');
    }
    const email = await this.cryptoWalletService.store('email', this.email!)
    this.createAuthenticationJWT(email)
  }
  
  isValidEmail = (email?: string) => {
    return String(email)
      .toLowerCase()
      .match(
        /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|.(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
      );
  };
  
  async registerDeviceForPushNotifications() {
    console.log(`Registering device for notifications (${environment.platform})...`);
    const device = await Device.getInfo()
    const deviceId = await Device.getId()
    const platform = device.platform
    const osVersion = device.osVersion
    const did = await this.cryptoWalletService.getStored('did')
    if (environment.platform != 'web') {
      this.dataSubscriptionService.publish<Array<PushNotificationSchema>>('notifications', [])
      await registerNotifications()
      await PushNotifications.addListener('registration', async token => {
        this.identityCommandController.registerAgent(did!, deviceId.identifier, platform, device.operatingSystem, osVersion, token.value).subscribe({
          error: error => {
            this.errorMessageService.setMessage('Could not register user agent')
            this.loading = false
          },
          next: () => {
            console.log("Native agent registered")
          }
        })
      })
      
      await PushNotifications.addListener('registrationError', err => {
        console.error('Registration error: ', err.error);
      });
      await PushNotifications.addListener('pushNotificationActionPerformed', notification => {
        console.log('Push notification action performed', notification.actionId, notification.inputValue);
      });
      
      await PushNotifications.addListener('pushNotificationReceived', notification => {
        this.dataSubscriptionService.onFirst<Array<PushNotificationSchema>>('notification').subscribe(currentNotifications => {
          currentNotifications.push(notification);
          this.dataSubscriptionService.publish<Array<PushNotificationSchema>>('notifications', currentNotifications)
        })
      });
    } else {
      const email = await this.cryptoWalletService.getStored('email')
      this.identityCommandController.registerAgent(did!, deviceId.identifier, device.platform, device.operatingSystem, device.osVersion, email!).subscribe({
        error: error => {
          this.loading = false
        },
        next: () => {
          console.log("Web agent registered")
        }
      })
    }
  }
  
  
  private async setupJWT(): Promise<string> {
    console.log("Setting up JWT...");
    this.loading = true
    const doatoaDID = environment.did
    const did = await this.cryptoWalletService.getStored('did')
    const header = {
      "alg": "EdDSA",
      "typ": "JWT"
    }
    const base64Header = btoa(JSON.stringify(header))
    const payload = {
      iss: did,
      aud: doatoaDID,
      exp: Date.now() + oneHour,
      att: [
        {
          with: environment.idpApiBaseUrl,
          can: "create-account",
        }
      ]
    }
    const base64Payload = btoa(JSON.stringify(payload))
    return await this.cryptoWalletService.sign(`${base64Header}.${base64Payload}`, 'doatoa').then(async signatureResult => {
      const signature = signatureResult.signature
      const completeJwt = `${base64Header}.${base64Payload}.${signature}`
      await this.cryptoWalletService.store('jwt', completeJwt)
      return completeJwt
    })
  }
  
  private createAuthenticationJWT(email: string) {
    this.identityCommandController.findMetadata(undefined, email).subscribe({
      error: error => {
        this.loading = false
      },
      next: async metadataView => {
        const did = metadataView.did
        const hasKeystore = await this.cryptoWalletService.hasStored(email)
        if (did == undefined || !hasKeystore) {
          await this.router.navigate(['/sign-up'])
        } else {
          const jwt = await this.createJwtUsing(did, [
            {
              with: environment.idpApiBaseUrl,
              can: "read",
            }, {
              with: environment.idpApiBaseUrl,
              can: "write",
            }
          ])
          await this.cryptoWalletService.store('jwt', jwt)
          console.log("JWT created successfully.");
          await this.registerDeviceForPushNotifications()
          this.boostrapService.init().then(() => {
            this.dataSubscriptionService.publish('dataLoading', false)
            this.dataSubscriptionService.publish('showMenu', true)
            this.router.navigate(['/my-identity'])
          })
        }
      }
    })
  }
  
  private async createJwtUsing(did: string, att: Attenuation[]): Promise<string> {
    await this.cryptoWalletService.store('did', did)
    console.log("Creating JWT...");
    const doatoaDID = environment.did
    const header = {
      "alg": "EdDSA",
      "typ": "JWT"
    }
    const base64Header = btoa(JSON.stringify(header))
    const payload = {
      iss: did,
      aud: doatoaDID,
      exp: Date.now() + oneHour,
      att: att
    }
    const base64Payload = btoa(JSON.stringify(payload))
    return await this.cryptoWalletService.sign(`${base64Header}.${base64Payload}`, 'doatoa').then(async signatureResult => {
      const signature = signatureResult.signature
      return `${base64Header}.${base64Payload}.${signature}`
    })
  }
}

const registerNotifications = async () => {
  let permStatus = await PushNotifications.checkPermissions();
  
  if (permStatus.receive === 'prompt') {
    permStatus = await PushNotifications.requestPermissions();
  }
  
  if (permStatus.receive !== 'granted') {
    throw new Error('User denied permissions!');
  }
  
  await PushNotifications.register();
}

