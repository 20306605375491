"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.createResponseMessage = exports.RESPONSE_EVENT = void 0;
const errors_1 = require("../constants/errors");
exports.RESPONSE_EVENT = 'RESPONSE_EVENT';
const createResponseMessage = (id, success, payload) => ({
    event: exports.RESPONSE_EVENT,
    type: exports.RESPONSE_EVENT,
    id,
    success,
    payload: success ? payload : (0, errors_1.serializeError)(payload),
});
exports.createResponseMessage = createResponseMessage;
