<div *ngIf="showSignIn" class="d-flex flex-column align-items-center justify-content-start">
    <div class="d-flex flex-column align-items-center justify-content-center max-width-300 mt-5">
        <app-logo class="m-3"></app-logo>
        <div class="col-auto text-center max-width-400 p-3">
            <div class="mb-2">
                <input [(ngModel)]="email"
                       [ngClass]="{ 'is-valid': isValidEmail(email) }"
                       id="email"
                       name="email"
                       class="form-control rounded-5 height-40"
                       placeholder="E-mail address"
                       type="email"
                       autocapitalize="off"
                       autocomplete="new-email">
            </div>
            <div class="mb-2">
                <app-generic-button
                        id="sign-in"
                        (click)="method = 'doatoa'; process()"
                        [type]="'submit'"
                        [disabled]="loading || !email || !isValidEmail(email)"
                        [loading]="loading"
                        [buttonText]="'Next'"
                        [btnClass]="'btn-regular'"
                        [primaryIcon]="'fa-solid fa-right-to-bracket'">
                </app-generic-button>
            </div>
        </div>

        <ng-container *ngIf="loading && !errorMessageService.errorHasOccurred()">
            <app-spinner [loading]="true"
                         class="m-auto mt-5"
                         [style]="'width: 183px; height: 183px;'"></app-spinner>
            <div class="mt-3">Signing in...</div>
        </ng-container>

        <app-error-message></app-error-message>
    </div>
</div>