import {HttpClient, HttpHeaders, HttpParameterCodec} from "@angular/common/http";
import {Configuration} from "../configuration";
import {Inject, Injectable, Optional} from "@angular/core";
import {BASE_PATH} from "../variables";
import {CustomHttpParameterCodec} from "../encoder";
import {environment} from "../../environments/environment";

@Injectable({
    providedIn: 'root'
})
export abstract class AbstractHttpService {

    protected defaultHeaders = new HttpHeaders();
    protected configuration = new Configuration();
    protected encoder: HttpParameterCodec;
    protected basePath = environment.idpApiBaseUrl;

    constructor(protected httpClient: HttpClient, @Optional() @Inject(BASE_PATH) basePath: string | string[], @Optional() configuration: Configuration) {
        if (configuration) {
            this.configuration = configuration;
        }
        if (typeof this.configuration.basePath !== 'string') {
            if (Array.isArray(basePath) && basePath.length > 0) {
                basePath = basePath[0];
            }

            if (typeof basePath !== 'string') {
                basePath = this.basePath;
            }
            this.configuration.basePath = basePath;
        }
        this.encoder = this.configuration.encoder || new CustomHttpParameterCodec();
    }


}