import {Component, OnInit} from '@angular/core';
import {DataSubscriptionService} from "../../../services/data-subscription.service";
import {IdentityView} from "../../../services/api/models/identity-view";
import {SubscriptionView} from "../../../services/api/models/subscription-view";
import {NgForOf, NgIf} from "@angular/common";
import {ToStatusClassPipe} from "../../common/pipes/ToStatusClassPipe";
import {SubscriptionComponent} from "../../common/subscription/subscription.component";
import {VisibilityScopeEnum} from "../../../services/api/models/visibility-scope";

@Component({
  selector: 'app-subscribers',
  templateUrl: './subscribers.component.html',
  standalone: true,
  imports: [
    NgForOf,
    ToStatusClassPipe,
    SubscriptionComponent,
    NgIf
  ],
  styleUrl: './subscribers.component.scss'
})
export class SubscribersComponent implements OnInit {
  protected errorOccurred: boolean = false
  protected requestToEdit: SubscriptionView = {}
  protected open: Map<string, boolean> = new Map<string, boolean>()
  protected loading: boolean = false;
  protected showMessage: boolean = false;
  protected message: string = '';
  protected identity?: IdentityView
  
  constructor(private dataSubscriptionService: DataSubscriptionService) {
  }
  
  ngOnInit(): void {
    this.dataSubscriptionService.onNext<boolean>('dataLoading').subscribe(dataLoading => {
      this.loading = dataLoading
    })
    this.dataSubscriptionService.onNext<IdentityView>('identity').subscribe(identity => {
      this.identity = identity
    })
  }
  
  protected resolveClass(index: number, request: SubscriptionView): string {
    if (request.status == 'pending') {
      return 'btn-basic-notify'
    }
    return index % 2 == 0 ? 'btn-basic-secondary-dark' : 'btn-basic-secondary';
  }
  
  protected openOrClose(id: string) {
    this.open.set(id, !this.open.get(id) || false)
  }
  
  protected delete($event: SubscriptionView) {
    this.dataSubscriptionService.onFirst<IdentityView>('identity').subscribe({
      next: identity => {
        identity!.subscribers!.forEach((element, index) => {
          if (identity!.subscribers![index].id == $event.id) identity!.subscribers!.splice(index, 1);
          this.message = 'Request deleted'
          this.showMessage = true
        });
        this.identity = identity;
        this.dataSubscriptionService.publish('identity', identity)
      }
    })
  }
  
  protected update($event: SubscriptionView) {
    this.dataSubscriptionService.onFirst<IdentityView>('identity').subscribe({
      next: identity => {
        identity!.subscribers!.forEach((element, index) => {
          if (identity!.subscribers![index].id == $event.id) {
            identity!.subscribers![index] = $event
          }
          this.message = 'Subscription updated'
          this.showMessage = true
        });
        this.identity = identity;
        this.dataSubscriptionService.publish('identity', identity)
      }
    })
  }
  
  protected readonly VisibilityScopeEnum = VisibilityScopeEnum;
}
