import {Injectable} from "@angular/core";

import {DataService} from "./data.service";
import {randomString} from "./random-strings";
import {PKCEService} from "./pkce.service";
import {Router} from "@angular/router";
import {environment} from "../environments/environment";


@Injectable({
  providedIn: 'root'
})
export class RedirectService {
  
  constructor(private pkceService: PKCEService, private dataService: DataService, private router: Router) {
  }
  
  async generateQueryParamsForAuthClient(redirectUri?: string): Promise<URLSearchParams> {
    let codeChallenge = await this.pkceService.createCodeChallenge();
    this.dataService.publish('code_verifier', codeChallenge.code_verifier, true)
    if (redirectUri == undefined) {
      redirectUri = encodeURIComponent(environment.webappBaseUrl + '/#/oauth-landing')
    }
    return new URLSearchParams({
      state: randomString(25),
      grant_type: 'authorization_code',
      client_id: environment.webappClientId,
      redirect_uri: redirectUri,
      nonce: randomString(25),
      scope: "api,openid",
      response_type: "code, id_token",
      response_mode: 'query',
      code_challenge: codeChallenge.code_challenge,
      code_challenge_method: 'S265'
    });
  }
  
  generateRedirectUri(): string {
    // return environment.webappBaseUrl + `/#/sign-in/?${queryParams.toString()}`
    return environment.webappBaseUrl + '/#/sign-in/'
  }
  
  redirectToSignInPage() {
    window.location.href = this.generateRedirectUri()
  }
  
  redirectToGateway() {
    window.location.href = "https://doatoa.com"
  }
  
}
