import {Component} from '@angular/core';
import {LogoComponent} from "../../common/logo/logo.component";
import {LandingComponent} from "../landing/landing.component";

@Component({
  selector: 'app-maintenance',
  templateUrl: './maintenance.component.html',
  standalone: true,
  imports: [
    LogoComponent,
    LandingComponent
  ],
  styleUrl: './maintenance.component.scss'
})
export class MaintenanceComponent {

}
