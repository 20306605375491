import {Component, OnInit} from '@angular/core';
import {Router} from "@angular/router";
import {DataSubscriptionService} from "../../../services/data-subscription.service";
import {IdentityControllerService} from "../../../services/api/identity-controller.service";
import {LogoComponent} from "../../common/logo/logo.component";
import {SpinnerComponent} from "../../common/spinner/spinner.component";
import * as uuid from 'uuid';
import {LandingComponent} from "../landing/landing.component";

@Component({
  selector: 'app-start-verification',
  standalone: true,
  imports: [
    LogoComponent,
    SpinnerComponent,
    LandingComponent
  ],
  templateUrl: './start-verification.component.html',
  styleUrl: './start-verification.component.scss'
})
export class StartVerificationComponent implements OnInit {
  
  protected classes: string = ''
  protected errorMessage: string = ''
  protected errorOccurred: boolean = false
  protected scopes: Array<string> = []
  protected scope?: string = undefined
  
  constructor(private router: Router, private dataSubscriptionService: DataSubscriptionService, private identityControllerService: IdentityControllerService) {
  }
  
  ngOnInit(): void {
    this.dataSubscriptionService.onFirst<string>('chosenVerificationMethod').subscribe({
      next: chosenVerificationMethod => {
        const correlationId = uuid.v4()
        this.dataSubscriptionService.publish<string>('correlationId', correlationId)
        this.identityControllerService.initiateVerificationProcessPayment(correlationId!, chosenVerificationMethod!).subscribe({
          next: actionView => {
            console.log("Verification request initiated", actionView);
            window.location.href = actionView.metadata.redirectUrl!
          },
          error: error => {
            this.router.navigate(['my-identity'])
          }
        })
      }
    })
  }
  
}
