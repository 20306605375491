<div class="m-3 card p-3">
    <div class="">
        <h5>Request a subscription</h5>
        <button aria-label="Close"
                class="btn-close float-end m-1"
                (click)="navigator.navigateTo('/my-claims', true)">
        </button>
    </div>
    <div class="">
        <div class="mb-3">
            <div class=""
                 id="request-{{selectedClaimScopes}}">
                <div class="container justify-content-center">
                    <form class="row">
                        <div class="p-2">
                            <div class="mb-3">
                                <label *ngIf="shareModal" for="subscriber">Subscriber</label>
                                <input [(ngModel)]="subscriber" *ngIf="shareModal"
                                       class="form-control rounded-5 mb-1"
                                       datatype="text"
                                       id="subscriber"
                                       name="subscriber"
                                       autocapitalize="off"
                                       autocomplete="off"
                                       type="text">
                                <div>Scopes
                                </div>
                                <div class="mb-2">
                                    <div class="input-group position-relative">
                                        <input (input)="filterScopes($event)"
                                               class="form-control m-auto"
                                               placeholder="Search"
                                               type="search"
                                               style="padding-right: 30px;">
                                        <i class="fa-solid fa-magnifying-glass"
                                           style="position: absolute; right: 10px; top: 50%; transform: translateY(-50%); pointer-events: none;"></i>
                                    </div>
                                </div>
                                <div id="subscription-scopes" class="height-220 overflow-y-auto no-scroll-bar m-3">
                                    <div *ngFor="let scope of filteredScopes; index as i"
                                         class="pointer pointer-hover selectable-scope"
                                         (click)="setScope(scope)">
                                        <div class="p-2">{{ scope | toReadableString }}</div>
                                    </div>
                                </div>
                                <div *ngFor="let scope of selectedClaimScopes"
                                     (click)="selectedClaimScopes.pop()"
                                     class="pointer color-secondary-blue m-3">
                                    {{ scope | toReadableString }}
                                </div>
                                <label for="duration">Duration</label>
                                <select (click)="setUnit($event)"
                                        id="#unit"
                                        class="rounded-5 col form-select mb-3"
                                        name="unit">
                                    <option
                                            value="hours">Hours
                                    </option>
                                    <option
                                            selected="selected"
                                            value="days">Days
                                    </option>
                                    <option
                                            value="months">Months
                                    </option>
                                    <option
                                            value="years">Years
                                    </option>
                                </select>
                                <input [(ngModel)]="duration"
                                       class="form-control rounded-5 mb-1"
                                       datatype="duration"
                                       id="duration"
                                       name="duration"
                                       min="1"
                                       max="365"
                                       type="number">
                            </div>
                            <div class="mb-3">
                                <label for="startingMoment">Starting moment (optional, default is today)</label>
                                <input [(ngModel)]="startingMoment"
                                       class="form-control rounded-5"
                                       datatype="startingMoment"
                                       id="startingMoment"
                                       name="startingMoment"
                                       [min]="todayDate"
                                       (ngModelChange)="setDate($event)"
                                       value="{{startingMoment}}"
                                       type="date">
                            </div>
                            <div class="mb-3">
                                <label for="description">Description</label>
                                <textarea [(ngModel)]="description"
                                          class="form-control rounded-1"
                                          datatype="text"
                                          id="description"
                                          name="description"
                                          placeholder="{{subscriptionRequest?.description || 'Enter description (optional)'}}"
                                          type="text"></textarea>
                            </div>
                        </div>
                    </form>
                </div>
            </div>
        </div>
    </div>
    <div class="row justify-content-center">
        <app-generic-button (click)="requestSubscription()"
                            class="m-3"
                            *ngIf="!subscriptionRequest && !showMessage"
                            [loading]="loading"
                            [disabled]="loading || selectedClaimScopes == undefined"
                            [primaryIcon]="'fa fa-check'"
                            [buttonText]="'Submit'"
                            [btnClass]="'btn btn-regular'"></app-generic-button>
        <lib-generic-message [show]="showMessage" [message]="message!"></lib-generic-message>
        <app-error-message></app-error-message>
    </div>
</div>