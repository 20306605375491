<div class="">
    <div *ngIf="identity!.subscriptions!.length > 0" class="w-100">
        <div class="p-3">Active subscriptions</div>
        <div *ngFor="let subscription of identity?.subscriptions; index as i"
             class=" justify-content-center">
            <div class="row text-dark">
                <div (click)="openOrClose(subscription.id!)"
                     [attr.aria-controls]="'subscription-'+subscription.id"
                     [attr.data-bs-target]="'#subscription-'+subscription.id"
                     aria-expanded="false"
                     data-bs-toggle="collapse"
                     class="p-0 mb-3 btn btn-regular w-100">
                    <div class="ps-3 d-flex align-items-center justify-content-between height-40">
                        <div class="d-flex align-items-center truncate-text">
                            <span class=""><i class="ms-2 me-2 color-secondary-blue {{subscription | toStatusClass}} fa-xl"></i></span>
                            <span class="truncate-text">By {{ subscription.subscriber }}</span>
                        </div>
                        <div class="pe-3 d-flex align-items-center justify-content-center height-40">
                            <i *ngIf="open.get(subscription.id!) || false" class="fa fa-chevron-up"></i>
                            <i *ngIf="!open.get(subscription.id!) || false" class="fa fa-chevron-down"></i>
                        </div>
                    </div>
                </div>
            </div>
            <div class="collapse"
                 id="subscription-{{subscription.id}}">
                <app-subscription [subscription]="subscription"
                                  (deleted)="removeSubscription($event)"
                                  [addressedToMe]="false"></app-subscription>
            </div>
        </div>
    </div>
    <div *ngIf="identity?.subscriptions?.length == 0" class="text-center m-3 p-3 d-flex justify-content-center">
        You have no subscriptions
    </div>
</div>
