/**
 * DoaToa
 * Manage your data and your digital identities, your data your choice.
 *
 * The version of the OpenAPI document: 1.0.0-alpha
 * Contact: info@compilit.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import {Injectable} from '@angular/core';
import {Observable} from 'rxjs';

import {ClaimView} from '../model/claim-reference-view';
import {AbstractHttpService} from "./abstract-http-service";
import {JsonPatchPayload} from "../model/json-patch-payload";
import {SignedData} from "../model/signed-data";
import {ClaimPayload} from "../model/claim-payload";
import {ClaimQueryPayload} from "../model/claim-query-payload";
import {ClaimSupport} from "../model/claim-support";

@Injectable({
    providedIn: 'root'
})
export class ClaimControllerService extends AbstractHttpService {

    public viewClaims<T>(owner?: string, claimId?: string, scope: string = "profile", pageNumber?: number, pageSize?: number, sortBy?: string, accept?: string): Observable<T> {

        let localVarHeaders = this.defaultHeaders;
        localVarHeaders = localVarHeaders.append('Content-Type', 'application/json')
        localVarHeaders = localVarHeaders.append('Accept', accept || 'application/json');

        let localVarPath = `/identity/claims/`
        const scopes = scope !== undefined ? [scope] : [];
        const claimQueryPayload: ClaimQueryPayload = {
            owner: owner,
            scopes: scopes,
            claimId: claimId,
            pageNumber: pageNumber,
            pageSize: pageSize,
            sortBy: sortBy,
        }
        return this.httpClient.request<T>('post', `${this.configuration.basePath}${localVarPath}`,
            {
                body: claimQueryPayload,
                withCredentials: this.configuration.withCredentials,
                headers: localVarHeaders
            }
        );
    }

    public getClaimMetadata(claimScopes?: string[], type?: string): Observable<Array<string>> {
        let localVarHeaders = this.defaultHeaders;
        localVarHeaders = localVarHeaders.append('Content-Type', 'application/json')
        localVarHeaders = localVarHeaders.append('Accept', 'application/json')
        if (type != null) {
            localVarHeaders = localVarHeaders.set('Accept', 'application/vnd.doatoa.metadata-scopes+json');
        }
        if (claimScopes != null) {
            localVarHeaders = localVarHeaders.set('Accept', 'application/vnd.doatoa.metadata-claim-types+json');
        }

        let localVarPath = `/identities/claims/metadata/`;
        if (type != null) {
            localVarPath = localVarPath + `?type=${type}`;
        }
        if (claimScopes != null) {
            localVarPath = localVarPath + `?scopes=${claimScopes}`
        }
        return this.httpClient.request<Array<string>>('get', `${this.configuration.basePath}${localVarPath}`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: localVarHeaders
            }
        );
    }

    public deleteClaim(claimId: string): Observable<any> {
        if (claimId === null || claimId === undefined) {
            throw new Error('Required parameter claimId was null or undefined when calling deleteClaim.');
        }
        let localVarHeaders = this.defaultHeaders;
        localVarHeaders = localVarHeaders.append('Content-Type', 'application/json')
        localVarHeaders = localVarHeaders.append('Accept', 'application/json')

        let localVarPath = `/identity/claim/`;
        return this.httpClient.request<any>('delete', `${this.configuration.basePath}${localVarPath}`,
            {
                body: {
                    claimId: claimId,
                },
                withCredentials: this.configuration.withCredentials,
                headers: localVarHeaders
            }
        );
    }

    public makeClaim(signedClaimPayloads: SignedData<ClaimPayload>): Observable<ClaimView> {
        let localVarHeaders = this.defaultHeaders;
        localVarHeaders = localVarHeaders.append('Content-Type', 'application/json')
        localVarHeaders = localVarHeaders.append('Accept', 'application/json')
        let localVarPath = `/identity/claim/`;
        return this.httpClient.request<ClaimView>('post', `${this.configuration.basePath}${localVarPath}`,
            {
                body: signedClaimPayloads,
                withCredentials: this.configuration.withCredentials,
                headers: localVarHeaders
            }
        );
    }

    public alterClaim(patchPayloads: Array<JsonPatchPayload>): Observable<ClaimView> {
        let localVarHeaders = this.defaultHeaders;
        localVarHeaders = localVarHeaders.append('Content-Type', 'application/json')
        localVarHeaders = localVarHeaders.append('Accept', 'application/json')
        let localVarPath = `/identity/claim/`;
        return this.httpClient.request<ClaimView>('patch', `${this.configuration.basePath}${localVarPath}`,
            {
                body: patchPayloads,
                withCredentials: this.configuration.withCredentials,
                headers: localVarHeaders
            }
        );
    }


    supportClaim(claimSupport: ClaimSupport): Observable<ClaimSupport> {
        let localVarHeaders = this.defaultHeaders;
        localVarHeaders = localVarHeaders.append('Content-Type', 'application/json')
        localVarHeaders = localVarHeaders.append('Accept', 'application/json')
        let localVarPath = `/identity/claims/support/`;
        return this.httpClient.request<ClaimSupport>('post', `${this.configuration.basePath}${localVarPath}`,
            {
                body: claimSupport,
                withCredentials: this.configuration.withCredentials,
                headers: localVarHeaders
            }
        );
    }
}
