import {Observable} from "rxjs";
import {DoaToaHttpService} from "./doa-toa-http.service";
import {Injectable} from "@angular/core";
import {environment} from "../../environments/environment";

@Injectable({
  providedIn: 'root'
})
export class ScopeControllerService {
  
  constructor(private client: DoaToaHttpService) {
  }
  
  public getClaimScopes(parentScopes: string[] = [], aspects: string[] = []): Observable<Array<string>> {
    let headers: Map<string, string> = new Map<string, string>();
    headers.set('Accept', 'application/json')
    headers.set('Content-Type', 'application/json')
    let localVarPath = `/identities/claims/scopes/`;
    return this.client.post<Array<string>>(`${environment.idpApiBaseUrl}${localVarPath}`, { parentScopes: parentScopes, aspects: aspects}, headers)
  }
  
}