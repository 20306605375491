import {Injectable} from "@angular/core";
import * as bip39 from '@scure/bip39';
import bs58check from 'bs58check'
import CryptoJS from "crypto-js";
import bs58 from "bs58";
import {bytesToHex, hexToBytes} from "@noble/curves/abstract/utils";
import {secp256k1} from "@noble/curves/secp256k1";


@Injectable({
    providedIn: 'root'
})
export class CryptographyService {

    private decoder: TextDecoder = new TextDecoder()

    constructor() {
    }


    async deriveAESKeyFromMnemonicPhrase(mnemonic: string) {
        // 1. Derive the seed from the mnemonic
        const seed = await bip39.mnemonicToSeed(mnemonic);

        // 2. Hash the seed using SHA-256
        const hashBuffer = await crypto.subtle.digest('SHA-256', seed);

        // 3. Import the hashed seed as an AES key
        const key = await crypto.subtle.importKey(
            'raw', // format of the key
            hashBuffer, // the hashed seed
            {name: 'AES-GCM'}, // algorithm identifier
            true, // set to true to allow exporting the key
            ['encrypt', 'decrypt'] // allowed usages of the key
        );

        // 4. Export the AES key to a raw format
        return await crypto.subtle.exportKey('raw', key);
    }

    async generateAESKey(): Promise<CryptoKey> {
        return await window.crypto.subtle.generateKey(
            {
                name: "AES-GCM",
                length: 256,
            },
            true,
            ["encrypt", "decrypt"]
        );
    }

    async toAESKey(privateKeyBytes: ArrayBuffer): Promise<CryptoKey> {
        return crypto.subtle.importKey(
            'raw',
            privateKeyBytes,
            {
                name: "AES-GCM",
                length: 256,
            },
            true,
            ['encrypt', 'decrypt']
        );
    }

    async encryptAES(value: string, key: CryptoKey): Promise<string> {
        const encoder = new TextEncoder();
        const data = encoder.encode(value);
        const salt = crypto.getRandomValues(new Uint8Array(16)); // Generate a random salt
        const iv = crypto.getRandomValues(new Uint8Array(12)); // Generate a random IV
        const encryptedValue = await crypto.subtle.encrypt(
            {
                name: 'AES-GCM',
                iv: iv
            },
            key,
            data
        );

        const combinedArray = new Uint8Array(salt.length + iv.length + encryptedValue.byteLength);
        combinedArray.set(salt, 0);
        combinedArray.set(iv, salt.length);
        combinedArray.set(new Uint8Array(encryptedValue), salt.length + iv.length);
        return await this.baseEncode(combinedArray)
    }

    async decryptAES(encryptedValueWithSalt: string, key: CryptoKey): Promise<string> {
        const combinedArrayBuffer = await this.baseDecode(encryptedValueWithSalt)
        const combinedArray = new Uint8Array(combinedArrayBuffer)
        const salt = combinedArray.slice(0, 16); // Extract the salt (first 16 bytes)
        const iv = combinedArray.slice(16, 28); // Extract the IV (next 12 bytes)
        const encryptedValue = combinedArray.slice(28); // Extract the encrypted value
        const decrypted = await crypto.subtle.decrypt(
            {
                name: 'AES-GCM',
                iv: iv
            },
            key,
            encryptedValue
        );
        const decoder = new TextDecoder();
        return decoder.decode(decrypted);
    }

    async publicRSAStringToCryptoKey(publicKey: string): Promise<CryptoKey> {
        const binaryDer = await this.baseDecode(publicKey)
        return crypto.subtle.importKey(
            'spki',
            binaryDer,
            {
                name: 'RSA-OAEP',
                hash: 'SHA-256',
            },
            true,
            ['encrypt']
        );
    }

    async privateEcdsaKeyStringToCryptoKey(privateKey: string): Promise<CryptoKey> {
        const rawKey = await this.baseDecode(privateKey)
        return await this.privateEcdsaKeyArrayBufferToCryptoKey(rawKey)
    }

    // Function to import the ECDSA public key from the ArrayBuffer
    async publicEcdsaKeyStringToCryptoKey(publicKey: string): Promise<CryptoKey> {
        const rawKey = await this.baseDecode(publicKey)
        return await window.crypto.subtle.importKey(
            "spki",
            rawKey,
            {
                name: "ECDSA",
                namedCurve: "P-256" // P-256 or "P-384", "P-521" depending on your curve
            },
            true, // whether the key is extractable (i.e., can be used in exportKey)
            ["verify"]
        );
    }

    async privateRSAStringToCryptoKey(privateKey: string): Promise<CryptoKey> {
        const rawKey = await this.baseDecode(privateKey)
        return await window.crypto.subtle.importKey(
            "pkcs8",
            rawKey,
            {
                name: 'RSA-OAEP',
                hash: {name: "SHA-256"}
            },
            true,
            ["decrypt"]
        );
    }

    async privateAESKeyStringToCryptoKey(privateKey: string): Promise<CryptoKey> {
        const rawKey = await this.baseDecode(privateKey)
        return await window.crypto.subtle.importKey(
            "raw",
            rawKey,
            {
                name: "AES-GCM",
            },
            true,
            ["encrypt", "decrypt"]
        );
    }

    async generateECKeyPair(): Promise<CryptoKeyPair> {
        return await window.crypto.subtle.generateKey(
            {
                name: "ECDSA",
                namedCurve: "P-256" // Can be "P-256", "P-384", or "P-521"
            },
            true, // whether the key is extractable (i.e., can be used in exportKey)
            ["sign", "verify"] // can be "sign" and "verify"
        );
    }

    // Function to encrypt a value using the RSA public key
    async encryptRSA(publicKey: CryptoKey, value: string): Promise<string> {
        const encoder = new TextEncoder();
        const encodedValue = encoder.encode(value);

        const encryptedBytes = await crypto.subtle.encrypt(
            {
                name: 'RSA-OAEP',
            },
            publicKey,
            encodedValue
        );
        return this.baseEncode(encryptedBytes)
    }

    async decryptRSA(cryptoKey: CryptoKey, encryptedValue: string): Promise<string> {
        const encodedValue = await this.baseDecode(encryptedValue)
        const bytes = await crypto.subtle.decrypt(
            {
                name: 'RSA-OAEP',
            },
            cryptoKey,
            encodedValue
        );
        return this.decoder.decode(bytes)
    }

    async baseEncode(value: ArrayBuffer, base: string = 'M'): Promise<string> {
        switch (base) {
            case 'M':
                return new Promise((resolve, reject) => {
                    resolve(base + btoa(this.arrayBufferToBinaryString(value)))
                });
            case 'z':
                return new Promise((resolve, reject) => {
                    resolve(base + bs58.encode(new Uint8Array(value)))
                });
            case 'Z':
                return new Promise((resolve, reject) => {
                    resolve(base + this.base58CheckEncode(new Uint8Array(value)))
                });
            case 'f':
                return new Promise((resolve, reject) => {
                    resolve(base + bytesToHex(new Uint8Array(value)))
                });
            case 'F':
                return new Promise((resolve, reject) => {
                    resolve(base + bytesToHex(new Uint8Array(value)).toUpperCase())
                });
            default:
                throw new Error(`Unsupported baseEncode: ${base}`);
        }

    }

    async baseDecode(mutlibaseEncodedValue: string): Promise<Uint8Array> {
        return new Promise((resolve, reject) => {
            const base = mutlibaseEncodedValue.charAt(0)
            switch (base) {
                case 'M': {
                    const decoded = atob(mutlibaseEncodedValue.substring(1))
                    const uint8Array = this.binaryStringToArrayBuffer(decoded)
                    return resolve(new Uint8Array(uint8Array));
                }
                case 'z': {
                    const decoded = this.base58Decode(mutlibaseEncodedValue.substring(1))
                    return resolve(decoded)
                }
                case 'Z': {
                    const decoded = this.base58CheckDecode(mutlibaseEncodedValue.substring(1))
                    return resolve(decoded)
                }
                case 'F':
                case 'f': {
                    const decoded = hexToBytes(mutlibaseEncodedValue.substring(1))
                    return resolve(decoded)
                }
                default:
                    return reject(new Error('Unknown base encoding value'))
            }
        });
    }

    arrayBufferToBinaryString(buffer: ArrayBuffer): string {
        let binary = '';
        const bytes = new Uint8Array(buffer);
        const len = bytes.byteLength;
        for (let i = 0; i < len; i++) {
            binary += String.fromCharCode(bytes[i]);
        }
        return binary;
    }

    binaryStringToArrayBuffer(binaryString: string): ArrayBuffer {
        const uint8Array = new Uint8Array(binaryString.length);
        for (let i = 0; i < binaryString.length; i++) {
            uint8Array[i] = binaryString.charCodeAt(i);
        }
        return uint8Array;
    }

    async generateRSAKeyPair() {
        return await crypto.subtle.generateKey(
            {
                name: 'RSA-OAEP',
                modulusLength: 2048,
                publicExponent: new Uint8Array([0x01, 0x00, 0x01]),
                hash: {name: 'SHA-256'},
            },
            true,
            ['encrypt', 'decrypt']
        );
    }

    async resolvePublicKeyForRSA(privateKey: CryptoKey) {
        const privateKeyJwk = await window.crypto.subtle.exportKey("jwk", privateKey);

        const publicKeyJwk = {
            kty: privateKeyJwk.kty,
            n: privateKeyJwk.n,
            e: privateKeyJwk.e
        };

        return await window.crypto.subtle.importKey(
            "jwk",
            publicKeyJwk,
            {
                name: "RSASSA-PKCS1-v1_5",
                hash: {name: "SHA-256"}
            },
            true,
            ["verify"]
        );
    }

    async resolvePublicKeyForEC(privateKey: CryptoKey) {
        const privateKeyJwk = await window.crypto.subtle.exportKey("jwk", privateKey);

        const publicKeyJwk = {
            kty: privateKeyJwk.kty,
            crv: privateKeyJwk.crv,
            x: privateKeyJwk.x,
            y: privateKeyJwk.y
        };

        return await window.crypto.subtle.importKey(
            "jwk",
            publicKeyJwk,
            {
                name: "ECDSA",
                namedCurve: privateKeyJwk.crv // Ensure the curve is the same
            },
            true,
            ["verify"]
        );
    }

    async signMessage(message: string, rawSigningPrivateKey: Uint8Array): Promise<string> {
        const encodedMessage =  new TextEncoder().encode(message);
        const signature = secp256k1.sign(encodedMessage, bytesToHex(rawSigningPrivateKey));
        const plainSignatureBytes = signature.toCompactRawBytes() //plain format: [32][32]
        const bip137SignatureBytes = new Uint8Array(1 + plainSignatureBytes.length);  //BIP137 format: [1][32][32] the single byte is the 'header', yielding information about the signature
        bip137SignatureBytes.set([31], 0) //todo: find out if this header is correct. for now, a hardcoded header to test...
        bip137SignatureBytes.set(plainSignatureBytes, 1)
        return this.baseEncode(bip137SignatureBytes)
    }

    async verifySignature(signature: string, publicKey: Uint8Array, message: string) {
        const encodedMessage =  new TextEncoder().encode(message);
        const signatureBytes = await this.baseDecode(signature)
        const plainSignatureBytes = signatureBytes.slice(1)
        const signatureHex = bytesToHex(plainSignatureBytes)
        return secp256k1.verify(signatureHex, encodedMessage, bytesToHex(publicKey))
    }

    async deriveRSAPublicKeyFrom(key: string) {
        const privateRSACryptoKey = await this.privateRSAStringToCryptoKey(key)

        const publicKeyBytes = await window.crypto.subtle.exportKey(
            'spki',
            privateRSACryptoKey
        );
        return this.baseEncode(publicKeyBytes)
    }

    base58CheckDecode(buffer: string): Uint8Array {
        return bs58check.decode(buffer)
    }

    base58CheckEncode(buffer: Uint8Array): string {
        return bs58check.encode(buffer)
    }

    base58Decode(value: string): Uint8Array {
        return bs58.decode(value)
    }

    sha256(buffer: Uint8Array): Uint8Array {
        const wordArray = CryptoJS.lib.WordArray.create(buffer as any);
        const hash = CryptoJS.SHA256(wordArray);
        return new Uint8Array(CryptoJS.enc.Hex.parse(hash.toString()).words);
    }

    async privateEcdsaKeyArrayBufferToCryptoKey(rawKey: ArrayBuffer): Promise<CryptoKey> {
        return await window.crypto.subtle.importKey(
            "spki",
            rawKey,
            {
                name: "ECDSA",
                namedCurve: "P-256" // or "P-384", "P-521" depending on your curve
            },
            true, // whether the key is extractable (i.e., can be used in exportKey)
            ["sign"]
        );
    }

    async publicEcdsaKeyArrayBufferToCryptoKey(rawKey: ArrayBuffer): Promise<CryptoKey> {
        return await window.crypto.subtle.importKey(
            "spki",
            rawKey,
            {
                name: "ECDSA",
                namedCurve: "P-256" // or "P-384", "P-521" depending on your curve
            },
            true, // whether the key is extractable (i.e., can be used in exportKey)
            ["verify"]
        );
    }
}