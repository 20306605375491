import {Routes} from '@angular/router';
import {SignInComponent} from "./views/sign-in/sign-in.component";

import {SignUpComponent} from "./views/sign-up/sign-up.component";

import {MaintenanceComponent} from "./views/landing/maintenance/maintenance.component";

import {MyIdentityComponent} from "./views/my-identity/my-identity.component";
import {SignOutComponent} from "./views/sign-out/sign-out.component";
import {AuthenticationMethodComponent} from "./views/sign-up/authentication-method/authentication-method.component";
import {MnemonicPhraseComponent} from "./views/sign-up/mnemonic-phrase/mnemonic-phrase.component";
import {RestoreKeysComponent} from "./views/sign-up/restore-keys/restore-keys.component";
import {ProcessComponent} from "./views/landing/process/process.component";
import {StartVerificationComponent} from "./views/landing/start-verification/start-verification.component";
import {Web2appRedirectComponent} from "./views/landing/web2app-redirect/web2app-redirect.component";
import {
  DeveloperConsoleComponent
} from "./views/common/settings/developers/developers-console/developer-console.component";
import {CloseAccountComponent} from "./views/common/settings/account/close-account/close-account.component";
import {MyClaimsComponent} from "./views/my-claims/my-claims.component";
import {MySubscriptionsComponent} from "./views/my-subscriptions/my-subscriptions.component";
import {
  ClaimVerificationMethodComponent
} from "./views/my-claims/claim-verification-method/claim-verification-method.component";
import {ErrorLandingComponent} from "./views/landing/error/error-landing.component";
import {ExportClaimsComponent} from "./views/common/export-claims/export-claims.component";
import {
  NewSubscriptionComponent
} from "./views/my-subscriptions/new-subscription/new-subscription.component";
import {NewClaimComponent} from "./views/my-claims/new-claim/new-claim.component";
import {DeleteClaimComponent} from "./views/my-claims/delete-claim/delete-claim.component";

export const routes: Routes = [
  // {path: '', redirectTo: '/under-construction', pathMatch: 'full'},
  //main views
  // {path: '', redirectTo: '/my-identity', pathMatch: 'full'},
  {path: 'my-identity', component: MyIdentityComponent},
  {path: 'my-claims', component: MyClaimsComponent},
  {path: 'my-subscriptions', component: MySubscriptionsComponent},
  
  //sub views
  {path: 'verification-method', component: ClaimVerificationMethodComponent},
  {path: 'new-subscription', component: NewSubscriptionComponent},
  {path: 'new-claim', component: NewClaimComponent},
  {path: 'delete-claim', component: DeleteClaimComponent},
  {path: 'export-claims', component: ExportClaimsComponent},
  {path: 'developers-console', component: DeveloperConsoleComponent},
  {path: 'close-account', component: CloseAccountComponent},
  
  //authentication and key management
  {path: 'sign-in', component: SignInComponent},
  {path: 'sign-up', component: SignUpComponent},
  {path: 'recover-keys', component: RestoreKeysComponent},
  {path: 'sign-up/authentication-methods', component: AuthenticationMethodComponent},
  {path: 'sign-up/mnemonic-phrase', component: MnemonicPhraseComponent},
  {path: 'sign-out', component: SignOutComponent},
  
  //landing
  {path: 'landing/callback', component: Web2appRedirectComponent},
  {path: 'landing/process', component: ProcessComponent},
  {path: 'landing/maintenance', component: MaintenanceComponent},
  {path: 'landing/verification', component: StartVerificationComponent},
  {path: 'error', component: ErrorLandingComponent}
];