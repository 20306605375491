import {Component, Input} from '@angular/core';

import {ErrorModalComponent} from "../../common/error-modal/error-modal.component";
import {LogoComponent} from "../../common/logo/logo.component";
import {NgIf} from "@angular/common";

@Component({
  selector: 'app-landing',
  templateUrl: './registered-landing.component.html',
  standalone: true,
    imports: [
        LogoComponent,
        ErrorModalComponent,
        ErrorModalComponent,
        NgIf
    ],
  styleUrl: './registered-landing.component.scss'
})
export class RegisteredLandingComponent {

  @Input() message: string = ''
  @Input() loading: boolean = false
  @Input() errorOccurred: boolean = false
}
