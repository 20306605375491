"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.createErrorMessage = exports.parseMessage = exports.CORE_EVENT = void 0;
exports.CORE_EVENT = 'CORE_EVENT';
const parseMessage = (messageData) => {
    const message = {
        event: messageData.event,
        type: messageData.type,
        payload: messageData.payload,
    };
    if (typeof messageData.id === 'number') {
        message.id = messageData.id;
    }
    if (typeof messageData.success === 'boolean') {
        message.success = messageData.success;
    }
    return message;
};
exports.parseMessage = parseMessage;
const createErrorMessage = (error) => ({
    success: false,
    payload: {
        error: error.message,
        code: error.code,
    },
});
exports.createErrorMessage = createErrorMessage;
