import {Component, EventEmitter, Input, Output} from '@angular/core';
import {ClaimControllerService, ClaimReferenceView, ClaimView,} from "../../common/services";
import * as uuid from "uuid";
import {DataService} from "../../common/services/data-storage.service";


@Component({
    selector: 'app-identity',
    templateUrl: './identity.component.html',
    styleUrl: './identity.component.scss'
})
export class IdentityComponent {

    @Output() protected reloadCurrentProfile: EventEmitter<any> = new EventEmitter();
    protected errorOccurred: boolean = false
    protected claimLoading: Map<string, boolean> = new Map<string, boolean>()
    protected open: Map<string, boolean> = new Map<string, boolean>()
    @Input() protected classList: string = ''
    protected shortSummary: string = ''
    protected profileId?: string = 'my-identity-reference-' + uuid.v4()
    protected requestedClaimReference: ClaimReferenceView = {}
    protected requestedClaims: Map<string, ClaimView> = new Map<string, ClaimView>()
    protected loading: boolean = false;
    protected claimLoaded: boolean = false;

    constructor(private claimControllerService: ClaimControllerService, protected dataService: DataService) {
    }

    getClaim(claimReference: ClaimReferenceView) {
        if (!this.claimLoaded) {
            this.open.set(claimReference.id!, !this.open.get(claimReference?.id || '') || false)
            this.claimLoading.set(claimReference.id!, true)
            this.claimControllerService.viewClaims<Array<ClaimView>>(undefined, claimReference!.id!, undefined, undefined, undefined, undefined, 'application/vnd.doatoa.claims+json')
                .subscribe({
                    next: (claimViews) => {
                        const claim = claimViews[0]
                        this.requestedClaimReference = claimReference
                        this.claimLoading.set(claim.id!, false)
                        this.requestedClaims.set(claim.id!, claim)
                        var value = claim.fact!.value!
                        this.claimLoaded = true
                    },
                    error: (error) => {
                        this.claimLoading.set(claimReference.id!, false)
                        this.errorOccurred = true
                    }
                });
        } else {
            this.claimLoaded = !this.claimLoaded
        }
    }


    protected readonly sessionStorage = sessionStorage;
}
