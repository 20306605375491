import {Component, OnInit} from '@angular/core';
import {ActivatedRoute, Router} from "@angular/router";
import * as jwt_decode from "jwt-decode";
import {OauthControllerService} from "../../../services/api/oauth-controller.service";
import {DataService} from "../../../services/data.service";
import {LogoComponent} from "../../common/logo/logo.component";
import {SpinnerComponent} from "../../common/spinner/spinner.component";
import {RedirectService} from "../../../services/redirect-service";


@Component({
    selector: 'app-oauth-landing',
    standalone: true,
    imports: [
        LogoComponent,
        SpinnerComponent
    ],
    templateUrl: './oauth-landing.component.html',
    styleUrl: './oauth-landing.component.scss'
})
export class OauthLandingComponent implements OnInit {

    loading: boolean = false
    queryParams?: any = undefined

    constructor(private oauthControllerService: OauthControllerService,
                private activatedRoute: ActivatedRoute,
                private dataService: DataService,
                private redirectService: RedirectService,
                private router: Router) {
    }


    ngOnInit() {
        this.activatedRoute.queryParamMap.subscribe((p: any) => {
            const queryParams = p.params
            if (queryParams.code != undefined) {
                this.dataService.last<string>('code_verifier').subscribe({
                    next: codeVerifier => {
                        this.oauthControllerService.exchangeAuthorizationCodeForToken(queryParams.code, codeVerifier)
                          .subscribe({
                              next: (response) => {
                                  const idToken = response.id_token!
                                  const decodedIdToken = this.getDecodedToken(idToken!)
                                  this.dataService.publish('did', decodedIdToken.sub);
                                  this.dataService.publish('jwt', response.access_token!);
                                  this.dataService.publish('refreshToken', response.refresh_token!);
                                  this.dataService.publish('idToken', response.id_token!)
                                  this.dataService.publish('idTokenHint', response.id_token_hint!)
                                  this.router.navigate(['/my-identity']).catch(error => console.log(error))
                              }
                          });
                    }
                })
            } else {
                this.redirectService.redirectToSignInPage()
            }
        })
    }

    getDecodedToken(token: string): any {
        try {
            return jwt_decode.jwtDecode(token)
        } catch (Error) {
            console.log(Error);
            return 'null'
        }
    }

}
