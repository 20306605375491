/**
 * DoaToa
 * Manage your data and your digital identities, your data your choice.
 *
 * The version of the OpenAPI document: 1.0.0-alpha
 * Contact: info@compilit.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
/* tslint:disable:no-unused-variable member-ordering */

import {Injectable} from '@angular/core';
import {HttpContext, HttpEvent, HttpParams, HttpResponse} from '@angular/common/http';
import {Observable} from 'rxjs';

// @ts-ignore
import {FactView} from '../model/fact-view';

// @ts-ignore
import {BASE_PATH} from '../variables';
import {addToHttpParams} from "./common-http-operations";
import {AbstractHttpService} from "./abstract-http-service";

@Injectable({
    providedIn: 'root'
})
export class FactControllerService extends AbstractHttpService {


    /**
     * Get all publicly available facts.
     * @param query The value you wish to search for.
     * @param pageNumber The page number you wish to view.
     * @param pageSize The size of the page.
     * @param sortBy State by which property you wish to sort, defaults to \&#39;id\&#39;.
     * @param observe set whether to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report subscription and response progress.
     */
    public getFacts(query?: string, pageNumber?: number, pageSize?: number, sortBy?: string): Observable<Array<FactView>>;

    public getFacts(query?: string, pageNumber?: number, pageSize?: number, sortBy?: string): Observable<HttpResponse<Array<FactView>>>;

    public getFacts(query?: string, pageNumber?: number, pageSize?: number, sortBy?: string): Observable<HttpEvent<Array<FactView>>>;

    public getFacts(query?: string, pageNumber?: number, pageSize?: number, sortBy?: string): Observable<any> {

        let localVarQueryParameters = new HttpParams({encoder: this.encoder});
        if (pageNumber !== undefined && pageNumber !== null) {
            localVarQueryParameters = addToHttpParams(localVarQueryParameters,
                <any>pageNumber, 'pageNumber');
        }
        if (pageSize !== undefined && pageSize !== null) {
            localVarQueryParameters = addToHttpParams(localVarQueryParameters,
                <any>pageSize, 'pageSize');
        }
        if (sortBy !== undefined && sortBy !== null) {
            localVarQueryParameters = addToHttpParams(localVarQueryParameters,
                <any>sortBy, 'sortBy');
        }
        if (query !== undefined && query !== null) {
            localVarQueryParameters = addToHttpParams(localVarQueryParameters,
                <any>query, 'query');
        }

        let localVarHeaders = this.defaultHeaders;
        localVarHeaders = localVarHeaders.append('Content-Type', 'application/json')
        localVarHeaders = localVarHeaders.append('Accept', 'application/json')

       

        


        
        

        let localVarPath = `/identities/claims/facts/`;
        return this.httpClient.request<Array<FactView>>('get', `${this.configuration.basePath}${localVarPath}`,
            {
                params: localVarQueryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: localVarHeaders
            }
        );
    }


}
