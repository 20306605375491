import {Component, EventEmitter, OnInit, Output} from '@angular/core';
import {ActivatedRoute} from "@angular/router";
import {DataService} from "../common/services/data-storage.service";

@Component({
    selector: 'app-settings',
    templateUrl: './settings.component.html',
    styleUrls: ['./settings.component.scss']
})
export class SettingsComponent implements OnInit {

    loading: boolean = false
    @Output() myAccountLoading: EventEmitter<boolean> = new EventEmitter<boolean>()
    errorOccurred: boolean = false
    errorMessage: string = ''

    constructor(protected activatedRoute: ActivatedRoute, protected dataService: DataService) {
    }

    ngOnInit() {
        this.loading = true
        this.myAccountLoading.emit(this.loading)
    }

}
