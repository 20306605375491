export interface JWT {
  iss?: string;
  aud?: string;
  exp?: number;
  nbf?: number;
  att?: Attenuation[];
  prf?: string[];
}

export interface Attenuation {
  with?: string;
  can?: string;
}

export interface KeyStore {
  type: string
  signingPrivateKey: string
  signingPublicKey: string
  rootEncryptionKey: string
}


export enum CodecCode {
  PUBLIC_KEY_ED25519 = 0xed,
  PRIVATE_KEY_ED25519 = 0x1300,
  PUBLIC_KEY_SECP256K1 = 0xe7,
  PUBLIC_KEY_RSA = 0x1205,
  PRIVATE_KEY_RSA = 0x1305,
  PRIVATE_KEY_SECP256K1 = 0x1301,
  SECRET_KEY_AES_256 = 0xa2,
  JSON = 0x0200
}
