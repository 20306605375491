<div class="d-flex flex-column align-items-center justify-content-start">
    <div class="flex-grow-1 d-flex flex-column align-items-center justify-content-center w-100">
        <div class="mt-4">
            <app-logo></app-logo>
        </div>
        <div class="col-auto text-center w-75 p-3">
<!--            <div *ngIf="mnemonic == ''" class="btn p-3" >-->
<!--                <div>DoaToa will now connect to you Hardware Wallet in order to create your digital claims.</div>-->
<!--            </div>-->
            <div *ngIf="mnemonic != ''" id="mnemonic-phrase" class="pointer p-3" (click)="clipboardService.copyToClipboard(this.mnemonic)">
                <p>This is your mnemonic recovery phrase, take care to store it somewhere safe (preferably offline). This phrase cannot be recreated and will only be shown once.</p>
                <div class="container">
                    <div class="row">
                        <div *ngFor="let m of mnemonicParts; index as i" class="col-6 d-flex">
                            <span class="ms-3 me-2">{{ i + 1 }}</span>
                            <span class="">{{ m }}</span>
                        </div>
                    </div>
                </div>
                <div class="d-flex justify-content-center" *ngIf="clipboardService.showCopyConfirmation">
                    <div class="position-fixed text-center color-primary-blue" >(Copied to clipboard)</div>
                </div>
            </div>
            <div class="form-check rounded-5 col-auto m-3">
                <label for="termsAndConditionsAccepted">I accept the
                    <a href="https://doatoa.com/terms-of-service/" target=”_blank”>terms and conditions.</a>
                </label>
                <input [checked]="termsAndConditionsAccepted"
                       (change)="switchTermsAndConditionsAccepted()"
                       class="form-check-input"
                       id="termsAndConditionsAccepted"
                       type="checkbox">
            </div>
            <div class="">
                <app-generic-button
                        (click)="createIdentity()"
                        [btnClass]="'btn-regular'"
                        [disabled]="loading || !termsAndConditionsAccepted"
                        [loading]="loading"
                        [buttonText]="'Create account'"
                        [primaryIcon]="'fa fa-angle-right'"></app-generic-button>
            </div>
        </div>
        <app-error-message></app-error-message>
    </div>
</div>
