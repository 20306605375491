<div class="min-width-300 overflow-x-hidden">
    <div *ngIf="identity!.subscribers!.length > 0" class="w-100">
        <div class="m-3 card p-3">
            <div *ngFor="let subscriber of identity?.subscribers; index as i"
                 class="mb-3 container justify-content-center">
                <div class="row text-dark ">
                    <div (click)="openOrClose(subscriber.id!)"
                         [attr.aria-controls]="'subscriber-'+subscriber.id"
                         [attr.data-bs-target]="'#subscriber-'+subscriber.id"
                         aria-expanded="false"
                         data-bs-toggle="collapse"
                         class="p-0 m-0 btn btn-regular min-width-220">
                        <div class="ps-3 d-flex align-items-center justify-content-between height-40">
                            <div class="d-flex align-items-center">
                                <span class=""><i class="ms-2 me-2 color-secondary-blue {{subscriber | toStatusClass}} fa-xl"></i></span>
                                <span class="truncate-text">From {{ subscriber.subscriber }}</span>
                            </div>
                            <div class="pe-3 d-flex align-items-center justify-content-center height-40">
                                <i *ngIf="open.get(subscriber.id!) || false" class="fa fa-chevron-up"></i>
                                <i *ngIf="!open.get(subscriber.id!) || false" class="fa fa-chevron-down"></i>
                            </div>
                        </div>
                    </div>


                    <!--                    <div (click)="openOrClose(subscription.id!)"-->
                    <!--                         [attr.aria-controls]="'subscription-'+subscription.id"-->
                    <!--                         [attr.data-bs-target]="'#subscription-'+subscription.id"-->
                    <!--                         aria-expanded="false"-->
                    <!--                         data-bs-toggle="collapse"-->
                    <!--                         class="p-0 m-0 pointer {{resolveClass(i, subscription)}}">-->
                    <!--                        <div class="container d-flex align-items-center justify-content-between">-->
                    <!--                            <span class=""><i class="ms-2 me-2 color-secondary-blue {{subscription | toStatusClass}} fa-xl"></i></span>-->
                    <!--                            <span class="truncate-text">By {{ subscription.subscriber }}</span>-->
                    <!--                            <app-action-button [showPrimary]="open.get(subscription.id!) || false"-->
                    <!--                                               [showSecondary]="!open.get(subscription.id!) || false"-->
                    <!--                                               [btnClass]="'btn-regular'"-->
                    <!--                                               [primaryIconClass]="'fa fa-chevron-up'"-->
                    <!--                                               [secondaryIconClass]="'fa fa-chevron-down'">-->
                    <!--                            </app-action-button>-->
                    <!--                        </div>-->
                    <!--                    </div>-->
                </div>
                <div class="collapse" [id]="'subscriber-'+subscriber.id">
                    <app-subscription [subscription]="subscriber"
                                      (deleted)="delete($event)"
                                      (updated)="update($event)"
                                      [addressedToMe]="true"></app-subscription>
                </div>
            </div>
        </div>
    </div>
    <div *ngIf="identity?.subscribers?.length == 0" class="text-center m-3 p-3 d-flex justify-content-center">
        You have no subscribers
    </div>
</div>