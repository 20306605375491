<div class="m-3 card p-3">
    <div class="">
        <h5>Add a new informal claim</h5>
        <button aria-label="Close"
                class="btn-close float-end m-1"
                (click)="navigator.navigateTo('/my-claims', true)">
        </button>
    </div>
    <div class="">
            <div class="container">
                <div class="row">
                    <select (change)="setScope($event)"
                            class="rounded-5 mb-3 col form-select"
                            id="claim-scope"
                            name="type">
                        <option disabled
                                [selected]="!scopeSelected"
                                value="">Choose a category...
                        </option>
                        <option *ngFor="let scope of scopes"
                                value="{{scope}}">{{ scope | toReadableString }}
                        </option>
                    </select>
                </div>
                <ng-container *ngIf="scopeSelected">
                    <div class="row">
                        <select (change)="setClaimScope($event)"
                                class="rounded-5 mb-3 col form-select"
                                id="claimType"
                                name="type">
                            <option disabled
                                    [selected]="selectedScope == undefined"
                                    value="">Make a claim about a...
                            </option>
                            <option *ngFor="let claimType of claimScopes | toFilteredClaimTypes"
                                    value="{{claimType}}">{{ claimType |  toReadableString }}
                            </option>
                        </select>
                    </div>
                    <div class="row">
                        <input [(ngModel)]="claimPayload.encryptedFactValue"
                               class="rounded-5 col form-control mb-3"
                               id="value"
                               name="value"
                               autocapitalize="off"
                               autocomplete="off"
                               placeholder="Fill in your '{{selectedClaimScopeString |  toReadableString}}'...">
                    </div>
                    <div class="row">
                <textarea (change)="updateDescription($event)"
                          class="rounded-1 col form-control mb-3"
                          id="description"
                          name="description"
                          placeholder="Add a description (optional)"></textarea>
                    </div>
                    <div class="d-flex justify-content-end p-0 mb-3">
                        <div class="d-flex border rounded-5 p-1 color-secondary-blue">
                            <app-action-button *ngFor="let visibilityScope of VisibilityScopeEnum.values(); let first = first; let last = last;"
                                               (click)="toggleVisibility(visibilityScope)"
                                               [btnClass]="claimPayload!.claimVisibilityScope == visibilityScope ? 'btn-regular' : 'btn-regular-disabled'"
                                               [showPrimary]="claimPayload!.claimVisibilityScope == visibilityScope"
                                               [showSecondary]="claimPayload!.claimVisibilityScope != visibilityScope"
                                               [primaryIconClass]="visibilityScope | toVisibilityScopeIcon"
                                               [secondaryIconClass]="visibilityScope | toOppositeVisibilityScopeIcon"
                                               class="{{last ? 'ms-1' : first ? '' : 'ms-1'}}">
                            </app-action-button>
                        </div>
                    </div>
                </ng-container>
            </div>
            <!--                    <app-verification-method (selectedMethod)="chosenVerificationMethod = $event" *ngIf="selectedClaimType != undefined && claimPayload.encryptedFactValue != undefined && claimPayload.encryptedFactValue != ''" [claimType]="selectedClaimType"></app-verification-method>-->
            <app-generic-button
                    (click)="makeClaim()"
                    *ngIf="selectedClaimScopeString != ''"
                    [disabled]="loading || claimPayload.encryptedFactValue == undefined || claimPayload.encryptedFactValue == ''"
                    [loading]="loading"
                    [buttonText]="'Make claim'"
                    [primaryIcon]="'fa-solid fa-flag'">
            </app-generic-button>
        </div>
        <div class="row justify-content-center">
            <div class="col-lg-9">
                <app-error-message (modalFinished)="resetError()"></app-error-message>
                <lib-generic-message [show]="claimMadeSuccessfully"
                                     [message]="'Claim was made successfully'"></lib-generic-message>
            </div>
        </div>
</div>

