// @ts-ignore
import {HttpParams} from "@angular/common/http";

export function addToHttpParams(httpParams: HttpParams, value: any, key?: string): HttpParams {
    if (typeof value === "object" && value instanceof Date === false) {
        httpParams = addToHttpParamsRecursive(httpParams, value);
    } else {
        httpParams = addToHttpParamsRecursive(httpParams, value, key);
    }
    return httpParams;
}

export function addToHttpParamsRecursive(httpParams: HttpParams, value?: any, key?: string): HttpParams {
    if (value == null) {
        return httpParams;
    }

    if (typeof value === "object") {
        if (Array.isArray(value)) {
            (value as any[]).forEach(elem => httpParams = addToHttpParamsRecursive(httpParams, elem, key));
        } else if (value instanceof Date) {
            if (key != null) {
                httpParams = httpParams.append(key, (value as Date).toISOString().substring(0, 10));
            } else {
                throw Error("key may not be null if value is Date");
            }
        } else {
            Object.keys(value).forEach(k => httpParams = addToHttpParamsRecursive(
                httpParams, value[k], key != null ? `${key}.${k}` : k));
        }
    } else if (key != null) {
        httpParams = httpParams.append(key, value);
    } else {
        throw Error("key may not be null if value is not object or array");
    }
    return httpParams;
}