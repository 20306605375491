import {Component, ElementRef, EventEmitter, Input, OnChanges, Output, Renderer2, SimpleChanges} from '@angular/core';
import {ClaimView, IdentityView, SubscriptionView,} from "../../../services/";
import * as uuid from "uuid";
import {DataService} from "../../../services/data.service";

@Component({
  selector: 'app-my-claims',
  templateUrl: './my-claims.component.html',
  styleUrls: ['./my-claims.component.scss']
})
export class MyClaimsComponent implements OnChanges {
  
  @Output() claimIdToFetchEmitter = new EventEmitter<string>()
  @Output() reloadProfileEmitter = new EventEmitter<Array<ClaimView>>()
  @Input() scopes: Array<string> = [];
  @Input() loadingInitialClaims: boolean = false;
  @Input() identity?: IdentityView;
  protected claims?: Array<ClaimView> = [];
  protected newRequestId?: string = 'new-subscription-' + uuid.v4()
  protected exportId?: string = 'export-' + uuid.v4()
  protected newClaimId?: string = 'new-claim-' + uuid.v4()
  protected deleteClaimClicked: boolean = false
  protected loading: Map<string, boolean> = new Map<string, boolean>()
  protected open: Map<string, boolean> = new Map<string, boolean>()
  protected errorOccurred: boolean = false
  protected claimType: string = ''
  protected claimToDelete: ClaimView = {aspects: []}
  protected requestedClaims: Map<string, ClaimView> = new Map<string, ClaimView>()
  protected errorMessage: string = ''
  protected scope?: string = undefined
  protected showMessage: boolean = false;
  protected message: string = '';
  protected readonly sessionStorage = sessionStorage;
  
  constructor(private renderer: Renderer2,
              private elementRef: ElementRef,
              protected dataService: DataService) {
  }
  
  ngOnChanges(changes:SimpleChanges): void {
    const currentValue = changes['identity'].currentValue as IdentityView;
    if (currentValue) {
      this.claims = currentValue.claims;
    }
    // this.dataService.last<IdentityView>('identity').subscribe({
    //   next: identity => {
    //     this.identity = identity
    //     this.claims = this.identity.claims
    //   }
    // })
  }
  
  protected tryDeleteClaim(claim: ClaimView) {
    this.deleteClaimClicked = true
    this.claimToDelete = claim
  }
  
  protected claimIsMandatory(claim: ClaimView) {
    let isMandatory = false;
    for (const aspect of claim.aspects!) {
      if (aspect == "mandatory") {
        isMandatory = true
      }
    }
    return isMandatory;
  }
  
  protected openCloseClaim(claimView: ClaimView) {
    this.open.set(claimView.id!, !this.open.get(claimView.id!) || false)
    // if (!this.requestedClaims.has(claimReference.id!)) {
    //   this.loading.set(claimReference.id!, true)
    //   this.dataService.last<string>('did').subscribe({
    //     next: did => {
    //       this.claimControllerService.viewClaims<Array<ClaimView>>(did, claimReference.id!, undefined, undefined, undefined, undefined, 'application/vnd.doatoa.claims+json')
    //         .subscribe({
    //           next: (response) => {
    //             const claim = response[0]
    //             this.loading.set(claimReference.id!, false)
    //             this.requestedClaims = this.requestedClaims.set(claim.id!, claim)
    //           },
    //           error: (error) => {
    //             this.loading.set(claimReference.id!, false)
    //             this.errorOccurred = true
    //           }
    //         });
    //     }
    //   })
    //
    // }
  }
  
  protected isLoading(claimId: string): boolean {
    return this.loading.has(claimId) && this.loading.get(claimId)!
  }
  
  protected removeClaimById(deletedClaimId: any) {
    this.dataService.last<IdentityView>('identity')?.subscribe({
      next: identity => {
        identity.claims?.forEach((element, index) => {
          if (identity?.claims![index].id == deletedClaimId) identity?.claims?.splice(index, 1);
          this.message = 'Claim deleted!'
          this.showMessage = true
          this.identity = identity
          this.dataService.publish<IdentityView>('identity', identity)
        });
      }
    })
  }
  
  protected resolveClass(index: number, claim: ClaimView): string {
    if (claim.events?.some(x => x.type == 'pending')) {
      return 'btn-basic-notify'
    }
    return index % 2 == 0 ? 'btn-basic-secondary-dark' : 'btn-basic-secondary';
  }
  
  protected updateClaimClass($event: string) {
    document.getElementById('claim-row-' + $event)
    this.dataService.last<IdentityView>('identity')?.subscribe({
      next: identity => {
        const element = this.elementRef.nativeElement.querySelector('#claim-row-' + $event);
        const claimView = identity?.claims?.find((c, i) => c.id == $event)
        const index = identity?.claims?.indexOf(claimView!)
        if (index == undefined)
          return
        this.renderer.addClass(element, index % 2 == 0 ? 'btn-basic-secondary-dark' : 'btn-basic-secondary');
      }
    })
  }
  
  protected addSubscription($event: SubscriptionView) {
    this.dataService.last<IdentityView>('identity')?.subscribe({
      next: identity => {
        identity.subscriptions!.push($event)
        this.dataService.publish<IdentityView>('identity', identity)
      }
    })
  }
  
}
