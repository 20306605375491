import {Injectable} from "@angular/core";
const genericErrorMessage = "Oops, something went wrong, please try again."
@Injectable({
    providedIn: 'root'
})
export class ErrorMessageService {
    
    private message?: string
    
    errorHasOccurred(): boolean {
        return this.message !== undefined
    }
    
    setMessage(message?: string, timeout: number = 5000) {
        this.message = message || genericErrorMessage
        setTimeout(() => {
            this.message = undefined
        }, timeout)
    }
    
    getMessage(): string {
        return this.message || genericErrorMessage
    }
    
    reset() {
        this.message = undefined
    }
}