import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';

import {HttpErrorResponse} from "@angular/common/http";
import {DataSubscriptionService} from "../../../services/data-subscription.service";
import {CryptographyService} from "../../../services/cryptography.service";
import {ActionButtonComponent} from "../action-button/action-button.component";
import {ToReadableStringPipe} from "../pipes/ToReadableStringPipe";
import {NgForOf, NgIf} from "@angular/common";
import {FormsModule} from "@angular/forms";
import {EventComponent} from "../event/event.component";
import {ToReadableSubscriptionStatusPipe} from "../pipes/ToReadableRequestStatusPipe";
import {ClaimView} from "../../../services/api/models/claim-reference-view";
import {SubscriptionView} from '../../../services/api/models/subscription-view';
import {SubscriptionControllerService} from "../../../services/api/subscription-controller.service";
import {DecisionPayload, DecisionPayloadNs} from "../../../services/api/models/decision-payload-ns";
import {CryptoWalletService} from "../../../services/crypto-wallet.service";
import {ToStatusClassPipe} from "../pipes/ToStatusClassPipe";
import {IpfsService} from "../../../services/api/ipfs.service";
import {ClaimControllerService} from "../../../services/api/claim-controller.service";
import {IdentityView} from "../../../services/api/models/identity-view";
import {sha256} from "js-sha256";
import {ClaimPayload} from "../../../services/api/models/claim-payload";
import {VisibilityScopeEnum} from "../../../services/api/models/visibility-scope";
import {IdentityControllerService} from "../../../services/api/identity-controller.service";
import DecisionEnum = DecisionPayloadNs.DecisionEnum;
import {ScopeControllerService} from "../../../services/api/scope-controller.service";
import {MetadataView} from "../../../services/api/models/metadata-view";
import {SignedData} from "../../../services/api/models/signed-data";
import {ErrorMessageComponent} from "../error-message/error-message.component";
import {FactView} from "../../../services/api/models/fact-view";

@Component({
  selector: 'app-subscription',
  templateUrl: './subscription.component.html',
  standalone: true,
  imports: [
    ActionButtonComponent,
    ToReadableStringPipe,
    NgForOf,
    NgIf,
    FormsModule,
    EventComponent,
    ToReadableSubscriptionStatusPipe,
    ToStatusClassPipe,
    ErrorMessageComponent
  ],
  styleUrls: ['./subscription.component.scss']
})
export class SubscriptionComponent implements OnInit {
  
  protected loading: boolean = false
  protected loadingMap: Map<string, boolean> = new Map<string, boolean>()
  protected reason?: string = undefined
  protected password?: string = undefined
  @Input() requestee: string = ''
  @Input() parent!: ClaimView;
  @Input() addressedToMe: boolean = false
  @Input() initiatedByMe: boolean = false
  @Input() subscription!: SubscriptionView
  protected metadataView?: MetadataView
  @Output() deleted: EventEmitter<SubscriptionView> = new EventEmitter<SubscriptionView>()
  @Output() updated: EventEmitter<SubscriptionView> = new EventEmitter<SubscriptionView>()
  errorOccurred: boolean = false
  
  constructor(private ipfsService: IpfsService,
              private dataSubscriptionService: DataSubscriptionService,
              private cryptoWalletService: CryptoWalletService,
              private cryptographyService: CryptographyService,
              private subscriptionCommandControllerService: SubscriptionControllerService,
              private claimControllerService: ClaimControllerService,
              private identityControllerService: IdentityControllerService) {
  }
  
  ngOnInit(): void {
    this.identityControllerService.findMetadata(this.subscription.subscriber).subscribe({
      next: metadataView => {
        this.metadataView = metadataView
      }
    })
  }
  
  //todo: move to pipe
  
  resolveIcon(status: string): string {
    switch (status) {
      case "created":
        return 'fa-solid fa-clock secondary-info'
      case "pending":
        return 'fa-solid fa-question-circle secondary-info'
      case "approved":
        return 'fa fa-check secondary-info'
      case "denied":
        return 'fa-solid fa-circle-xmark primary-danger'
      case "altered":
        return 'fa fa-question-circle secondary-info'
      case "exception":
        return 'fa fa-question-circle primary-danger'
      case "archived":
        return 'fa fa-archive greyed-out'
      default:
        return ''
    }
  }
  
  async processSubscription(decision: DecisionEnum) {
    if (this.requestIsOpen(this.subscription)) {
      this.loadingMap.set(decision, true)
      let myEncryptedAESPrivateKey = undefined
      const publicKeyString = this.subscription.secretSharingPublicKey!
      const providedRSAPublicKey = await this.cryptographyService.publicRSAStringToCryptoKey(publicKeyString)
      myEncryptedAESPrivateKey = await this.cryptoWalletService.encryptSecretKey(providedRSAPublicKey)
      const decisionPayload: DecisionPayload = {
        subscriptionId: this.subscription.id!,
        reason: this.reason,
        decision: decision,
        encryptedEncryptionKey: decision == DecisionEnum.Approve ? myEncryptedAESPrivateKey : undefined
      }
      this.subscriptionCommandControllerService.processSubscriptionRequest(decisionPayload)
        .subscribe({
            next: (response) => {
              this.loadingMap.set(decision, false)
              this.subscription = response
              this.updated.emit(this.subscription)
            },
            error: (error) => {
              this.errorOccurred = true
              this.loading = false
            }
          }
        )
    }
  }
  
  requestIsOpen(request: SubscriptionView): boolean {
    switch (request.status) {
      case "pending":
        return true
      case "approved":
        return false
      case "denied":
        return false
      default:
        return true
    }
  }
  
  deleteSubscription() {
    this.loadingMap.set('delete', true)
    this.dataSubscriptionService.onFirst<IdentityView>('identity').subscribe({
      next: (identity) => {
        if (this.subscription.subscriber != identity.did) {
          const reEncryptedClaims: SignedData<ClaimPayload>[] = []
          const cidsToDeleted: string[] = []
          identity.claims?.forEach(claim => {
            this.ipfsService.getFact(claim.cid).subscribe({
              next: fact => {
                cidsToDeleted.push(claim.cid!)
                this.reEncrypt(fact, claim, reEncryptedClaims);
                this.updateClaims(reEncryptedClaims, cidsToDeleted, identity);
                identity.subscribers?.forEach(async subscription => {
                  if (subscription.status === 'approved' && subscription.id != this.subscription.id) {
                    await this.processSubscription(DecisionEnum.Approve)
                  }
                })
                this.subscriptionCommandControllerService.deleteSubscription(this.subscription.id!)
                  .subscribe({
                    next: (response) => {
                      this.loadingMap.set('delete', false)
                      this.deleted.emit(this.subscription)
                    },
                    error: (error) => {
                      this.errorOccurred = true
                      this.loadingMap.set('delete', false)
                    }
                  });
              }
            })
          })
        }
      }
    })
  }
  
  private updateClaims(reEncryptedClaims: SignedData<ClaimPayload>[], cidsToDeleted: string[], identity: IdentityView) {
    this.claimControllerService.makeClaims(reEncryptedClaims).subscribe({
      next: (claimView) => {
        this.claimControllerService.deleteClaims(cidsToDeleted).subscribe({
          next: () => {
            identity.claims = claimView
            this.dataSubscriptionService.publish('identity', identity)
          }
        })
      },
      error: (error) => {
        this.errorOccurred = true
        this.loadingMap.set('delete', false)
      }
    });
  }
  
  private reEncrypt(fact: FactView, claim: ClaimView, reEncryptedClaims: SignedData<ClaimPayload>[]) {
    this.cryptoWalletService.decrypt(fact.value!).then(decryptedValue => {
      this.cryptoWalletService.rollSalt().then(salt => {
        this.identityControllerService.patchIdentity([
          {
            path: "/salt",
            value: salt,
            op: "replace"
          }
        ]).subscribe({
          next: _ => {
            this.cryptoWalletService.encrypt(decryptedValue).then(encryptedValue => {
              this.cryptoWalletService.sign(decryptedValue).then(signatureResult => {
                let claimToUpdate: ClaimPayload = {
                  encryptedFactValue: encryptedValue,
                  thumbprint: sha256.hex(decryptedValue),
                  claimScope: claim.type,
                  claimVisibilityScope: VisibilityScopeEnum.Protected,
                }
                if (signatureResult) {
                  reEncryptedClaims.push({
                    data: claimToUpdate,
                    signature: signatureResult.signature
                  })
                }
              })
            })
          },
          error: (error) => {
            this.errorOccurred = true
            this.loadingMap.set('delete', false)
          }
        })
      })
    })
  }
}