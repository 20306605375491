<div class="container p-3" *ngIf="!dataService.has('identity')">
    <div class="row">
<!--        <div class="col-xl-4">-->
<!--            <div class="height-220 mb-4">-->
<!--                <lib-loading-placeholder></lib-loading-placeholder>-->
<!--            </div>-->
<!--        </div>-->
        <div class="col-xl-6">
            <div class="height-220 mb-4">
                <lib-loading-placeholder></lib-loading-placeholder>
            </div>
        </div>
        <div class="col-xl-4">
            <div class="height-220 mb-6">
                <lib-loading-placeholder></lib-loading-placeholder>
            </div>
        </div>
    </div>
</div>

<div class="container p-3" *ngIf="dataService.has('identity')">
    <div class="row">
<!--        <div class="col-xl-4">-->
<!--            <app-admin *ngIf="this.dataService.last<IdentityView>('identity')?.license?.details?.name == 'admin'"></app-admin>-->
<!--            <app-license></app-license>-->
<!--        </div>-->
        <div class="col-xl-6">
            <app-developers></app-developers>
        </div>
        <div class="col-xl-6">
            <app-account></app-account>
        </div>
    </div>
</div>

