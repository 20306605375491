import {Pipe, PipeTransform} from '@angular/core';
import {LicenseDetailsView} from "../../../services";
import LicenseNameEnum = LicenseDetailsView.LicenseNameEnum;

@Pipe({
    name: 'toLicenseNameWithFrequency',
    standalone: true
})
export class ToLicenseNameWithFrequencyPipe implements PipeTransform {
    constructor() {
    }

    public transform(value: LicenseNameEnum | undefined): string {
        if (value == undefined)
            return ''
        switch (value) {
            case LicenseNameEnum.admin:
                return 'Administrator'
            case LicenseNameEnum.freemium:
                return 'Freemium'
            case LicenseNameEnum.monthlyPremium:
                return 'Premium - monthly paid'
            case LicenseNameEnum.yearlyPremium:
                return 'Premium - yearly paid'
            case LicenseNameEnum.nonPayingProfessional:
                return 'Non-paying professional'
            default:
                return ''
        }
    }
}