import {Component} from '@angular/core';
import {CommonModule} from "@angular/common";
import {ApiStatusComponent} from "./api-status/api-status.component";
import {ErrorMessageComponent} from "../../../error-message/error-message.component";

@Component({
  selector: 'app-developer-console',
  standalone: true,
  imports: [
    CommonModule,
    ApiStatusComponent,
    ErrorMessageComponent
  ],
  templateUrl: './developer-console.component.html',
  styleUrl: './developer-console.component.scss'
})
export class DeveloperConsoleComponent {
  
  loading: boolean = false
  
}
