import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {DeveloperConsoleComponent} from "./developer-console.component";

import {ApiStatusComponent} from "./api-status/api-status.component";
import {GenericButtonComponent} from "../common/generic-button/generic-button.component";
import {ErrorModalComponent} from "../common/error-modal/error-modal.component";


@NgModule({
    declarations: [DeveloperConsoleComponent],
    imports: [
        CommonModule,
        ApiStatusComponent,
        GenericButtonComponent,
        ErrorModalComponent
    ]
})
export class DevelopersModule {
}
