<div class="container">
    <form autocomplete="off"
          class="row justify-content-center">
        <app-logo [classes]="'mx-auto d-block mt-2'"
                  [height]="'200'"
                  [image]="'/doatoa-logo-full.svg'"
                  [width]="'300'"></app-logo>
        <div *ngIf="stepOne" class="col-auto text-center card max-width-300 p-3">
            <div class="position-relative">
                <h2 class="text-center">Email address</h2>
                <div class="position-relative">
                    <input [(ngModel)]="email"
                           class="rounded-5 form-control {{email == undefined || email == '' ? '' : email != undefined && isValidEmail(email) ? 'is-valid' : 'is-invalid'}}"
                           datatype="email"
                           autocapitalize="off"
                           id="email"
                           name="email"
                           placeholder="E-mail address"
                           type="email"
                           autocomplete="new-email">
                    <i class="secondary-blue fa-solid fa-circle-question p-1"
                       style="position: absolute; right: 10px; top: 50%; transform: translateY(-50%);"
                       (click)="flipShowEmailInfo()"></i>
                </div>
            </div>
            <div class="p-3"
                 *ngIf="showEmailInfo">The mandatory e-mail address used for all DoaToa correspondence.
            </div>
            <div class="mt-2">
                <app-generic-button [buttonText]="'Next'"
                                    [btnClass]="'btn-basic-info'"
                                    [icon]="'fa fa-angle-right'"
                                    [disabled]="email == undefined || !isValidEmail(email)"
                                    (click)="selectEmail()"></app-generic-button>
            </div>
        </div>
        <div *ngIf="stepTwo" class="col-auto text-center card max-width-300 p-3">
            <h2 class="text-center">Provide recovery phrase</h2>
            <input type="text"
                   id="mnemonic-phrase"
                   autocapitalize="off"
                                               autocomplete="off"
                   class="form-control rounded-5"
                   (input)="tryDivide()"
                   (emptied)="mnemonicPhrase = undefined; mnemonicParts = []"
                   [(ngModel)]="mnemonicPhrase">
            <div *ngIf="mnemonicParts.length > 0">
                <div class="row m-2">
                    <div *ngFor="let m of mnemonicParts; let i = index" class="col-6">
                        <div>{{ i + 1 }} {{ m }}</div>
                    </div>
                </div>
            </div>
            <div class="mt-2">
                <app-generic-button [buttonText]="'Next'"
                                    [btnClass]="'btn-basic-info'"
                                    [icon]="'fa fa-angle-right'"
                                    [disabled]="mnemonicPhrase == undefined || mnemonicPhrase == '' "
                                    (click)="addMnemonic()"></app-generic-button>
            </div>
        </div>

        <div *ngIf="stepThree" class="col-auto text-center card max-width-300 p-3">
            <div class="m-4">
                <h2>Authentication method</h2>
                <div *ngFor="let method of methods" class="row">
                    <input (change)="flipMethod(method)"
                           [checked]="chosenMethod == method"
                           class="rounded-5 form-check-input col-2"
                           id="restoreAuthMethodSwitch-{{method}}"
                           type="checkbox">
                    <label class="col-10" for="restoreAuthMethodSwitch-{{method}}">{{ method }}
                        <a href="https://doatoa.com/docs/auth-methods" target="_blank">Learn more</a>
                    </label>
                </div>
            </div>
            <div class="mb-2">
                <app-generic-button
                        id="recover-keys"
                        (click)="recoverKeys(chosenMethod!)"
                        [btnClass]="'btn-basic-info'"
                        [disabled]="chosenMethod == undefined || mnemonicParts.length != 24"
                        [loading]="loading"
                        [buttonText]="'Recover keys'"
                        [icon]="'fa-solid fa-key'">
                </app-generic-button>
            </div>
        </div>
    </form>
    <div class="m-3"></div>
    <div class="row justify-content-center">
        <div class="max-width-300 card text-center">
            <div class="p-2">
                Already have an account?
                <a class="secondary-blue"
                   routerLink="/sign-in">Sign in
                </a>
            </div>
        </div>
    </div>
    <div class="row justify-content-center">
        <img class="max-width-400 mx-auto" alt="sign-in" ngSrc="/sign-in.png" height="400" width="400">
    </div>
</div>

