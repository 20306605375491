import {Injectable} from "@angular/core";
import {IdentityControllerService} from "./api/identity-controller.service";
import {ScopeControllerService} from "./api/scope-controller.service";
import {IdentityView} from "./api/models/identity-view";
import {SplashScreen} from "@capacitor/splash-screen";
import {VerificationMethodView} from "./api/models/verification-method-view";
import {DataSubscriptionService} from "./data-subscription.service";
import {CryptoWalletService} from "./crypto-wallet.service";
import {DataStorageService} from "./data-storage.service";

@Injectable({providedIn: 'root'})
export class BootstrapService {
  
  private isInitialized: boolean = false;
  
  constructor(private identityControllerService: IdentityControllerService,
              private metadataControllerService: ScopeControllerService,
              private dataSubscriptionService: DataSubscriptionService,
              private dataStorageService: DataStorageService,
              private cryptoWalletService: CryptoWalletService) {
  }
  
  async init() {
    this.dataSubscriptionService.publish<boolean>('dataLoading', true)
    if (this.isInitialized) {
      this.dataSubscriptionService.publish('dataLoading', false)
      return
    }
    const did = await this.cryptoWalletService.getStored('did')
    this.loadScopeMetadata();
    this.loadIdentity(did!);
  }
  
  async destroy() {
    this.isInitialized = false
    await this.dataSubscriptionService.destroy()
    await this.cryptoWalletService.removeStored('email')
    await this.cryptoWalletService.removeStored('did')
    await this.cryptoWalletService.removeStored('jwt')
    await this.cryptoWalletService.removeStored('identity')
  }
  
  private loadIdentity(did: string) {
    console.log("Restoring identity...")
    this.dataStorageService.getValue<IdentityView>(did).then(async identity => {
      if (identity) {
        console.log("Restored identity from local database", identity)
        await this.cryptoWalletService.store('salt', identity.salt!)
        this.finishLoading(identity);
      } else {
        this.identityControllerService.findIdentity(did!)
          .subscribe({
            next: async (identity) => {
              if (identity) {
                await this.dataStorageService.addKeyValuePair(did, identity)
                await this.cryptoWalletService.store('salt', identity.salt!)
                this.finishLoading(identity);
              } else {
                throw Error(`Failed to load identity, too many or few matches for ${did}`);
              }
            }
          });
      }
    })
  }
  
  private finishLoading(identity: IdentityView) {
    this.dataSubscriptionService.publish('identity', identity)
    this.isInitialized = true
    this.dataSubscriptionService.publish('dataLoading', false)
    SplashScreen.hide();
  }
  
  private loadScopeMetadata() {
    this.dataStorageService.getValue<string[]>('scopes').then(scopes => {
      if (scopes && scopes.length > 0) {
        this.dataSubscriptionService.publish('scopes', scopes)
      } else {
        this.metadataControllerService.getClaimScopes([], [])
          .subscribe({
            next: (scopes) => {
              this.dataStorageService.addKeyValuePair('scopes', scopes)
              this.dataSubscriptionService.publish('scopes', scopes)
            }
          });
      }
    })
    this.dataStorageService.getValue<string[]>('informalScopeAggregates').then(scopes => {
      if (scopes && scopes.length > 0) {
        this.dataSubscriptionService.publish('informalScopeAggregates', scopes)
      } else {
        this.metadataControllerService.getClaimScopes([], ['informal', 'aggregate_root'])
          .subscribe({
            next: (scopes) => {
              this.dataStorageService.addKeyValuePair('informalScopeAggregates', scopes)
              this.dataSubscriptionService.publish('informalScopeAggregates', scopes)
            }
          });
      }
    })
    this.dataStorageService.getValue<string[]>('scopeAggregates').then(scopeAggregates => {
      if (scopeAggregates && scopeAggregates.length > 0) {
        this.dataSubscriptionService.publish('scopeAggregates', scopeAggregates)
      } else {
        this.metadataControllerService.getClaimScopes([], ['formal', 'aggregate_root'])
          .subscribe({
            next: (scopes) => {
              this.dataStorageService.addKeyValuePair('scopeAggregates', scopes)
              this.dataSubscriptionService.publish('scopeAggregates', scopes)
            }
          });
      }
    })
    this.dataStorageService.getValue<VerificationMethodView[]>('verificationMethods').then(verificationMethods => {
      if (verificationMethods && verificationMethods.length > 0) {
        this.dataSubscriptionService.publish('verificationMethods', verificationMethods)
      } else {
        this.identityControllerService.getVerificationMethods().subscribe({
          next: methods => {
            this.dataSubscriptionService.publish('verificationMethods', methods)
          }
        })
      }
    })
  }
}