import {Component} from '@angular/core';

import {Router, RouterLink} from "@angular/router";

// import TrezorConnect from "@trezor/connect-web";
import {environment} from "../../environments/environment";
import {DataService} from "../../services/data.service";
import {LogoComponent} from "../common/logo/logo.component";
import {FormsModule} from "@angular/forms";
import {NgIf} from "@angular/common";
import {GenericButtonComponent} from "../common/generic-button/generic-button.component";
import {ErrorModalComponent} from "../common/error-modal/error-modal.component";
import {ErrorMessageService} from "../../services/error-message-service";


@Component({
    selector: 'app-new-license',
    templateUrl: './sign-up.component.html',
    standalone: true,
  imports: [
    LogoComponent,
    FormsModule,
    NgIf,
    GenericButtonComponent,
    ErrorModalComponent,
    RouterLink
  ],
    styleUrls: ['./sign-up.component.scss']
})
export class SignUpComponent {

    protected termsAndConditionsAccepted: boolean = false
    protected method?: string = undefined;
    protected email?: string = undefined;
    protected loading: boolean = false;
    protected showEmailInfo: boolean = false;
    protected didIsTaken?: boolean = undefined
    protected hide = true;
    protected showCopyConfirmation: boolean = false;
    protected methods: string[] = environment.authenticationMethods

    toggleVisibility(): void {
        this.hide = !this.hide;
    }

    constructor(private dataService: DataService, private router: Router, private errorMessageService: ErrorMessageService) {
    }

    isValidEmail = (email?: string) => {
        return String(email)
            .toLowerCase()
            .match(
                /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|.(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
            );
    };

    resolveClass(): string {
        if (this.email == '' || this.email == undefined)
            return 'greyed-out'
        return 'is-valid'
    }

    switchTermsAndConditionsAccepted() {
        this.termsAndConditionsAccepted = !this.termsAndConditionsAccepted
    }

    flipShowEmailInfo() {
        this.showEmailInfo = !this.showEmailInfo
    }

    onNext() {
        if (this.email != undefined && this.isValidEmail(this.email)) {
            this.dataService.publish('email', this.email)
            this.router.navigate(['sign-up/authentication-methods'])
        }
    }

    errorOccurred(): boolean {
       return this.errorMessageService.errorHasOccurred()
    }
    
    private async resolvePublicKey(method: string): Promise<string> {
        switch (method) {
            // case 'trezor': {
            //     const publicKeyRetrievalResponse = await TrezorConnect.getPublicKey({
            //         path: environment.cryptoPath
            //     })
            //     if (publicKeyRetrievalResponse.success) {
            //         return publicKeyRetrievalResponse.payload.publicKey
            //     }
            //     throw new Error(publicKeyRetrievalResponse.payload.error)
            // }
            case 'doatoa' :
                return new Promise((resolve, reject) => {
                    resolve(this.dataService.getSigningPublicKey())
                })
            default:
                throw new Error(`Unknown method: ${method}`)
        }
    }
}
