import {Routes} from '@angular/router';
import {ExtensionLandingComponent} from "./components/landing/extension-landing/extension-landing.component";
import {SignInComponent} from "./components/sign-in/sign-in.component";
import {ActivateUserInitComponent} from "./components/landing/activate/activate-user-init.component";
import {ErrorComponent} from "./components/error/error.component";
import {ConsentPage} from "./components/consent-page/consent-page";
import {SignUpComponent} from "./components/sign-up/sign-up.component";
import {RestoreKeysComponent} from "./components/restore-keys/restore-keys.component";
import {RegisteredLandingComponent} from "./components/landing/register/registered-landing.component";
import {MaintenanceComponent} from "./components/landing/maintenance/maintenance.component";
import {SuccessLandingComponent} from "./components/landing/success/success-landing.component";
import {OauthLandingComponent} from "./components/landing/oauth-landing/oauth-landing.component";
import {SettingsComponent} from "./components/settings/settings.component";
import {DeveloperConsoleComponent} from "./components/developers/developer-console.component";
import {MyIdentityComponent} from "./components/my-identity/my-identity.component";
import {MyClaimsComponent} from "./components/my-identity/my-claims/my-claims.component";
import {CloseAccountComponent} from "./components/close-account/close-account.component";
import {IdentitiesComponent} from "./components/identities/identities.component";
import {SignOutComponent} from "./components/sign-out/sign-out.component";
import {
    AuthenticationMethodComponent
} from "./components/sign-up/authentication-method/authentication-method.component";
import {MnemonicPhraseComponent} from "./components/sign-up/mnemonic-phrase/mnemonic-phrase.component";
import {VerificationMethodComponent} from "./components/sign-up/verification-method/verification-method.component";
import {PaymentComponent} from "./components/sign-up/payment/payment.component";
import {UnderConstructionComponent} from "./components/under-construction/under-construction.component";

export const routes: Routes = [
    {path: '', redirectTo: '/under-construction', pathMatch: 'full'},
    {path: 'under-construction', component: UnderConstructionComponent},
    // {path: '', redirectTo: '/my-identity', pathMatch: 'full'},
    // {path: 'extension', component: ExtensionLandingComponent},
    // {path: 'sign-up', component: SignUpComponent},
    // {path: 'sign-up/authentication-methods', component: AuthenticationMethodComponent},
    // {path: 'sign-up/mnemonic-phrase', component: MnemonicPhraseComponent},
    // {path: 'sign-up/verification-methods', component: VerificationMethodComponent},
    // {path: 'sign-up/payment', component: PaymentComponent},
    // {path: 'sign-in', component: SignInComponent},
    // {path: 'sign-out', component: SignOutComponent},
    // {path: 'activate', component: ActivateUserInitComponent},
    // {path: 'error', component: ErrorComponent},
    // {path: 'consent', component: ConsentPage},
    // {path: 'recover-keys', component: RestoreKeysComponent},
    // {path: 'registered-landing', component: RegisteredLandingComponent},
    // {path: 'maintenance', component: MaintenanceComponent},
    // {path: 'success-landing', component: SuccessLandingComponent},
    // {path: 'oauth-landing', component: OauthLandingComponent},
    // {path: 'error', component: ErrorComponent},
    // {path: 'settings', component: SettingsComponent},
    // {path: 'developers-console', component: DeveloperConsoleComponent},
    // {path: 'my-identity', component: MyIdentityComponent},
    // {path: 'my-claims', component: MyClaimsComponent},
    // {path: 'close-account', component: CloseAccountComponent},
    // {path: 'identities', component: IdentitiesComponent},
];