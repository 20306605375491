import {Injectable} from "@angular/core";
import {hapticsImpactMedium} from "../framework";
import {environment} from "../environments/environment";

@Injectable({
  providedIn: 'root'
})
export class ClipboardService {
  
  showCopyConfirmation: boolean = false;
  
  async copyToClipboard(value: string): Promise<void> {
    if (environment.platform != 'web') {
      await hapticsImpactMedium()
    }
    try {
      await navigator.clipboard.writeText(value);
      this.showCopyConfirmation = true
      setTimeout(() => {
        this.showCopyConfirmation = false
      }, 1000);
    } catch (error) {
      console.error("Failed to copy to clipboard:", error);
    }
  };
}