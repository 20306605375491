import {Injectable} from '@angular/core';
import {IdentityControllerService} from "./api/identity-controller.service";
import {ClaimControllerService} from "./api/claim-controller.service";
import {
  debounceTime,
  distinctUntilChanged,
  filter,
  lastValueFrom,
  Observable,
  of,
  ReplaySubject,
  Subject,
  take
} from "rxjs";
import {KeyStore} from "./key-store";

// import {db, DoaToaDB, Key} from "./doa-toa-db";

@Injectable({
  providedIn: 'root'
})
export class DataService {
  
  private dataStore: Map<string, Subject<any>> = new Map<string, Subject<any>>();
  private initialized: boolean = false
  private isLargeViewport: boolean = true;
  
  constructor(private IdentityControllerService: IdentityControllerService,
              private claimControllerService: ClaimControllerService) {
  }
  
  isLargeViewPort(): boolean {
    return this.isLargeViewport
  }
  
  checkViewportSize(): void {
    this.isLargeViewport = window.innerWidth >= 750;
  }
  
  async getKeyStore(email: string): Promise<KeyStore> {
    const keyStoreString = await lastValueFrom(this.last<string>(email))
    return JSON.parse(keyStoreString) as KeyStore
  }
  
  async getSigningPrivateKey(): Promise<string> {
    const email = await lastValueFrom(this.last<string>('email'))
    const keyStore = await this.getKeyStore(email)
    return keyStore.signingPrivateKey
  }
  
  async getSigningPublicKey(): Promise<string> {
    const email = await lastValueFrom(this.last<string>('email'))
    const keyStore = await this.getKeyStore(email)
    return keyStore.signingPublicKey
  }
  
  async getPreferredMethod(): Promise<string> {
    return new Promise(resolve => {
      this.last<string>('email').subscribe({
        next: async email => {
          const keyStore: KeyStore = await this.getKeyStore(email)
          resolve(keyStore.type)
        }
      })
    })
  }
  
  getEncryptionPrivateKey(): Promise<string> {
    return new Promise(resolve => {
      this.last<string>('email').subscribe({
        next: async email => {
          const keyStore: KeyStore = await this.getKeyStore(email)
          resolve(keyStore.encryptionPrivateKey)
        }
      })
    })
  }
  
  getExchangePrivateKey(): Promise<string> {
    return new Promise(resolve => {
      this.last<string>('email').subscribe({
        next: email => {
          this.last<KeyStore>(email).subscribe({
            next: keyStore => {
              resolve(keyStore.exchangePrivateKey)
            }
          });
        }
      })
    })
  }
  
  continuousPublications<T>(key: string): Observable<T> {
    this.guard(key);
    if (localStorage.getItem(key) != undefined) {
      return of(JSON.parse(localStorage.getItem(key)!));
    }
    return this.dataStore.get(key)!
      .asObservable()
      .pipe(filter(x => x !== undefined))
  }
  
  continuousChanges<T>(key: string): Observable<T> {
    this.guard(key);
    if (localStorage.getItem(key) != undefined) {
      return of(JSON.parse(localStorage.getItem(key)!));
    }
    return this.dataStore.get(key)!
      .asObservable()
      .pipe(filter(x => x !== undefined))
      .pipe(distinctUntilChanged())
  }
  
  last<T>(key: string): Observable<T> {
    return this.continuousChanges<T>(key)
      .pipe(debounceTime(0), take(1))
  }
  
  publish<T>(key: string, value?: T, durable: boolean = false): T | undefined {
    this.guard(key);
    if (durable) {
      localStorage.setItem(key, JSON.stringify(value))
    }
    this.dataStore.get(key)!.next(value);
    return value
  }
  
  private guard(key: string) {
    if (!this.dataStore.has(key)) {
      this.dataStore.set(key, new ReplaySubject<any>(1))
    }
  }
  
  has(name: string): boolean {
    return this.dataStore.has(name) || localStorage.getItem(name) != undefined
  }
  
  remove(name: string) {
    localStorage.removeItem(name)
    this.publish(name)
  }
  
  clear() {
    localStorage.clear()
    this.dataStore.clear()
  }
  
  init() {
    if (this.initialized) {
      return;
    }
    this.publish('dataLoading', true)
    this.last<string>('did').subscribe({
      next: (did) => {
        this.loadScopes();
        this.loadScopeAggregates();
        this.loadIdentity(did);
      }
    })
  }
  
  private loadIdentity(did: string) {
    this.IdentityControllerService.viewIdentity(did!)
      .subscribe({
        next: (identity) => {
          this.publish('identity', identity, true)
          // this.publish('license', identity.license?.details?.name!!)
          this.publish('dataLoading', false)
          this.initialized = true;
        }
      });
  }
  
  private loadScopeAggregates() {
    if (!this.has('scopeAggregates')) {
      this.claimControllerService.getClaimMetadata(undefined, "aggregates")
        .subscribe({
          next: (scopes) => {
            this.publish('scopeAggregates', scopes)
            this.publish('filteredScopeAggregates', scopes.filter((x, y, z) => x != 'profile'))
            scopes.forEach(scope => this.loadClaimTypes(scope + 'claimTypes', scope))
          }
        });
    }
  }
  
  private loadClaimTypes(key: string, scope: string) {
    if (!this.has(key)) {
      this.claimControllerService.getClaimMetadata([scope])
        .subscribe({
          next: (response) => {
            this.publish(key, response)
          }
        });
    }
  }
  
  private loadScopes() {
    if (!this.has('scopes')) {
      this.claimControllerService.getClaimMetadata(undefined, "openid")
        .subscribe({
          next: (response) => {
            this.publish('scopes', response)
          }
        });
    }
  }
  
}