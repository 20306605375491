import {Injectable} from '@angular/core';
import {IdentityControllerService} from "./api/identity-controller.service";
import {debounceTime, distinctUntilChanged, filter, first, Observable, ReplaySubject, Subject, take, tap} from "rxjs";
import {ScopeControllerService} from "./api/scope-controller.service";
import {CryptoWalletService} from "./crypto-wallet.service";
import {DataStorageService} from "./data-storage.service";
import {IdentityView} from "./api/models/identity-view";
import {SplashScreen} from "@capacitor/splash-screen";
import {VerificationMethodView} from "./api/models/verification-method-view";

@Injectable({
  providedIn: 'root'
})
export class DataSubscriptionService {
  
  private dataStore: Map<string, Subject<any>> = new Map<string, Subject<any>>();
  private isLargeViewport: boolean = true;
  
  isLargeViewPort(): boolean {
    return this.isLargeViewport
  }
  
  checkViewportSize(): void {
    this.isLargeViewport = window.innerWidth >= 750;
  }
  
  // Returns every next encountered non-undefined value
  onNext<T>(key: string): Observable<T> {
    this.guard(key);
    return this.dataStore.get(key)!
      .asObservable()
      .pipe(filter(x => x != undefined));
  }
  
  // Returns every next encountered non-undefined 'unique' value.
  // If multiples of the same value are published, only the first one is passed as an observable.
  onChanges<T>(key: string): Observable<T> {
    this.guard(key);
    return this.onNext<T>(key)
      .pipe(distinctUntilChanged())
  }
  
  // Completes after the first non-undefined value
  onFirst<T>(key: string): Observable<T> {
    this.guard(key);
    return this.onNext<T>(key)
      .pipe(first());
  }
  
  publish<T>(key: string, value?: T): T | undefined {
    this.guard(key);
    this.dataStore.get(key)!.next(value);
    return value
  }
  
  has(name: string): boolean {
    return this.dataStore.has(name)
  }
  
  private guard(key: string) {
    if (!this.dataStore.has(key)) {
      this.dataStore.set(key, new ReplaySubject<any>(1))
    }
  }
  
  unsubscribe<T>(key: string) {
    this.publish<T>(key)
  }
  
  async destroy() {
    Array.from(this.dataStore.keys()).filter(key => key != 'showMenu').forEach(key => this.unsubscribe(key))
  }
}