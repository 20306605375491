/**
 * DoaToa
 * Manage your data and your digital identities, your data your choice.
 *
 * The version of the OpenAPI document: 1.0.0-alpha
 * Contact: info@compilit.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
/* tslint:disable:no-unused-variable member-ordering */

import {Injectable} from '@angular/core';
import {HttpContext} from '@angular/common/http';
import {Observable} from 'rxjs';

// @ts-ignore
import {CredentialsPayload} from '../model/credentials-payload';

// @ts-ignore
import {BASE_PATH, COLLECTION_FORMATS} from '../variables';
import {AbstractHttpService} from "./abstract-http-service";


@Injectable({
    providedIn: 'root'
})
export class ApiStatusControllerService extends AbstractHttpService {


    checkApiStatus(options?: {
        httpHeaderAccept?: undefined,
        context?: HttpContext
    }): Observable<any> {
        let localVarHeaders = this.defaultHeaders;
        localVarHeaders = localVarHeaders.append('Content-Type', 'application/json')
        localVarHeaders = localVarHeaders.append('Accept', 'application/json')

        

        


        

        
        

        let localVarPath = `/public/api-status/health`;
        return this.httpClient.request<any>('get', `${this.configuration.basePath}${localVarPath}`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: localVarHeaders
            }
        );
    }


}
