import {EventEmitter, Injectable} from '@angular/core';

import {CacheService} from "./cache-service";
import {DataService} from "./data.service";
import {OauthControllerService} from "./api/oauth-controller.service";

@Injectable({
    providedIn: 'root'
})
export class TokenRefreshService {

    private lastRefresh: number = Date.now()
    private refreshing: boolean = false;
    private eventEmitter: EventEmitter<Event> = new EventEmitter<Event>();

    constructor(private dataService: DataService, private cacheService: CacheService, private oauthControllerService: OauthControllerService) {
        document.addEventListener('click', (event) => {
            this.eventEmitter.emit(event);
        });
        this.on('click', (event: Event) => {
            if (!(event.target instanceof Element) || this.refreshing) {
                return;
            }
            const clickedElement = event.target as Element;
            const clickedElementId = clickedElement.id;
            if (this.shouldExcludeEvent(clickedElementId)) {
                return;
            }
            this.refreshTokensManually()
        });
    }

    private shouldExcludeEvent(elementId: string): boolean {
        const exclusions = Array.of(
            'did',
            'password',
            'sign-in',
            'password-reset',
            'sign-out-menu-item',
            'consent-button-true',
            'consent-button-false',
            'settings-sign-out-button'
        )
        return exclusions.includes(elementId)
    }

    on(eventName: string, callback: (event: Event) => void) {
        this.eventEmitter.subscribe((event: Event) => {
            if (eventName === event.type) {
                callback(event);
            }
        });
    }

    refreshTokens(intervalInMinutes: number) {
        setInterval(() => {
            this.refreshTokensManually()
        }, intervalInMinutes * 60 * 1000);
    }

    refreshTokensManually() {
        if (Date.now() - this.lastRefresh >= 60000) {
            this.refreshing = true
            this.dataService.last<string>('refreshToken').subscribe({
                next: refreshToken => {
                    this.cacheService.bypassCache = true
                    this.oauthControllerService.refreshToken(refreshToken!)
                        .subscribe({
                            next: (response) => {
                                this.dataService.publish('jwt', response.access_token!)
                                this.dataService.publish('refreshToken', response.refresh_token!)
                                this.dataService.publish('idToken', response.id_token!)
                                this.dataService.publish('idTokenHint', response.id_token_hint!)
                                this.lastRefresh = Date.now()
                                this.refreshing = false
                            }
                        });
                }
            })
        }
    }
}
