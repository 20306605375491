import {Component, EventEmitter, Output} from '@angular/core';

import {HttpErrorResponse} from "@angular/common/http";
import {IdentityReferenceView} from "../common/services/model/identity-reference-view";
import {CacheService} from "../common/services/cache-service";
import {DataService} from "../common/services/data-storage.service";
import {IdentityControllerService} from "../common/services";

@Component({
    selector: 'app-identities',
    templateUrl: './identities.component.html',
    styleUrls: ['./identities.component.scss']
})
export class IdentitiesComponent {

    protected loading: boolean = false
    protected defaultSelected: boolean = false
    @Output() protected publicProfilesLoading: EventEmitter<boolean> = new EventEmitter<boolean>()
    protected claimTypes: Array<string> = []
    protected identities: Array<IdentityReferenceView> = []
    protected did?: string = undefined
    protected defaultRequestMessage = 'No subscription status filter'
    protected requestStatus?: string = this.defaultRequestMessage
    protected errorOccurred: boolean = false
    protected errorMessage: string = ''
    protected currentPage: number = 0
    protected pageSize: number = 10
    protected counter: number = 1

    constructor(private cacheService: CacheService, protected dataService: DataService, private identityControllerService: IdentityControllerService) {
    }

    reset() {
        this.defaultSelected = true
        this.identities = []
        this.requestStatus = this.defaultRequestMessage
        this.did = undefined
        this.pageSize = 10
        this.currentPage = 0
    }

    findIdentities() {
        this.loading = true
        this.publicProfilesLoading.emit(this.loading)
        this.cacheService.bypassCache = true
        const queriedStatus = this.requestStatus == this.defaultRequestMessage ? undefined : this.requestStatus
        this.identityControllerService.findIdentities(this.did, queriedStatus, this.currentPage, this.pageSize)
            .subscribe({
                next: (response) => {
                    this.identities.push(...response)
                    this.loading = false
                    this.publicProfilesLoading.emit(this.loading)
                },
                error: (error) => {
                    this.loading = false
                    this.publicProfilesLoading.emit(this.loading)
                    if (error instanceof HttpErrorResponse) {
                        this.errorOccurred = true
                    }
                }
            });
    }

    onScrolledDown() {
        this.currentPage++
        this.findIdentities();
    }

    setStatusFilter($event: any) {
        this.requestStatus = $event.target.value
    }
}
