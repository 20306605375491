import {Component, Input} from '@angular/core';

@Component({
  selector: 'app-logo',
  standalone: true,
  templateUrl: './logo.component.html',
  imports: [],
  styleUrls: ['./logo.component.scss']
})
export class LogoComponent {
  
  @Input() width: string = '200'
  @Input() height: string = '150'
  @Input() classes: string = 'mx-auto d-block mt-2'
  
}
