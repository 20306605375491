import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';

import {HttpErrorResponse} from "@angular/common/http";
import {
    ClaimView,
    
    DecisionPayload,
    DecisionPayloadNs,
    SubscriptionControllerService,
    SubscriptionView
} from "../../../services";
import {DataService} from "../../../services/data.service";
import {CryptographyService} from "../../../services/cryptography.service";
import {ActionButtonComponent} from "../action-button/action-button.component";
import {ToReadableStringPipe} from "../pipes/ToReadableStringPipe";
import {NgForOf, NgIf} from "@angular/common";
import {FormsModule} from "@angular/forms";
import {EventComponent} from "../event/event.component";
import {ToReadableSubscriptionStatusPipe} from "../pipes/ToReadableRequestStatusPipe";
import DecisionEnum = DecisionPayloadNs.DecisionEnum;

@Component({
    selector: 'app-subscription',
    templateUrl: './subscription.component.html',
    standalone: true,
    imports: [
        ActionButtonComponent,
        ToReadableStringPipe,
        NgForOf,
        NgIf,
        FormsModule,
        EventComponent,
        ToReadableSubscriptionStatusPipe
    ],
    styleUrls: ['./subscription.component.scss']
})
export class SubscriptionComponent implements OnInit {

    protected loading: boolean = false
    protected loadingMap: Map<string, boolean> = new Map<string, boolean>()
    protected reason?: string = undefined
    protected password?: string = undefined
    @Input() requestee: string = ''
    @Input() parent!: ClaimView;
    @Input() addressedToMe: boolean = false
    @Input() initiatedByMe: boolean = false
    @Input() subscription!: SubscriptionView
    @Output() requestDeleted: EventEmitter<SubscriptionView> = new EventEmitter<SubscriptionView>()
    @Output() requestUpdated: EventEmitter<SubscriptionView> = new EventEmitter<SubscriptionView>()
    errorOccurred: boolean = false

    constructor(private dataService: DataService, private cryptographyService: CryptographyService, private subscriptionCommandControllerService: SubscriptionControllerService) {
    }

    ngOnInit(): void {
    }

    //todo: move to pipe

    resolveIcon(status: string): string {
        switch (status) {
            case "created":
                return 'fa-solid fa-clock secondary-info'
            case "pending":
                return 'fa-solid fa-question-circle secondary-info'
            case "approved":
                return 'fa fa-check secondary-info'
            case "denied":
                return 'fa-solid fa-circle-xmark primary-danger'
            case "altered":
                return 'fa fa-question-circle secondary-info'
            case "exception":
                return 'fa fa-question-circle primary-danger'
            case "archived":
                return 'fa fa-archive greyed-out'
            default:
                return ''
        }
    }

    async processRequest(verdict: DecisionEnum) {
        if (this.requestIsOpen(this.subscription)) {
            this.loadingMap.set(verdict, true)
            const publicKeyString = this.subscription.publicKey!
            const myPrivateKey = await this.dataService.getEncryptionPrivateKey()
            const cryptoKey = await this.cryptographyService.publicRSAStringToCryptoKey(publicKeyString)
            const encryptedPrivateKey = await this.cryptographyService.encryptRSA(cryptoKey, myPrivateKey)
            const decisionPayload: DecisionPayload = {
                reason: this.reason,
                decision: verdict,
                encryptedPrivateKey: encryptedPrivateKey
            }
            this.subscriptionCommandControllerService.processRequest(this.subscription.id!!, decisionPayload)
                .subscribe({
                        next: (response) => {
                            this.loadingMap.set(verdict, false)
                            this.subscription = response
                            this.requestUpdated.emit(this.subscription)
                        },
                        error: (error) => {
                            if (error instanceof HttpErrorResponse) {
                                this.errorOccurred = true
                            }
                            this.loading = false
                        }
                    }
                )
        }
    }

    requestIsOpen(request
                      :
                      SubscriptionView
    ):
        boolean {
        switch (request.status) {
            case "none":
                return true
            case "created":
                return true
            case "pending":
                return true
            case "approved":
                return false
            case "denied":
                return false
            case "altered":
                return true
            case "exception":
                return true
            case "archived":
                return false
            default:
                return true
        }
    }

    deleteRequest() {
        this.loadingMap.set('delete', true)
        this.subscriptionCommandControllerService.delete(this.subscription.id!)
            .subscribe({
                next: (response) => {
                    this.loadingMap.set('delete', false)
                    this.requestDeleted.emit(this.subscription)
                },
                error: (error) => {
                    this.loading = false
                    if (error instanceof HttpErrorResponse) {
                        this.errorOccurred = true
                    }
                }
            });
    }
}