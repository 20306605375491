<div class="container p-3">
    <div class="row">
        <div class="col-xl-6">
            <app-account></app-account>
        </div>
        <div class="col-xl-6">
            <app-developers></app-developers>
        </div>
    </div>
</div>

