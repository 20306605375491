import {NgModule} from '@angular/core';
import {AccountComponent} from "./account/account.component";
import {DevelopersComponent} from "./developers/developers.component";
import {LicenseIconComponent} from "./license-icon/license-icon.component";
import {SettingsComponent} from "./settings.component";
import {GenericMessageComponent} from "../common/generic-message/generic-message.component";
import {FormsModule} from "@angular/forms";
import {GenericButtonComponent} from "../common/generic-button/generic-button.component";
import {ErrorModalComponent} from "../common/error-modal/error-modal.component";
import {SpinnerComponent} from "../common/spinner/spinner.component";
import {LoadingPlaceholderComponent} from "../common/loading-placeholder/loading-placeholder.component";
import {NgIf, NgOptimizedImage} from "@angular/common";
import {ToLicenseNameWithFrequencyPipe} from "../common/pipes/ToLicenseNameWithFrequencyPipe";
import {ToDateFormatPipe} from "../common/pipes/ToDateFormatPipe";
import {ToCurrencySymbolPipe} from "../common/pipes/ToCurrencySymbolPipe";
import {RouterLink} from "@angular/router";


@NgModule({
  imports: [
    GenericMessageComponent,
    FormsModule,
    GenericButtonComponent,
    ErrorModalComponent,
    SpinnerComponent,
    LoadingPlaceholderComponent,
    NgIf,
    ToLicenseNameWithFrequencyPipe,
    ToDateFormatPipe,
    RouterLink,
    NgOptimizedImage
  ],
    declarations: [AccountComponent, DevelopersComponent, LicenseIconComponent, SettingsComponent, ToCurrencySymbolPipe]
})
export class SettingsModule {
}