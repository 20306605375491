export const environment = {
  production: true,
  appScheme: "doatoa://",
  platform: 'web',
  did: "did:key:z6Mkg5xJwFgykERcsHkaE5eSyjKs1Q2tqAHtBi5FRbPF221X",
  idpApiBaseUrl: 'https://dev.api.doatoa.io',
  webappBaseUrl: 'https://dev.app.doatoa.io',
  cryptoPath: "m/44'/0'/0'/0/0", //m / purpose' / coin_type' / account' / change / address_index
  cryptoNet: 0x00, //0x00 for mainnet, 0x6f for testnet
};
