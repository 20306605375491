import { Component } from '@angular/core';
import {LogoComponent} from "../common/logo/logo.component";

@Component({
  selector: 'app-under-construction',
  standalone: true,
  imports: [
    LogoComponent
  ],
  templateUrl: './under-construction.component.html',
  styleUrl: './under-construction.component.scss'
})
export class UnderConstructionComponent {

}
