import {Component, OnInit} from '@angular/core';
import {LogoComponent} from "../../common/logo/logo.component";
import {DataSubscriptionService} from "../../../services/data-subscription.service";
import {CryptoWalletService} from "../../../services/crypto-wallet.service";
import {Router} from "@angular/router";

@Component({
  selector: 'app-authentication-method',
  standalone: true,
  imports: [
    LogoComponent
  ],
  templateUrl: './authentication-method.component.html',
  styleUrl: './authentication-method.component.scss'
})
export class AuthenticationMethodComponent implements OnInit {
  
  protected chosenAuthenticationMethod? = 'doatoa'
  
  constructor(private dataSubscriptionService: DataSubscriptionService, private cryptoWalletService: CryptoWalletService, private router: Router) {
  }
  
  ngOnInit() {
    this.initialiseKeyStore()
  }
  
  flipMethod(method: string) {
    if (this.chosenAuthenticationMethod == method) {
      this.chosenAuthenticationMethod = undefined
    } else {
      this.chosenAuthenticationMethod = method
    }
  }
  
  async initialiseKeyStore() {
    this.dataSubscriptionService.publish('authenticationMethod', this.chosenAuthenticationMethod);
    const email = await this.cryptoWalletService.getStored('email')
    const mnemonic = await this.cryptoWalletService.initialise(email!, this.chosenAuthenticationMethod!)
    this.dataSubscriptionService.publish('mnemonic', mnemonic)
    this.router.navigate(['sign-up/mnemonic-phrase'])
  }
}
