import {Component, HostListener, OnInit, ViewEncapsulation} from '@angular/core';
import {Router, RouterOutlet} from "@angular/router";
import {TextFieldModule} from "@angular/cdk/text-field";
import {DataSubscriptionService} from "./services/data-subscription.service";
import {MobileMenuComponent} from "./views/common/menubar/mobile-menu.component";
import {DataStorageService} from "./services/data-storage.service";
import {performBiometricVerification} from "./services/biometric-auth.service";
import {environment} from "./environments/environment";
import {SplashScreen} from "@capacitor/splash-screen";
import {
  getSafeAreaInsets,
  getStatusBarHeight,
  onAppUrlOpen,
  onKeyboardWillHide,
  onKeyboardWillShow,
  onResume, showSplashScreen
} from "./framework";

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
  standalone: true,
  imports: [
    TextFieldModule,
    RouterOutlet,
    MobileMenuComponent
  ],
  encapsulation: ViewEncapsulation.None
})
export class AppComponent implements OnInit {
  title = 'doatoa-wallet';
  protected showMenu: boolean = false;
  protected readonly environment = environment;
  
  constructor(private dataSubscriptionService: DataSubscriptionService, private sqliteService: DataStorageService, private router: Router) {
  }
  
  @HostListener('window:resize', ['$event'])
  onResize(event: any) {
    this.dataSubscriptionService.checkViewportSize();
  }
  
  ngOnInit(): void {
    SplashScreen.show()
    this.dataSubscriptionService.checkViewportSize();
    // if (environment.platform == "web") {
    //   alert("You are using DoaToa in a browser, which is not secure. Consider switching to the app.")
    // }
    this.dataSubscriptionService.onChanges<boolean>('showMenu').subscribe(showMenu => {
      this.showMenu = showMenu;
    })

    this.configureNativeKeyboard();
    this.configureSafeAreaInsets();
    this.subscribeToWeb2AppProcesses();
    this.configureAuthorisation();
  }
  
  private configureAuthorisation() {
    onResume(this.router, () => {
      showSplashScreen()
    })
  }
  
  private subscribeToWeb2AppProcesses() {
    onAppUrlOpen((data: any) => {
      if (data) {
        console.log('App opened with URL: ' + data?.url);
        
        const url = new URL(data.url);
        const params = new URLSearchParams(url.search);
        
        const correlationId = params.get('correlationId');
        const origin = params.get('origin');
        
        if (correlationId && origin) {
          this.dataSubscriptionService.publish('showMenu', false)
          this.dataSubscriptionService.publish('processParams', {correlationId: correlationId, origin: origin})
          this.router.navigate(['/landing/process'])
        } else this.router.navigate(['/my-identity']);
      }
    });
  }
  
  private configureSafeAreaInsets() {
    getSafeAreaInsets().then(({insets}) => {
      for (const [key, value] of Object.entries(insets)) {
        document.documentElement.style.setProperty(
          `--safe-area-inset-${key}`,
          `${value}px`,
        );
      }
    });
    
    getStatusBarHeight().then(({statusBarHeight}) => {
      document.documentElement.style.setProperty(
        `--safe-area-inset-statusbar`,
        `${statusBarHeight}px`,
      );
    });
  }
  
  private configureNativeKeyboard() {
    if (environment.platform != 'web') {
      onKeyboardWillShow((info) => {
        document.body.style.paddingBottom = `${info.keyboardHeight}px`;
      });
      onKeyboardWillHide(() => {
        document.body.style.paddingBottom = 'env(safe-area-inset-bottom)';
      })
    }
  }
}

