import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import * as uuid from "uuid";
import {IdentityView} from "../../../../services/api/models/identity-view";
import {ClaimView} from "../../../../services/api/models/claim-reference-view";
import {DataSubscriptionService} from "../../../../services/data-subscription.service";
import {NgClass, NgForOf, NgIf} from "@angular/common";
import {ToReadableStringPipe} from "../../../common/pipes/ToReadableStringPipe";
import {ToVisibilityScopeIconPipe} from "../../../common/pipes/ToVisibilityScopeIconPipe";
import {ClaimComponent} from "../../../common/claim/claim.component";


@Component({
  selector: 'app-claims',
  standalone: true,
  templateUrl: './claims.component.html',
  imports: [
    NgForOf,
    NgIf,
    ToReadableStringPipe,
    ToVisibilityScopeIconPipe,
    ClaimComponent,
    NgClass
  ],
  styleUrl: './claims.component.scss'
})
export class ClaimsComponent implements OnInit {
  
  @Output() protected reloadCurrentProfile: EventEmitter<any> = new EventEmitter();
  @Input() identity!: IdentityView
  protected errorOccurred: boolean = false
  protected claimLoading: Map<string, boolean> = new Map<string, boolean>()
  protected open: Map<string, boolean> = new Map<string, boolean>()
  protected shortSummary: string = ''
  protected profileId?: string = 'my-claims-reference-' + uuid.v4()
  protected requestedClaim: ClaimView = {}
  protected requestedClaims: Map<string, ClaimView> = new Map<string, ClaimView>()
  protected loading: boolean = false;
  protected claimLoaded: boolean = false;
  
  constructor(protected dataSubscriptionService: DataSubscriptionService) {
  }
  
  ngOnInit() {
  }
  
  openCloseClaim(claimView: ClaimView) {
    this.open.set(claimView.cid!, !this.open.get(claimView?.cid || '') || false)
  }
  
  
  protected readonly sessionStorage = sessionStorage;
}
