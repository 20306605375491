import {ConfigurationParameters} from "../services/";

export const environment = {
    production: true,
    did: "did:key:z6Mkg5xJwFgykERcsHkaE5eSyjKs1Q2tqAHtBi5FRbPF221X",
    idpApiBaseUrl: 'https://dev.api.doatoa.io',
    webappBaseUrl: 'https://dev.app.doatoa.io',
    webappClientId: 'dbf3007a-60d3-48c6-83cc-9d214119af24',
    webappExtensionClientId: '59284a9d-7c21-4c20-b1cb-0ed90a279d92',
    cryptoPath: "m/44'/0'/0'/0/0", //m / purpose' / coin_type' / account' / change / address_index
    cryptoNet: 0x00, //for mainnet, 0x6f for testnet
    authenticationMethods: ['doatoa', 'trezor'],
    verificationMethods: ['idin'],
    configurationParameters: new class implements ConfigurationParameters {
        withCredentials = true
    }
};
