/**
 * DoaToa
 * Manage your data and your digital identities, your data your choice.
 *
 * The version of the OpenAPI document: 1.0.0-alpha
 * Contact: info@compilit.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


export interface DecisionPayload {
    decision?: DecisionPayloadNs.DecisionEnum;
    reason?: string;
    encryptedPrivateKey?: string;
}

export namespace DecisionPayloadNs {
    export type DecisionEnum = 'approve' | 'deny';
    export const DecisionEnum = {
        Approve: 'approve' as DecisionEnum,
        Deny: 'deny' as DecisionEnum
    };
}


