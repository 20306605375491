import {Component, Input} from '@angular/core';

@Component({
  selector: 'lib-loading-placeholder',
  templateUrl: './loading-placeholder.component.html',
  standalone: true,
  styleUrl: './loading-placeholder.component.css'
})
export class LoadingPlaceholderComponent {

  @Input() classes: string = ''
  @Input() show: boolean = false
}
