<div class="container">
    <div class="row justify-content-center">
        <app-logo [classes]="'mx-auto d-block mt-2'"
                  [height]="'200'"
                  [image]="'/doatoa-logo-full.svg'"
                  [width]="'300'"></app-logo>
        <div class="col-auto text-center card max-width-400 p-3">
            <div class="mb-2">Select your authentication method.</div>
            <div *ngFor="let method of methods" class="row ms-2">
                <input (change)="flipMethod(method)"
                       [checked]="chosenAuthenticationMethod == method"
                       class="rounded-5 form-check-input w-auto col-2"
                       id="authMethodSwitch-{{method}}"
                       type="checkbox">
                <label class="col-10" for="authMethodSwitch-{{method}}">{{ method | toCapitalizedText }}
                    <a href="https://doatoa.com/docs/auth-methods" target="_blank">Learn more</a>
                </label>
            </div>
            <div class="mt-2">
                <app-generic-button [buttonText]="'Next'"
                                    [btnClass]="'btn-basic-info'"
                                    [icon]="'fa fa-angle-right'"
                                    [disabled]="chosenAuthenticationMethod == undefined"
                                    (click)="initialiseKeyStore()"></app-generic-button>
            </div>
        </div>
    </div>
</div>