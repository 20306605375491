<svg xmlns="http://www.w3.org/2000/svg"
     [attr.width]="width"
     [attr.height]="height"
     style="{margin: auto}"
     viewBox="-0.5 -0.5 371 201">
    <defs/>
    <g>
        <ellipse cx="325"
                 cy="145"
                 rx="35"
                 ry="35"
                 fill="transparent"
                 stroke="#3A86FF"
                 stroke-width="20"
                 pointer-events="all"/>
        <path d="M 360 100 L 360 190"
              fill="none"
              stroke="#3A86FF"
              stroke-width="20"
              stroke-miterlimit="10"
              pointer-events="stroke"/>
        <ellipse cx="255"
                 cy="145"
                 rx="35"
                 ry="35"
                 fill="transparent"
                 stroke="#1eb6ff"
                 stroke-width="20"
                 pointer-events="all"/>
        <ellipse cx="185"
                 cy="145"
                 rx="35"
                 ry="35"
                 fill="transparent"
                 stroke="#3A86FF"
                 stroke-width="20"
                 pointer-events="all"/>
        <ellipse cx="115"
                 cy="145"
                 rx="35"
                 ry="35"
                 fill="transparent"
                 stroke="#1eb6ff"
                 stroke-width="20"
                 pointer-events="all"/>
        <ellipse cx="45"
                 cy="145"
                 rx="35"
                 ry="35"
                 fill="transparent"
                 stroke="#3333ff"
                 stroke-width="20"
                 pointer-events="all"/>
        <path d="M 80 10 L 80 190"
              fill="none"
              stroke="#3333ff"
              stroke-width="20"
              stroke-miterlimit="10"
              pointer-events="stroke"/>
        <path d="M 260 145 L 220 144.76"
              fill="none"
              stroke="#fa4be8"
              stroke-width="20"
              stroke-miterlimit="10"
              pointer-events="stroke"/>
        <path d="M 220 10 L 220 190"
              fill="none"
              stroke="#fa4be8"
              stroke-width="20"
              stroke-miterlimit="10"
              pointer-events="stroke"/>
    </g>
</svg>
