<div aria-hidden="true"
     aria-labelledby="new-claim-modal-label"
     class="modal fade"
     [id]="customId"
     role="dialog"
     tabindex="-1">
    <div class="modal-dialog"
         role="document">
        <div class="modal-content">
            <div class="modal-header">
                <h5 *ngIf="proposed" class="modal-title"
                    >{{'Propose a claim to ' + subject }}</h5>
                <h5 *ngIf="!proposed" class="modal-title"
                    >Add a new claim</h5>
                <button (click)="reset()"
                        aria-label="Close"
                        class="btn-close float-end m-1"
                        data-bs-dismiss="modal"
                        type="button">
                </button>
            </div>
            <div class="modal-body">
                <form (submit)="tryMakeClaim()">
                    <div class="container">
                        <div class="row">
                            <select (change)="setScope($event)"
                                    class="rounded-5 mb-3 col form-select"
                                    id="claim-scope"
                                    name="type">
                                <option disabled
                                        [selected]="!scopeSelected"
                                        value="">Choose a category...
                                </option>
                                <option *ngFor="let scope of scopes"
                                        value="{{scope}}">{{ scope | toReadableString }}
                                </option>
                            </select>
                        </div>
                        <ng-container *ngIf="scopeSelected">
                            <div class="row">
                                <select (change)="setClaimType($event)"
                                        class="rounded-5 mb-3 col form-select"
                                        id="claimType"
                                        name="type">
                                    <option disabled
                                            [selected]="selectedClaimType == undefined"
                                            value="">Make a claim about a...
                                    </option>
                                    <option *ngFor="let claimType of claimTypes | toFilteredClaimTypes"
                                            value="{{claimType}}">{{ claimType |  toReadableString }}
                                    </option>
                                </select>
                            </div>
                            <div class="row">
                                <input *ngIf="!isImageValue" [(ngModel)]="claimPayload.factValue"
                                       class="rounded-5 col form-control mb-3"
                                       id="value"
                                       name="value"
                                       placeholder="Fill in your '{{selectedClaimTypeString |  toReadableString}}'...">
                                <div *ngIf="isImageValue"
                                     class="m-3">
                                    <label class="custom-file-upload z-2 mb-3">
                                        <input (change)="fileChangeEvent($event)"
                                               accept="image/*"
                                               id="claim-input-file"
                                               type="file"/>
                                    </label>
                                </div>
                            </div>
                            <div class="row">
                <textarea (change)="updateDescription($event)"
                          class="rounded-1 col form-control mb-3"
                          id="description"
                          name="description"
                          placeholder="Add a description (optional)"></textarea>
                            </div>
                            <div class="d-flex justify-content-end p-0 mb-3" *ngIf="!proposed">
                                <div class="d-flex border rounded-5 p-1 secondary-blue">
                                    <app-action-button *ngFor="let visibilityScope of VisibilityScopeEnum.values(); let first = first; let last = last;"
                                                       (click)="toggleVisibility(visibilityScope)"
                                                       [btnClass]="claimPayload!.claimVisibilityScope == visibilityScope ? 'btn-basic-info' : 'btn-basic-disabled'"
                                                       [showPrimary]="claimPayload!.claimVisibilityScope == visibilityScope"
                                                       [showSecondary]="claimPayload!.claimVisibilityScope != visibilityScope"
                                                       [primaryIconClass]="visibilityScope | toVisibilityScopeIcon"
                                                       [secondaryIconClass]="visibilityScope | toOppositeVisibilityScopeIcon"
                                                       class="{{last ? 'ms-1' : first ? '' : 'ms-1'}}">
                                    </app-action-button>
                                </div>
                            </div>
                        </ng-container>
                    </div>
                    <app-generic-button
                            *ngIf="selectedClaimTypeString != ''"
                            [type]="'submit'"
                            [disabled]="loading || claimPayload.factValue == undefined || claimPayload.factValue == ''"
                            [loading]="loading"
                            [buttonText]="proposed ? 'Propose claim' : 'Make claim'"
                            [icon]="'fa-solid fa-flag'">
                    </app-generic-button>
                </form>
                <div class="row justify-content-center">
                    <div class="col-lg-9">
                        <app-error-modal [message]="errorMessage"
                                         (modalFinished)="resetError()"
                                         [showModal]="errorOccurred"></app-error-modal>
                        <lib-generic-message [show]="claimMadeSuccessfully"
                                             [message]="'Claim was made successfully'"></lib-generic-message>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>

