<form>
    <div *ngIf="filePath == ''">
        <div [ngStyle]="{backgroundImage: 'url(/person-circle-dark.svg)', borderRadius: '185px', backgroundRepeat: 'no-repeat', backgroundSize: 'cover', width: '185px', height: '185px'}"
             class="image-container">
            <app-spinner [loading]="loading"
                         class="z-3"
                         [style]="'width: 185px; height: 185px;'"></app-spinner>
            <label class="custom-file-upload z-3"
                   for="avatar-input-file">
                <app-action-button class="z-n1"
                                   *ngIf="!loading"
                                   [showPrimary]="true"
                                   [useSpinner]="true"
                                   [loading]="loading"
                                   [btnClass]="'btn-basic-info m-2'"
                                   [primaryIconClass]="'fa fa-pencil'"></app-action-button>
            </label>
        </div>
    </div>
    <div *ngIf="filePath != ''"
         [ngClass]="{'box-shadow-avatar': !!filePath}"
         [ngStyle]="{backgroundImage: 'url(' + filePath + ')', backgroundSize: 'cover', width: '185px', height: '185px', border: '3px solid rgb(58,134,255)', borderRadius: '185px',}"
         class="image-container">
        <label class="custom-file-upload z-2"
               for="avatar-input-file">
            <app-action-button *ngIf="base64UploadedImage == ''  && !loading"
                               data-bs-target="#avatar-input-file"
                               class="z-2"
                               [showPrimary]="base64UploadedImage == ''"
                               [useSpinner]="true"
                               [disabled]="loading"
                               [loading]="loading"
                               [btnClass]="'btn-basic-info m-2'"
                               [primaryIconClass]="'fa fa-pencil'"></app-action-button>
        </label>
        <app-action-button *ngIf="base64UploadedImage != ''"
                           (click)="uploadPicture()"
                           class="z-3"
                           [showPrimary]="base64UploadedImage != ''"
                           [useSpinner]="true"
                           [disabled]="loading"
                           [loading]="loading"
                           [btnClass]="'btn-basic-info m-2'"
                           [primaryIconClass]="'fa fa-check'"></app-action-button>
    </div>
    <input (change)="fileChangeEvent($event)"
           accept="image/*"
           id="avatar-input-file"
           type="file"/>

</form>