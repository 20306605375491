import {ApplicationConfig, importProvidersFrom, inject, provideZoneChangeDetection} from '@angular/core';
import {provideRouter, withHashLocation} from '@angular/router';

import {routes} from './app.routes';
import {
  HttpErrorResponse,
  HttpInterceptorFn,
  HttpResponse,
  provideHttpClient,
  withInterceptors,
  withJsonpSupport
} from '@angular/common/http';
import {BrowserModule} from "@angular/platform-browser";
import {provideZxvbnServiceForPSM, ZxvbnConfigType} from "angular-password-strength-meter/zxcvbn";
import {ErrorMessageService} from "./services/error-message-service";
import {catchError, from, map, switchMap} from "rxjs";
import {translations} from '@zxcvbn-ts/language-en';
import {SignOutService} from "./services/signout-service";
import {CryptoWalletService} from "./services/crypto-wallet.service";
import {Haptics, ImpactStyle} from "@capacitor/haptics";
import {DataSubscriptionService} from "./services/data-subscription.service";

export const nonAuthenticatedPaths = ['.well-known', 'ipfs', 'pinata', 'metadata']

export const oneMinute = 60 * 1000;
export const oneHour = 60 * 60 * 1000;
export const twentyFourHours = 24 * 60 * 60 * 1000;
export const threeDays = 3 * 24 * 60 * 60 * 1000;
export const sevenDays = 7 * 24 * 60 * 60 * 1000;
export const fourteenDays = 14 * 24 * 60 * 60 * 1000;
export const thirtyDays = 30 * 24 * 60 * 60 * 1000;
export const threeMonths = thirtyDays * 3;

export const ucanAuthenticationInterceptor: HttpInterceptorFn = (req, next) => {
  const cryptoWalletService = inject(CryptoWalletService)
  const signOutService = inject(SignOutService)
  const shouldBeAuthenticated = nonAuthenticatedPaths.find(path => req.url.includes(path)) == undefined;
  if (shouldBeAuthenticated) {
    return from(cryptoWalletService.getStored('jwt')).pipe(
      switchMap((jwt: string | undefined) => {
        if (jwt == undefined || jwt === '' || jwt == 'null') {
          signOutService.signOut();
        }
        const clonedRequest = req.clone({
          setHeaders: {
            Authorization: `Basic ${jwt}`
          }
        });
        return next(clonedRequest);
      })
    )
  }
  return next(req)
}

export const errorResponseInterceptor: HttpInterceptorFn = (req, next) => {
  const errorMessageService = inject(ErrorMessageService)
  const signOutService = inject(SignOutService)
  const dataSubscriptionService = inject(DataSubscriptionService)
  return next(req).pipe(
    map((event: any) => {
      if (event instanceof HttpResponse) {
        return event
      }
      return event
    }),
    catchError((response: any) => {
      if (response instanceof HttpErrorResponse) {
        if (response.status === 401) {
          signOutService.signOut()
        }
        if (response.status === 403) {
          errorMessageService.setMessage(response?.error?.message || "You are not allowed to take this action.")
        } else {
          errorMessageService.setMessage(response?.error?.message)
        }
      }
      dataSubscriptionService.publish('showMenu', true)
      throw response
    })
  );
};


const zxvbnConfig: ZxvbnConfigType = {
  translations: translations,
};

export const appConfig: ApplicationConfig = {
  providers: [
    provideHttpClient(withJsonpSupport(), withInterceptors([ucanAuthenticationInterceptor, errorResponseInterceptor])),
    provideZoneChangeDetection({eventCoalescing: true}),
    importProvidersFrom([BrowserModule]),
    provideRouter(routes, withHashLocation()),
    provideZxvbnServiceForPSM(zxvbnConfig)
  ]
};


