import {Component, Input} from '@angular/core';
import {EventView} from "../services";
import {ToReadableSubscriptionStatusPipe} from "../pipes/ToReadableRequestStatusPipe";
import {ToDateTimeFormatPipe} from "../pipes/ToDateTimeFormatPipe";

@Component({
  selector: 'app-event',
  templateUrl: './event.component.html',
  standalone: true,
  imports: [
    ToReadableSubscriptionStatusPipe,
    ToDateTimeFormatPipe
  ],
  styleUrl: './event.component.scss'
})
export class EventComponent {

  @Input() event: EventView = {}
}
