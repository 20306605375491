import {Pipe, PipeTransform} from '@angular/core';
import {VisibilityScopeEnum} from "../../../services/model/visibility-scope";

@Pipe({
    name: 'toOppositeVisibilityScopeIcon',
    standalone: true
})
export class ToOppositeVisibilityScopeIconPipe implements PipeTransform {
    constructor() {
    }

    public transform(value?: VisibilityScopeEnum): any {
        switch (value) {
            case VisibilityScopeEnum.Public:
                return 'fa fa-eye greyed-out'
            case VisibilityScopeEnum.Protected:
                return 'fa fa-shield-halved greyed-out'
            case VisibilityScopeEnum.Private:
                return 'fa fa-eye-slash greyed-out'
            default:
                return ''
        }
    }
}