// src/app/services/crypto.service.ts

import {Injectable} from '@angular/core';
import {SigningResult} from "./api/models/signing-result";
import {CryptographyService} from "./cryptography.service";
import {wordlist} from "@scure/bip39/wordlists/english";
import * as bip39 from "@scure/bip39";
import {HDKey} from "@scure/bip32";
import {SecureStoragePlugin} from "capacitor-secure-storage-plugin";
import {GetResult} from "@capacitor/preferences";
import {environment} from "../environments/environment";
import {oneHour} from "../app.config";
import {KeyStore} from "../models/app.models";

@Injectable({
  providedIn: 'root'
})
export class CryptoWalletService {
  private decoder: TextDecoder = new TextDecoder()
  private encoder = new TextEncoder();
  private isWeb: boolean = environment.platform == "web"
  
  constructor(private cryptographyService: CryptographyService) {
  }
  
  //todo: encrypt mnemonic phrase and aes with user password?
  async initialise(emailAddress: string, method: string, providedMnemonic?: string): Promise<string> {
    if (this.isWeb) {
      console.warn("Using non secure web-based wallet!")
    }
    const mnemonic = providedMnemonic || bip39.generateMnemonic(wordlist, 256) //used for (HDKey and) AES private key deterministic derivation
    
    let base58CheckEncodedSigningPrivateKey = ''
    let base58CheckEncodedSigningPublicKey = ''
    
    if (method == 'doatoa') {
      //ECDSA
      
      const seed = await bip39.mnemonicToSeed(mnemonic);
      const masterKeypair = HDKey.fromMasterSeed(seed);
      const firstChild = masterKeypair.deriveChild(1)
      
      //encode for sharing purposes and for DID:key determination
      base58CheckEncodedSigningPrivateKey = await this.cryptographyService.baseEncode(firstChild.privateKey!, 'z')
      base58CheckEncodedSigningPublicKey = await this.cryptographyService.baseEncode(firstChild.publicKey!, 'z')
      
      //decode when received
      const rawSigningPrivateKey = await this.cryptographyService.baseDecode(base58CheckEncodedSigningPrivateKey)
      const rawSigningPublicKey = await this.cryptographyService.baseDecode(base58CheckEncodedSigningPublicKey)
      
      //actual signing test
      const message = "test"
      const signature = await this.cryptographyService.signMessage(message, rawSigningPrivateKey)
      const isValid = await this.cryptographyService.verifySignature(signature, rawSigningPublicKey, message)
      console.log(`Wallet successfully initialised: ${isValid}`)
    }
    //AES
    const encryptionArrayBuffer = await this.cryptographyService.deriveAESKeyFromMnemonic(mnemonic)
    const rootEncryptionKey = await this.cryptographyService.baseEncode(encryptionArrayBuffer)
    const salt = this.getHashedBase58EncodedSalt()
    // //RSA
    // const exchangeKeypair = await this.cryptographyService.generateRSAKeyPair()
    // const exchangeExportedKeyBuffer = await crypto.subtle.exportKey('pkcs8', exchangeKeypair.privateKey)
    // const exchangeExportedPublicKeyBuffer = await crypto.subtle.exportKey('pkcs8', exchangeKeypair.publicKey)
    // const exchangePrivateKey = await this.cryptographyService.baseEncode(exchangeExportedKeyBuffer)
    // const exchangePublicKey = await this.cryptographyService.baseEncode(exchangeExportedPublicKeyBuffer)
    
    const keyStore: KeyStore = {
      type: method,
      signingPrivateKey: base58CheckEncodedSigningPrivateKey,
      signingPublicKey: base58CheckEncodedSigningPublicKey,
      rootEncryptionKey: rootEncryptionKey
    }
    await this.setKeystore(emailAddress, keyStore)
    return mnemonic
  }
  
  async generateRSAKeyPair(): Promise<{ privateKey: string, publicKey: string }> {
    const exchangeKeypair = await this.cryptographyService.generateRSAKeyPair()
    const exchangeExportedKeyBuffer = await crypto.subtle.exportKey('pkcs8', exchangeKeypair.privateKey)
    const exchangeExportedPublicKeyBuffer = await crypto.subtle.exportKey('spki', exchangeKeypair.publicKey)
    const exchangePrivateKey = await this.cryptographyService.baseEncode(exchangeExportedKeyBuffer)
    const exchangePublicKey = await this.cryptographyService.baseEncode(exchangeExportedPublicKeyBuffer)
    
    return {
      privateKey: exchangePrivateKey,
      publicKey: exchangePublicKey
    }
  }
  
  async encrypt(data: string): Promise<string> {
    const secretKey = await this.getEncryptionKey()
    const basicSecretKey = await this.cryptographyService.baseDecode(secretKey)
    const cryptoKey = await this.cryptographyService.encryptionKeyToCryptKey(basicSecretKey)
    return await this.encryptAES(data, cryptoKey)
  }
  
  async decrypt(data: string): Promise<string> {
    const secretKey = await this.getEncryptionKey()
    const basicSecretKey = await this.cryptographyService.baseDecode(secretKey)
    const cryptoKey = await this.cryptographyService.encryptionKeyToCryptKey(basicSecretKey)
    return this.decryptAES(data, cryptoKey!)
  }
  
  // Function to encrypt a value using the RSA public key
  async encryptRSA(publicKey: CryptoKey, value: string): Promise<string> {
    const encodedValue = await this.cryptographyService.baseDecode(value);
    const encryptedBytes = await crypto.subtle.encrypt(
      {
        name: 'RSA-OAEP',
      },
      publicKey,
      encodedValue
    );
    return this.cryptographyService.baseEncode(encryptedBytes)
  }
  
  async decryptRSA(cryptoKey: CryptoKey, encryptedValue: Uint8Array): Promise<Uint8Array> {
    const arrayBuffer = await crypto.subtle.decrypt(
      {
        name: 'RSA-OAEP',
      },
      cryptoKey,
      encryptedValue
    );
    return new Uint8Array(arrayBuffer);
  }
  
  
  async sign(data: string, method?: string): Promise<SigningResult> {
    if (this.isWeb) {
      console.warn("Using non secure web-based wallet!")
    }
    // const isAuthenticated = this.isWeb || await performBiometricVerification()
    // if (isAuthenticated) {
    const preferredMethod = method || await this.getAuthenticationMethod()
    switch (preferredMethod) {
      case 'doatoa': {
        const signingKey = await this.getSigningPrivateKey()
        const rawSigningKey = signingKey != undefined ? await this.cryptographyService.baseDecode(signingKey) : undefined
        const signature = rawSigningKey != undefined ? await this.cryptographyService.signMessage(data, rawSigningKey) : undefined
        if (signature) {
          return new Promise((resolve, reject) => {
            resolve({
              address: "",
              signature: signature,
            })
          });
        } else {
          throw new Error('Unknown signature')
        }
      }
      default:
        throw new Error()
    }
  }
  
  async store(key: string, value: string): Promise<string> {
    await SecureStoragePlugin.set({
      key: key,
      value: value
    })
    return Promise.resolve(value)
  }
  
  async setKeystore(email: string, keystore: KeyStore) {
    await SecureStoragePlugin.set({
      key: email,
      value: JSON.stringify(keystore)
    })
  }
  
  async getKeystore(key: string): Promise<KeyStore> {
    const jsonKeystore = await SecureStoragePlugin.get({key})
    return JSON.parse(jsonKeystore.value)
  }
  
  async getStored(key: string): Promise<string | undefined> {
    let getResult: GetResult = await SecureStoragePlugin.get({key: key})
    if (getResult.value == undefined) {
      return undefined
    }
    return getResult.value
  }
  
  async hasStored(key: string): Promise<boolean> {
    try {
      const secureStorageValue = await SecureStoragePlugin.get({key: key})
      return secureStorageValue?.value != undefined
    } catch (e) {
      return false
    }
  }
  
  async removeStored(key: string): Promise<void> {
    try {
      await SecureStoragePlugin.remove({key: key})
    }
    catch (e) {
      console.log(`Failed to remove ${key}`, e)
    }
  }
  
  async getSigningPublicKey(): Promise<string> {
    const email = await this.getStored('email')
    const keyStore = await this.getKeystore(email!)
    return keyStore!.signingPublicKey
  }
  
  async getAuthenticationMethod(): Promise<string> {
    return new Promise(async resolve => {
      const email = await this.getStored('email')
      const keyStore = await this.getKeystore(email!)
      resolve(keyStore.type)
    })
  }
  
  private async encryptAES(value: string, key: CryptoKey): Promise<string> {
    const data = this.encoder.encode(value);
    const iv = this.generateRandomBytes(12);
    const encryptedValue = await crypto.subtle.encrypt(
      {
        name: 'AES-GCM',
        iv: iv
      },
      key,
      data
    );
    
    const combinedArray = new Uint8Array(iv.length + encryptedValue.byteLength);
    combinedArray.set(iv, 0);
    combinedArray.set(new Uint8Array(encryptedValue), iv.length);
    return await this.cryptographyService.baseEncode(combinedArray)
  }
  
  private async decryptAES(encryptedValue: string, key: CryptoKey): Promise<string> {
    if (!encryptedValue) {
      throw Error(`Could not decrypt encryptedValue: ${encryptedValue}`)
    }
    const combinedArrayBuffer = await this.cryptographyService.baseDecode(encryptedValue);
    const combinedArray = new Uint8Array(combinedArrayBuffer);
    const iv = combinedArray.slice(0, 12);
    const encryptedData = combinedArray.slice(12);
    const decrypted = await crypto.subtle.decrypt(
      {
        name: 'AES-GCM',
        iv: iv
      },
      key,
      encryptedData
    );
    return this.decoder.decode(decrypted);
  }
  
  private async getSigningPrivateKey(): Promise<string> {
    const email = await this.getStored('email')
    const keyStore = await this.getKeystore(email!)
    return keyStore?.signingPrivateKey
  }
  
  private async getEncryptionKey(): Promise<string> {
    const salt = await this.getStored('salt')
    const email = await this.getStored('email')
    console.log("Getting encryption key", email)
    const keyStore = await this.getKeystore(email!)
    const rootEncryptionKey = keyStore?.rootEncryptionKey
    const encryptionArrayBuffer = await this.cryptographyService.deriveAESKeyFromSeed(rootEncryptionKey, salt!)
    return this.cryptographyService.baseEncode(encryptionArrayBuffer)
  }
  
  async encryptSecretKey(providedRSAPublicKey: CryptoKey): Promise<string> {
    const myAESPrivateKey = await this.getEncryptionKey()
    return this.encryptRSA(providedRSAPublicKey, myAESPrivateKey)
  }
  
  async decryptRSAToAESToValue(encryptedValue: string | undefined, providedEncryptedAESPrivateKey: string, myEncryptedRSAPrivateKey: string): Promise<string | undefined> {
    if (!encryptedValue) {
      return encryptedValue
    }
    const myRSAPrivateKey = await this.decrypt(myEncryptedRSAPrivateKey)
    const myRSAKeyCryptoKey = await this.cryptographyService.privateRSAStringToCryptoKey(myRSAPrivateKey)
    const basicProvidedEncryptedAESPrivateKey = await this.cryptographyService.baseDecode(providedEncryptedAESPrivateKey)
    const providedAESPrivateKey = await this.decryptRSA(myRSAKeyCryptoKey, basicProvidedEncryptedAESPrivateKey)
    const providedAESCryptoKey = await this.cryptographyService.encryptionKeyToCryptKey(providedAESPrivateKey)
    return await this.decryptAES(encryptedValue, providedAESCryptoKey)
  }
  
  async rollSalt(): Promise<string> {
    const newSalt = this.getHashedBase58EncodedSalt()
    await this.store('salt', newSalt)
    return newSalt
  }
  
  private generateRandomBytes(length: number = 15): Uint8Array {
    return crypto.getRandomValues(new Uint8Array(length));
  }
  
  private getHashedBase58EncodedSalt(length: number = 15): string {
    const salt = this.generateRandomBytes(length)
    const hash = this.cryptographyService.sha256(salt)
    return this.cryptographyService.base58Encode(hash);
  }
  
  async setupJWT(att: { with: string, can: string }[], aud: string): Promise<string> {
    console.log("Setting up JWT...");
    const did = await this.getStored('did')
    const header = {
      "alg": "EdDSA",
      "typ": "JWT"
    }
    const base64Header = btoa(JSON.stringify(header))
    const payload = {
      iss: did,
      aud: aud,
      exp: Date.now() + oneHour,
      att: att
    }
    const base64Payload = btoa(JSON.stringify(payload))
    return await this.sign(`${base64Header}.${base64Payload}`, 'doatoa').then(async signatureResult => {
      const signature = signatureResult.signature
      return `${base64Header}.${base64Payload}.${signature}`
    })
  }

  async clear() {
    await this.removeStored('did')
    const email = await this.getStored('email')
    if (email) {
      await this.removeStored(email)
    }
    await this.clearAuthentication()
  }
  
  async clearAuthentication() {
    await this.removeStored('jwt')
    await this.removeStored('salt')
    await this.removeStored('email')
  }
}