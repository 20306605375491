import {Component} from '@angular/core';

@Component({
    selector: 'app-developers',
    templateUrl: './developers.component.html',
    styleUrl: './developers.component.scss'
})
export class DevelopersComponent {

    loading: boolean = false
    errorOccurred: boolean = false
    errorMessage: string = ''
}