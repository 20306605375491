"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.KeyofEnumBuilder = void 0;
const typebox_1 = require("@sinclair/typebox");
class KeyofEnumBuilder extends typebox_1.JavaScriptTypeBuilder {
    KeyOfEnum(schema, options) {
        const keys = Object.keys(schema).map(key => this.Literal(key));
        return this.Union(keys, Object.assign(Object.assign({}, options), { [typebox_1.Hint]: 'KeyOfEnum' }));
    }
    Enum(schema, options) {
        const anyOf = Object.entries(schema)
            .filter(([key, _value]) => typeof key === 'string' || !isNaN(key))
            .map(([key, value]) => this.Literal(value, { $id: key }));
        return this.Union(anyOf, Object.assign(Object.assign({}, options), { [typebox_1.Hint]: 'Enum' }));
    }
}
exports.KeyofEnumBuilder = KeyofEnumBuilder;
