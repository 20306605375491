<div class="modal fade"
     [id]="customId"
     aria-hidden="true"
     aria-labelledby="requestEditModal"
     role="dialog"
     tabindex="-1">
    <div class="modal-dialog"
         role="document">
        <div class="modal-content">
            <div class="modal-header">
                <h5 class="modal-title">{{title}}</h5>
                <button aria-label="Close"
                        class="btn-close float-end m-1"
                        data-bs-dismiss="modal"
                        type="button">
                </button>
            </div>
            <div class="modal-body">
                <div class="mb-3">
                    <div class="{{appClaimClass}}"
                         id="request-{{selectedClaimScope}}">
                        <div class="container justify-content-center">
                            <form class="row">
                                <div class="p-2">
                                    <div class="mb-3">
                                        <label  *ngIf="shareModal" for="subscriber">Subscriber</label>
                                        <input [(ngModel)]="subscriber" *ngIf="shareModal"
                                               class="form-control rounded-5 mb-1"
                                               datatype="text"
                                               id="subscriber"
                                               name="subscriber"
                                               type="text">
                                        <label for="subscription-scope">Scope
                                        </label>
                                        <select (change)="setScope($event)"
                                                class="rounded-5 mb-2 col form-select"
                                                id="subscription-scope"
                                                name="scope">
                                            <option disabled
                                                    selected
                                                    value="">{{defaultScopeMessage}}
                                            </option>
                                            <option *ngFor="let scope of scopes"
                                                    value="{{scope}}">{{ scope | toReadableString }}
                                            </option>
                                            <option selected
                                                    *ngIf="selectedClaimScopeString != defaultScopeMessage"
                                                    value="{{selectedClaimScopeString}}">{{ selectedClaimScope | toReadableString }}
                                            </option>
                                        </select>
                                        <label for="duration"
                                               >Duration
                                        </label>
                                        <select (click)="setUnit($event)"
                                                id="#unit"
                                                class="rounded-5 col form-select mb-3"
                                                name="unit">
                                            <option
                                                    value="hours">Hours
                                            </option>
                                            <option
                                                    selected="selected"
                                                    value="days">Days
                                            </option>
                                            <option
                                                    value="months">Months
                                            </option>
                                            <option
                                                    value="years">Years
                                            </option>
                                        </select>
                                        <input [(ngModel)]="duration"
                                               class="form-control rounded-5 mb-1"
                                               datatype="duration"
                                               id="duration"
                                               name="duration"
                                               min="1"
                                               max="365"
                                               type="number">
                                    </div>
                                    <div class="mb-3">
                                        <label for="startingMoment"
                                               >Starting moment (optional, default is today)
                                        </label>
                                        <input [(ngModel)]="startingMoment"
                                               class="form-control rounded-5"
                                               datatype="startingMoment"
                                               id="startingMoment"
                                               name="startingMoment"
                                               [min]="todayDate"
                                               (ngModelChange)="startingMoment = $event"
                                               value="{{startingMoment}}"
                                               type="datetime-local">

                                    </div>
                                    <div class="mb-3">
                                        <label for="description"
                                               >Description
                                        </label>
                                        <textarea [(ngModel)]="description"
                                                  class="form-control rounded-1"
                                                  datatype="text"
                                                  id="description"
                                                  name="description"
                                                  placeholder="{{subscriptionRequest?.description || 'Enter description (optional)'}}"
                                                  type="text"></textarea>
                                    </div>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
            <div class="modal-footer justify-content-center">
                <app-generic-button (click)="requestSubscription()"
                                    class="col-md-auto m-3"
                                    *ngIf="!subscriptionRequest"
                                    [loading]="loading"
                                    [disabled]="loading || selectedClaimScope == undefined"
                                    [icon]="'fa fa-check'"
                                    [buttonText]="'Submit'"
                                    [btnClass]="'btn btn-basic-info'"></app-generic-button>
                <lib-generic-message [show]="showMessage" [message]="message!"></lib-generic-message>
                <app-error-modal [showModal]="errorOccurred"></app-error-modal>
            </div>
        </div>
    </div>
</div>