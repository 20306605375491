import {Injectable} from '@angular/core';
import {CapacitorSQLite, SQLiteConnection, SQLiteDBConnection} from '@capacitor-community/sqlite';
import {environment} from "../environments/environment";
import {DataSubscriptionService} from "./data-subscription.service";
import {KeyValuePairsMigrationStatements} from "./migrations/key-value-pairs.migrations.statements";

const connectionName = 'doatoa';
const version = 0

//This service only works in native environments, not in a browser. In a browser all methods will return default/empty values.
@Injectable({
  providedIn: 'root',
})
export class DataStorageService {
  private sqlite?: SQLiteConnection = undefined;
  private dbConnection: SQLiteDBConnection | undefined;
  private keyValuePairsMigrationStatements: KeyValuePairsMigrationStatements = new KeyValuePairsMigrationStatements();
  
  constructor() {
  }
  
  async initConnection(): Promise<void> {
    if (environment.platform == 'web') {
      console.log("Web environment, not using SQLite...");
      return Promise.resolve();
    }
    if (this.dbConnection) {
      console.log("SQLite connection already exists");
      return Promise.resolve();
    }
    try {
      console.log("Initializing SQLite");
      this.sqlite = new SQLiteConnection(CapacitorSQLite);
      await this.sqlite.createConnection(connectionName, false, 'no-encryption', version, false);
      this.dbConnection = await this.sqlite.retrieveConnection(connectionName, false);
      
      await this.dbConnection.open();
      
      await this.dbConnection.execute(this.keyValuePairsMigrationStatements.migrations[version]!)
        .then(() => console.log("Initialized key_value_pairs table"))
      
      this.sqlite.getDatabaseList().then((dbList) => {
        console.log(`Databases present: ${dbList.values?.length}`);
      })
    } catch (error) {
      console.error('Error initializing database:', error);
    }
  }
  
  async closeConnection(): Promise<void> {
    try {
      if (this.dbConnection) {
        await this.sqlite!.closeConnection(connectionName, false);
        this.dbConnection = undefined;
        console.log('Database connection closed');
      }
    } catch (error) {
      console.error('Error closing database connection:', error);
    }
  }
  
  async addKeyValuePair<T>(key: string, value: T) {
    if (environment.platform == 'web') return;
    const stringValue = JSON.stringify(value)
    console.log(`Adding ${key} ${stringValue}`)
    await this.dbConnection?.run(
      `INSERT OR
       REPLACE
       INTO key_value_pairs (id, json)
       VALUES (?, ?)`, [key, stringValue],
    ).catch(error => console.log(`Error adding ${key} ${error.message || 'no-message'}`));
  }
  
  async getValue<T>(key: string): Promise<T | undefined> {
    console.log(`Getting ${key} from database`)
    if (environment.platform == 'web') return undefined;
    const valueResult = await this.dbConnection?.query(
      'SELECT * FROM key_value_pairs WHERE id = ?', [key])
    const result = valueResult?.values as KeyValuePair[]
    if (result && result.length > 0) {
      console.log(`${key} results: ${result.length}`)
      return JSON.parse(result[0].json) as T | undefined
    }
    console.log(`${key} results: 0`)
    return undefined
  }
  
}

export interface KeyValuePair {
  id: string;
  json: string;
}
