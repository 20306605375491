import {Component, OnInit} from '@angular/core';
import {ActivatedRoute, Router} from "@angular/router";
import {OauthControllerService} from "../../services/api/oauth-controller.service";
import {LogoComponent} from "../common/logo/logo.component";
import {ToReadableStringPipe} from "../common/pipes/ToReadableStringPipe";
import {GenericButtonComponent} from "../common/generic-button/generic-button.component";
import {NgForOf} from "@angular/common";

@Component({
    selector: 'app-consent-page',
    templateUrl: './consent-page.html',
    standalone: true,
    imports: [
        LogoComponent,
        ToReadableStringPipe,
        GenericButtonComponent,
        NgForOf
    ],
    styleUrl: './consent-page.scss'
})
export class ConsentPage implements OnInit {

    consentedLoading: boolean = false
    deniedLoading: boolean = false
    state: string = ''
    nonce?: string;
    scope?: string;
    responseType?: string;
    clientName?: string;
    scopes?: Array<string> = []

    constructor(private activatedRoute: ActivatedRoute, private router: Router, private oauthControllerService: OauthControllerService) {
    }

    ngOnInit(): void {
        this.activatedRoute.queryParamMap.subscribe((p: any) => {
            this.state = p.params.state
            this.scopes = p.params.scopes
            this.clientName = p.params.client_name
        })
    }

    submitConsent(consent: boolean) {
        if (consent)
            this.consentedLoading = true
        else this.deniedLoading = true
        this.oauthControllerService.process(consent, this.state)
            .subscribe({
                next: (response) => {
                    this.consentedLoading = false
                    this.deniedLoading = false
                    if (!consent) {
                        this.router.navigate(['/sign-in']).catch((e) => console.log(e))
                    }
                    window.location.href = response.redirectUri!
                },
                error: (error) => {
                    this.consentedLoading = false
                    this.deniedLoading = false
                }
            });
    }
}
