/**
 * DoaToa
 * Manage your data and your digital identities, your data your choice.
 *
 * The version of the OpenAPI document: 1.0.0-alpha
 * Contact: info@compilit.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import {Injectable} from '@angular/core';
import {Observable} from 'rxjs';
import {AbstractHttpService} from "./abstract-http-service";
import {FactView} from "../model/fact-view";
import {IpfsStatus} from "../model/ipfs-status";

@Injectable({
  providedIn: 'root'
})
export class IpfsService extends AbstractHttpService {
  
  public getStatus(): Observable<IpfsStatus> {
    let localVarHeaders = this.defaultHeaders;
    return this.httpClient.request<IpfsStatus>('get', `https://pinata.statuspage.io/api/v2/status.json`,
      {
        withCredentials: this.configuration.withCredentials,
        headers: localVarHeaders
      }
    );
  }
  
  public getFact(hash?: string): Observable<FactView> {
    let localVarHeaders = this.defaultHeaders;
    // return this.httpClient.request<FactView>('get', `${this.configuration.basePath}/ipfs/${hash}`,
    return this.httpClient.request<FactView>('get', `https://ipfs.io/ipfs/${hash}`,
      {
        withCredentials: this.configuration.withCredentials,
        headers: localVarHeaders
      }
    );
  }
}
