import {Component, OnInit} from '@angular/core';
import {environment} from "../../../environments/environment";
import {QueryParamService} from "../../../services/query-param-service";
import {LandingComponent} from "../landing/landing.component";

@Component({
  selector: 'app-web2app-redirect',
  standalone: true,
  imports: [
    LandingComponent
  ],
  templateUrl: './web2app-redirect.component.html',
  styleUrl: './web2app-redirect.component.scss'
})
export class Web2appRedirectComponent implements OnInit {
  
  constructor(private queryParamService: QueryParamService) {
  }
  
  ngOnInit(): void {
    this.queryParamService.getQueryParams().subscribe({
      next: queryParams => {
        const correlationId = queryParams.get("correlationId")
        const origin = queryParams.get("origin")
        window.location.href = `${environment.appScheme}?correlationId=${correlationId}&origin=${origin}`
      }
    })
  }
}
