import {Injectable} from '@angular/core';
import {ActivatedRoute, ParamMap} from '@angular/router';
import {Observable} from 'rxjs';

@Injectable({
    providedIn: 'root'
})
export class QueryParamService {
    constructor(private route: ActivatedRoute) {}

    getQueryParams(): Observable<ParamMap> {
        return this.route.queryParamMap;
    }
}
