import {Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges} from '@angular/core';
import * as uuid from 'uuid';

import {ClaimsComponent} from "./claims/claims.component";
import {ToVisibilityScopeIconPipe} from "../../common/pipes/ToVisibilityScopeIconPipe";
import {ToReadableStringPipe} from "../../common/pipes/ToReadableStringPipe";
import {IdentityView} from "../../../services/api/models/identity-view";
import {DataSubscriptionService} from "../../../services/data-subscription.service";
import {CryptoWalletService} from "../../../services/crypto-wallet.service";
import {ClaimView} from "../../../services/api/models/claim-reference-view";
import {SubscriptionView} from "../../../services/api/models/subscription-view";
import {ActionButtonComponent} from "../../common/action-button/action-button.component";
import {SubscriptionControllerService} from "../../../services/api/subscription-controller.service";
import {NgIf} from "@angular/common";
import {NavigatorService} from "../../common/navigator.service";

@Component({
  imports: [
    ActionButtonComponent,
    ToVisibilityScopeIconPipe,
    ToReadableStringPipe,
    ClaimsComponent,
    NgIf
  ],
  selector: 'app-identity',
  standalone: true,
  styleUrls: ['./identity.component.scss'],
  templateUrl: './identity.component.html'
})
export class IdentityComponent implements OnInit, OnChanges {
  
  @Output() reloadCurrentIdentity: EventEmitter<any> = new EventEmitter();
  @Input() scopes: Array<string> = []
  @Input() identity!: IdentityView
  @Input() index!: number;
  protected loadingAvatar: boolean = false
  protected errorOccurred: boolean = false
  protected claimLoading: Map<string, boolean> = new Map<string, boolean>()
  protected open: Map<string, boolean> = new Map<string, boolean>()
  protected shortSummary: string = ''
  protected identityIdentifier?: string = 'my-claims-reference-' + uuid.v4()
  protected newClaimIdentifier?: string = 'new-claim-' + uuid.v4()
  protected newRequestIdentifier?: string = 'new-subscription-' + uuid.v4()
  protected subscribedByCurrentIdentity: boolean = false
  protected exportIdentifier?: string = 'export-' + uuid.v4()
  protected filePath: string = '';
  protected fileName: string = '';
  protected imageChangedEvent: any = '';
  protected base64Avatar: string = '';
  protected showEditAvatar: boolean = true;
  protected dataLoaded: boolean = false
  protected readonly sessionStorage = sessionStorage;
  
  constructor(protected dataSubscriptionService: DataSubscriptionService,
              protected navigator: NavigatorService,
              private cryptoWalletService: CryptoWalletService,
              private subscriptionControllerService: SubscriptionControllerService) {
  }
  
  ngOnChanges(changes: SimpleChanges): void {
    if (this.identity?.introduction != undefined && this.identity?.introduction!.length > 50) {
      this.shortSummary = this.identity?.introduction?.substring(0, 50) + '...'
    } else {
      this.shortSummary = this.identity?.introduction || ''
    }
  }
  
  ngOnInit(): void {
    // this.newClaimIdentifier = 'new-claim-' + this.identity!!.hash!!.toLowerCase()
    // this.newRequestIdentifier = 'new-subscription-' + this.identity!!.hash!!.toLowerCase()
    // this.exportIdentifier = 'export-' + this.identity!!.hash!!.toLowerCase()
    this.cryptoWalletService.getStored('did').then(did => {
      this.subscribedByCurrentIdentity = this.identity?.subscribers!.some(subscription => subscription.subscriber == did)
    })
    this.dataSubscriptionService.onFirst<Array<string>>('scopeAggregates').subscribe({
      next: scopes => {
        this.scopes = scopes
      }
    })
  }
  
  resolveClass() {
    return this.index % 2 == 0 ? 'btn-basic-secondary' : 'btn-basic-secondary-dark';
  }
  
  resolveSource() {
    return this.index % 2 == 0 ? "..//person-circle-light.svg" : "..//person-circle-dark.svg";
  }
  
  addSubscription($event: SubscriptionView) {
    this.identity.subscribers?.push($event)
    this.dataSubscriptionService.onFirst<IdentityView>('identity').subscribe({
      next: identity => {
        identity.subscriptions?.push($event)
        this.dataSubscriptionService.publish('identity', identity)
      }
    })
  }
  
  addClaim($event: ClaimView) {
    this.dataSubscriptionService.onFirst<IdentityView>('identity').subscribe({
      next: identity => {
        identity?.claims?.push($event)
        this.identity = identity
        this.dataSubscriptionService.publish('identity', identity)
      }
    })
  }
  
  noClaimsMade() {
    return this.identity?.claims?.length == 0;
  }
  
}
