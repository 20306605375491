"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.BinanceSignTransaction = exports.BinancePreparedTransaction = exports.BinancePreparedMessage = exports.BinanceSDKTransaction = void 0;
const constants_1 = require("../../../constants");
const params_1 = require("../../params");
const schema_utils_1 = require("@trezor/schema-utils");
exports.BinanceSDKTransaction = schema_utils_1.Type.Object({
    chain_id: schema_utils_1.Type.String(),
    account_number: schema_utils_1.Type.Optional(schema_utils_1.Type.Number()),
    memo: schema_utils_1.Type.Optional(schema_utils_1.Type.String()),
    sequence: schema_utils_1.Type.Optional(schema_utils_1.Type.Number()),
    source: schema_utils_1.Type.Optional(schema_utils_1.Type.Number()),
    transfer: schema_utils_1.Type.Optional(constants_1.PROTO.BinanceTransferMsg),
    placeOrder: schema_utils_1.Type.Optional(constants_1.PROTO.BinanceOrderMsg),
    cancelOrder: schema_utils_1.Type.Optional(constants_1.PROTO.BinanceCancelMsg),
});
exports.BinancePreparedMessage = schema_utils_1.Type.Union([
    schema_utils_1.Type.Intersect([
        constants_1.PROTO.BinanceTransferMsg,
        schema_utils_1.Type.Object({
            type: schema_utils_1.Type.Literal('BinanceTransferMsg'),
        }),
    ]),
    schema_utils_1.Type.Intersect([
        constants_1.PROTO.BinanceOrderMsg,
        schema_utils_1.Type.Object({
            type: schema_utils_1.Type.Literal('BinanceOrderMsg'),
        }),
    ]),
    schema_utils_1.Type.Intersect([
        constants_1.PROTO.BinanceCancelMsg,
        schema_utils_1.Type.Object({
            type: schema_utils_1.Type.Literal('BinanceCancelMsg'),
        }),
    ]),
]);
exports.BinancePreparedTransaction = schema_utils_1.Type.Intersect([
    exports.BinanceSDKTransaction,
    schema_utils_1.Type.Object({
        messages: schema_utils_1.Type.Array(exports.BinancePreparedMessage),
        account_number: schema_utils_1.Type.Number(),
        sequence: schema_utils_1.Type.Number(),
        source: schema_utils_1.Type.Number(),
    }),
]);
exports.BinanceSignTransaction = schema_utils_1.Type.Object({
    path: params_1.DerivationPath,
    transaction: exports.BinanceSDKTransaction,
    chunkify: schema_utils_1.Type.Optional(schema_utils_1.Type.Boolean()),
});
