"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.ArrayBufferBuilder = void 0;
const typebox_1 = require("@sinclair/typebox");
typebox_1.TypeRegistry.Set('ArrayBuffer', (_, value) => value instanceof ArrayBuffer);
class ArrayBufferBuilder extends typebox_1.JavaScriptTypeBuilder {
    ArrayBuffer(options) {
        return this.Create(Object.assign(Object.assign({}, options), { [typebox_1.Kind]: 'ArrayBuffer', type: 'ArrayBuffer' }));
    }
}
exports.ArrayBufferBuilder = ArrayBufferBuilder;
