import {Component, EventEmitter, Input, OnDestroy, Output} from '@angular/core';
import {NgIf} from "@angular/common";
import {ErrorMessageService} from "../../../services/error-message-service";

@Component({
  selector: 'app-error-message',
  standalone: true,
  templateUrl: './error-message.component.html',
  imports: [
    NgIf
  ],
  styleUrl: './error-message.component.scss'
})
export class ErrorMessageComponent implements OnDestroy {
  
  protected message: string = ''
  @Output() modalFinished: EventEmitter<any> = new EventEmitter()
  @Input() useTimout: boolean = true
  
  constructor(private errorMessageService: ErrorMessageService) {
  }
  
  ngOnDestroy(): void {
    this.errorMessageService.reset()
  }
  
  retrieveMessage() {
    return this.errorMessageService.getMessage()
  }
  
  showMessage() {
    return this.errorMessageService.errorHasOccurred()
  }
}
