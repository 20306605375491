<div class="container">
    <div class="row justify-content-center">
        <app-logo [classes]="'mx-auto d-block mt-2'"
                  [height]="'200'"
                  [image]="'/doatoa-logo-full.svg'"
                  [width]="'300'"></app-logo>
        <div class="max-width-300 card p-3">
            <h2 class="text-center">Under (heavy) construction</h2>
            <p class="text-center">DoaToa was once a centralized app to manager your identity, like a traditional IDP. However, this requires everyone to just trust the creators of DoaToa to keep their data safe. This is unacceptable. So the next step for DoaToa is to become decentralized, where you will truly be the sole owner of your digital identity.</p>
            <p class="text-center">Please, stay tuned</p>
        </div>
    </div>
</div>

