import {Pipe, PipeTransform} from '@angular/core';
import {ClaimView} from "../services";

@Pipe({
    name: 'toFilteredClaimTypes',
    standalone: true
})
export class ToFilteredClaimTypesPipe implements PipeTransform {
    constructor() {
    }

    public transform(value: Array<string>): any {
        return value.filter(it =>
            it != "avatar"
            && it != 'account_email_address'
        )
    }
}