import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {ClaimControllerService, ClaimView, IdentityView,} from "../../../services/";
import * as uuid from "uuid";
import {DataService} from "../../../services/data.service";


@Component({
  selector: 'app-identity',
  templateUrl: './identity.component.html',
  styleUrl: './identity.component.scss'
})
export class IdentityComponent implements OnInit {
  
  @Output() protected reloadCurrentProfile: EventEmitter<any> = new EventEmitter();
  protected errorOccurred: boolean = false
  protected identity?: IdentityView
  protected claimLoading: Map<string, boolean> = new Map<string, boolean>()
  protected open: Map<string, boolean> = new Map<string, boolean>()
  @Input() protected classList: string = ''
  protected shortSummary: string = ''
  protected profileId?: string = 'my-identity-reference-' + uuid.v4()
  protected requestedClaim: ClaimView = {}
  protected requestedClaims: Map<string, ClaimView> = new Map<string, ClaimView>()
  protected loading: boolean = false;
  protected claimLoaded: boolean = false;
  
  constructor(protected dataService: DataService) {
  }
  
  ngOnInit() {
    this.dataService.last<IdentityView>('identity').subscribe(identity => this.identity = identity)
  }
  
  openCloseClaim(claimReference: ClaimView) {
    this.open.set(claimReference.id!, !this.open.get(claimReference?.id || '') || false)
  }
  
  
  protected readonly sessionStorage = sessionStorage;
}
