import {Component, Input} from '@angular/core';
import {ClaimView, IdentityView, SubscriptionView} from "../../common/services";
import {DataService} from "../../common/services/data-storage.service";

@Component({
    selector: 'app-subscriptions',
    templateUrl: './subscriptions.component.html',
    styleUrl: './subscriptions.component.scss'
})
export class SubscriptionsComponent {
    @Input() identity?: IdentityView;
    protected loading: Map<string, boolean> = new Map<string, boolean>()
    protected errorOccurred: boolean = false
    protected requestToEdit: SubscriptionView = {}
    protected open: Map<string, boolean> = new Map<string, boolean>()
    protected showMessage: boolean = false;
    protected message: string = '';

    constructor(protected dataService: DataService) {
    }

    protected resolveClass(index: number, claim: ClaimView): string {
        const identity = this.dataService.getIdentity()!
        if (identity?.subscriptions?.some((x, y) => x.status == 'approved' || x.status == 'denied')) {
            return 'btn-basic-notify'
        }
        return index % 2 == 0 ? 'btn-basic-secondary-dark' : 'btn-basic-secondary';
    }

    protected readonly sessionStorage = sessionStorage;

    protected openOrClose(id: string) {
        this.open.set(id, !this.open.get(id) || false)
    }

    protected removeRequest($event: SubscriptionView) {
        const identity = this.dataService.getIdentity()!
        identity!.subscriptions!.forEach((element, index) => {
            if (identity!.subscriptions![index].id == $event.id) identity!.subscriptions!.splice(index, 1);
            this.message = 'Request deleted!'
            this.showMessage = true
            this.dataService.publish('identity', identity)
        });
    }
}
