<div class="p-3">
    <div class="text-truncate">By
        <span class="color-secondary-blue text-truncate">{{ subscription.subscriber }}</span>
    </div>
    <div class="text-truncate">Hash
        <span class="color-secondary-blue text-truncate">{{ metadataView?.hash ||  '?'}}</span>
    </div>
    <div class="text-truncate">Alias
        <span class="color-secondary-blue text-truncate">{{ metadataView?.alias || 'none'}}</span>
    </div>

    <div>on:
        <span *ngFor="let scope of subscription.scopes" class="color-secondary-blue">{{ scope | toReadableString }}</span>
    </div>
    <div>Status:
        <span class="color-secondary-blue">
                <i class="{{subscription | toStatusClass}}"></i>
                ({{ subscription.status | toReadableSubscriptionStatus }})
            </span>
    </div>
    <!--<div class="row mb-3">-->
    <!--    <div class="col-lg-auto">Description:-->
    <!--        <span class="secondary-blue">{{ subscription.description }}</span>-->
    <!--    </div>-->
    <!--</div>-->
    <!--    <div class="row mb-3" *ngIf="addressedToMe && requestIsOpen(subscription)">-->
    <!--        <div class="col">-->
    <!--             <textarea [(ngModel)]="reason"-->
    <!--                       class="form-control rounded-1 w-100"-->
    <!--                       id="decisionReason"-->
    <!--                       name="value"-->
    <!--                       placeholder="Reason for decision (optional)">-->
    <!--                </textarea>-->
    <!--        </div>-->
    <!--    </div>-->
    <div class="row mb-3" *ngIf="!!subscription.events && subscription.events!.length > 0">
        <h5>Last event:</h5>
        <div class="col">
            <app-event [event]="subscription!.events![subscription.events!.length - 1]!"></app-event>
        </div>
    </div>
    <app-error-message></app-error-message>
    <div class="row align-items-center justify-content-between mb-2">
        <div class="d-flex justify-content-end p-0">
            <app-action-button
                    class="me-2 {{!requestIsOpen(subscription) || !addressedToMe ? 'd-none':''}}"
                    [useSpinner]="false"
                    (click)="processSubscription('approve')"
                    [disabled]="!requestIsOpen(subscription) || !addressedToMe || (loadingMap.get('approve') || false)"
                    [showPrimary]="requestIsOpen(subscription) && addressedToMe"
                    [showSecondary]="!requestIsOpen(subscription) || !addressedToMe"
                    [loading]="loadingMap.get('approve') || false"
                    [btnClass]="'btn-regular'"
                    [primaryIconClass]="'fa-solid fa-circle-check'"
                    [secondaryIconClass]="'fa-solid fa-circle-check greyed out'">
            </app-action-button>
            <app-action-button
                    class="me-2 {{!requestIsOpen(subscription) || !addressedToMe ? 'd-none':''}}"
                    [useSpinner]="false"
                    (click)="processSubscription('deny')"
                    [disabled]="!requestIsOpen(subscription) || !addressedToMe || (loadingMap.get('deny') || false)"
                    [showPrimary]="requestIsOpen(subscription) && addressedToMe"
                    [showSecondary]="!requestIsOpen(subscription) || !addressedToMe"
                    [loading]="loadingMap.get('deny') || false"
                    [btnClass]="'btn-alert'"
                    [primaryIconClass]="'fa-solid fa-circle-xmark'"
                    [secondaryIconClass]="'fa-solid fa-circle-xmark greyed-out'">
            </app-action-button>
            <app-action-button *ngIf="initiatedByMe"
                               class="me-2 {{!initiatedByMe ? 'd-none':''}}"
                               [useSpinner]="false"
                               [disabled]="!initiatedByMe"
                               [showPrimary]="initiatedByMe"
                               [showSecondary]="!initiatedByMe"
                               [primaryIconClass]="'fa fa-pencil'"
                               [secondaryIconClass]="'fa fa-pencil greyed-out'"
                               data-bs-target="#requestEditModal"
                               [attr.data-bs-toggle]="initiatedByMe ? 'modal' : null">
            </app-action-button>
            <app-action-button
                    class="me-2"
                    (click)="deleteSubscription()"
                    [disabled]="loadingMap.get('delete') || false"
                    [loading]="loadingMap.get('delete') || false"
                    [showPrimary]="true"
                    [primaryIconClass]="'fa fa-trash'">
            </app-action-button>
            <app-action-button
                    data-bs-target="#requestHistory"
                    data-bs-toggle="modal"
                    [useSpinner]="false"
                    [showPrimary]="true"
                    [primaryIconClass]="'fa fa-clock-rotate-left'">
            </app-action-button>
        </div>
    </div>
</div>
<div aria-hidden="true"
     aria-labelledby="requestHistoryLabel"
     class="modal padded-modal fade"
     id="requestHistory"
     role="dialog"
     tabindex="-1">
    <div class="modal-dialog"
         role="document">
        <div class="modal-content">
            <div class="modal-header">
                <h5 class="modal-title"
                >Request history</h5>
                <button aria-label="Close"
                        class="btn-close float-end m-1"
                        data-bs-dismiss="modal"
                        type="button">
                </button>
            </div>
            <div class="modal-body">
                <div class="container justify-content-center">
                    <div *ngFor="let event of subscription?.events; index as i" class="row">
                        <i *ngIf="i != 0"
                           class="mb-1 mt-3 text-center fa-solid fa-2x fa-chevron-down color-primary-blue"></i>
                        <app-event [event]="event"></app-event>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>