import {Injectable} from '@angular/core';
import {Observable} from 'rxjs';

import {JsonPatchPayload} from './models/json-patch-payload';
import {IdentityPayload} from "./models/identity-payload";
import {DoaToaHttpService} from "./doa-toa-http.service";
import {IdentityView} from "./models/identity-view";
import {ActionView} from './models/action-view';
import {MetadataView} from "./models/metadata-view";
import {IdentityQueryPayload} from "./models/identity-query-payload";
import {environment} from "../../environments/environment";
import {VerificationMethodView} from "./models/verification-method-view";
import {VerificationRequestResultView} from "./models/verification-request-result-view";
import {VerificationMethodQueryPayload} from "./models/verification-method-query-payload";


@Injectable({
  providedIn: 'root'
})
export class IdentityControllerService {
  
  constructor(private client: DoaToaHttpService) {
  }
  
  public patchIdentity(jsonPatchPayload: Array<JsonPatchPayload>, token?: string): Observable<any> {
    
    let headers = new Map<string, string>()
    headers.set('Content-Type', 'application/json-patch+json')
    headers.set('Accept', 'application/json')
    let localVarPath = `/identity/`;
    return this.client.patch<Array<JsonPatchPayload>>(`${environment.idpApiBaseUrl}${localVarPath}`, jsonPatchPayload, headers)
  }
  
  public getVerificationMethods(): Observable<Array<VerificationMethodView>> {
    let headers: Map<string, string> = new Map<string, string>();
    headers.set('Accept', 'application/json')
    headers.set('Content-Type', 'application/json')
    let localVarPath = `/identity/verification-process/methods/`;
    return this.client.post<Array<VerificationMethodView>>(`${environment.idpApiBaseUrl}${localVarPath}`, {}, headers)
  }
  
  public viewVerificationMethods(scopes: string[]): Observable<Array<VerificationMethodView>> {
    
    let headers: Map<string, string> = new Map<string, string>();
    headers.set('Accept', 'application/json')
    headers.set('Content-Type', 'application/json')
    
    let localVarPath = `/identities/verification-process/methods/`
    const verificationMethodQueryPayload: VerificationMethodQueryPayload = {
      scopes: scopes
    }
    return this.client.post<Array<VerificationMethodView>>(`${environment.idpApiBaseUrl}${localVarPath}`, verificationMethodQueryPayload, headers)
  }
  
  public register(identityPayload: IdentityPayload): Observable<any> {
    let headers: Map<string, string> = new Map<string, string>();
    headers.set('Accept', 'application/json')
    headers.set('Content-Type', 'application/json')
    let localVarPath = `/identity/`;
    return this.client.post<IdentityPayload>(`${environment.idpApiBaseUrl}${localVarPath}`, identityPayload, headers)
  }
  
  public closeAccount(did: string): Observable<any> {
    let headers: Map<string, string> = new Map<string, string>();
    headers.set('Accept', 'application/json')
    headers.set('Content-Type', 'application/json')
    let localVarPath = `/identities/`;
    return this.client.delete<any>(`${environment.idpApiBaseUrl}${localVarPath}`, {did: did}, headers)
  }
  
  public findMetadata(did?: string, email?: string): Observable<MetadataView> {
    let headers: Map<string, string> = new Map<string, string>();
    headers.set('Accept', 'application/json')
    headers.set('Content-Type', 'application/json')
    let localVarPath = `/identities/metadata/`;
    return this.client.post<MetadataView>(`${environment.idpApiBaseUrl}${localVarPath}`, {
      did: did,
      email: email
    }, headers)
  }
  
  public findIdentity(didHashAlias?: string): Observable<IdentityView | undefined> {
    let query: IdentityQueryPayload = {identifier: didHashAlias}
    let headers: Map<string, string> = new Map<string, string>();
    headers.set('Accept', 'application/json')
    headers.set('Content-Type', 'application/json')
    let localVarPath = `/identities/`;
    return this.client.post<IdentityView | undefined>(`${environment.idpApiBaseUrl}${localVarPath}`, query, headers)
  }
  
  initiateVerificationProcessPayment(correlationId: string, chosenMethod: string): Observable<ActionView> {
    let headers: Map<string, string> = new Map<string, string>();
    headers.set('Accept', 'application/json')
    headers.set('Content-Type', 'application/json')
    
    let localVarPath = `/identity/verification-process/payment/`;
    return this.client.post<ActionView>(`${environment.idpApiBaseUrl}${localVarPath}`, {
      correlationId: correlationId,
      method: chosenMethod
    }, headers)
    
  }
  
  continueVerificationProcess(correlationId: string): Observable<ActionView> {
    let headers: Map<string, string> = new Map<string, string>();
    headers.set('Accept', 'application/json')
    headers.set('Content-Type', 'application/json')
    
    let localVarPath = `/identity/verification-process/challenge/`;
    return this.client.post<ActionView>(`${environment.idpApiBaseUrl}${localVarPath}`, {
      id: correlationId
    }, headers)
  }
  
  finalizeVerificationProcess(correlationId: string): Observable<VerificationRequestResultView> {
    let headers: Map<string, string> = new Map<string, string>();
    headers.set('Accept', 'application/json')
    headers.set('Content-Type', 'application/json')
    
    let localVarPath = `/identity/verification-process/result/`;
    return this.client.post<VerificationRequestResultView>(`${environment.idpApiBaseUrl}${localVarPath}`, {
      id: correlationId
    }, headers)
  }
  
  
  registerAgent(did: string, id: string, platform: string, os: string, osVersion: string, destination: string) {
    let headers: Map<string, string> = new Map<string, string>();
    headers.set('Accept', 'application/json')
    headers.set('Content-Type', 'application/json')
    let localVarPath = `/identity/agent/`;
    return this.client.post(`${environment.idpApiBaseUrl}${localVarPath}`, {
      did: did,
      id: id,
      platform: platform,
      os: os,
      osVersion: osVersion,
      destination: destination
    }, headers)
    
  }
}
