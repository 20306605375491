import {Component, Input, OnInit} from '@angular/core';
import {HttpErrorResponse} from "@angular/common/http";
import {DataSubscriptionService} from "../../../services/data-subscription.service";
import {CacheService} from "../../../services/cache-service";
import {CryptoWalletService} from "../../../services/crypto-wallet.service";
import {IdentityControllerService} from "../../../services/api/identity-controller.service";
import {ClaimControllerService} from "../../../services/api/claim-controller.service";
import {ClipboardService} from "../../../services/clipboard.service";
import {ActionButtonComponent} from "../../common/action-button/action-button.component";
import {ToVisibilityScopeIconPipe} from "../../common/pipes/ToVisibilityScopeIconPipe";
import {ToReadableStringPipe} from "../../common/pipes/ToReadableStringPipe";
import {ToOppositeVisibilityScopeIconPipe} from "../../common/pipes/ToOppositeClaimAspectIconPipe";
import {FormsModule} from "@angular/forms";
import {NgClass, NgForOf, NgIf} from "@angular/common";
import {QRCodeModule} from "angularx-qrcode";
import {SettingsComponent} from "../../common/settings/settings.component";
import {IdentityView} from "../../../services/api/models/identity-view";
import {VisibilityScopeEnum} from "../../../services/api/models/visibility-scope";

@Component({
  selector: 'app-identity-card',
  templateUrl: './identity-card.component.html',
  standalone: true,
  imports: [
    ActionButtonComponent,
    ToVisibilityScopeIconPipe,
    ToReadableStringPipe,
    ToOppositeVisibilityScopeIconPipe,
    FormsModule,
    NgIf,
    NgForOf,
    NgClass,
    QRCodeModule,
    SettingsComponent
  ],
  styleUrl: './identity-card.component.scss'
})
export class IdentityCardComponent implements OnInit {
  
  protected loading: boolean = false
  protected showIdCard: boolean = false
  protected identity?: IdentityView
  protected loadingMap: Map<VisibilityScopeEnum, boolean> = new Map<VisibilityScopeEnum, boolean>()
  protected did: string = ""
  protected introductionUpdateLoading: boolean = false
  protected showQrCode: boolean = false
  protected errorOccurred: boolean = false
  protected errorMessage: string = ''
  protected introduction: string = ''
  protected readonly VisibilityScopeEnum = VisibilityScopeEnum;
  
  constructor(protected dataSubscriptionService: DataSubscriptionService,
              protected clipboardService: ClipboardService,
              private profileCommandControllerService: IdentityControllerService,
              private cacheService: CacheService) {
  }
  
  ngOnInit(): void {
    this.dataSubscriptionService.onNext<boolean>('dataLoading').subscribe(dataLoading => {
      this.loading = dataLoading
    })
    this.dataSubscriptionService.onNext<IdentityView>('identity').subscribe({
      next: identity => {
        this.identity = identity;
      }
    })
  }
  
  alterVisibility(visibilityScope: VisibilityScopeEnum) {
    this.loadingMap.set(visibilityScope, true)
    this.cacheService.bypassCache = true
    this.profileCommandControllerService.patchIdentity([{
      op: "replace",
      path: "/visibilityScope",
      value: visibilityScope.toLowerCase()
    }]).subscribe({
      next: () => {
        this.loadingMap.set(visibilityScope, false)
        this.identity!.visibilityScope = visibilityScope
        this.dataSubscriptionService.publish('identity', this.identity)
      },
      error: (error) => {
        this.loadingMap.set(visibilityScope, false)
        if (error instanceof HttpErrorResponse) {
          this.errorOccurred = true
        }
      }
    })
  }
  
}