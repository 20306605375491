import {Component} from '@angular/core';
import {Router} from "@angular/router";
import {GenericButtonComponent} from "../../generic-button/generic-button.component";
import {DataSubscriptionService} from "../../../../services/data-subscription.service";
import {oneMinute} from "../../../../app.config";
import {QRCodeModule} from "angularx-qrcode";


@Component({
  selector: 'app-account',
  standalone: true,
  templateUrl: './account.component.html',
  imports: [
    GenericButtonComponent,
    QRCodeModule
  ],
  styleUrls: ['./account.component.scss']
})
export class AccountComponent {
  
  protected loading: boolean = false;
  protected dangerZoneClicked: number = 0;
  protected dangerZoneClickedTime: number = 0;
  protected patchLoading: boolean = false
  protected errorOccurred: boolean = false
  protected signOutLoading: boolean = false;
  
  constructor(private router: Router, protected dataSubscriptionService: DataSubscriptionService) {
  }
  
  signOut() {
    this.router.navigate(['/sign-out'])
  }
  
  dangerZone() {
    this.dangerZoneClicked++
    this.dangerZoneClickedTime = new Date().getTime();
    console.log(this.dangerZoneClicked)
    if (this.dangerZoneClicked >= 10) {
      this.router.navigate(['/close-account'])
    }
    setInterval(() => {
      if (this.dangerZoneClickedTime < new Date().getTime() + oneMinute) {
        this.dangerZoneClicked = 0;
      }
    }, oneMinute);
  }
}
