<div class="card p-3 mb-4">
    <h3>Account</h3>
<!--    <app-generic-button (click)="redirectToChangePasswordPage()"-->
<!--                        [buttonText]="'Change password'"-->
<!--                        [icon]="'fa-solid fa-unlock-keyhole'">-->
<!--    </app-generic-button>-->
    <div class="mb-3"></div>
    <app-generic-button id="my-account-sign-out-button"
                        (click)="signOut()"
                        [loading]="signoutLoading"
                        [buttonText]="'Sign out for all devices'"
                        [icon]="'fa-solid fa-right-from-bracket'">
    </app-generic-button>
    <hr class="mb-3"/>
    <app-generic-button routerLink="/close-account"
                        [btnClass]="'btn-primary-danger'"
                        [loading]="loading"
                        [buttonText]="'Delete account...'"
                        [icon]="'fa-solid fa-solid fa-trash-o'">
    </app-generic-button>
<!--    <div class="row">-->
<!--        <div class="col-auto">-->
<!--            <div class="form-check ms-3 mt-3">-->
<!--                <input [checked]="dataService.getIdentity()?.twoStepAuthenticationEnabled"-->
<!--                       (change)="switch2FA()"-->
<!--                       [disabled]="patchLoading"-->
<!--                       class="rounded-5 form-check-input"-->
<!--                       id="termsAndConditionsAccepted"-->
<!--                       type="checkbox">-->
<!--                <label for="termsAndConditionsAccepted">Use two-step authentication</label>-->
<!--            </div>-->
<!--        </div>-->
<!--        <div class="col-auto mt-3">-->
<!--            <app-spinner [loading]="patchLoading"></app-spinner>-->
<!--        </div>-->
<!--    </div>-->
</div>