<div class="container">
    <div *ngIf="!errorOccurred">
        <div class="row justify-content-center">
            <a href="https://doatoa.com"><app-logo [classes]="'mx-auto d-block mt-2'"
                      [height]="'200'"
                      [image]="'/doatoa-logo-full.svg'"
                      [width]="'300'"></app-logo>
            </a>
            <div class="card justify-content-center p-4 max-width-400 text-center">
                <p>Thank you for registering!</p>
                <p>Please verify your e-mail address through the e-mail message you'll receive shortly in your mailbox.</p>
                <p>This page can be closed safely.</p>
                <img class="m-auto" ngSrc="/registered.png" alt="registered" height="300" width="300">
                <a href="https://doatoa.com">Back to DoaToa.</a>
            </div>
            <div class="height-90"></div>
        </div>
    </div>
    <app-error-modal [showModal]="errorOccurred" [message]="message"></app-error-modal>
</div>