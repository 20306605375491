import {Pipe, PipeTransform} from '@angular/core';
import {VisibilityScopeEnum} from "../services/model/visibility-scope";

@Pipe({
    name: 'toVisibilityScopeIcon',
    standalone: true
})
export class ToVisibilityScopeIconPipe implements PipeTransform {
    constructor() {
    }

    public transform(value?: VisibilityScopeEnum): any {
        switch (value) {
            case VisibilityScopeEnum.Public:
                return 'fa fa-eye'
            case VisibilityScopeEnum.Protected:
                return 'fa fa-shield-halved'
            case VisibilityScopeEnum.Private:
                return 'fa fa-eye-slash'
            default:
                return ''
        }
    }
}