"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.RippleSignedTx = exports.RippleSignTransaction = exports.RippleTransaction = exports.RipplePayment = void 0;
const params_1 = require("../../params");
const schema_utils_1 = require("@trezor/schema-utils");
exports.RipplePayment = schema_utils_1.Type.Object({
    amount: schema_utils_1.Type.String(),
    destination: schema_utils_1.Type.String(),
    destinationTag: schema_utils_1.Type.Optional(schema_utils_1.Type.Number()),
});
exports.RippleTransaction = schema_utils_1.Type.Object({
    fee: schema_utils_1.Type.String(),
    flags: schema_utils_1.Type.Optional(schema_utils_1.Type.Number()),
    sequence: schema_utils_1.Type.Number(),
    maxLedgerVersion: schema_utils_1.Type.Optional(schema_utils_1.Type.Number()),
    payment: exports.RipplePayment,
});
exports.RippleSignTransaction = schema_utils_1.Type.Object({
    path: params_1.DerivationPath,
    transaction: exports.RippleTransaction,
    chunkify: schema_utils_1.Type.Optional(schema_utils_1.Type.Boolean()),
});
exports.RippleSignedTx = schema_utils_1.Type.Object({
    serializedTx: schema_utils_1.Type.String(),
    signature: schema_utils_1.Type.String(),
});
