import {Component, OnInit} from '@angular/core';
import {CacheService} from "../../common/services/cache-service";
import {IdentityControllerService, IdentityView} from "../../common/services";
import {RedirectService} from "../../common/services/redirect-service";
import {SignOutService} from "../../common/services/signout-service";
import {DataService} from "../../common/services/data-storage.service";


@Component({
    selector: 'app-account',
    templateUrl: './account.component.html',
    styleUrls: ['./account.component.scss']
})
export class AccountComponent implements OnInit {

    loading: boolean = false;
    patchLoading: boolean = false
    errorOccurred: boolean = false
    signoutLoading: boolean = false;

    constructor(private cacheService: CacheService, private identityControllerService: IdentityControllerService, private redirectService: RedirectService, private signoutService: SignOutService, protected dataService: DataService) {
    }

    ngOnInit(): void {
    }

    signOut() {
        this.dataService.last<string>('idToken').subscribe({
            next: idToken => {
                if (idToken == undefined) {
                    this.redirectService.redirectToGateway()
                } else this.signoutService.signOut(idToken!)
            }
        })

    }

    redirectToChangePasswordPage() {
        this.dataService.last<string>('accessToken').subscribe({
            next: accessToken => {
                this.redirectService.redirectToChangePasswordPage(accessToken!!)
            }
        })
    }

    switch2FA() {
        this.cacheService.bypassCache = true
        this.patchLoading = true
        this.identityControllerService.patchIdentity([{
            op: this.dataService.getIdentity()?.twoStepAuthenticationEnabled ? "remove" : "add",
            path: "/aspects",
            value: 'two_step_authentication_enabled'
        }]).subscribe({
            next: (response) => {
                const identity = this.dataService.getIdentity()
                const patchedIdentity: IdentityView = {
                    did: identity?.did,
                    preferredDid: identity?.preferredDid,
                    avatarId: identity?.avatarId,
                    introduction: identity?.introduction,
                    visibilityScope: identity?.visibilityScope,
                    authorities: identity?.authorities,
                    claims: identity?.claims,
                    subscriptions: identity?.subscriptions,
                    license: identity?.license,
                    twoStepAuthenticationEnabled: !identity?.twoStepAuthenticationEnabled
                }
                this.patchLoading = false
                this.dataService.publish('identity', patchedIdentity)
            }
        })
    }
}
