<div class="container" *ngIf="loading">
    <div class="mt-4 row">
        <div class="col-lg-3">
            <div class="height-220 mb-4">
                <lib-loading-placeholder [show]="loading"></lib-loading-placeholder>
            </div>
        </div>
        <div class="col-lg-9">
            <div class="height-220 mb-4">
                <lib-loading-placeholder [show]="loading"></lib-loading-placeholder>
            </div>
        </div>
    </div>
    <div class="row">
        <div class="col-lg-6">
            <div class="height-220 mb-4">
                <lib-loading-placeholder [show]="loading"></lib-loading-placeholder>
            </div>
        </div>
        <div class="col-lg-6">
            <div class="height-220 mb-4">
                <lib-loading-placeholder [show]="loading"></lib-loading-placeholder>
            </div>
        </div>
    </div>
</div>
<div class="container" *ngIf="!loading">
    <div class="row text-center">
        <div class="col-lg-3">
            <div class="card p-3 mt-2 height-220 mb-3">
                <app-avatar
                        [classList]="'d-flex justify-content-center align-items-center'"></app-avatar>
            </div>
        </div>
        <div class="col-lg-9 mt-2">
            <app-identity-summary [loading]="loading"></app-identity-summary>
        </div>
    </div>
    <div class="row">
        <app-my-claims
                class="col-lg-6"
                [loadingInitialClaims]="loading"></app-my-claims>
        <app-subscriptions
                class="col-lg-6"
                [loadingSubscriptions]="loading"></app-subscriptions>
    </div>
</div>