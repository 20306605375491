import {Pipe, PipeTransform} from '@angular/core';

@Pipe({
    name: 'toReadableSubscriptionStatus',
    standalone: true
})
export class ToReadableSubscriptionStatusPipe implements PipeTransform {
    constructor() {
    }

    public transform(value?: string): string {
        if (value == undefined)
            return ''
        let newValue = value.toLocaleLowerCase()
        while (newValue.indexOf('_') != -1) {
            newValue = newValue.replace('_', ' ')
        }
        return newValue
    }
}