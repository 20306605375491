import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {HttpErrorResponse} from "@angular/common/http";

import {ActionButtonComponent} from "../action-button/action-button.component";
import {ToVisibilityScopeIconPipe} from "../pipes/ToVisibilityScopeIconPipe";
import {ToOppositeVisibilityScopeIconPipe} from "../pipes/ToOppositeClaimAspectIconPipe";
import {FactComponent} from "../fact/fact.component";
import {ToReadableStringPipe} from "../pipes/ToReadableStringPipe";
import {NgForOf, NgIf} from "@angular/common";
import {ClaimControllerService, ClaimView, FactView, IdentityView} from "../../../services";
import {VisibilityScopeEnum} from '../../../services/model/visibility-scope';
import {ErrorMessageService} from "../../../services/error-message-service";
import {CryptographyService} from "../../../services/cryptography.service";
import {DataService} from "../../../services/data.service";
import {CryptoWalletService} from "../../../services/crypto-wallet.service";
import {CacheService} from "../../../services/cache-service";
import {IpfsService} from "../../../services/api/ipfs.service";
import {SpinnerComponent} from "../spinner/spinner.component";


@Component({
  selector: 'app-claim',
  templateUrl: './claim.component.html',
  standalone: true,
  imports: [
    ActionButtonComponent,
    ToVisibilityScopeIconPipe,
    ToOppositeVisibilityScopeIconPipe,
    FactComponent,
    ToReadableStringPipe,
    NgForOf,
    NgIf,
    SpinnerComponent
  ],
  styleUrls: ['./claim.component.scss']
})
export class ClaimComponent implements OnInit {
  @Input() claim!: ClaimView
  @Input() loading: boolean = false
  @Input() myProfile: boolean = false
  @Input() inMySubscriptionsOverview: boolean = false
  @Output() deletedSubscriptionId: EventEmitter<string> = new EventEmitter<string>();
  @Output() claimChanged: EventEmitter<string> = new EventEmitter<string>();
  protected fact?: FactView
  protected loadingMap: Map<string, boolean> = new Map<string, boolean>()
  protected deleteClaimClicked: boolean = false
  protected encrypted: boolean = true
  protected errorOccurred: boolean = false
  protected type: string = ''
  protected claimToDelete?: ClaimView
  protected password?: string;
  protected readonly VisibilityScopeEnum = VisibilityScopeEnum;
  protected showCopyConfirmation: boolean = false;
  
  constructor(private factsService: IpfsService, private errorMessageService: ErrorMessageService, private cryptographyService: CryptographyService, private dataService: DataService, private cryptoWalletService: CryptoWalletService, private cacheService: CacheService, private claimControllerService: ClaimControllerService) {
  }
  
  ngOnInit(): void {
    this.factsService.getFact(this.claim?.cid).subscribe({
      next: fact => {
        this.fact = fact
      }
    })
  }
  
  protected tryDeleteClaim(claim?: ClaimView) {
    if (!!claim && !this.claimIsMandatory(claim)) {
      this.deleteClaimClicked = true
      this.claimToDelete = claim
      this.dataService.publish<ClaimView>('claimToDelete', claim)
    }
  }
  
  protected claimIsMandatory(claim?: ClaimView) {
    let isMandatory = false;
    for (const aspect of claim?.aspects || []) {
      if (aspect == "mandatory") {
        isMandatory = true
      }
    }
    return isMandatory;
  }
  
  protected alterVisibility(id: string, visibilityScope?: VisibilityScopeEnum) {
    this.loadingMap.set(id + visibilityScope, true)
    this.cacheService.bypassCache = true
    this.claimControllerService.alterClaim([{
      op: "replace",
      path: `${id}/visibilityScope`,
      value: visibilityScope?.toLowerCase()
    }]).subscribe({
      next: (claim) => {
        this.claim.visibilityScope = visibilityScope
        this.loadingMap.set(id + visibilityScope, false)
      },
      error: (error) => {
        this.loadingMap.set(id + visibilityScope, false)
        if (error instanceof HttpErrorResponse) {
          this.errorOccurred = true
        }
      }
    });
  }
  
  protected async encryptDecryptClaim() {
    let privateKeyString = ''
    if (this.myProfile) {
      privateKeyString = await this.dataService.getEncryptionPrivateKey()
    } else {
      privateKeyString = this.claim?.encryptedPrivateKey!
    }
    if (this.encrypted) {
      await this.decryptClaimUsingKey(privateKeyString)
    } else {
      await this.encryptClaimUsingKey(privateKeyString!)
    }
  }
  
  private async encryptClaimUsingKey(privateKeyString: string) {
    const cryptoKey = await this.cryptographyService.privateAESKeyStringToCryptoKey(privateKeyString)
    const encryptedValue = await this.cryptographyService.encryptAES(this.fact!.value!, cryptoKey)
    this.fact = this.fact = {
      ...this.fact,
      value: encryptedValue
    }
    this.encrypted = true
  }
  
  private async decryptClaimUsingKey(privateKeyString: string) {
    const cryptoKey = await this.cryptographyService.privateAESKeyStringToCryptoKey(privateKeyString)
    const decryptedValue = await this.cryptographyService.decryptAES(this.fact!.value!, cryptoKey)
    this.fact = this.fact = {
      ...this.fact,
      value: decryptedValue
    }
    this.encrypted = false
  }
  
  supportClaim() {
    this.dataService.last<IdentityView>('identity').subscribe({
      next: async (identity) => {
        const did = identity?.did!
        const signatureResult = await this.cryptoWalletService.signData(JSON.stringify(this.claim))
        if (signatureResult) {
          const signature = signatureResult.signature
          this.claimControllerService.supportClaim({claimId: this.claim!.id!, did: did, signature: signature})
          this.dataService.publish('identity', identity)
        } else {
          this.errorOccurred = true;
          this.errorMessageService.setMessage("Failed to get a signature.")
        }
      }
    })
    
  }
  
}