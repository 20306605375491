import {Component, Input} from '@angular/core';
import {LogoComponent} from "../../common/logo/logo.component";
import {SpinnerComponent} from "../../common/spinner/spinner.component";

@Component({
  selector: 'app-landing',
  standalone: true,
  imports: [
    LogoComponent,
    SpinnerComponent
  ],
  templateUrl: './landing.component.html',
  styleUrl: './landing.component.scss'
})
export class LandingComponent {
  @Input() message!: string;
}
