export class KeyValuePairsMigrationStatements {
  migrations = [
    `CREATE TABLE IF NOT EXISTS key_value_pairs (
             id TEXT PRIMARY KEY,
             json TEXT
         );`
    /* add new statements below for next database version when required*/
    /*
    {
    toVersion: 2,
    statements: [
    
    ]
    },
    */
  ]
}