/// <reference types="@angular/localize" />

import {enableProdMode} from '@angular/core';
import {environment} from "./app/environments/environment";
import {AppComponent} from "./app/app.component";
import {bootstrapApplication} from "@angular/platform-browser";
import {appConfig} from "./app/app.config";

if (environment.production) {
    enableProdMode();
}

// Helper function
let domReady = ({cb}: { cb: any }) => {
    document.readyState === 'interactive' || document.readyState === 'complete'
        ? cb()
        : document.addEventListener('DOMContentLoaded', cb);
};

domReady({
    cb: () => {
        // Display body when DOM is loaded
        document.body.style.visibility = 'visible';
    }
});

bootstrapApplication(AppComponent, appConfig)
    .catch((err) => console.error(err));
