<div class="container">
    <div class="row justify-content-center">
        <app-logo [classes]="'mx-auto d-block mt-2'"
                  [height]="'200'"
                  [image]="'/doatoa-logo-full.svg'"
                  [width]="'300'"></app-logo>
        <div class="col-auto text-center card max-width-300 p-3">
            <h2>Sign up</h2>
            <div class="position-relative">
                <input [(ngModel)]="email"
                       class="rounded-5 form-control {{email == undefined || email == '' ? '' : email != undefined && isValidEmail(email) ? 'is-valid' : 'is-invalid'}}"
                       datatype="email"
                       id="email"
                       name="email"
                       placeholder="E-mail address"
                       type="email"
                       autocomplete="new-email">
                <i class="secondary-blue fa-solid fa-circle-question p-1"
                   style="position: absolute; right: 10px; top: 50%; transform: translateY(-50%);"
                   (click)="flipShowEmailInfo()"></i>
            </div>
            <div class="p-3"
                 *ngIf="showEmailInfo">The mandatory e-mail address used for all DoaToa correspondence.
            </div>
            <div class="mt-2">
                <app-generic-button [buttonText]="'Next'"
                                    [widthClass]="''"
                                    [icon]="'fa fa-angle-right'"
                                    [disabled]="email == undefined || !isValidEmail(email)"
                                    (click)="selectEmail()"></app-generic-button>
            </div>
        </div>
    </div>
</div>
<div class="mt-3 mb-3">
    <div class="row justify-content-center">
        <div class="max-width-300 card text-center">
            <div class="p-2">
                Already have an account?
                <a class="secondary-blue"
                   routerLink="/sign-in">Sign in
                </a>
            </div>
        </div>
    </div>
    <div class="row justify-content-center">
        <div class="col-auto m-auto text-center">
            <app-error-modal [message]="'This did is already taken'"
                             [showModal]="!!didIsTaken && didIsTaken"
                             [extraClasses]="'max-width-300 m-3'"></app-error-modal>
        </div>
    </div>
    <div class="row justify-content-center">
        <div class="max-width-300 text-center">
            <app-error-modal [showModal]="errorOccurred"
                             [extraClasses]="'m-3'"></app-error-modal>
        </div>
    </div>
</div>