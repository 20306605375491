<div class="m-3 card p-3">
    <div class="">
        <h5>Export claims.</h5>
        <button aria-label="Close"
                class="btn-close float-end m-1"
                (click)="navigator.navigateTo('/my-claims', true)">
        </button>
    </div>
    <div class="">
        <select (change)="setScopeToDownload($event)"
                class="rounded-5 mb-2 col form-select"
                id="#"
                name="type">
            <option disabled
                    [selected]="scope == undefined"
                    value="">Select a scope...
            </option>
            <option *ngFor="let scope of scopes"
                    value="{{scope}}">{{ scope | toReadableString }}
            </option>
        </select>
    </div>
    <div class="d-flex justify-content-center">
        <app-generic-button
                (click)="download()"
                [primaryIcon]="'fa fa-download'"
                [buttonText]="'Download'"
                [disabled]="exportLoading || scope == undefined"
                [loading]="exportLoading">
        </app-generic-button>
        <app-error-message></app-error-message>
    </div>
</div>