import {DataService} from "./services/data-storage.service";
import {RedirectService} from "./services/redirect-service";
import {Component} from "@angular/core";

const nonAuthorizedPaths = ['sign-in', 'sign-up', 'consent', 'error'];
@Component({template: ``})
export abstract class AuthorisedComponent {

    protected constructor(protected dataService: DataService, redirectService: RedirectService) {
        if (!nonAuthorizedPaths.some(path => window.location.href.includes(path))) {
            if (!dataService.has('accessToken')) {
                redirectService.redirectToSignInPage()
            }
        }

    }
}