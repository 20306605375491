import {Router} from "@angular/router";
import {Injectable} from "@angular/core";
import {DataSubscriptionService} from "../../services/data-subscription.service";

@Injectable({providedIn: 'root'})
export class NavigatorService {
  
  constructor(private router: Router, private dataSubscriptionService: DataSubscriptionService) {
  }
  
  navigateTo(path: string, showMenu = true) {
    this.router.navigate([path]);
    this.dataSubscriptionService.publish('showMenu', showMenu)
  }
  
}