<div class="">
    <div class="w-100 max-height-50 z-2">
        <div class="m-3 card p-1 z-max">
            <div class="d-flex justify-content-between p-2">
                <div class="ps-3 d-flex justify-content-between align-items-center"
                     aria-expanded="true"
                     aria-controls="settings"
                     data-bs-target="#settings"
                     data-bs-toggle="collapse">
                    <div class="border-end">
                        <i class="pointer fa-solid fa-bars fa-2x color-secondary-blue me-3 mt-auto mb-auto"></i>
                    </div>
                    <i class="fa-solid fa-passport fa-2x color-secondary-blue ms-3 me-2 mt-auto mb-auto"></i>
                </div>
                <div class="">
                    <div class="d-flex color-secondary-blue border rounded-pill">
                        <app-action-button *ngFor="let visibilityScope of VisibilityScopeEnum.values()"
                                           (click)="alterVisibility(visibilityScope)"
                                           [btnClass]="identity?.visibilityScope == visibilityScope ? 'btn-regular' : 'btn-regular-disabled'"
                                           [loading]="loadingMap.get(visibilityScope) || false"
                                           [useSpinner]="true"
                                           [showPrimary]="identity?.visibilityScope == visibilityScope"
                                           [showSecondary]="identity?.visibilityScope != visibilityScope"
                                           [primaryIconClass]="visibilityScope | toVisibilityScopeIcon"
                                           [secondaryIconClass]="visibilityScope | toOppositeVisibilityScopeIcon"
                                           class="p-1">
                        </app-action-button>
                    </div>
                </div>
            </div>
            <div id="settings" class="collapse">
                <app-settings></app-settings>
            </div>
        </div>
    </div>
    <div class="margin-top-90px d-flex justify-content-center">
        <div class="flip-card mb-5 " [ngClass]="{'flip-card-flip': showQrCode}">
            <div class="flip-card-inner">
                <div *ngIf="identity" class="card p-2 id-card-ratio m-auto flip-card-front min-width-300">
                    <div class="rounded-4 border p-1 h-100 background-color-white ">
                        <div class="position-absolute end-0 top-0 p-3 color-secondary-blue">
                            <app-action-button
                                    (click)="showQrCode = !showQrCode"
                                    [showPrimary]="!showQrCode"
                                    [showSecondary]="showQrCode"
                                    [secondaryIconClass]="'fa-solid fa-passport'"
                                    [primaryIconClass]="'fa-solid fa-qrcode'">
                            </app-action-button>
                        </div>
                        <div class="p-3 justify-content-start row">
                            <div *ngIf="showQrCode"
                                 class="col d-flex justify-content-center"
                                 (click)="showQrCode = !showQrCode">
                                <qrcode colorDark="#3333FF"
                                        [allowEmptyString]="true"
                                        [qrdata]="did"
                                        class="m-auto"
                                        [margin]=0
                                        [width]="70"
                                        [imageWidth]="70"
                                        [errorCorrectionLevel]="'M'"></qrcode>
                            </div>
                            <div *ngIf="!showQrCode" class="col ps-0">
                                <div class="truncate-text pointer"
                                     (click)="clipboardService.copyToClipboard(identity!.hash!)">
                                    <i class="fa-solid fa-fingerprint color-secondary-blue me-2 max-height-50"></i>
                                    Hash: {{ identity.hash || 'none' }}
                                </div>
                                <div class="truncate-text pointer"
                                     (click)="clipboardService.copyToClipboard(identity!.alias || 'none')">
                                    <i class="fa-solid fa-mask color-secondary-blue me-2 max-height-50"></i>
                                    Alias: {{ identity.alias || 'none' }}
                                </div>
                                <div class="truncate-text pointer" *ngIf="identity">
                                    <i class="{{ identity.visibilityScope | toVisibilityScopeIcon }} color-secondary-blue me-2 max-height-50"></i>
                                    Visibility: {{ identity.visibilityScope | toReadableString }}
                                </div>
                            </div>
                            <div class="pointer"
                                 (click)="clipboardService.copyToClipboard(identity!.did!)">
                                {{ identity.did || '...' }}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div *ngIf="clipboardService.showCopyConfirmation" class="d-flex justify-content-center">
        <div class="position-fixed text-center color-primary-blue">(Copied to clipboard)</div>
    </div>
</div>