<div class="vh-100 d-flex flex-column align-items-center justify-content-start">
    <div class="logo-container mt-4">
        <app-logo
                [classes]="'mx-auto d-block'"
                [height]="'200'"
                [image]="'/doatoa-logo-full.svg'"
                [width]="'300'">
        </app-logo>
    </div>
    <div class="container justify-content-center">
        <app-spinner [loading]="true"
                     class="m-auto mt-5"
                     [style]="'width: 183px; height: 183px;'"></app-spinner>
        <div class="mt-3 text-center">Signing in...</div>
    </div>
</div>