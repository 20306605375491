"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.EnumTxVersion = exports.TxVersion = exports.EnumTxType = exports.TxType = exports.EnumNetworks = exports.Networks = void 0;
const schema_utils_1 = require("@trezor/schema-utils");
var Networks;
(function (Networks) {
    Networks[Networks["mainnet"] = 104] = "mainnet";
    Networks[Networks["testnet"] = 152] = "testnet";
    Networks[Networks["mijin"] = 96] = "mijin";
})(Networks || (exports.Networks = Networks = {}));
exports.EnumNetworks = schema_utils_1.Type.Enum(Networks);
var TxType;
(function (TxType) {
    TxType[TxType["TRANSFER"] = 257] = "TRANSFER";
    TxType[TxType["COSIGNING"] = 258] = "COSIGNING";
    TxType[TxType["IMPORTANCE_TRANSFER"] = 2049] = "IMPORTANCE_TRANSFER";
    TxType[TxType["AGGREGATE_MODIFICATION"] = 4097] = "AGGREGATE_MODIFICATION";
    TxType[TxType["MULTISIG_SIGNATURE"] = 4098] = "MULTISIG_SIGNATURE";
    TxType[TxType["MULTISIG"] = 4100] = "MULTISIG";
    TxType[TxType["PROVISION_NAMESPACE"] = 8193] = "PROVISION_NAMESPACE";
    TxType[TxType["MOSAIC_CREATION"] = 16385] = "MOSAIC_CREATION";
    TxType[TxType["SUPPLY_CHANGE"] = 16386] = "SUPPLY_CHANGE";
})(TxType || (exports.TxType = TxType = {}));
exports.EnumTxType = schema_utils_1.Type.Enum(TxType);
var TxVersion;
(function (TxVersion) {
    TxVersion[TxVersion["mainnet"] = 1744830464] = "mainnet";
    TxVersion[TxVersion["testnet"] = -1744830464] = "testnet";
    TxVersion[TxVersion["mijin"] = 1610612736] = "mijin";
})(TxVersion || (exports.TxVersion = TxVersion = {}));
exports.EnumTxVersion = schema_utils_1.Type.Enum(TxVersion);
