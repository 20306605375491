import {Injectable} from "@angular/core";

@Injectable({
    providedIn: 'root'
})
export class ErrorMessageService {

    private message?: string
    private errorOccurred: boolean = false;
    
    errorHasOccurred(): boolean {
        return this.errorOccurred
    }
    
    setMessage(message?: string) {
        this.errorOccurred = true
        this.message = message || "Oops, something went wrong, please try again."
    }

    getMessage(): string {
        return this.message || "Oops, something went wrong, please try again."
    }
    
    reset() {
        this.message = undefined
        this.errorOccurred = false
    }
}