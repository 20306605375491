<!--<div class="h-100">-->
<!--    <div *ngFor="let claim of identity?.claims; index as i"-->
<!--         class="justify-content-center" (click)="openCloseClaim(claim)">-->
<!--        <div *ngIf="!loading" class="row text-dark">-->
<!--            <div class="mb-3 pointer {{i % 2 == 0 ? 'btn-basic-secondary-dark' : 'btn-basic-secondary'}}"-->
<!--                 [id]="'claim-row-'+i"-->
<!--                 [attr.data-bs-target]="'#claims-claim-'+i"-->
<!--                 aria-expanded="false"-->
<!--                 data-bs-toggle="collapse">-->
<!--                <div class="d-flex align-items-center justify-content-between">-->
<!--                    <div class="d-flex align-items-center">-->
<!--                        <i class="fa fa-fw color-primary-blue {{claim.type | toClaimScopeIcon}}"></i>-->
<!--                        <div class="ms-3 text-start truncate-text">{{ claim.type |  toReadableString }}: {{ claim.description }}</div>-->
<!--                        <i class="ms-2 fa fa-fw color-primary-blue {{claim.visibilityScope | toVisibilityScopeIcon}}"></i>-->
<!--                    </div>-->
<!--                    <app-action-button [useSpinner]="true"-->
<!--                                       [loading]="claimLoading.get(claim.cid!) || false"-->
<!--                                       [showPrimary]="open.get(claim.cid!) || false"-->
<!--                                       [showSecondary]="!open.get(claim.cid!) || false"-->
<!--                                       [btnClass]="'btn-regular'"-->
<!--                                       [primaryIconClass]="'fa fa-chevron-up'"-->
<!--                                       [secondaryIconClass]="'fa fa-chevron-down'">-->
<!--                    </app-action-button>-->
<!--                </div>-->
<!--            </div>-->
<!--        </div>-->
<!--        <div class="collapse" [id]="'claims-claim-'+i">-->
<!--            <app-claim [myProfile]="false"-->
<!--                       [identity]="identity"-->
<!--                       [claim]="claim"></app-claim>-->
<!--        </div>-->
<!--    </div>-->
<!--    <div *ngIf="identity?.claims?.length == 0"-->
<!--         class="mt-3 text-center greyed-out ps-3">No claims visible to you-->
<!--    </div>-->
<!--</div>-->
<div class="container">
    <div *ngFor="let claim of identity?.claims; index as i" class="mb-3 container justify-content-center">
        <div class="row text-dark">
            <div (click)="openCloseClaim(claim)"
                 [attr.aria-controls]="'claim-'+i"
                 [attr.data-bs-target]="'#claim-'+i"
                 [ngClass]="'btn-regular'"
                 [id]="'claim-row-'+i"
                 aria-expanded="false"
                 data-bs-toggle="collapse"
                 class="p-0 m-0 btn btn-regular min-width-220">
                <div class="ps-3 d-flex align-items-center justify-content-between height-40">
                    <div class="d-flex align-items-center">
                        <i class="color-primary-blue"
                           [ngClass]="{'fa fa-fw fa-check color-nominal': claim.attestations?.length || 0 > 0, 'fa fa-fw fa-question': claim.attestations?.length || 0 == 0}"></i>
                        <div class="ms-2 text-start truncate-text">{{ claim.type | toReadableString }}</div>
                        <i class="ms-2 fa fa-fw color-secondary-blue {{claim.visibilityScope | toVisibilityScopeIcon}}"></i>
                    </div>
                    <div class="pe-3 d-flex align-items-center justify-content-center height-40">
                        <i *ngIf="open.get(claim.cid!) || false" class="fa fa-chevron-up"></i>
                        <i *ngIf="!open.get(claim.cid!) || false" class="fa fa-chevron-down"></i>
                    </div>
                </div>
            </div>
            <div [id]="'claim-'+i" class="collapse">
                <app-claim [myProfile]="false"
                           [loading]="claimLoading.get(claim.cid!) || false"
                           [claim]=claim></app-claim>
            </div>
        </div>
    </div>
    <div *ngIf="identity?.claims?.length == 0"
         class="mt-3 text-center greyed-out ps-3">No claims visible to you
    </div>
</div>
