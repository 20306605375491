"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.getDeepValue = exports.setDeepValue = void 0;
function setDeepValue(obj, [prop, ...path], value) {
    if (!path.length) {
        obj[prop] = value;
    }
    else {
        if (!(prop in obj))
            obj[prop] = {};
        setDeepValue(obj[prop], path, value);
    }
}
exports.setDeepValue = setDeepValue;
function getDeepValue(obj, [prop, ...path]) {
    if (!path.length) {
        return obj[prop];
    }
    else {
        if (!(prop in obj))
            return undefined;
        return getDeepValue(obj[prop], path);
    }
}
exports.getDeepValue = getDeepValue;
