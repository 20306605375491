<div class="min-width-300 overflow-x-hidden">
    <div class="w-100">
        <div class="m-3 card p-1 z-max">
            <div class="d-flex justify-content-between p-2">
                <div class="ps-3 d-flex justify-content-between align-items-center"
                     aria-expanded="true"
                     aria-controls="settings"
                     data-bs-target="#settings"
                     data-bs-toggle="collapse">
                    <div class="border-end">
                        <i class="pointer fa-solid fa-bars fa-2x color-secondary-blue me-3 mt-auto mb-auto"></i>
                    </div>
                    <i class="fa-solid fa-bell fa-2x color-secondary-blue ms-3 me-2 mt-auto mb-auto "></i>
                </div>
                <div class="">
                    <div class="d-flex color-secondary-blue border rounded-pill">
                        <app-action-button class="p-1"
                                           (click)="toggleSubscribersSubscriptions()"
                                           [iconStack]="true"
                                           [showPrimary]="subscriptionsActivated"
                                           [showSecondary]="!subscriptionsActivated"
                                           [stackedPrimaryFirstIconClass]="'fa-solid fa-bell'"
                                           [stackedPrimarySecondIconClass]="'fa-solid fa-arrow-up'"
                                           [stackedSecondaryFirstIconClass]="'fa-solid fa-bell'"
                                           [stackedSecondarySecondIconClass]="'fa-solid fa-arrow-down'">
                        </app-action-button>
                    </div>
                </div>
            </div>
            <div id="settings" class="collapse">
                <app-settings></app-settings>
            </div>
        </div>
    </div>
    <div class="w-100">
        <div class="m-3 card p-3">
            <div *ngIf="subscriptionsActivated" class="mb-3">
                <div class="d-flex color-secondary-blue border rounded-pill w-100 align-items-center">
                    <input [(ngModel)]="didHashAlias"
                           aria-label="did"
                           autocapitalize="off"
                           class="text-truncate form-control rounded-5 m-1 flex-grow-1 min-width-0"
                           placeholder="did/hash/alias"
                           type="search">
                    <app-action-button
                            class="p-1"
                            (click)="findIdentities()"
                            [disabled]="loading"
                            [loading]="loading"
                            [showPrimary]="true"
                            [primaryIconClass]="'fa-solid fa-magnifying-glass'">
                    </app-action-button>
                    <app-action-button
                            class="p-1"
                            [showPrimary]="true"
                            [disabled]="loading"
                            [loading]="loading"
                            [primaryIconClass]="'fa-solid fa-qrcode'"
                            (click)="scanBarcode()">
                    </app-action-button>
                </div>
            </div>
            <div class="p-3">
                <div *ngIf="searchedIdentity" class="m-3 justify-content-center">
                    <div class="row text-dark">
                        <div (click)="openCloseIdentity()"
                             [attr.aria-controls]="'identity'"
                             [attr.data-bs-target]="'#identity'"
                             [ngClass]="'btn-regular'"
                             [id]="'identity-row'"
                             aria-expanded="false"
                             data-bs-toggle="collapse"
                             class="p-0 m-0 btn btn-regular min-width-220">
                            <div class="ps-3 d-flex align-items-center justify-content-between height-40">
                                <div class="d-flex align-items-center">
                                    <i class="color-primary-blue fa-user-circle"></i>
                                    <div class="ms-2 text-start truncate-text">{{ getIdentityIdentifier(searchedIdentity) }}</div>
                                    <i class="ms-2 fa fa-fw color-secondary-blue {{searchedIdentity.visibilityScope | toVisibilityScopeIcon}}"></i>
                                </div>
                                <div class="pe-3 d-flex align-items-center justify-content-center height-40">
                                    <i *ngIf="open.get(searchedIdentity?.did!) || false" class="fa fa-chevron-up"></i>
                                    <i *ngIf="!open.get(searchedIdentity?.did!) || false"
                                       class="fa fa-chevron-down"></i>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div [id]="'identity'" class="collapse">
                        <app-identity [identity]="searchedIdentity"></app-identity>
                    </div>
                </div>
                <div *ngIf="!searchedIdentity" class="p-3 d-flex justify-content-center">
                    No matches found
                </div>
            </div>
            <hr/>
            <div class="p-3">
                <app-subscriptions *ngIf="subscriptionsActivated"></app-subscriptions>
                <app-subscribers *ngIf="!subscriptionsActivated"></app-subscribers>
            </div>
        </div>
    </div>
</div>