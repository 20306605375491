"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.parseConnectSettings = exports.corsValidator = exports.DEFAULT_PRIORITY = void 0;
const version_1 = require("./version");
exports.DEFAULT_PRIORITY = 2;
const initialSettings = {
    configSrc: './data/config.json',
    version: version_1.VERSION,
    debug: false,
    priority: exports.DEFAULT_PRIORITY,
    trustedHost: true,
    connectSrc: version_1.DEFAULT_DOMAIN,
    iframeSrc: `${version_1.DEFAULT_DOMAIN}iframe.html`,
    popup: false,
    popupSrc: `${version_1.DEFAULT_DOMAIN}popup.html`,
    webusbSrc: `${version_1.DEFAULT_DOMAIN}webusb.html`,
    transports: undefined,
    pendingTransportEvent: true,
    env: 'node',
    lazyLoad: false,
    timestamp: new Date().getTime(),
    interactionTimeout: 600,
    sharedLogger: true,
};
const parseManifest = (manifest) => {
    if (!manifest)
        return;
    if (typeof manifest.email !== 'string')
        return;
    if (typeof manifest.appUrl !== 'string')
        return;
    return {
        email: manifest.email,
        appUrl: manifest.appUrl,
    };
};
const corsValidator = (url) => {
    if (typeof url !== 'string')
        return;
    if (url.match(/^https:\/\/([A-Za-z0-9\-_]+\.)*trezor\.io\//))
        return url;
    if (url.match(/^https?:\/\/localhost:[58][0-9]{3}\//))
        return url;
    if (url.match(/^https:\/\/([A-Za-z0-9\-_]+\.)*sldev\.cz\//))
        return url;
    if (url.match(/^https?:\/\/([A-Za-z0-9\-_]+\.)*trezoriovpjcahpzkrewelclulmszwbqpzmzgub37gbcjlvluxtruqad\.onion\//))
        return url;
};
exports.corsValidator = corsValidator;
const parseConnectSettings = (input = {}) => {
    var _a;
    const settings = { ...initialSettings };
    if ('debug' in input) {
        if (typeof input.debug === 'boolean') {
            settings.debug = input.debug;
        }
        else if (typeof input.debug === 'string') {
            settings.debug = input.debug === 'true';
        }
    }
    if (input.trustedHost === false) {
        settings.trustedHost = input.trustedHost;
    }
    if (typeof input.connectSrc === 'string' && ((_a = input.connectSrc) === null || _a === void 0 ? void 0 : _a.startsWith('http'))) {
        settings.connectSrc = (0, exports.corsValidator)(input.connectSrc);
    }
    else if (settings.trustedHost) {
        settings.connectSrc = input.connectSrc;
    }
    const src = settings.connectSrc || version_1.DEFAULT_DOMAIN;
    settings.iframeSrc = `${src}iframe.html`;
    settings.popupSrc = `${src}popup.html`;
    settings.webusbSrc = `${src}webusb.html`;
    if (typeof input.transportReconnect === 'boolean') {
        settings.transportReconnect = input.transportReconnect;
    }
    if (typeof input.webusb === 'boolean') {
        settings.webusb = input.webusb;
    }
    if (Array.isArray(input.transports)) {
        settings.transports = input.transports;
    }
    if (typeof input.popup === 'boolean') {
        settings.popup = input.popup;
    }
    if (typeof input.lazyLoad === 'boolean') {
        settings.lazyLoad = input.lazyLoad;
    }
    if (typeof input.pendingTransportEvent === 'boolean') {
        settings.pendingTransportEvent = input.pendingTransportEvent;
    }
    if (typeof input.extension === 'string') {
        settings.extension = input.extension;
    }
    if (typeof input.env === 'string') {
        settings.env = input.env;
    }
    if (typeof input.timestamp === 'number') {
        settings.timestamp = input.timestamp;
    }
    if (typeof input.interactionTimeout === 'number') {
        settings.interactionTimeout = input.interactionTimeout;
    }
    if (typeof input.manifest === 'object') {
        settings.manifest = parseManifest(input.manifest);
    }
    if (typeof input.sharedLogger === 'boolean') {
        settings.sharedLogger = input.sharedLogger;
    }
    if (typeof input.useCoreInPopup === 'boolean') {
        settings.useCoreInPopup = input.useCoreInPopup;
    }
    if (typeof input._extendWebextensionLifetime === 'boolean') {
        settings._extendWebextensionLifetime = input._extendWebextensionLifetime;
    }
    return settings;
};
exports.parseConnectSettings = parseConnectSettings;
