<div class="p-3 overflow-x-hidden overflow-y-auto">
    <div class="row mb-2">
        <div class="pointer text-dark">
            <div class="justify-content-center">
                <div class="truncate-text">
                    <i class="fa-solid fa-id-card color-secondary-blue me-2 max-height-50"></i>
                    DID: {{ identity.did }}
                </div>
                <div class="truncate-text">
                    <i class="fa-solid fa-fingerprint color-secondary-blue me-2 max-height-50"></i>
                    Hash: {{ identity.hash || 'none' }}
                </div>
                <div class="truncate-text">
                    <i class="fa-solid fa-mask color-secondary-blue me-2 max-height-50"></i>
                    Alias: {{ identity.alias || 'none' }}
                </div>
                <div class="truncate-text">
                    <i class="fa {{ identity.visibilityScope | toVisibilityScopeIcon }} color-secondary-blue me-2 max-height-50"></i>
                    Visibility: {{ identity.visibilityScope | toReadableString }}
                </div>
            </div>
        </div>
    </div>
    <div class="d-flex justify-content-end">
        <div class="d-flex m-auto color-secondary-blue border rounded-pill">
            <app-action-button
                    *ngIf="!subscribedByCurrentIdentity"
                    class="p-1"
                    [useSpinner]="true"
                    [disabled]="identity.visibilityScope != 'public' && !subscribedByCurrentIdentity"
                    [showPrimary]="true"
                    [btnClass]="'btn-regular'"
                    [primaryIconClass]="'fa-solid fa-bell'"
                    (click)="navigator.navigateTo('/new-subscription', false)">
            </app-action-button>
            <app-action-button
                    class="p-1"
                    [useSpinner]="true"
                    [disabled]="!subscribedByCurrentIdentity"
                    [showPrimary]="true"
                    [btnClass]="'btn-regular'"
                    [primaryIconClass]="'fa fa-cloud-arrow-down'"
                    (click)="navigator.navigateTo('/export-claims', false)">
            </app-action-button>

        </div>
    </div>
</div>
<app-claims [identity]="identity"></app-claims>

