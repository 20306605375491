import {Component, Input, OnInit} from '@angular/core';
import {ClaimControllerService, ClaimPayload, IdentityControllerService, IdentityView} from "../../../services/";
import {VisibilityScopeEnum} from "../../../services/model/visibility-scope";
import {HttpErrorResponse} from "@angular/common/http";
import {DataService} from "../../../services/data.service";
import {CacheService} from "../../../services/cache-service";
import {CryptoWalletService} from "../../../services/crypto-wallet.service";
import {sha256} from "js-sha256";

@Component({
  selector: 'app-identity-summary',
  templateUrl: './profile-introduction.component.html',
  styleUrl: './profile-introduction.component.scss'
})
export class ProfileIntroductionComponent implements OnInit {
  
  @Input() loading: boolean = false
  protected identity?: IdentityView
  protected loadingMap: Map<VisibilityScopeEnum, boolean> = new Map<VisibilityScopeEnum, boolean>()
  protected introductionUpdateLoading: boolean = false
  protected errorOccurred: boolean = false
  protected errorMessage: string = ''
  protected introduction: string = ''
  protected maxCharacters: number = 100
  protected remainingCharacters: number = 100
  
  constructor(protected dataService: DataService, private profileCommandControllerService: IdentityControllerService, private claimControllerService: ClaimControllerService, private cacheService: CacheService, private cryptoWalletService: CryptoWalletService) {
  }
  
  ngOnInit() {
    this.dataService.last<IdentityView>('identity').subscribe(identity => this.identity = identity)
  }
  
  alterVisibility(visibilityScope: VisibilityScopeEnum) {
    this.loadingMap.set(visibilityScope, true)
    this.cacheService.bypassCache = true
    this.profileCommandControllerService.patchIdentity([{
      op: "replace",
      path: "/visibilityScope",
      value: visibilityScope.toLowerCase()
    }]).subscribe({
      next: (response) => {
        this.loadingMap.set(visibilityScope, false)
        this.dataService.last<IdentityView>('identity').subscribe({
          next: identity => {
            identity!.visibilityScope = visibilityScope
            this.dataService.publish('identity', identity)
          }
        })
      },
      error: (error) => {
        this.loadingMap.set(visibilityScope, false)
        if (error instanceof HttpErrorResponse) {
          this.errorOccurred = true
        }
      }
    })
  }
  
  
  async submitIntroduction() {
    const claimPayload: ClaimPayload = {
      claimVisibilityScope: VisibilityScopeEnum.Protected,
      claimAspects: []
    }
    claimPayload.claimType = 'introduction'
    this.cacheService.bypassCache = true
    this.introductionUpdateLoading = true
    const encryptedValue = await this.cryptoWalletService.encrypt(this.introduction)
    const signatureResult = await this.cryptoWalletService.signData(encryptedValue)
    claimPayload.thumbprint = sha256.hex(this.introduction)
    claimPayload.encryptedFactValue = encryptedValue
    if (signatureResult) {
      this.claimControllerService.makeClaim({
        data: claimPayload,
        signature: signatureResult.signature
      }).subscribe({
        next: (ClaimView) => {
          this.dataService.last<IdentityView>('identity').subscribe({
            next: identity => {
              identity.claims?.push(ClaimView)
              this.dataService.publish('identity', identity)
              this.introductionUpdateLoading = false
            }
          })
        },
        error: (error) => {
          this.errorOccurred = true
          this.introductionUpdateLoading = false
        }
      });
    }
  }
  
  // submitIntroduction() {
  //   this.cacheService.bypassCache = true
  //   this.introductionUpdateLoading = true
  //   const factClaimPayload: ClaimPayload = {
  //     encryptedFactValue: this.introduction,
  //     claimType: "introduction",
  //     claimVisibilityScope: VisibilityScopeEnum.Public,
  //     claimAspects: []
  //   }
  //   this.cryptoWalletService.signData(factClaimPayload.encryptedFactValue!).then(signature => {
  //     if (signature) {
  //       this.claimControllerService.makeClaim({
  //         data: factClaimPayload,
  //         signature: signature.signature
  //       }).subscribe({
  //         next: (response) => {
  //           this.dataService.last<IdentityView>('identity').subscribe({
  //             next: identity => {
  //               identity!.claims?.push(response)
  //               identity!.introduction = this.introduction
  //               this.introductionUpdateLoading = false
  //               this.dataService.publish('identity', identity)
  //             }
  //           })
  //         },
  //         error: (error) => {
  //           this.introductionUpdateLoading = false
  //           if (error instanceof HttpErrorResponse) {
  //             this.errorOccurred = true
  //           }
  //         }
  //       })
  //     }
  //   })
  // }
  
  onIntroductionInput(event: any) {
    const enteredText = event.target.value;
    this.remainingCharacters = this.maxCharacters - enteredText.length;
  }
  
  protected readonly VisibilityScopeEnum = VisibilityScopeEnum;
  
}