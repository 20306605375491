<div class="mb-3">
    <div class="row align-items-center justify-content-between">
        <div class="d-flex justify-content-between p-3">
            <div *ngIf="myProfile" class="d-flex justify-content-end border rounded-5 p-1 color-secondary-blue">
                <app-action-button *ngFor="let visibilityScope of VisibilityScopeEnum.values(); let first = first; let last = last;"
                                   (click)="alterVisibility(claim.cid!, visibilityScope)"
                                   [disabled]="loading"
                                   [btnClass]="claim!.visibilityScope == visibilityScope ? 'btn-regular' : 'btn-regular-disabled'"
                                   [loading]="loadingMap.get(claim.cid!+visibilityScope) || false"
                                   [showPrimary]="claim!.visibilityScope == visibilityScope"
                                   [showSecondary]="claim!.visibilityScope != visibilityScope"
                                   [primaryIconClass]="visibilityScope | toVisibilityScopeIcon"
                                   [secondaryIconClass]="visibilityScope | toOppositeVisibilityScopeIcon"
                                   class="{{last ? 'ms-1' : first ? '' : 'ms-1'}} ">
                </app-action-button>
            </div>

            <div class="d-flex justify-content-start border rounded-5 p-1 color-secondary-blue">
                <app-action-button
                        (click)="encryptDecryptClaim()"
                        [btnClass]="encrypted ? 'btn-regular' : 'btn-alert'"
                        [showPrimary]="true"
                        [primaryIconClass]="'fa-solid fa-key'"
                        [disabled]="loading || (!encrypted && !myProfile)">
                </app-action-button>
<!--                <app-action-button-->
<!--                        *ngIf="!myProfile"-->
<!--                        class="ms-1"-->
<!--                        (click)="supportClaim()"-->
<!--                        [btnClass]="'btn-regular'"-->
<!--                        [showPrimary]="true"-->
<!--                        [primaryIconClass]="'fa-solid fa-check'"-->
<!--                        [disabled]="myProfile || loading">-->
<!--                </app-action-button>-->
                <app-action-button *ngIf="myProfile" (click)="tryDeleteClaim(claim)"
                                   [useSpinner]="false"
                                   [disabled]="loading"
                                   [btnClass]="'btn-alert'"
                                   [showPrimary]="true"
                                   [primaryIconClass]="'fa-solid fa-trash'"
                                   class="ms-1">
                </app-action-button>
            </div>
        </div>
    </div>
    <div class="text-center mt-3">{{ claim.type |  toReadableString }}:</div>
    <div *ngIf="fact != undefined && !loading">
        <app-fact [fact]="fact"></app-fact>
    </div>
    <app-spinner [loading]="fact == undefined || loading"></app-spinner>
    <div class="text-center">Description:</div>
    <div class="text-center color-secondary-blue">{{ claim.description }}</div>
    <div class="text-center color-primary-blue" *ngIf="showCopyConfirmation">(Copied to clipboard)</div>
</div>
