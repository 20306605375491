<div class="container">
    <div class="row justify-content-center">
        <app-logo [classes]="'mx-auto d-block mt-2'"
                  [height]="'200'"
                  [image]="'/doatoa-logo-full.svg'"
                  [width]="'300'"></app-logo>
        <div class="col-auto text-center card max-width-400 p-3">
            <div *ngIf="mnemonic == ''" class="btn p-3" (click)="copyToClipboard()">
                <div>DoaToa will now connect to you Hardware Wallet in order to create your digital identity.</div>
            </div>
            <div *ngIf="mnemonic != ''" id="mnemonic-phrase" class="btn p-3" (click)="copyToClipboard()">
                <p>This is your mnemonic recovery phrase, take extra care to store it somewhere safe (preferably offline). This phrase cannot be recreated. Once lost, you'll never be able to recover your digital identity if you've also lost your keys.</p>
                <div class="container">
                    <div class="row">
                        <div *ngFor="let m of mnemonicParts; index as i" class="col-6 d-flex">
                            <span class="me-2">{{ i + 1 }}</span>
                            <span class="fw-bold">{{ m }}</span>
                        </div>
                    </div>
                </div>
                <div class="text-center primary-blue" *ngIf="showCopyConfirmation">(Copied to clipboard)</div>
            </div>
            <div class="form-check rounded-5 col-auto m-3">
                <label for="termsAndConditionsAccepted">I accept the
                    <a href="https://doatoa.com/terms-of-service/" target=”_blank”>terms and conditions.</a>
                </label>
                <input [checked]="termsAndConditionsAccepted"
                       (change)="switchTermsAndConditionsAccepted()"
                       class="form-check-input"
                       id="termsAndConditionsAccepted"
                       type="checkbox">
            </div>
            <div class="">
                <app-generic-button
                        (click)="createIdentity()"
                        [btnClass]="'btn-basic-info'"
                        [disabled]="loading || !termsAndConditionsAccepted"
                        [loading]="loading"
                        [buttonText]="'Create account'"
                        [icon]="'fa fa-angle-right'"></app-generic-button>
            </div>
        </div>
        <app-error-modal [showModal]="errorOccurred"></app-error-modal>
    </div>
</div>
