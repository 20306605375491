import {Component, Input} from '@angular/core';
import {NgIf, NgOptimizedImage} from "@angular/common";
import {SpinnerComponent} from "../spinner/spinner.component";

@Component({
  selector: 'app-generic-button',
  standalone: true,
  templateUrl: './generic-button.component.html',
  imports: [
    NgIf,
    SpinnerComponent,
    NgOptimizedImage
  ],
  styleUrl: './generic-button.component.scss'
})
export class GenericButtonComponent {

  @Input() loading: boolean = false
  @Input() disabled: boolean = false
  @Input() buttonText: string = ''
  @Input() btnClass: string = 'btn-basic-info min-width-300'
  @Input() icon: string = ''
  @Input() type: string = ''
  @Input() dataBsDismiss: string = ''
  @Input() primaryIcon: string = ''
  @Input() width: number = 50
  @Input() height: number = 50
  @Input() secondaryIcon: string = ''
  @Input() active: boolean = false
  @Input() useSpinner: boolean = true
  @Input() showPrimaryIcon: boolean = true
  @Input() showSecondaryIcon: boolean = false
  @Input() alwaysShowText: boolean = false
}
