import {Component, Input, OnInit} from '@angular/core';
import {saveAs} from "file-saver";
import {HttpErrorResponse} from "@angular/common/http";
import {GenericButtonComponent} from "../generic-button/generic-button.component";
import {ToReadableStringPipe} from "../pipes/ToReadableStringPipe";
import {ErrorMessageComponent} from "../error-message/error-message.component";
import {NgForOf} from "@angular/common";
import {DataSubscriptionService} from "../../../services/data-subscription.service";
import {ClaimControllerService} from "../../../services/api/claim-controller.service";
import {ClaimView} from "../../../services/api/models/claim-reference-view";
import {NavigatorService} from "../navigator.service";

@Component({
    selector: 'app-export-claims',
    templateUrl: './export-claims.component.html',
    standalone: true,
    imports: [
        GenericButtonComponent,
        ToReadableStringPipe,
        ErrorMessageComponent,
        NgForOf
    ],
    styleUrl: './export-claims.component.scss'
})
export class ExportClaimsComponent implements OnInit {

    protected customId?: string
    protected subject?: string;
    protected exportLoading: boolean = false
    protected scopes: Array<string> = []
    protected scope?: string

    constructor(protected navigator: NavigatorService, private claimControllerService: ClaimControllerService, protected dataSubscriptionService: DataSubscriptionService) {
    }

    ngOnInit(): void {
        this.dataSubscriptionService.onFirst<string>('did').subscribe({
            next: did => {
                this.subject = did
            }
        })
        this.dataSubscriptionService.onFirst<Array<string>>('scopeAggregates').subscribe({
            next: scopes => {
                this.scopes = scopes
            }
        })
    }

    download() {
        this.exportLoading = true
        const did = this.subject
        const fileName = `${did!}_${new Date().getTime()}.json`
        this.claimControllerService.viewClaims<Array<ClaimView>>(did!, undefined, this.scope)
            .subscribe({
                next: (response) => {
                    const jsonString = JSON.stringify(response)
                    const blob = new Blob([jsonString], { type: "application/json" });
                    saveAs(blob, fileName);
                    this.exportLoading = false
                },
                error: (error) => {
                    this.exportLoading = false
                }
            });
    }

    setScopeToDownload(event: any) {
        this.scope = event.target.value as string;
    }


}
