import {Injectable} from '@angular/core';
import {Observable} from 'rxjs';
import {DoaToaHttpService} from "./doa-toa-http.service";
import {IpfsStatus} from "./models/ipfs-status";
import {FactView} from "./models/fact-view";

@Injectable({
  providedIn: 'root'
})
export class IpfsService {
  
  constructor(private client: DoaToaHttpService) {
  }
  
  public getStatus(): Observable<IpfsStatus> {
    let headers: Map<string, string> = new Map<string, string>();
    
    headers = headers.set('Accept', 'application/json');
    headers = headers.set('Content-Type', 'application/json');
    return this.client.get<IpfsStatus>(`https://pinata.statuspage.io/api/v2/status.json`, headers)
  }
  
  public getFact(hash?: string): Observable<FactView> {
    let headers: Map<string, string> = new Map<string, string>();
    
    headers = headers.set('Accept', 'application/json');
    headers = headers.set('Content-Type', 'application/json');
    return this.client.get<FactView>(`https://ipfs.io/ipfs/${hash}`, headers)
  }
  
}