import {Component, Input, OnInit} from '@angular/core';
import {saveAs} from "file-saver";
import {HttpErrorResponse} from "@angular/common/http";
import {ClaimControllerService} from "../services";
import {DataService} from "../services/data-storage.service";
import {GenericButtonComponent} from "../generic-button/generic-button.component";
import {ToReadableStringPipe} from "../pipes/ToReadableStringPipe";
import {ErrorModalComponent} from "../error-modal/error-modal.component";
import {NgForOf} from "@angular/common";

@Component({
    selector: 'app-export-modal',
    templateUrl: './export-modal.component.html',
    standalone: true,
    imports: [
        GenericButtonComponent,
        ToReadableStringPipe,
        ErrorModalComponent,
        NgForOf
    ],
    styleUrl: './export-modal.component.scss'
})
export class ExportModalComponent implements OnInit {

    @Input() customId?: string
    @Input() subject?: string;
    protected exportLoading: boolean = false
    protected scopes: Array<string> = []
    protected scope?: string
    protected errorMessage: string = ''
    protected errorOccurred: boolean = false

    constructor(private claimControllerService: ClaimControllerService, protected dataService: DataService) {
    }

    ngOnInit(): void {
        this.dataService.last<string>('did').subscribe({
            next: did => {
                this.subject = did
            }
        })
        this.dataService.last<Array<string>>('scopeAggregates').subscribe({
            next: scopes => {
                this.scopes = scopes
            }
        })
    }

    downloadAsPdf() {
        this.exportLoading = true
        const did = this.subject
        const file_name = `${did!}_${new Date().getTime()}.pdf`
        this.claimControllerService.viewClaims<Blob>(did!, undefined, this.scope, undefined, undefined, undefined, 'application/pdf')
            .subscribe({
                next: (response) => {
                    saveAs(response, file_name);
                    this.exportLoading = false
                },
                error: (error) => {
                    this.exportLoading = false
                    if (error instanceof HttpErrorResponse) {
                        this.errorOccurred = true
                    }

                }
            });
    }

    setScopeToDownload(event: any) {
        this.scope = event.target.value as string;
    }


}
