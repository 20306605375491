"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.TypeClone = exports.Optional = exports.Type = exports.AssertWeak = exports.Assert = exports.Validate = void 0;
const typebox_1 = require("@sinclair/typebox");
Object.defineProperty(exports, "Optional", { enumerable: true, get: function () { return typebox_1.Optional; } });
Object.defineProperty(exports, "TypeClone", { enumerable: true, get: function () { return typebox_1.TypeClone; } });
const errors_1 = require("@sinclair/typebox/errors");
const ts_mixer_1 = require("ts-mixer");
const custom_types_1 = require("./custom-types");
const errors_2 = require("./errors");
const utils_1 = require("./utils");
class CustomTypeBuilder extends (0, ts_mixer_1.Mixin)(typebox_1.JavaScriptTypeBuilder, custom_types_1.ArrayBufferBuilder, custom_types_1.BufferBuilder, custom_types_1.KeyofEnumBuilder, custom_types_1.UintBuilder) {
}
function Validate(schema, value) {
    try {
        Assert(schema, value);
        return true;
    }
    catch (e) {
        return false;
    }
}
exports.Validate = Validate;
function FindErrorInUnion(error) {
    const currentValue = error.value;
    const unionMembers = error.schema.anyOf;
    const hasValidMember = unionMembers.find(unionSchema => Validate(unionSchema, currentValue));
    if (!hasValidMember) {
        const possibleMatchesByLiterals = unionMembers.filter(unionSchema => {
            if (unionSchema[typebox_1.Kind] !== 'Object')
                return false;
            return !Object.entries(unionSchema.properties).find(([property, propertySchema]) => propertySchema.const && propertySchema.const !== currentValue[property]);
        });
        if (possibleMatchesByLiterals.length === 1) {
            Assert(possibleMatchesByLiterals[0], currentValue);
        }
        else if (possibleMatchesByLiterals.length > 1) {
            const errorsOfPossibleMatches = possibleMatchesByLiterals.map((matchSchema) => ({
                schema: matchSchema,
                errors: [...(0, errors_1.Errors)(matchSchema, currentValue)],
            }));
            const sortedErrors = errorsOfPossibleMatches.sort((a, b) => a.errors.length - b.errors.length);
            const [bestMatch] = sortedErrors;
            Assert(bestMatch.schema, currentValue);
        }
        throw new errors_2.InvalidParameter(error.message, error.path, error.type, error.value);
    }
}
function Assert(schema, value) {
    const errors = [...(0, errors_1.Errors)(schema, value)];
    let [error] = errors;
    while (error) {
        if (error.path === '/' && errors.length > 1) {
        }
        else if (error.value == null && error.schema[typebox_1.Optional] === 'Optional') {
        }
        else if (error.type === errors_1.ValueErrorType.Union) {
            FindErrorInUnion(error);
        }
        else if (error.type === errors_1.ValueErrorType.Number && typeof error.value === 'string') {
            const currentValue = error.value;
            const parsedNumber = Number(currentValue);
            if (!Number.isNaN(parsedNumber) && currentValue === parsedNumber.toString()) {
                const pathParts = error.path.slice(1).split('/');
                (0, utils_1.setDeepValue)(value, pathParts, parsedNumber);
            }
            else {
                throw new errors_2.InvalidParameter(error.message, error.path, error.type, error.value);
            }
        }
        else {
            throw new errors_2.InvalidParameter(error.message, error.path, error.type, error.value);
        }
        errors.shift();
        [error] = errors;
    }
}
exports.Assert = Assert;
function AssertWeak(schema, value) {
    try {
        Assert(schema, value);
    }
    catch (e) {
        if (e instanceof errors_2.InvalidParameter) {
            if (e.type === errors_1.ValueErrorType.ObjectRequiredProperty) {
                throw e;
            }
            console.warn('Method params validation failed', e);
        }
        else {
            throw e;
        }
    }
}
exports.AssertWeak = AssertWeak;
exports.Type = new CustomTypeBuilder();
