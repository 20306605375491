<nav class="bg-white p-0 w-auto m-3 card" *ngIf="showMenu">
    <div class="d-flex ">
        <div ariaCurrentWhenActive='page'
             class="btn nav-item neumorphism-flat height-90 width-90 flex-grow-1 d-flex justify-content-center align-items-center {{dataLoading ? 'disabled' : ''}}"
             routerLink="/my-identity"
             routerLinkActive="active neumorphism-pressed">
            <i class="fa-solid fa-passport fa-2x"></i>
        </div>
        <div ariaCurrentWhenActive='page'
             class="btn nav-item neumorphism-flat height-90 width-90 flex-grow-1 d-flex justify-content-center align-items-center {{dataLoading ? 'disabled' : ''}}"
             routerLink="/my-claims"
             routerLinkActive="active neumorphism-pressed">
            <i class="fa-solid fa-list-check fa-2x"></i>
        </div>
        <div ariaCurrentWhenActive='page'
             class="btn nav-item neumorphism-flat height-90 width-90 flex-grow-1 d-flex justify-content-center align-items-center {{dataLoading ? 'disabled' : ''}}"
             routerLink="/my-subscriptions"
             routerLinkActive="active neumorphism-pressed">
            <i class="fa-solid fa-bell fa-2x"></i>
        </div>
    </div>
</nav>