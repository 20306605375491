import {HttpClient, HttpHeaders} from "@angular/common/http";
import {Injectable} from "@angular/core";
import {environment} from "../../environments/environment";
import {catchError, from, map, Observable, switchMap, throwError} from "rxjs";
import {CapacitorHttp, HttpOptions, HttpHeaders as NativeHeaders} from "@capacitor/core";
import {CryptoWalletService} from "../crypto-wallet.service";
import {nonAuthenticatedPaths} from "../../app.config";

@Injectable({
  providedIn: 'root'
})
export class DoaToaHttpService {
  
  protected constructor(protected cryptoWalletService: CryptoWalletService, protected httpClient: HttpClient) {
  }
  
  get<T>(url: string, headers: Map<string, string>): Observable<T> {
    return this.request<T>('get', url, headers);
  }
  
  post<T>(url: string, body: any, headers: Map<string, string>): Observable<T> {
    return this.request<T>('post', url, headers, body);
  }
  
  put<T>(url: string, body: any, headers: Map<string, string>): Observable<T> {
    return this.request<T>('put', url, headers, body);
  }
  
  patch<T>(url: string, body: any, headers: Map<string, string>): Observable<T> {
    return this.request<T>('patch', url, headers, body);
  }
  
  delete<T>(url: string, body: any, headers: Map<string, string>): Observable<T> {
    return this.request<T>('delete', url, headers, body);
  }
  
  private request<T>(
    method: 'get' | 'post' | 'put' | 'patch' | 'delete',
    url: string,
    headers: Map<string, string>,
    body?: any,
  ): Observable<T> {
    const shouldAuthenticate = !nonAuthenticatedPaths.some(path => url.includes(path));
    if (shouldAuthenticate) {
      return from(this.cryptoWalletService.getStored('jwt')).pipe(
        switchMap((jwt) => {
          const httpHeaders = this.createHeaders(headers, jwt);
          if (environment.platform === 'web') {
            return this.httpClient.request<T>(method, url, {
              headers: httpHeaders as HttpHeaders,
              body: method !== 'get' ? body : undefined,
            });
          } else {
            const options: HttpOptions = {
              url,
              headers: httpHeaders as NativeHeaders,
              data: method !== 'get' ? body : undefined,
            };
            return from(this.executeRequest(method, options)).pipe(
              map(response => response.data as T),
              catchError(error => {
                console.error(`Error during ${method.toUpperCase()} request:`, error);
                throw error;
              })
            );
          }
        }),
        catchError(error => {
          console.error('Error in request:', error);
          return throwError(() => new Error('Failed to execute HTTP request.'));
        })
      );
    } else {
      const httpHeaders = this.createHeaders(headers, undefined);
      if (environment.platform === 'web') {
        return this.httpClient.request<T>(method, url, {
          headers: httpHeaders as HttpHeaders,
          body: method !== 'get' ? body : undefined,
        });
      } else {
        const options: HttpOptions = {
          url,
          headers: httpHeaders as NativeHeaders,
          data: method !== 'get' ? body : undefined,
        };
        return from(this.executeRequest(method, options)).pipe(
          map(response => response.data as T),
          catchError(error => {
            console.error(`Error during ${method.toUpperCase()} request:`, error);
            throw error; // Re-throw to handle errors upstream
          })
        );
      }
    }
  }
  
  
  private executeRequest(method: "get" | "post" | "put" | "patch" | "delete", options: HttpOptions) {
    switch (method) {
      case 'get':
        return CapacitorHttp.get(options);
      case 'post':
        return CapacitorHttp.post(options);
      case 'put':
        return CapacitorHttp.put(options);
      case 'patch':
        return CapacitorHttp.patch(options);
      case 'delete':
        return CapacitorHttp.delete(options);
      default:
        throw new Error(`Unsupported HTTP method: ${method}`);
    }
  }
  
  private createHeaders(headers: Map<string, string>, jwt?: string): HttpHeaders | NativeHeaders {
    const modifiedHeaders = new Map(headers);
    if (jwt) {
      modifiedHeaders.set('Authorization', `Bearer ${jwt}`);
    }
    if (environment.platform === 'web') {
      let webHeaders = new HttpHeaders();
      modifiedHeaders.forEach((value, key) => {
        webHeaders = webHeaders.set(key, value);
      });
      return webHeaders;
    } else {
      return Object.fromEntries(modifiedHeaders) as NativeHeaders;
    }
  }
}