<div class="card p-2 mt-2 mb-3">
    <div class="container mt-3 max-height-400 overflow-y-scroll">
        <h5 class="text-center mt-1">Subscriptions on my claims</h5>
        <div *ngFor="let request of dataService.getIdentity()?.subscriptions; index as i"
             class="container justify-content-center">
            <div class="row text-dark">
                <div (click)="openOrClose(request.id!)"
                     [attr.aria-controls]="'subscription-'+request.id"
                     [attr.data-bs-target]="'#subscription-'+request.id"
                     role="button"
                     aria-expanded="false"
                     data-bs-toggle="collapse"
                     class="mb-3 p-0 m-0 btn {{resolveClass(i, request)}}">
                    <div class="pe-0 ps-3 container d-flex align-items-center justify-content-between">
                        <div class="d-flex align-items-center">
                            <div class="ms-3 text-start">From {{ request.initiator }}</div>
                            <i class="ms-3 {{request | toStatusClass}}"></i>
                        </div>
                        <app-action-button [showPrimary]="open.get(request.id!) || false"
                                           [showSecondary]="!open.get(request.id!) || false"
                                           [btnClass]="'btn-basic-info'"
                                           [primaryIconClass]="'fa fa-chevron-up'"
                                           [secondaryIconClass]="'fa fa-chevron-down'">
                        </app-action-button>
                    </div>
                </div>
            </div>
            <div class="collapse" [id]="'subscription-'+request.id">
                <app-subscription [subscription]="request"
                             (requestDeleted)="remove($event)"
                             (requestUpdated)="update($event)"
                             [addressedToMe]="true"></app-subscription>
            </div>
        </div>
        <app-spinner [loading]="loadingSubscriptions"
                     [style]="'width: 40px; height: 40px;'"></app-spinner>
        <div class="mb-3" *ngIf="dataService.getIdentity()?.subscriptions?.length == 0">
            <div class="text-center greyed-out">No subscriptions</div>
        </div>
    </div>
</div>