import {DataSubscriptionService} from "../../services/data-subscription.service";
import {Component} from "@angular/core";
import {SignOutService} from "../../services/signout-service";
import {CryptoWalletService} from "../../services/crypto-wallet.service";
import {performBiometricVerification} from "../../services/biometric-auth.service";
import {DataStorageService} from "../../services/data-storage.service";
import {environment} from "../../environments/environment";
import {NavigatorService} from "./navigator.service";

@Component({template: ``})
export abstract class AuthorisedComponent {
  
  protected shouldAuthenticate: boolean = false;
  
  protected constructor(protected dataSubscriptionService: DataSubscriptionService,
                        private signOutService: SignOutService,
                        protected cryptoWalletService: CryptoWalletService,
                        private navigatorService: NavigatorService,
                        private dataStorageService: DataStorageService) {
    this.verifyJwt();
    this.dataSubscriptionService.onNext<boolean>('authRequired').subscribe({
      next: authRequired => {
        if (authRequired) {
          this.verifyJwt();
        }
      }
    });
    if (this.shouldAuthenticate) {
      this.authorise()
    }
  }
  
  private verifyJwt() {
    this.cryptoWalletService.hasStored('jwt').then(hasTwt => {
      if (!hasTwt) {
        console.log("Signing out...");
        this.signOutService.signOut()
      }
    })
  }
  
  
  private authorise() {
    console.log("Authorising access...")
    if (environment.platform != "web") {
      performBiometricVerification().then(isAuthenticated => {
        if (isAuthenticated) {
          this.dataStorageService.initConnection().then(() => {
            this.navigatorService.navigateTo('/sign-in', false);
          })
        }
      })
    } else {
      this.dataStorageService.initConnection().then(() => {
        this.navigatorService.navigateTo('/sign-in', false);
      })
    }
  }
  
  
}