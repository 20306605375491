<div class="d-flex flex-column align-items-center justify-content-start">
    <div class="d-flex flex-column align-items-center justify-content-center max-width-300 mt-5">
        <app-logo class="m-3"></app-logo>
        <div class="col-auto text-center max-width-300 p-3">
            <div class="mb-2">
                <app-generic-button [buttonText]="'Create account'"
                                    [btnClass]="'btn-regular'"
                                    [primaryIcon]="'fa-solid fa-plus'"
                                    [loading]="loading"
                                    [disabled]="email == undefined || !isValidEmail(email)"
                                    (click)="onNext()"></app-generic-button>
            </div>
            <div class="mb-2">
                <app-generic-button [buttonText]="'Restore account'"
                                    [btnClass]="'btn-regular'"
                                    [loading]="loading"
                                    [primaryIcon]="'fa-solid fa-wallet'"
                                    routerLink="/recover-keys"></app-generic-button>
            </div>
        </div>
    </div>
    <div class="row justify-content-center">
        <div class="max-width-300 text-center">
            <app-error-message></app-error-message>
        </div>
    </div>
</div>