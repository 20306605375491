"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.createMessageFromType = exports.createMessageFromName = exports.parseConfigure = exports.isPrimitiveField = void 0;
const tslib_1 = require("tslib");
const protobuf = tslib_1.__importStar(require("protobufjs/light"));
const primitiveTypes = [
    'bool',
    'string',
    'bytes',
    'int32',
    'int64',
    'uint32',
    'uint64',
    'sint32',
    'sint64',
    'fixed32',
    'fixed64',
    'sfixed32',
    'sfixed64',
    'double',
    'float',
];
const isPrimitiveField = (field) => primitiveTypes.includes(field);
exports.isPrimitiveField = isPrimitiveField;
function parseConfigure(data) {
    if (typeof data === 'string') {
        return protobuf.Root.fromJSON(JSON.parse(data));
    }
    return protobuf.Root.fromJSON(data);
}
exports.parseConfigure = parseConfigure;
const createMessageFromName = (messages, name) => {
    const Message = messages.lookupType(name);
    const MessageType = messages.lookupEnum('MessageType');
    let messageTypeId = MessageType.values[`MessageType_${name}`];
    if (typeof messageTypeId !== 'number' && Message.options) {
        messageTypeId = Message.options['(wire_type)'];
    }
    return {
        Message,
        messageType: messageTypeId !== null && messageTypeId !== void 0 ? messageTypeId : name,
    };
};
exports.createMessageFromName = createMessageFromName;
const createMessageFromType = (messages, messageType) => {
    if (typeof messageType === 'string') {
        const Message = messages.lookupType(messageType);
        return {
            Message,
            messageName: messageType,
        };
    }
    const messageTypes = messages.lookupEnum('MessageType');
    const messageName = messageTypes.valuesById[messageType].replace('MessageType_', '');
    const Message = messages.lookupType(messageName);
    return {
        Message,
        messageName,
    };
};
exports.createMessageFromType = createMessageFromType;
