"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.ServiceWorkerWindowChannel = void 0;
const connect_common_1 = require("@trezor/connect-common");
class ServiceWorkerWindowChannel extends connect_common_1.AbstractMessageChannel {
    constructor({ name, channel, logger, lazyHandshake, legacyMode, allowSelfOrigin = false, currentId, }) {
        super({
            channel,
            sendFn: (message) => {
                if (!this.port)
                    throw new Error('port not assigned');
                this.port.postMessage(message);
            },
            logger,
            lazyHandshake,
            legacyMode,
        });
        this.name = name;
        this.allowSelfOrigin = allowSelfOrigin;
        this.currentId = currentId;
        this.connect();
    }
    connect() {
        chrome.runtime.onConnect.addListener(port => {
            var _a, _b, _c, _d;
            if (port.name !== this.name)
                return;
            if (((_a = this.currentId) === null || _a === void 0 ? void 0 : _a.call(this)) && ((_b = this.currentId) === null || _b === void 0 ? void 0 : _b.call(this)) !== ((_d = (_c = port.sender) === null || _c === void 0 ? void 0 : _c.tab) === null || _d === void 0 ? void 0 : _d.id))
                return;
            this.port = port;
            this.port.onMessage.addListener((message, { sender }) => {
                var _a, _b, _c, _d, _e;
                if (!sender) {
                    (_a = this.logger) === null || _a === void 0 ? void 0 : _a.error('service-worker-window', 'no sender');
                    return;
                }
                const { origin } = sender;
                const whitelist = [
                    'https://connect.trezor.io',
                    'https://staging-connect.trezor.io',
                    'https://suite.corp.sldev.cz',
                    'https://dev.suite.sldev.cz',
                    'http://localhost:8088',
                ];
                const webextensionId = (_b = chrome === null || chrome === void 0 ? void 0 : chrome.runtime) === null || _b === void 0 ? void 0 : _b.id;
                if (webextensionId) {
                    whitelist.push(`chrome-extension://${webextensionId}`);
                }
                const webextensionUrl = (_c = chrome === null || chrome === void 0 ? void 0 : chrome.runtime) === null || _c === void 0 ? void 0 : _c.getURL('');
                if (webextensionUrl) {
                    whitelist.push(webextensionUrl.slice(0, -1));
                }
                if (!origin) {
                    (_d = this.logger) === null || _d === void 0 ? void 0 : _d.error('connect-webextension/messageChannel/extensionPort/onMessage', 'no origin');
                    return;
                }
                if (!whitelist.includes(origin)) {
                    (_e = this.logger) === null || _e === void 0 ? void 0 : _e.error('connect-webextension/messageChannel/extensionPort/onMessage', 'origin not whitelisted', origin);
                    return;
                }
                if (origin === self.origin && !this.allowSelfOrigin) {
                    return;
                }
                this.onMessage(message);
            });
        });
        this.isConnected = true;
    }
    disconnect() {
        var _a;
        if (!this.isConnected)
            return;
        (_a = this.port) === null || _a === void 0 ? void 0 : _a.disconnect();
        this.clear();
        this.isConnected = false;
    }
}
exports.ServiceWorkerWindowChannel = ServiceWorkerWindowChannel;
