import {Component} from '@angular/core';
import {ErrorMessageService} from "../../../services/error-message-service";
import {ErrorMessageComponent} from "../../common/error-message/error-message.component";
import {LogoComponent} from "../../common/logo/logo.component";


@Component({
  selector: 'app-error-landing',
  templateUrl: './error-landing.component.html',
  standalone: true,
  imports: [
    ErrorMessageComponent,
    LogoComponent
  ],
  styleUrl: './error-landing.component.scss'
})
export class ErrorLandingComponent {
  
  constructor(errorMessageService: ErrorMessageService) {
    errorMessageService.setMessage('Something went wrong while registering. Please try again later.')
  }
}
