import {Injectable} from '@angular/core';
import {Observable} from 'rxjs';

import {DecisionPayload} from "./models/decision-payload-ns";
import {SubscriptionView} from "./models/subscription-view";
import {Subscriptions} from "./models/subscription-payload";
import {DoaToaHttpService} from "./doa-toa-http.service";
import {environment} from "../../environments/environment";


@Injectable({
  providedIn: 'root'
})
export class SubscriptionControllerService {
  
  constructor(private client: DoaToaHttpService) {
  }
  
  public requestSubscription(subscriptionPayload?: Subscriptions.SubscriptionPayload): Observable<SubscriptionView> {
    
    let headers: Map<string, string> = new Map<string, string>();
    headers.set('Accept', 'application/json')
    headers.set('Content-Type', 'application/json')
    
    let localVarPath = `/identity/subscriptions/`;
    return this.client.post(`${environment.idpApiBaseUrl}${localVarPath}`,
      subscriptionPayload,
      headers
    );
  }
  
  
  public processSubscriptionRequest(decisionPayload: DecisionPayload): Observable<SubscriptionView> {
    
    let headers: Map<string, string> = new Map<string, string>();
    headers.set('Accept', 'application/json')
    headers.set('Content-Type', 'application/vnd.doatoa.decision+json')
    let localVarPath = `/identity/subscriptions/`;
    return this.client.patch<SubscriptionView>(`${environment.idpApiBaseUrl}${localVarPath}`, decisionPayload, headers)
  }
  
  
  public deleteSubscription(subscriptionId: string): Observable<any> {
    let headers: Map<string, string> = new Map<string, string>();
    headers.set('Accept', 'application/json')
    headers.set('Content-Type', 'application/json')
    
    let localVarPath = `/identity/subscriptions/`;
    return this.client.delete<any>(`${environment.idpApiBaseUrl}${localVarPath}`, {subscriptionId: subscriptionId}, headers)
  }
  
  
}
