import {NgModule} from '@angular/core';
import {AvatarComponent} from "./avatar/avatar.component";
import {MyClaimsComponent} from "./my-claims/my-claims.component";
import {ProfileIntroductionComponent} from "./identity-summary/profile-introduction.component";
import {MyIdentityComponent} from "./my-identity.component";
import {SubscriptionsComponent} from "./subscriptions/subscriptions.component";
import {PasswordStrengthMeterComponent} from "angular-password-strength-meter";
import {LoadingPlaceholderComponent} from "../common/loading-placeholder/loading-placeholder.component";
import {NgClass, NgForOf, NgIf, NgStyle} from "@angular/common";
import {ToStatusClassPipe} from "../common/pipes/ToStatusClassPipe";
import {ActionButtonComponent} from "../common/action-button/action-button.component";
import {SubscriptionComponent} from "../common/subscription/subscription.component";
import {SpinnerComponent} from "../common/spinner/spinner.component";
import {ExportModalComponent} from "../common/export-modal/export-modal.component";
import {NewSubscriptionModalComponent} from "../common/new-request-modal/new-subscription-modal.component";
import {NewClaimModalComponent} from "../common/new-claim-modal/new-claim-modal.component";
import {DeleteClaimModalComponent} from "../common/delete-claim-modal/delete-claim-modal.component";
import {ErrorModalComponent} from "../common/error-modal/error-modal.component";
import {GenericMessageComponent} from "../common/generic-message/generic-message.component";
import {ToReadableStringPipe} from "../common/pipes/ToReadableStringPipe";
import {ToVisibilityScopeIconPipe} from "../common/pipes/ToVisibilityScopeIconPipe";
import {ClaimComponent} from "../common/claim/claim.component";
import {ToOppositeVisibilityScopeIconPipe} from "../common/pipes/ToOppositeClaimAspectIconPipe";
import {FormsModule} from "@angular/forms";


@NgModule({
    imports: [
        PasswordStrengthMeterComponent,
        LoadingPlaceholderComponent,
        NgIf,
        ToStatusClassPipe,
        ActionButtonComponent,
        SubscriptionComponent,
        SpinnerComponent,
        NgForOf,
        ExportModalComponent,
        NewSubscriptionModalComponent,
        NewClaimModalComponent,
        DeleteClaimModalComponent,
        ErrorModalComponent,
        GenericMessageComponent,
        NgClass,
        ToReadableStringPipe,
        ToVisibilityScopeIconPipe,
        ClaimComponent,
        NgStyle,
        ToOppositeVisibilityScopeIconPipe,
        FormsModule
    ],
    exports: [
    ],
    declarations: [
        AvatarComponent,
        MyClaimsComponent,
        ProfileIntroductionComponent,
        MyIdentityComponent,
        SubscriptionsComponent
    ]
})
export class MyIdentityModule {
}