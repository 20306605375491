import {Component, OnInit} from '@angular/core';

import {HttpErrorResponse} from "@angular/common/http";
import {CacheService} from "../../services/cache-service";
import {IdentityView} from "../../services/api/models/identity-view";
import {IdentityControllerService} from "../../services/api/identity-controller.service";
import {IdentityComponent} from "./identity/identity.component";
import {NgClass, NgIf} from "@angular/common";
import {FormsModule} from "@angular/forms";
import {CryptoWalletService} from "../../services/crypto-wallet.service";
import {
  CapacitorBarcodeScanner,
  CapacitorBarcodeScannerOptions,
  CapacitorBarcodeScannerScanOrientation,
  CapacitorBarcodeScannerTypeHintALLOption
} from "@capacitor/barcode-scanner";
import {ActionButtonComponent} from "../common/action-button/action-button.component";
import {AuthorisedComponent} from "../common/authorised-component";
import {DataSubscriptionService} from "../../services/data-subscription.service";
import {SignOutService} from "../../services/signout-service";
import {SettingsComponent} from "../common/settings/settings.component";
import {ToVisibilityScopeIconPipe} from "../common/pipes/ToVisibilityScopeIconPipe";
import {VisibilityScopeEnum} from "../../services/api/models/visibility-scope";
import {SubscriptionsComponent} from "./subscriptions/subscriptions.component";
import {SubscribersComponent} from "./subscribers/subscribers.component";
import {NavigatorService} from "../common/navigator.service";
import {DataStorageService} from "../../services/data-storage.service";

@Component({
  selector: 'app-my-subscriptions',
  templateUrl: './my-subscriptions.component.html',
  standalone: true,
  imports: [
    FormsModule,
    NgIf,
    ActionButtonComponent,
    SettingsComponent,
    SubscriptionsComponent,
    SubscribersComponent,
    NgClass,
    IdentityComponent,
    ToVisibilityScopeIconPipe
  ],
  styleUrls: ['./my-subscriptions.component.scss']
})
export class MySubscriptionsComponent extends AuthorisedComponent implements OnInit {
  
  protected loading: boolean = false
  protected subscriptionsActivated: boolean = true
  protected defaultSelected: boolean = false
  protected claimTypes: Array<string> = []
  protected identity?: IdentityView
  protected searchedIdentity?: IdentityView
  // protected subscribedDIDs: Array<string> = []
  protected subscribedIdentities: Array<IdentityView> = []
  protected open: Map<string, boolean> = new Map<string, boolean>()
  protected didHashAlias?: string = undefined
  protected errorOccurred: boolean = false
  protected cameraAccessGranted: boolean = false
  protected errorMessage: string = ''
  protected warningMessage?: string = ''
  protected readonly VisibilityScopeEnum = VisibilityScopeEnum;
  
  constructor(private cacheService: CacheService,
              private identityControllerService: IdentityControllerService,
              dataSubscriptionService: DataSubscriptionService,
              signOutService: SignOutService,
              cryptoWalletService: CryptoWalletService,
              navigatorService: NavigatorService,
              dataStorageService: DataStorageService) {
    super(dataSubscriptionService, signOutService, cryptoWalletService, navigatorService, dataStorageService)
  }
  
  ngOnInit(): void {
    this.shouldAuthenticate = true
    this.dataSubscriptionService.onNext<IdentityView>('identity').subscribe(identity => {
      this.identity = identity
    })
  }
  
  reset() {
    this.defaultSelected = true
    this.searchedIdentity = undefined
    this.didHashAlias = undefined
  }
  
  findIdentities() {
    this.loading = true
    this.searchedIdentity = undefined
    this.cacheService.bypassCache = true
    
    this.identityControllerService.findIdentity(this.didHashAlias)
      .subscribe({
        next: async (identity) => {
          this.searchedIdentity = identity
          this.loading = false
        },
        error: (error) => {
          this.loading = false
          if (error instanceof HttpErrorResponse) {
            this.errorOccurred = true
          }
        }
      });
  }
  
  protected openCloseIdentity() {
    if (this.searchedIdentity) {
      this.open.set(this.searchedIdentity.did!, !this.open.get(this.searchedIdentity.did!) || false)
    }
  }
  
  protected getIdentityIdentifier(identityView: IdentityView | undefined) {
    return identityView?.alias || identityView?.hash
  }
  
  private options: CapacitorBarcodeScannerOptions = {
    scanButton: false,
    hint: CapacitorBarcodeScannerTypeHintALLOption.ALL,
    scanOrientation: CapacitorBarcodeScannerScanOrientation.PORTRAIT,
  };
  
  public async scanBarcode(): Promise<void> {
    this.checkCameraPermission().catch(e => console.log(e));
    const barcodeResult = (await CapacitorBarcodeScanner.scanBarcode(this.options)).ScanResult;
    console.log(barcodeResult);
    this.processData(barcodeResult)
  }
  
  async checkCameraPermission() {
    const permissionStatus = await navigator.permissions.query({name: 'camera' as PermissionName});
    if (permissionStatus.state === 'granted') {
      this.cameraAccessGranted = true;
      console.log('Camera access approved:');
    } else if (permissionStatus.state === 'prompt') {
      try {
        const stream = await navigator.mediaDevices.getUserMedia({video: true});
        stream.getTracks().forEach((track) => track.stop());
        this.cameraAccessGranted = true;
      } catch (error) {
        console.log('Camera access denied:', error);
        this.cameraAccessGranted = false;
      }
    } else {
      console.log('Camera permission denied.');
      this.cameraAccessGranted = false;
      this.warningMessage = 'This functionality requires camera access.'
      setTimeout(() => {
        this.warningMessage = undefined
      }, 5000)
    }
  }
  
  processData(data: any) {
    this.identityControllerService.findIdentity(data).subscribe({
      next: (identity) => {
        this.searchedIdentity = identity
      }
    })
  }
  
  toggleSubscribersSubscriptions() {
    this.subscriptionsActivated = !this.subscriptionsActivated;
  }
}
