import {Pipe, PipeTransform} from '@angular/core';
import {SubscriptionView} from "../services";

@Pipe({
    standalone: true,
    name: 'toStatusClass'
})
export class ToStatusClassPipe implements PipeTransform {
    constructor() {
    }

    public transform(value?: SubscriptionView): any {
        switch (value?.status) {
            case "pending":
                return 'fa-solid fa-spinner'
            case "approved":
                return 'fa-solid fa-circle-check secondary-blue'
            case "denied":
                return 'fa-solid fa-circle-xmark primary-danger'
        }
    }
}