import {Component, ElementRef, EventEmitter, Input, OnInit, Output, Renderer2} from '@angular/core';
import {ClaimControllerService, ClaimReferenceView, ClaimView, SubscriptionView,} from "../../common/services";
import * as uuid from "uuid";
import {DataService} from "../../common/services/data-storage.service";

@Component({
    selector: 'app-my-claims',
    templateUrl: './my-claims.component.html',
    styleUrls: ['./my-claims.component.scss']
})
export class MyClaimsComponent implements OnInit {

    @Output() claimIdToFetchEmitter = new EventEmitter<string>()
    @Output() reloadProfileEmitter = new EventEmitter<Array<ClaimReferenceView>>()
    @Input() scopes: Array<string> = [];
    @Input() loadingInitialClaims: boolean = false;
    protected newRequestId?: string = 'new-subscription-' + uuid.v4()
    protected exportId?: string = 'export-' + uuid.v4()
    protected newClaimId?: string = 'new-claim-' + uuid.v4()
    protected deleteClaimClicked: boolean = false
    protected loading: Map<string, boolean> = new Map<string, boolean>()
    protected open: Map<string, boolean> = new Map<string, boolean>()
    protected errorOccurred: boolean = false
    protected claimType: string = ''
    protected claimToDelete: ClaimReferenceView = {aspects: []}
    protected requestedClaims: Map<string, ClaimView> = new Map<string, ClaimView>()
    protected errorMessage: string = ''
    protected scope?: string = undefined
    protected showMessage: boolean = false;
    protected message: string = '';
    protected readonly sessionStorage = sessionStorage;

    constructor(private renderer: Renderer2,
                private elementRef: ElementRef,
                protected dataService: DataService,
                private claimControllerService: ClaimControllerService) {
    }

    ngOnInit(): void {
    }

    protected tryDeleteClaim(claim: ClaimReferenceView) {
        this.deleteClaimClicked = true
        this.claimToDelete = claim
    }

    protected claimIsMandatory(claim: ClaimReferenceView) {
        let isMandatory = false;
        for (const aspect of claim.aspects!) {
            if (aspect == "mandatory") {
                isMandatory = true
            }
        }
        return isMandatory;
    }

    protected fetchClaim(claimReference: ClaimReferenceView) {
        this.open.set(claimReference.id!, !this.open.get(claimReference.id!) || false)
        if (!this.requestedClaims.has(claimReference.id!)) {
            this.loading.set(claimReference.id!, true)
            this.dataService.last<string>('did').subscribe({
                next: did => {
                    this.claimControllerService.viewClaims<Array<ClaimView>>(did, claimReference.id!, undefined, undefined, undefined, undefined, 'application/vnd.doatoa.claims+json')
                        .subscribe({
                            next: (response) => {
                                const claim = response[0]
                                this.loading.set(claimReference.id!, false)
                                this.requestedClaims = this.requestedClaims.set(claim.id!, claim)
                            },
                            error: (error) => {
                                this.loading.set(claimReference.id!, false)
                                this.errorOccurred = true
                            }
                        });
                }
            })

        }
    }

    protected isLoading(claimId: string): boolean {
        return this.loading.has(claimId) && this.loading.get(claimId)!
    }

    protected removeClaimById(deletedClaimId: any) {
        this.dataService.getIdentity()?.claims?.forEach((element, index) => {
            if (this.dataService.getIdentity()?.claims![index].id == deletedClaimId) this.dataService.getIdentity()?.claims?.splice(index, 1);
            this.message = 'Claim deleted!'
            this.showMessage = true
        });
    }

    protected resolveClass(index: number, claim: ClaimReferenceView): string {
        if (claim.events?.some((x, y) => x.type == 'pending')) {
            return 'btn-basic-notify'
        }
        return index % 2 == 0 ? 'btn-basic-secondary-dark' : 'btn-basic-secondary';
    }

    protected updateClaimClass($event: string) {
        document.getElementById('claim-row-' + $event)
        const element = this.elementRef.nativeElement.querySelector('#claim-row-' + $event);
        const claimView = this.dataService.getIdentity()?.claims?.find((c, i) => c.id == $event)
        const index = this.dataService.getIdentity()?.claims?.indexOf(claimView!)
        if (index == undefined)
            return
        this.renderer.addClass(element, index % 2 == 0 ? 'btn-basic-secondary-dark' : 'btn-basic-secondary');
    }

    protected addSubscription($event: SubscriptionView) {
        this.dataService.getIdentity()?.subscriptions!.push($event)
        //todo: find out if this is enough
    }


}
