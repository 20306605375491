import {Component, OnInit} from '@angular/core';
import {NgForOf, NgIf} from "@angular/common";
import {SubscriptionComponent} from "../../common/subscription/subscription.component";
import {IdentityView} from "../../../services/api/models/identity-view";
import {SubscriptionView} from "../../../services/api/models/subscription-view";
import {DataSubscriptionService} from "../../../services/data-subscription.service";
import {ClaimView} from "../../../services/api/models/claim-reference-view";
import {ToStatusClassPipe} from "../../common/pipes/ToStatusClassPipe";

@Component({
  selector: 'app-subscriptions',
  templateUrl: './subscriptions.component.html',
  standalone: true,
  imports: [
    SubscriptionComponent,
    NgForOf,
    SubscriptionComponent,
    NgIf,
    ToStatusClassPipe
  ],
  styleUrl: './subscriptions.component.scss'
})
export class SubscriptionsComponent implements OnInit {
  protected identity?: IdentityView;
  protected loading: Map<string, boolean> = new Map<string, boolean>()
  protected errorOccurred: boolean = false
  protected requestToEdit: SubscriptionView = {}
  protected open: Map<string, boolean> = new Map<string, boolean>()
  protected showMessage: boolean = false;
  protected message: string = '';
  
  constructor(protected dataSubscriptionService: DataSubscriptionService) {
  }
  
  ngOnInit(): void {
    this.dataSubscriptionService.onNext<IdentityView>('identity').subscribe(identity => {
      this.identity = identity
    })
  }
  
  protected resolveClass(index: number, claim: ClaimView): string {
    if (this.identity?.subscriptions?.some((x, y) => x.status == 'approved' || x.status == 'denied')) {
      return 'btn-basic-notify'
    }
    return index % 2 == 0 ? 'btn-basic-secondary-dark' : 'btn-basic-secondary';
  }
  
  protected readonly sessionStorage = sessionStorage;
  
  protected openOrClose(id: string) {
    this.open.set(id, !this.open.get(id) || false)
  }
  
  protected removeSubscription($event: SubscriptionView) {
    this.dataSubscriptionService.onFirst<IdentityView>('identity').subscribe({
      next: identity => {
        identity!.subscriptions!.forEach((subscription, index) => {
          if (subscription.id == $event.id) {
            identity!.subscriptions!.splice(index, 1);
          }
          this.message = 'Request deleted!'
          this.showMessage = true
          this.identity = identity;
          this.dataSubscriptionService.publish('identity', identity)
        });
      }
    })
  }
}
