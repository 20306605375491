import {Component} from '@angular/core';
import {RouterLink} from "@angular/router";
import {GenericButtonComponent} from "../../generic-button/generic-button.component";

@Component({
  selector: 'app-developers',
  standalone: true,
  templateUrl: './developers.component.html',
  imports: [
    GenericButtonComponent,
    RouterLink
  ],
  styleUrl: './developers.component.scss'
})
export class DevelopersComponent {
  
  loading: boolean = false
  errorOccurred: boolean = false
  errorMessage: string = ''
  protected readonly window = window;
}