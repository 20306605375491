import {Component, OnInit, ViewEncapsulation} from '@angular/core';
import {TokenRefreshService} from "./components/common/services/token-refresh.service";
import {MenuComponent} from "./components/common/menu/menu.component";
import {RouterOutlet} from "@angular/router";
import {SignUpComponent} from "./components/sign-up/sign-up.component";
import {SignInComponent} from "./components/sign-in/sign-in.component";
import {RestoreKeysComponent} from "./components/restore-keys/restore-keys.component";
import {IdentitiesModule} from "./components/identities/identities.module";
import {LandingModule} from "./components/landing/landing.module";
import {MyIdentityModule} from "./components/my-identity/my-identity.module";
import {ErrorComponent} from "./components/error/error.component";
import {DevelopersModule} from "./components/developers/developers.module";
import {ConsentPage} from "./components/consent-page/consent-page";
import {CloseAccountComponent} from "./components/close-account/close-account.component";
import {SettingsModule} from "./components/settings/settings.module";
import {TextFieldModule} from "@angular/cdk/text-field";
import {InfiniteScrollDirective} from "ngx-infinite-scroll";
import {SignOutComponent} from "./components/sign-out/sign-out.component";
import {DataService} from "./components/common/services/data-storage.service";
import {RedirectService} from "./components/common/services/redirect-service";
import {AuthorisedComponent} from "./components/common/authorised-component";

@Component({
    selector: 'app-root',
    templateUrl: './app.component.html',
    styleUrls: ['./app.component.scss'],
    standalone: true,
    imports: [
        MenuComponent,
        SignUpComponent,
        SignInComponent,
        SignOutComponent,
        CloseAccountComponent,
        RestoreKeysComponent,
        IdentitiesModule,
        MyIdentityModule,
        ErrorComponent,
        ConsentPage,
        SettingsModule,
        DevelopersModule,
        TextFieldModule,
        InfiniteScrollDirective,
        RouterOutlet
    ],
    encapsulation: ViewEncapsulation.None
})
export class AppComponent extends AuthorisedComponent implements OnInit {
    title = 'doatoa-resource-webapp';
    protected showMenu: boolean = false;
    protected publicFactsLoading: boolean = false;
    protected myAccountLoading: boolean = false;
    protected myProfileLoading: boolean = false;
    protected publicProfilesLoading: boolean = false;

    constructor(private tokenRefreshService: TokenRefreshService, dataService: DataService, protected redirectService: RedirectService) {
        super(dataService, redirectService)
    }

    ngOnInit(): void {
        this.tokenRefreshService.refreshTokens(4)
        this.dataService.continuous<boolean>('showMenu').subscribe(showMenu => {
            console.log(showMenu)
            this.showMenu = showMenu;
        })
    }

    onActivate($event: Event) {
        if ('publicFactsLoading' in $event) {
            ($event as any).publicFactsLoading.subscribe((loading: boolean) => {
                this.publicFactsLoading = loading;
            });
        }
        if ('myAccountLoading' in $event) {
            ($event as any).myAccountLoading.subscribe((loading: boolean) => {
                this.myAccountLoading = loading;
            });
        }
        if ('myProfileLoading' in $event) {
            ($event as any).myProfileLoading.subscribe((loading: boolean) => {
                this.myProfileLoading = loading;
            });
        }
        if ('publicProfilesLoading' in $event) {
            ($event as any).publicProfilesLoading.subscribe((loading: boolean) => {
                this.publicProfilesLoading = loading;
            });
        }
    }

}
