
import {Injectable} from "@angular/core";

import {DataService} from "./data-storage.service";
import {randomString} from "./random-strings";
import {PKCEService} from "./pkce.service";
import {environment} from "../../../environments/environment";
import {Router} from "@angular/router";


@Injectable({
    providedIn: 'root'
})
export class RedirectService {

    constructor(private pkceService: PKCEService, private dataService: DataService, private router: Router) {
    }

    async generateQueryParamsForAuthClient(redirectUri?: string): Promise<URLSearchParams> {
        let codeChallenge = await this.pkceService.createCodeChallenge();
        this.dataService.publish('code_verifier', codeChallenge.code_verifier, true)
        if (redirectUri == undefined) {
            redirectUri = encodeURIComponent(environment.webappBaseUrl + '/#/oauth-landing')
        }
        return new URLSearchParams({
            state: randomString(25),
            grant_type: 'authorization_code',
            client_id: environment.webappClientId,
            redirect_uri: redirectUri,
            nonce: randomString(25),
            scope: "api,openid",
            response_type: "code, id_token",
            response_mode: 'query',
            code_challenge: codeChallenge.code_challenge,
            code_challenge_method: 'S265'
        });
    }

    async generateRedirectUri(queryParams: URLSearchParams): Promise<string> {
        return environment.webappBaseUrl + `/#/sign-in/?${queryParams.toString()}`
    }

    async redirectTo(redirectUri: string) {
        let queryParams = await this.generateQueryParamsForAuthClient(encodeURIComponent(redirectUri));
        this.generateRedirectUri(queryParams).then(redirectUri => window.location.href = redirectUri)
    }

    redirectToSignInPage() {
        this.generateQueryParamsForAuthClient()
            .then(queryParams =>  this.generateRedirectUri(queryParams).then(redirectUri => window.location.href = redirectUri))
    }

    redirectToAuthWebappExtension(params: any) {
        this.generateRedirectUri(params).then(redirectUri => window.location.href = redirectUri);
    }

    redirectToGateway() {
        window.location.href = "https://doatoa.com"
    }

    redirectToChangePasswordPage(accessToken: string) {
        window.location.href = environment.webappBaseUrl + `/#/change-password?token=${accessToken}`
    }

    redirectToMyIdentity() {
        window.location.href = environment.webappBaseUrl + `/#/my-identity`
    }
}
