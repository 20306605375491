import {Injectable} from '@angular/core';
import {RedirectService} from "./redirect-service";
import {DataService} from "./data-storage.service";
import {OauthControllerService} from "./api/oauth-controller.service";

@Injectable({
    providedIn: 'root'
})
export class SignOutService {

    constructor(private oauthControllerService: OauthControllerService, private redirectService: RedirectService, private sharedService: DataService) {
    }

    signOut(idToken: string): any {
        this.oauthControllerService.signOut(idToken!)
            .subscribe({
                next: (response) => {
                    this.sharedService.remove('did')
                    this.sharedService.remove('accessToken')
                    this.sharedService.remove('refreshToken')
                    this.sharedService.remove('idToken')
                    this.sharedService.remove('idTokenHint')
                    const redirectUri = response.redirectUri
                    if (!!redirectUri) {
                        window.location.href = decodeURIComponent(redirectUri)
                    } else {
                        this.redirectService.redirectToGateway()
                    }
                },
                error: (error) => {
                    this.sharedService.remove('did')
                    this.sharedService.remove('accessToken')
                    this.sharedService.remove('refreshToken')
                    this.sharedService.remove('idToken')
                    this.sharedService.remove('idTokenHint')
                    this.redirectService.redirectToGateway()
                }
            });
    }

}
