"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.BufferBuilder = void 0;
const typebox_1 = require("@sinclair/typebox");
typebox_1.TypeRegistry.Set('Buffer', (_, value) => value instanceof Buffer);
class BufferBuilder extends typebox_1.JavaScriptTypeBuilder {
    Buffer(options) {
        return this.Create(Object.assign(Object.assign({}, options), { [typebox_1.Kind]: 'Buffer', type: 'Buffer' }));
    }
}
exports.BufferBuilder = BufferBuilder;
