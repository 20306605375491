import {Component, Input, OnInit} from '@angular/core';
import {DataService} from "../services/data-storage.service";
import {Router, RouterLink, RouterLinkActive} from "@angular/router";
import {LogoComponent} from "../logo/logo.component";
import {SpinnerComponent} from "../spinner/spinner.component";
import {NgIf} from "@angular/common";
import {ActionButtonComponent} from "../action-button/action-button.component";

@Component({
    selector: 'app-menu',
    standalone: true,
    templateUrl: './menu.component.html',
    imports: [
        LogoComponent,
        SpinnerComponent,
        RouterLinkActive,
        RouterLink,
        NgIf,
        ActionButtonComponent
    ],
    styleUrls: ['./menu.component.scss']
})
export class MenuComponent implements OnInit {

    @Input() showMenu: boolean = false
    protected logoutIsLoading: boolean = false
    protected open: boolean = false
    constructor(private router: Router, protected dataService: DataService) {
    }

    ngOnInit(): void {
    }

    signOut() {
        this.logoutIsLoading = true
        this.router.navigate(['/sign-out'])
    }
}
