"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.createBlockchainMessage = exports.BLOCKCHAIN = exports.BLOCKCHAIN_EVENT = void 0;
exports.BLOCKCHAIN_EVENT = 'BLOCKCHAIN_EVENT';
exports.BLOCKCHAIN = {
    CONNECT: 'blockchain-connect',
    RECONNECTING: 'blockchain-reconnecting',
    ERROR: 'blockchain-error',
    BLOCK: 'blockchain-block',
    NOTIFICATION: 'blockchain-notification',
    FIAT_RATES_UPDATE: 'fiat-rates-update',
};
const createBlockchainMessage = (type, payload) => ({
    event: exports.BLOCKCHAIN_EVENT,
    type,
    payload,
});
exports.createBlockchainMessage = createBlockchainMessage;
