"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.CoinInfo = exports.MiscNetworkInfo = exports.EthereumNetworkInfo = exports.BitcoinNetworkInfo = exports.BlockchainLink = exports.CoinSupport = exports.CoinObj = exports.Network = exports.Bip32 = void 0;
const schema_utils_1 = require("@trezor/schema-utils");
const fees_1 = require("./fees");
const device_1 = require("./device");
exports.Bip32 = schema_utils_1.Type.Object({
    public: schema_utils_1.Type.Number(),
    private: schema_utils_1.Type.Number(),
});
exports.Network = schema_utils_1.Type.Object({
    messagePrefix: schema_utils_1.Type.String(),
    bech32: schema_utils_1.Type.String(),
    bip32: exports.Bip32,
    pubKeyHash: schema_utils_1.Type.Number(),
    scriptHash: schema_utils_1.Type.Number(),
    wif: schema_utils_1.Type.Number(),
    forkId: schema_utils_1.Type.Optional(schema_utils_1.Type.Number()),
});
exports.CoinObj = schema_utils_1.Type.Object({
    coin: schema_utils_1.Type.String(),
    identity: schema_utils_1.Type.Optional(schema_utils_1.Type.String()),
});
exports.CoinSupport = schema_utils_1.Type.Composite([
    schema_utils_1.Type.Object({
        connect: schema_utils_1.Type.Boolean(),
    }),
    schema_utils_1.Type.Record(schema_utils_1.Type.KeyOfEnum(device_1.DeviceModelInternal), schema_utils_1.Type.Union([schema_utils_1.Type.String(), schema_utils_1.Type.Literal(false)])),
]);
exports.BlockchainLink = schema_utils_1.Type.Object({
    type: schema_utils_1.Type.String(),
    url: schema_utils_1.Type.Array(schema_utils_1.Type.String()),
});
const Common = schema_utils_1.Type.Object({
    label: schema_utils_1.Type.String(),
    name: schema_utils_1.Type.String(),
    shortcut: schema_utils_1.Type.String(),
    slip44: schema_utils_1.Type.Number(),
    support: exports.CoinSupport,
    decimals: schema_utils_1.Type.Number(),
    blockchainLink: schema_utils_1.Type.Optional(exports.BlockchainLink),
    blockTime: schema_utils_1.Type.Number(),
    minFee: schema_utils_1.Type.Number(),
    maxFee: schema_utils_1.Type.Number(),
    defaultFees: schema_utils_1.Type.Array(fees_1.FeeLevel),
});
exports.BitcoinNetworkInfo = schema_utils_1.Type.Intersect([
    Common,
    schema_utils_1.Type.Object({
        type: schema_utils_1.Type.Literal('bitcoin'),
        cashAddrPrefix: schema_utils_1.Type.Optional(schema_utils_1.Type.String()),
        curveName: schema_utils_1.Type.String(),
        dustLimit: schema_utils_1.Type.Number(),
        forceBip143: schema_utils_1.Type.Boolean(),
        hashGenesisBlock: schema_utils_1.Type.String(),
        maxAddressLength: schema_utils_1.Type.Number(),
        maxFeeSatoshiKb: schema_utils_1.Type.Number(),
        minAddressLength: schema_utils_1.Type.Number(),
        minFeeSatoshiKb: schema_utils_1.Type.Number(),
        segwit: schema_utils_1.Type.Boolean(),
        xPubMagic: schema_utils_1.Type.Number(),
        xPubMagicSegwitNative: schema_utils_1.Type.Optional(schema_utils_1.Type.Number()),
        xPubMagicSegwit: schema_utils_1.Type.Optional(schema_utils_1.Type.Number()),
        taproot: schema_utils_1.Type.Optional(schema_utils_1.Type.Boolean()),
        network: exports.Network,
        isBitcoin: schema_utils_1.Type.Boolean(),
    }),
]);
exports.EthereumNetworkInfo = schema_utils_1.Type.Intersect([
    Common,
    schema_utils_1.Type.Object({
        type: schema_utils_1.Type.Literal('ethereum'),
        chainId: schema_utils_1.Type.Number(),
        network: schema_utils_1.Type.Optional(schema_utils_1.Type.Undefined()),
    }),
]);
exports.MiscNetworkInfo = schema_utils_1.Type.Intersect([
    Common,
    schema_utils_1.Type.Object({
        type: schema_utils_1.Type.Union([schema_utils_1.Type.Literal('misc'), schema_utils_1.Type.Literal('nem')]),
        curve: schema_utils_1.Type.String(),
        network: schema_utils_1.Type.Optional(schema_utils_1.Type.Undefined()),
    }),
]);
exports.CoinInfo = schema_utils_1.Type.Union([exports.BitcoinNetworkInfo, exports.EthereumNetworkInfo, exports.MiscNetworkInfo]);
