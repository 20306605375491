<div class="card p-3 mb-3">
    <h3 (click)="dangerZone()">Account</h3>
    <div class="row justify-content-center mb-3">
        <app-generic-button
                id="my-account-sign-out-button"
                (click)="signOut()"
                [buttonText]="'Sign out'"
                [primaryIcon]="'fa-solid fa-right-from-bracket'">
        </app-generic-button>
    </div>
</div>
