import {Component} from '@angular/core';
import {LogoComponent} from "../common/logo/logo.component";
import {ErrorModalComponent} from "../common/error-modal/error-modal.component";

@Component({
  selector: 'app-error',
  templateUrl: './error.component.html',
  standalone: true,
  imports: [
    LogoComponent,
    ErrorModalComponent
  ],
  styleUrl: './error.component.scss'
})
export class ErrorComponent {

}
