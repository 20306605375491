import {Component, Input, OnInit} from '@angular/core';
import {IdentityView, SubscriptionView} from "../../../services/";
import {DataService} from "../../../services/data.service";


@Component({
  selector: 'app-subscriptions',
  templateUrl: './subscriptions.component.html',
  styleUrl: './subscriptions.component.scss'
})
export class SubscriptionsComponent implements OnInit {
  @Input() loadingSubscriptions: boolean = false;
  protected loading: Map<string, boolean> = new Map<string, boolean>()
  protected errorOccurred: boolean = false
  protected requestToEdit: SubscriptionView = {}
  protected open: Map<string, boolean> = new Map<string, boolean>()
  protected showMessage: boolean = false;
  protected message: string = '';
  protected identity?: IdentityView
  
  constructor(protected dataService: DataService) {
  }
  
  ngOnInit(): void {
    this.dataService.last<IdentityView>('identity').subscribe({
      next: identity => {
        this.identity = identity;
      }
    })
  }
  
  protected resolveClass(index: number, request: SubscriptionView): string {
    if (request.status == 'pending') {
      return 'btn-basic-notify'
    }
    return index % 2 == 0 ? 'btn-basic-secondary-dark' : 'btn-basic-secondary';
  }
  
  protected openOrClose(id: string) {
    this.open.set(id, !this.open.get(id) || false)
  }
  
  protected remove($event: SubscriptionView) {
    this.dataService.last<IdentityView>('identity').subscribe({
      next: identity => {
        identity!.subscriptions!.forEach((element, index) => {
          if (identity!.subscriptions![index].id == $event.id) identity!.subscriptions!.splice(index, 1);
          this.message = 'Request deleted'
          this.showMessage = true
        });
        this.identity = identity;
        this.dataService.publish('identity', identity)
      }
    })
  }
  
  protected update($event: SubscriptionView) {
    this.dataService.last<IdentityView>('identity').subscribe({
      next: identity => {
        identity!.subscriptions!.forEach((element, index) => {
          if (identity!.subscriptions![index].id == $event.id) {
            identity!.subscriptions![index] = $event
          }
          this.message = 'Subscription updated'
          this.showMessage = true
        });
        this.identity = identity;
        this.dataService.publish('identity', identity)
      }
    })
  }
}
