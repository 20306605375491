"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.MODULES = exports.TYPES = void 0;
exports.TYPES = {
    bitcoin: 'Bitcoin',
    ethereum: 'Ethereum',
    eos: 'Eos',
    nem: 'NEM',
    stellar: 'Stellar',
    cardano: 'Cardano',
    ripple: 'Ripple',
    tezos: 'Tezos',
    binance: 'Binance',
    solana: 'Solana',
};
exports.MODULES = [
    'binance',
    'cardano',
    'eos',
    'ethereum',
    'nem',
    'ripple',
    'solana',
    'stellar',
    'tezos',
];
