"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.TezosBallotType = exports.TezosContractType = exports.StellarSignerType = exports.StellarMemoType = exports.StellarAssetType = exports.NEMImportanceTransferMode = exports.NEMModificationType = exports.NEMSupplyChangeType = exports.NEMMosaicLevy = exports.MoneroNetworkType = exports.BootCommand = exports.Enum_WordRequestType = exports.SdProtectOperationType = exports.Enum_RecoveryType = exports.RecoveryDeviceInputMethod = exports.Enum_Capability = exports.Enum_RecoveryStatus = exports.Enum_BackupAvailability = exports.Enum_HomescreenFormat = exports.Enum_SafetyCheckLevel = exports.Enum_BackupType = exports.EthereumDataType = exports.EthereumDefinitionType = exports.DebugPhysicalButton = exports.DebugButton = exports.Enum_PinMatrixRequestType = exports.Enum_ButtonRequestType = exports.FailureType = exports.CardanoTxWitnessType = exports.CardanoTxSigningMode = exports.CardanoCVoteRegistrationFormat = exports.CardanoTxAuxiliaryDataSupplementType = exports.CardanoPoolRelayType = exports.CardanoDRepType = exports.CardanoCertificateType = exports.CardanoTxOutputSerializationFormat = exports.CardanoNativeScriptHashDisplayFormat = exports.CardanoNativeScriptType = exports.CardanoAddressType = exports.CardanoDerivationType = exports.Enum_RequestType = exports.AmountUnit = exports.DecredStakingSpendType = exports.Enum_OutputScriptType = exports.Enum_InputScriptType = exports.BinanceTimeInForce = exports.BinanceOrderSide = exports.BinanceOrderType = exports.DeviceModelInternal = void 0;
var DeviceModelInternal;
(function (DeviceModelInternal) {
    DeviceModelInternal["T1B1"] = "T1B1";
    DeviceModelInternal["T2T1"] = "T2T1";
    DeviceModelInternal["T2B1"] = "T2B1";
    DeviceModelInternal["T3T1"] = "T3T1";
})(DeviceModelInternal || (exports.DeviceModelInternal = DeviceModelInternal = {}));
var BinanceOrderType;
(function (BinanceOrderType) {
    BinanceOrderType[BinanceOrderType["OT_UNKNOWN"] = 0] = "OT_UNKNOWN";
    BinanceOrderType[BinanceOrderType["MARKET"] = 1] = "MARKET";
    BinanceOrderType[BinanceOrderType["LIMIT"] = 2] = "LIMIT";
    BinanceOrderType[BinanceOrderType["OT_RESERVED"] = 3] = "OT_RESERVED";
})(BinanceOrderType || (exports.BinanceOrderType = BinanceOrderType = {}));
var BinanceOrderSide;
(function (BinanceOrderSide) {
    BinanceOrderSide[BinanceOrderSide["SIDE_UNKNOWN"] = 0] = "SIDE_UNKNOWN";
    BinanceOrderSide[BinanceOrderSide["BUY"] = 1] = "BUY";
    BinanceOrderSide[BinanceOrderSide["SELL"] = 2] = "SELL";
})(BinanceOrderSide || (exports.BinanceOrderSide = BinanceOrderSide = {}));
var BinanceTimeInForce;
(function (BinanceTimeInForce) {
    BinanceTimeInForce[BinanceTimeInForce["TIF_UNKNOWN"] = 0] = "TIF_UNKNOWN";
    BinanceTimeInForce[BinanceTimeInForce["GTE"] = 1] = "GTE";
    BinanceTimeInForce[BinanceTimeInForce["TIF_RESERVED"] = 2] = "TIF_RESERVED";
    BinanceTimeInForce[BinanceTimeInForce["IOC"] = 3] = "IOC";
})(BinanceTimeInForce || (exports.BinanceTimeInForce = BinanceTimeInForce = {}));
var Enum_InputScriptType;
(function (Enum_InputScriptType) {
    Enum_InputScriptType[Enum_InputScriptType["SPENDADDRESS"] = 0] = "SPENDADDRESS";
    Enum_InputScriptType[Enum_InputScriptType["SPENDMULTISIG"] = 1] = "SPENDMULTISIG";
    Enum_InputScriptType[Enum_InputScriptType["EXTERNAL"] = 2] = "EXTERNAL";
    Enum_InputScriptType[Enum_InputScriptType["SPENDWITNESS"] = 3] = "SPENDWITNESS";
    Enum_InputScriptType[Enum_InputScriptType["SPENDP2SHWITNESS"] = 4] = "SPENDP2SHWITNESS";
    Enum_InputScriptType[Enum_InputScriptType["SPENDTAPROOT"] = 5] = "SPENDTAPROOT";
})(Enum_InputScriptType || (exports.Enum_InputScriptType = Enum_InputScriptType = {}));
var Enum_OutputScriptType;
(function (Enum_OutputScriptType) {
    Enum_OutputScriptType[Enum_OutputScriptType["PAYTOADDRESS"] = 0] = "PAYTOADDRESS";
    Enum_OutputScriptType[Enum_OutputScriptType["PAYTOSCRIPTHASH"] = 1] = "PAYTOSCRIPTHASH";
    Enum_OutputScriptType[Enum_OutputScriptType["PAYTOMULTISIG"] = 2] = "PAYTOMULTISIG";
    Enum_OutputScriptType[Enum_OutputScriptType["PAYTOOPRETURN"] = 3] = "PAYTOOPRETURN";
    Enum_OutputScriptType[Enum_OutputScriptType["PAYTOWITNESS"] = 4] = "PAYTOWITNESS";
    Enum_OutputScriptType[Enum_OutputScriptType["PAYTOP2SHWITNESS"] = 5] = "PAYTOP2SHWITNESS";
    Enum_OutputScriptType[Enum_OutputScriptType["PAYTOTAPROOT"] = 6] = "PAYTOTAPROOT";
})(Enum_OutputScriptType || (exports.Enum_OutputScriptType = Enum_OutputScriptType = {}));
var DecredStakingSpendType;
(function (DecredStakingSpendType) {
    DecredStakingSpendType[DecredStakingSpendType["SSGen"] = 0] = "SSGen";
    DecredStakingSpendType[DecredStakingSpendType["SSRTX"] = 1] = "SSRTX";
})(DecredStakingSpendType || (exports.DecredStakingSpendType = DecredStakingSpendType = {}));
var AmountUnit;
(function (AmountUnit) {
    AmountUnit[AmountUnit["BITCOIN"] = 0] = "BITCOIN";
    AmountUnit[AmountUnit["MILLIBITCOIN"] = 1] = "MILLIBITCOIN";
    AmountUnit[AmountUnit["MICROBITCOIN"] = 2] = "MICROBITCOIN";
    AmountUnit[AmountUnit["SATOSHI"] = 3] = "SATOSHI";
})(AmountUnit || (exports.AmountUnit = AmountUnit = {}));
var Enum_RequestType;
(function (Enum_RequestType) {
    Enum_RequestType[Enum_RequestType["TXINPUT"] = 0] = "TXINPUT";
    Enum_RequestType[Enum_RequestType["TXOUTPUT"] = 1] = "TXOUTPUT";
    Enum_RequestType[Enum_RequestType["TXMETA"] = 2] = "TXMETA";
    Enum_RequestType[Enum_RequestType["TXFINISHED"] = 3] = "TXFINISHED";
    Enum_RequestType[Enum_RequestType["TXEXTRADATA"] = 4] = "TXEXTRADATA";
    Enum_RequestType[Enum_RequestType["TXORIGINPUT"] = 5] = "TXORIGINPUT";
    Enum_RequestType[Enum_RequestType["TXORIGOUTPUT"] = 6] = "TXORIGOUTPUT";
    Enum_RequestType[Enum_RequestType["TXPAYMENTREQ"] = 7] = "TXPAYMENTREQ";
})(Enum_RequestType || (exports.Enum_RequestType = Enum_RequestType = {}));
var CardanoDerivationType;
(function (CardanoDerivationType) {
    CardanoDerivationType[CardanoDerivationType["LEDGER"] = 0] = "LEDGER";
    CardanoDerivationType[CardanoDerivationType["ICARUS"] = 1] = "ICARUS";
    CardanoDerivationType[CardanoDerivationType["ICARUS_TREZOR"] = 2] = "ICARUS_TREZOR";
})(CardanoDerivationType || (exports.CardanoDerivationType = CardanoDerivationType = {}));
var CardanoAddressType;
(function (CardanoAddressType) {
    CardanoAddressType[CardanoAddressType["BASE"] = 0] = "BASE";
    CardanoAddressType[CardanoAddressType["BASE_SCRIPT_KEY"] = 1] = "BASE_SCRIPT_KEY";
    CardanoAddressType[CardanoAddressType["BASE_KEY_SCRIPT"] = 2] = "BASE_KEY_SCRIPT";
    CardanoAddressType[CardanoAddressType["BASE_SCRIPT_SCRIPT"] = 3] = "BASE_SCRIPT_SCRIPT";
    CardanoAddressType[CardanoAddressType["POINTER"] = 4] = "POINTER";
    CardanoAddressType[CardanoAddressType["POINTER_SCRIPT"] = 5] = "POINTER_SCRIPT";
    CardanoAddressType[CardanoAddressType["ENTERPRISE"] = 6] = "ENTERPRISE";
    CardanoAddressType[CardanoAddressType["ENTERPRISE_SCRIPT"] = 7] = "ENTERPRISE_SCRIPT";
    CardanoAddressType[CardanoAddressType["BYRON"] = 8] = "BYRON";
    CardanoAddressType[CardanoAddressType["REWARD"] = 14] = "REWARD";
    CardanoAddressType[CardanoAddressType["REWARD_SCRIPT"] = 15] = "REWARD_SCRIPT";
})(CardanoAddressType || (exports.CardanoAddressType = CardanoAddressType = {}));
var CardanoNativeScriptType;
(function (CardanoNativeScriptType) {
    CardanoNativeScriptType[CardanoNativeScriptType["PUB_KEY"] = 0] = "PUB_KEY";
    CardanoNativeScriptType[CardanoNativeScriptType["ALL"] = 1] = "ALL";
    CardanoNativeScriptType[CardanoNativeScriptType["ANY"] = 2] = "ANY";
    CardanoNativeScriptType[CardanoNativeScriptType["N_OF_K"] = 3] = "N_OF_K";
    CardanoNativeScriptType[CardanoNativeScriptType["INVALID_BEFORE"] = 4] = "INVALID_BEFORE";
    CardanoNativeScriptType[CardanoNativeScriptType["INVALID_HEREAFTER"] = 5] = "INVALID_HEREAFTER";
})(CardanoNativeScriptType || (exports.CardanoNativeScriptType = CardanoNativeScriptType = {}));
var CardanoNativeScriptHashDisplayFormat;
(function (CardanoNativeScriptHashDisplayFormat) {
    CardanoNativeScriptHashDisplayFormat[CardanoNativeScriptHashDisplayFormat["HIDE"] = 0] = "HIDE";
    CardanoNativeScriptHashDisplayFormat[CardanoNativeScriptHashDisplayFormat["BECH32"] = 1] = "BECH32";
    CardanoNativeScriptHashDisplayFormat[CardanoNativeScriptHashDisplayFormat["POLICY_ID"] = 2] = "POLICY_ID";
})(CardanoNativeScriptHashDisplayFormat || (exports.CardanoNativeScriptHashDisplayFormat = CardanoNativeScriptHashDisplayFormat = {}));
var CardanoTxOutputSerializationFormat;
(function (CardanoTxOutputSerializationFormat) {
    CardanoTxOutputSerializationFormat[CardanoTxOutputSerializationFormat["ARRAY_LEGACY"] = 0] = "ARRAY_LEGACY";
    CardanoTxOutputSerializationFormat[CardanoTxOutputSerializationFormat["MAP_BABBAGE"] = 1] = "MAP_BABBAGE";
})(CardanoTxOutputSerializationFormat || (exports.CardanoTxOutputSerializationFormat = CardanoTxOutputSerializationFormat = {}));
var CardanoCertificateType;
(function (CardanoCertificateType) {
    CardanoCertificateType[CardanoCertificateType["STAKE_REGISTRATION"] = 0] = "STAKE_REGISTRATION";
    CardanoCertificateType[CardanoCertificateType["STAKE_DEREGISTRATION"] = 1] = "STAKE_DEREGISTRATION";
    CardanoCertificateType[CardanoCertificateType["STAKE_DELEGATION"] = 2] = "STAKE_DELEGATION";
    CardanoCertificateType[CardanoCertificateType["STAKE_POOL_REGISTRATION"] = 3] = "STAKE_POOL_REGISTRATION";
    CardanoCertificateType[CardanoCertificateType["STAKE_REGISTRATION_CONWAY"] = 7] = "STAKE_REGISTRATION_CONWAY";
    CardanoCertificateType[CardanoCertificateType["STAKE_DEREGISTRATION_CONWAY"] = 8] = "STAKE_DEREGISTRATION_CONWAY";
    CardanoCertificateType[CardanoCertificateType["VOTE_DELEGATION"] = 9] = "VOTE_DELEGATION";
})(CardanoCertificateType || (exports.CardanoCertificateType = CardanoCertificateType = {}));
var CardanoDRepType;
(function (CardanoDRepType) {
    CardanoDRepType[CardanoDRepType["KEY_HASH"] = 0] = "KEY_HASH";
    CardanoDRepType[CardanoDRepType["SCRIPT_HASH"] = 1] = "SCRIPT_HASH";
    CardanoDRepType[CardanoDRepType["ABSTAIN"] = 2] = "ABSTAIN";
    CardanoDRepType[CardanoDRepType["NO_CONFIDENCE"] = 3] = "NO_CONFIDENCE";
})(CardanoDRepType || (exports.CardanoDRepType = CardanoDRepType = {}));
var CardanoPoolRelayType;
(function (CardanoPoolRelayType) {
    CardanoPoolRelayType[CardanoPoolRelayType["SINGLE_HOST_IP"] = 0] = "SINGLE_HOST_IP";
    CardanoPoolRelayType[CardanoPoolRelayType["SINGLE_HOST_NAME"] = 1] = "SINGLE_HOST_NAME";
    CardanoPoolRelayType[CardanoPoolRelayType["MULTIPLE_HOST_NAME"] = 2] = "MULTIPLE_HOST_NAME";
})(CardanoPoolRelayType || (exports.CardanoPoolRelayType = CardanoPoolRelayType = {}));
var CardanoTxAuxiliaryDataSupplementType;
(function (CardanoTxAuxiliaryDataSupplementType) {
    CardanoTxAuxiliaryDataSupplementType[CardanoTxAuxiliaryDataSupplementType["NONE"] = 0] = "NONE";
    CardanoTxAuxiliaryDataSupplementType[CardanoTxAuxiliaryDataSupplementType["CVOTE_REGISTRATION_SIGNATURE"] = 1] = "CVOTE_REGISTRATION_SIGNATURE";
})(CardanoTxAuxiliaryDataSupplementType || (exports.CardanoTxAuxiliaryDataSupplementType = CardanoTxAuxiliaryDataSupplementType = {}));
var CardanoCVoteRegistrationFormat;
(function (CardanoCVoteRegistrationFormat) {
    CardanoCVoteRegistrationFormat[CardanoCVoteRegistrationFormat["CIP15"] = 0] = "CIP15";
    CardanoCVoteRegistrationFormat[CardanoCVoteRegistrationFormat["CIP36"] = 1] = "CIP36";
})(CardanoCVoteRegistrationFormat || (exports.CardanoCVoteRegistrationFormat = CardanoCVoteRegistrationFormat = {}));
var CardanoTxSigningMode;
(function (CardanoTxSigningMode) {
    CardanoTxSigningMode[CardanoTxSigningMode["ORDINARY_TRANSACTION"] = 0] = "ORDINARY_TRANSACTION";
    CardanoTxSigningMode[CardanoTxSigningMode["POOL_REGISTRATION_AS_OWNER"] = 1] = "POOL_REGISTRATION_AS_OWNER";
    CardanoTxSigningMode[CardanoTxSigningMode["MULTISIG_TRANSACTION"] = 2] = "MULTISIG_TRANSACTION";
    CardanoTxSigningMode[CardanoTxSigningMode["PLUTUS_TRANSACTION"] = 3] = "PLUTUS_TRANSACTION";
})(CardanoTxSigningMode || (exports.CardanoTxSigningMode = CardanoTxSigningMode = {}));
var CardanoTxWitnessType;
(function (CardanoTxWitnessType) {
    CardanoTxWitnessType[CardanoTxWitnessType["BYRON_WITNESS"] = 0] = "BYRON_WITNESS";
    CardanoTxWitnessType[CardanoTxWitnessType["SHELLEY_WITNESS"] = 1] = "SHELLEY_WITNESS";
})(CardanoTxWitnessType || (exports.CardanoTxWitnessType = CardanoTxWitnessType = {}));
var FailureType;
(function (FailureType) {
    FailureType[FailureType["Failure_UnexpectedMessage"] = 1] = "Failure_UnexpectedMessage";
    FailureType[FailureType["Failure_ButtonExpected"] = 2] = "Failure_ButtonExpected";
    FailureType[FailureType["Failure_DataError"] = 3] = "Failure_DataError";
    FailureType[FailureType["Failure_ActionCancelled"] = 4] = "Failure_ActionCancelled";
    FailureType[FailureType["Failure_PinExpected"] = 5] = "Failure_PinExpected";
    FailureType[FailureType["Failure_PinCancelled"] = 6] = "Failure_PinCancelled";
    FailureType[FailureType["Failure_PinInvalid"] = 7] = "Failure_PinInvalid";
    FailureType[FailureType["Failure_InvalidSignature"] = 8] = "Failure_InvalidSignature";
    FailureType[FailureType["Failure_ProcessError"] = 9] = "Failure_ProcessError";
    FailureType[FailureType["Failure_NotEnoughFunds"] = 10] = "Failure_NotEnoughFunds";
    FailureType[FailureType["Failure_NotInitialized"] = 11] = "Failure_NotInitialized";
    FailureType[FailureType["Failure_PinMismatch"] = 12] = "Failure_PinMismatch";
    FailureType[FailureType["Failure_WipeCodeMismatch"] = 13] = "Failure_WipeCodeMismatch";
    FailureType[FailureType["Failure_InvalidSession"] = 14] = "Failure_InvalidSession";
    FailureType[FailureType["Failure_FirmwareError"] = 99] = "Failure_FirmwareError";
})(FailureType || (exports.FailureType = FailureType = {}));
var Enum_ButtonRequestType;
(function (Enum_ButtonRequestType) {
    Enum_ButtonRequestType[Enum_ButtonRequestType["ButtonRequest_Other"] = 1] = "ButtonRequest_Other";
    Enum_ButtonRequestType[Enum_ButtonRequestType["ButtonRequest_FeeOverThreshold"] = 2] = "ButtonRequest_FeeOverThreshold";
    Enum_ButtonRequestType[Enum_ButtonRequestType["ButtonRequest_ConfirmOutput"] = 3] = "ButtonRequest_ConfirmOutput";
    Enum_ButtonRequestType[Enum_ButtonRequestType["ButtonRequest_ResetDevice"] = 4] = "ButtonRequest_ResetDevice";
    Enum_ButtonRequestType[Enum_ButtonRequestType["ButtonRequest_ConfirmWord"] = 5] = "ButtonRequest_ConfirmWord";
    Enum_ButtonRequestType[Enum_ButtonRequestType["ButtonRequest_WipeDevice"] = 6] = "ButtonRequest_WipeDevice";
    Enum_ButtonRequestType[Enum_ButtonRequestType["ButtonRequest_ProtectCall"] = 7] = "ButtonRequest_ProtectCall";
    Enum_ButtonRequestType[Enum_ButtonRequestType["ButtonRequest_SignTx"] = 8] = "ButtonRequest_SignTx";
    Enum_ButtonRequestType[Enum_ButtonRequestType["ButtonRequest_FirmwareCheck"] = 9] = "ButtonRequest_FirmwareCheck";
    Enum_ButtonRequestType[Enum_ButtonRequestType["ButtonRequest_Address"] = 10] = "ButtonRequest_Address";
    Enum_ButtonRequestType[Enum_ButtonRequestType["ButtonRequest_PublicKey"] = 11] = "ButtonRequest_PublicKey";
    Enum_ButtonRequestType[Enum_ButtonRequestType["ButtonRequest_MnemonicWordCount"] = 12] = "ButtonRequest_MnemonicWordCount";
    Enum_ButtonRequestType[Enum_ButtonRequestType["ButtonRequest_MnemonicInput"] = 13] = "ButtonRequest_MnemonicInput";
    Enum_ButtonRequestType[Enum_ButtonRequestType["_Deprecated_ButtonRequest_PassphraseType"] = 14] = "_Deprecated_ButtonRequest_PassphraseType";
    Enum_ButtonRequestType[Enum_ButtonRequestType["ButtonRequest_UnknownDerivationPath"] = 15] = "ButtonRequest_UnknownDerivationPath";
    Enum_ButtonRequestType[Enum_ButtonRequestType["ButtonRequest_RecoveryHomepage"] = 16] = "ButtonRequest_RecoveryHomepage";
    Enum_ButtonRequestType[Enum_ButtonRequestType["ButtonRequest_Success"] = 17] = "ButtonRequest_Success";
    Enum_ButtonRequestType[Enum_ButtonRequestType["ButtonRequest_Warning"] = 18] = "ButtonRequest_Warning";
    Enum_ButtonRequestType[Enum_ButtonRequestType["ButtonRequest_PassphraseEntry"] = 19] = "ButtonRequest_PassphraseEntry";
    Enum_ButtonRequestType[Enum_ButtonRequestType["ButtonRequest_PinEntry"] = 20] = "ButtonRequest_PinEntry";
})(Enum_ButtonRequestType || (exports.Enum_ButtonRequestType = Enum_ButtonRequestType = {}));
var Enum_PinMatrixRequestType;
(function (Enum_PinMatrixRequestType) {
    Enum_PinMatrixRequestType[Enum_PinMatrixRequestType["PinMatrixRequestType_Current"] = 1] = "PinMatrixRequestType_Current";
    Enum_PinMatrixRequestType[Enum_PinMatrixRequestType["PinMatrixRequestType_NewFirst"] = 2] = "PinMatrixRequestType_NewFirst";
    Enum_PinMatrixRequestType[Enum_PinMatrixRequestType["PinMatrixRequestType_NewSecond"] = 3] = "PinMatrixRequestType_NewSecond";
    Enum_PinMatrixRequestType[Enum_PinMatrixRequestType["PinMatrixRequestType_WipeCodeFirst"] = 4] = "PinMatrixRequestType_WipeCodeFirst";
    Enum_PinMatrixRequestType[Enum_PinMatrixRequestType["PinMatrixRequestType_WipeCodeSecond"] = 5] = "PinMatrixRequestType_WipeCodeSecond";
})(Enum_PinMatrixRequestType || (exports.Enum_PinMatrixRequestType = Enum_PinMatrixRequestType = {}));
var DebugButton;
(function (DebugButton) {
    DebugButton[DebugButton["NO"] = 0] = "NO";
    DebugButton[DebugButton["YES"] = 1] = "YES";
    DebugButton[DebugButton["INFO"] = 2] = "INFO";
})(DebugButton || (exports.DebugButton = DebugButton = {}));
var DebugPhysicalButton;
(function (DebugPhysicalButton) {
    DebugPhysicalButton[DebugPhysicalButton["LEFT_BTN"] = 0] = "LEFT_BTN";
    DebugPhysicalButton[DebugPhysicalButton["MIDDLE_BTN"] = 1] = "MIDDLE_BTN";
    DebugPhysicalButton[DebugPhysicalButton["RIGHT_BTN"] = 2] = "RIGHT_BTN";
})(DebugPhysicalButton || (exports.DebugPhysicalButton = DebugPhysicalButton = {}));
var EthereumDefinitionType;
(function (EthereumDefinitionType) {
    EthereumDefinitionType[EthereumDefinitionType["NETWORK"] = 0] = "NETWORK";
    EthereumDefinitionType[EthereumDefinitionType["TOKEN"] = 1] = "TOKEN";
})(EthereumDefinitionType || (exports.EthereumDefinitionType = EthereumDefinitionType = {}));
var EthereumDataType;
(function (EthereumDataType) {
    EthereumDataType[EthereumDataType["UINT"] = 1] = "UINT";
    EthereumDataType[EthereumDataType["INT"] = 2] = "INT";
    EthereumDataType[EthereumDataType["BYTES"] = 3] = "BYTES";
    EthereumDataType[EthereumDataType["STRING"] = 4] = "STRING";
    EthereumDataType[EthereumDataType["BOOL"] = 5] = "BOOL";
    EthereumDataType[EthereumDataType["ADDRESS"] = 6] = "ADDRESS";
    EthereumDataType[EthereumDataType["ARRAY"] = 7] = "ARRAY";
    EthereumDataType[EthereumDataType["STRUCT"] = 8] = "STRUCT";
})(EthereumDataType || (exports.EthereumDataType = EthereumDataType = {}));
var Enum_BackupType;
(function (Enum_BackupType) {
    Enum_BackupType[Enum_BackupType["Bip39"] = 0] = "Bip39";
    Enum_BackupType[Enum_BackupType["Slip39_Basic"] = 1] = "Slip39_Basic";
    Enum_BackupType[Enum_BackupType["Slip39_Advanced"] = 2] = "Slip39_Advanced";
    Enum_BackupType[Enum_BackupType["Slip39_Single_Extendable"] = 3] = "Slip39_Single_Extendable";
    Enum_BackupType[Enum_BackupType["Slip39_Basic_Extendable"] = 4] = "Slip39_Basic_Extendable";
    Enum_BackupType[Enum_BackupType["Slip39_Advanced_Extendable"] = 5] = "Slip39_Advanced_Extendable";
})(Enum_BackupType || (exports.Enum_BackupType = Enum_BackupType = {}));
var Enum_SafetyCheckLevel;
(function (Enum_SafetyCheckLevel) {
    Enum_SafetyCheckLevel[Enum_SafetyCheckLevel["Strict"] = 0] = "Strict";
    Enum_SafetyCheckLevel[Enum_SafetyCheckLevel["PromptAlways"] = 1] = "PromptAlways";
    Enum_SafetyCheckLevel[Enum_SafetyCheckLevel["PromptTemporarily"] = 2] = "PromptTemporarily";
})(Enum_SafetyCheckLevel || (exports.Enum_SafetyCheckLevel = Enum_SafetyCheckLevel = {}));
var Enum_HomescreenFormat;
(function (Enum_HomescreenFormat) {
    Enum_HomescreenFormat[Enum_HomescreenFormat["Toif"] = 1] = "Toif";
    Enum_HomescreenFormat[Enum_HomescreenFormat["Jpeg"] = 2] = "Jpeg";
    Enum_HomescreenFormat[Enum_HomescreenFormat["ToiG"] = 3] = "ToiG";
})(Enum_HomescreenFormat || (exports.Enum_HomescreenFormat = Enum_HomescreenFormat = {}));
var Enum_BackupAvailability;
(function (Enum_BackupAvailability) {
    Enum_BackupAvailability[Enum_BackupAvailability["NotAvailable"] = 0] = "NotAvailable";
    Enum_BackupAvailability[Enum_BackupAvailability["Required"] = 1] = "Required";
    Enum_BackupAvailability[Enum_BackupAvailability["Available"] = 2] = "Available";
})(Enum_BackupAvailability || (exports.Enum_BackupAvailability = Enum_BackupAvailability = {}));
var Enum_RecoveryStatus;
(function (Enum_RecoveryStatus) {
    Enum_RecoveryStatus[Enum_RecoveryStatus["Nothing"] = 0] = "Nothing";
    Enum_RecoveryStatus[Enum_RecoveryStatus["Recovery"] = 1] = "Recovery";
    Enum_RecoveryStatus[Enum_RecoveryStatus["Backup"] = 2] = "Backup";
})(Enum_RecoveryStatus || (exports.Enum_RecoveryStatus = Enum_RecoveryStatus = {}));
var Enum_Capability;
(function (Enum_Capability) {
    Enum_Capability[Enum_Capability["Capability_Bitcoin"] = 1] = "Capability_Bitcoin";
    Enum_Capability[Enum_Capability["Capability_Bitcoin_like"] = 2] = "Capability_Bitcoin_like";
    Enum_Capability[Enum_Capability["Capability_Binance"] = 3] = "Capability_Binance";
    Enum_Capability[Enum_Capability["Capability_Cardano"] = 4] = "Capability_Cardano";
    Enum_Capability[Enum_Capability["Capability_Crypto"] = 5] = "Capability_Crypto";
    Enum_Capability[Enum_Capability["Capability_EOS"] = 6] = "Capability_EOS";
    Enum_Capability[Enum_Capability["Capability_Ethereum"] = 7] = "Capability_Ethereum";
    Enum_Capability[Enum_Capability["Capability_Lisk"] = 8] = "Capability_Lisk";
    Enum_Capability[Enum_Capability["Capability_Monero"] = 9] = "Capability_Monero";
    Enum_Capability[Enum_Capability["Capability_NEM"] = 10] = "Capability_NEM";
    Enum_Capability[Enum_Capability["Capability_Ripple"] = 11] = "Capability_Ripple";
    Enum_Capability[Enum_Capability["Capability_Stellar"] = 12] = "Capability_Stellar";
    Enum_Capability[Enum_Capability["Capability_Tezos"] = 13] = "Capability_Tezos";
    Enum_Capability[Enum_Capability["Capability_U2F"] = 14] = "Capability_U2F";
    Enum_Capability[Enum_Capability["Capability_Shamir"] = 15] = "Capability_Shamir";
    Enum_Capability[Enum_Capability["Capability_ShamirGroups"] = 16] = "Capability_ShamirGroups";
    Enum_Capability[Enum_Capability["Capability_PassphraseEntry"] = 17] = "Capability_PassphraseEntry";
    Enum_Capability[Enum_Capability["Capability_Solana"] = 18] = "Capability_Solana";
    Enum_Capability[Enum_Capability["Capability_Translations"] = 19] = "Capability_Translations";
    Enum_Capability[Enum_Capability["Capability_Brightness"] = 20] = "Capability_Brightness";
    Enum_Capability[Enum_Capability["Capability_Haptic"] = 21] = "Capability_Haptic";
})(Enum_Capability || (exports.Enum_Capability = Enum_Capability = {}));
var RecoveryDeviceInputMethod;
(function (RecoveryDeviceInputMethod) {
    RecoveryDeviceInputMethod[RecoveryDeviceInputMethod["ScrambledWords"] = 0] = "ScrambledWords";
    RecoveryDeviceInputMethod[RecoveryDeviceInputMethod["Matrix"] = 1] = "Matrix";
})(RecoveryDeviceInputMethod || (exports.RecoveryDeviceInputMethod = RecoveryDeviceInputMethod = {}));
var Enum_RecoveryType;
(function (Enum_RecoveryType) {
    Enum_RecoveryType[Enum_RecoveryType["NormalRecovery"] = 0] = "NormalRecovery";
    Enum_RecoveryType[Enum_RecoveryType["DryRun"] = 1] = "DryRun";
    Enum_RecoveryType[Enum_RecoveryType["UnlockRepeatedBackup"] = 2] = "UnlockRepeatedBackup";
})(Enum_RecoveryType || (exports.Enum_RecoveryType = Enum_RecoveryType = {}));
var SdProtectOperationType;
(function (SdProtectOperationType) {
    SdProtectOperationType[SdProtectOperationType["DISABLE"] = 0] = "DISABLE";
    SdProtectOperationType[SdProtectOperationType["ENABLE"] = 1] = "ENABLE";
    SdProtectOperationType[SdProtectOperationType["REFRESH"] = 2] = "REFRESH";
})(SdProtectOperationType || (exports.SdProtectOperationType = SdProtectOperationType = {}));
var Enum_WordRequestType;
(function (Enum_WordRequestType) {
    Enum_WordRequestType[Enum_WordRequestType["WordRequestType_Plain"] = 0] = "WordRequestType_Plain";
    Enum_WordRequestType[Enum_WordRequestType["WordRequestType_Matrix9"] = 1] = "WordRequestType_Matrix9";
    Enum_WordRequestType[Enum_WordRequestType["WordRequestType_Matrix6"] = 2] = "WordRequestType_Matrix6";
})(Enum_WordRequestType || (exports.Enum_WordRequestType = Enum_WordRequestType = {}));
var BootCommand;
(function (BootCommand) {
    BootCommand[BootCommand["STOP_AND_WAIT"] = 0] = "STOP_AND_WAIT";
    BootCommand[BootCommand["INSTALL_UPGRADE"] = 1] = "INSTALL_UPGRADE";
})(BootCommand || (exports.BootCommand = BootCommand = {}));
var MoneroNetworkType;
(function (MoneroNetworkType) {
    MoneroNetworkType[MoneroNetworkType["MAINNET"] = 0] = "MAINNET";
    MoneroNetworkType[MoneroNetworkType["TESTNET"] = 1] = "TESTNET";
    MoneroNetworkType[MoneroNetworkType["STAGENET"] = 2] = "STAGENET";
    MoneroNetworkType[MoneroNetworkType["FAKECHAIN"] = 3] = "FAKECHAIN";
})(MoneroNetworkType || (exports.MoneroNetworkType = MoneroNetworkType = {}));
var NEMMosaicLevy;
(function (NEMMosaicLevy) {
    NEMMosaicLevy[NEMMosaicLevy["MosaicLevy_Absolute"] = 1] = "MosaicLevy_Absolute";
    NEMMosaicLevy[NEMMosaicLevy["MosaicLevy_Percentile"] = 2] = "MosaicLevy_Percentile";
})(NEMMosaicLevy || (exports.NEMMosaicLevy = NEMMosaicLevy = {}));
var NEMSupplyChangeType;
(function (NEMSupplyChangeType) {
    NEMSupplyChangeType[NEMSupplyChangeType["SupplyChange_Increase"] = 1] = "SupplyChange_Increase";
    NEMSupplyChangeType[NEMSupplyChangeType["SupplyChange_Decrease"] = 2] = "SupplyChange_Decrease";
})(NEMSupplyChangeType || (exports.NEMSupplyChangeType = NEMSupplyChangeType = {}));
var NEMModificationType;
(function (NEMModificationType) {
    NEMModificationType[NEMModificationType["CosignatoryModification_Add"] = 1] = "CosignatoryModification_Add";
    NEMModificationType[NEMModificationType["CosignatoryModification_Delete"] = 2] = "CosignatoryModification_Delete";
})(NEMModificationType || (exports.NEMModificationType = NEMModificationType = {}));
var NEMImportanceTransferMode;
(function (NEMImportanceTransferMode) {
    NEMImportanceTransferMode[NEMImportanceTransferMode["ImportanceTransfer_Activate"] = 1] = "ImportanceTransfer_Activate";
    NEMImportanceTransferMode[NEMImportanceTransferMode["ImportanceTransfer_Deactivate"] = 2] = "ImportanceTransfer_Deactivate";
})(NEMImportanceTransferMode || (exports.NEMImportanceTransferMode = NEMImportanceTransferMode = {}));
var StellarAssetType;
(function (StellarAssetType) {
    StellarAssetType[StellarAssetType["NATIVE"] = 0] = "NATIVE";
    StellarAssetType[StellarAssetType["ALPHANUM4"] = 1] = "ALPHANUM4";
    StellarAssetType[StellarAssetType["ALPHANUM12"] = 2] = "ALPHANUM12";
})(StellarAssetType || (exports.StellarAssetType = StellarAssetType = {}));
var StellarMemoType;
(function (StellarMemoType) {
    StellarMemoType[StellarMemoType["NONE"] = 0] = "NONE";
    StellarMemoType[StellarMemoType["TEXT"] = 1] = "TEXT";
    StellarMemoType[StellarMemoType["ID"] = 2] = "ID";
    StellarMemoType[StellarMemoType["HASH"] = 3] = "HASH";
    StellarMemoType[StellarMemoType["RETURN"] = 4] = "RETURN";
})(StellarMemoType || (exports.StellarMemoType = StellarMemoType = {}));
var StellarSignerType;
(function (StellarSignerType) {
    StellarSignerType[StellarSignerType["ACCOUNT"] = 0] = "ACCOUNT";
    StellarSignerType[StellarSignerType["PRE_AUTH"] = 1] = "PRE_AUTH";
    StellarSignerType[StellarSignerType["HASH"] = 2] = "HASH";
})(StellarSignerType || (exports.StellarSignerType = StellarSignerType = {}));
var TezosContractType;
(function (TezosContractType) {
    TezosContractType[TezosContractType["Implicit"] = 0] = "Implicit";
    TezosContractType[TezosContractType["Originated"] = 1] = "Originated";
})(TezosContractType || (exports.TezosContractType = TezosContractType = {}));
var TezosBallotType;
(function (TezosBallotType) {
    TezosBallotType[TezosBallotType["Yay"] = 0] = "Yay";
    TezosBallotType[TezosBallotType["Nay"] = 1] = "Nay";
    TezosBallotType[TezosBallotType["Pass"] = 2] = "Pass";
})(TezosBallotType || (exports.TezosBallotType = TezosBallotType = {}));
