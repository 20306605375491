import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';

import {Observable, ReplaySubject} from "rxjs";
import {ClaimControllerService, ClaimPayload, ClaimReferenceView} from "../services";
import {VisibilityScopeEnum} from "../services/model/visibility-scope";
import {DataService} from "../services/data-storage.service";
import {CryptographyService} from "../services/cryptography.service";
import {CryptoWalletService} from "../services/crypto-wallet.service";
import {CacheService} from "../services/cache-service";
import {NgForOf, NgIf} from "@angular/common";
import {ToReadableStringPipe} from "../pipes/ToReadableStringPipe";
import {FormsModule} from "@angular/forms";
import {ToFilteredClaimTypesPipe} from "../pipes/ToFilteredClaimTypesPipe";
import {ActionButtonComponent} from "../action-button/action-button.component";
import {ToVisibilityScopeIconPipe} from "../pipes/ToVisibilityScopeIconPipe";
import {ToOppositeVisibilityScopeIconPipe} from "../pipes/ToOppositeClaimAspectIconPipe";
import {GenericButtonComponent} from "../generic-button/generic-button.component";
import {ErrorModalComponent} from "../error-modal/error-modal.component";
import {GenericMessageComponent} from "../generic-message/generic-message.component";


declare var $: any;

@Component({
    selector: 'app-new-claim-modal',
    templateUrl: './new-claim-modal.component.html',
    standalone: true,
    imports: [
        NgIf,
        ToReadableStringPipe,
        NgForOf,
        FormsModule,
        ToFilteredClaimTypesPipe,
        ActionButtonComponent,
        ToVisibilityScopeIconPipe,
        ToOppositeVisibilityScopeIconPipe,
        GenericButtonComponent,
        ErrorModalComponent,
        GenericMessageComponent
    ],
    styleUrls: ['./new-claim-modal.component.scss']
})
export class NewClaimModalComponent implements OnInit {
    @Output() protected claimMade: EventEmitter<ClaimReferenceView> = new EventEmitter<ClaimReferenceView>()
    @Input() proposed?: boolean = false;
    @Input() subject?: string;
    @Input() customId?: string;
    protected scopes: Array<string> = []
    protected filePath: string = '';
    protected fileName: string = '';
    protected errorMessage: string = '';
    protected numOfFactValues: number = 1
    protected claimPayload: ClaimPayload = {
        claimVisibilityScope: VisibilityScopeEnum.Protected,
        factAspects: [],
        claimAspects: []
    }
    protected errorOccurred: boolean = false
    protected loading: boolean = false
    protected isTextValue: boolean = false
    protected isImageValue: boolean = false
    protected claimCreationAccepted: boolean = false
    protected claimTypes: Array<string> = []
    protected defaultClaimMessage: string = 'Make a claim about...'
    protected defaultScopeMessage: string = 'Choose a category'
    protected selectedClaimTypeString?: string = this.defaultClaimMessage
    protected selectedClaimScopeString?: string = this.defaultScopeMessage
    protected selectedClaimType?: string
    protected selectedClaimScope?: string
    protected publicClaim: boolean = false;
    protected scopeSelected: boolean = false;
    protected claimMadeSuccessfully: boolean = false;

    protected readonly VisibilityScopeEnum = VisibilityScopeEnum;

    constructor(protected dataService: DataService, private cryptographyService: CryptographyService, private cryptoWalletService: CryptoWalletService, private cacheService: CacheService, private claimControllerService: ClaimControllerService) {
    }

    ngOnInit(): void {
        this.dataService.last<Array<string>>('filteredScopeAggregates').subscribe({
            next: scopes => {
                this.scopes = scopes
            }
        })
    }

    async tryMakeClaim() {
        this.claimPayload.claimType = this.selectedClaimTypeString
        if (this.claimPayload.claimType == 'account_email_address') {
            $('#changeAccountEmailModal').modal('show');
        } else {
            this.claimCreationAccepted = true
            await this.makeClaim()
        }
    }

    async makeClaim() {
        if (this.claimPayload.claimType != undefined) {
            this.loading = true
            this.cacheService.bypassCache = true
            this.claimCreationAccepted = true
            const privateKey = await this.dataService.getEncryptionPrivateKey()
            const cryptoKey = await this.cryptographyService.privateAESKeyStringToCryptoKey(privateKey)
            const encryptedValue = await this.cryptographyService.encryptAES(this.claimPayload.factValue!, cryptoKey)
            const signatureResult = await this.cryptoWalletService.signData(this.claimPayload.factValue!)
            this.claimPayload.factValue = encryptedValue
            if (signatureResult.success) {
                this.claimControllerService.makeClaim({
                    data: this.claimPayload,
                    signature: signatureResult.payload.signature
                }).subscribe({
                    next: (claimReferenceView) => {
                        this.reset()
                        this.showSuccessMessage()
                        const identity = this.dataService.getIdentity()!
                        identity.claims?.push(claimReferenceView)
                        this.dataService.publish('identity', identity)
                    },
                    error: (error) => {
                        this.errorOccurred = true
                        this.reset()
                    }
                });
            }
        }
    }

    toggleVisibility(visibilityScope: VisibilityScopeEnum) {
        this.claimPayload.claimVisibilityScope = visibilityScope
    }

    reset() {
        this.loading = false
        this.publicClaim = false
        this.isTextValue = false
        this.isImageValue = false
        this.claimTypes = []
        this.selectedClaimTypeString = this.defaultClaimMessage
        this.selectedClaimScopeString = this.defaultScopeMessage
        this.scopeSelected = false
        this.selectedClaimType = undefined
        this.selectedClaimScope = undefined
        this.claimPayload = {claimVisibilityScope: VisibilityScopeEnum.Protected, factAspects: [], claimAspects: []}
        this.claimCreationAccepted = false
        this.resetInput();
    }

    fileChangeEvent(event: any): void {
        const files = event.target.files as FileList;
        if (files.length > 0) {
            const file = files[0]
            this.filePath = URL.createObjectURL(file);
            this.fileName = file.name
            this.convertFile(file).subscribe(base64 => {
                this.claimPayload.factValue = base64
            });
            this.resetInput();
        }
    }

    convertFile(file: File): Observable<string> {
        const result = new ReplaySubject<string>(1);
        const reader = new FileReader();
        reader.readAsBinaryString(file);
        reader.onload = (event) => result.next(btoa(event!.target!.result!.toString()));
        return result;
    }

    resetInput() {
        const input = document.getElementById('input-image') as HTMLInputElement;
        if (input) {
            input.value = "";
        }
    }

    resolveClaimTypeField($event: string) {
        this.isImageValue = false
        switch ($event) {
            case "passport":
            case "identityCard":
            case "driversLicense":
            case "avatar":
            case "certificate":
            case "certificateOfConduct":
            case "diploma":
            case "degree":
            case "reference":
                this.isImageValue = true
                break;
        }
    }

    resetError() {
        this.errorOccurred = false
    }

    showSuccessMessage() {
        this.claimMadeSuccessfully = true
        setTimeout(() => {
            this.claimMadeSuccessfully = false
        }, 5000);
    }

    setScope(event: any) {
        const scope = event.target.value;
        const name = scope + 'claimTypes'
        this.selectedClaimScope = event
        this.scopeSelected = true
        this.selectedClaimScopeString = event.toString()
        if (this.dataService.has(name)) {
            this.dataService.last<Array<string>>(name).subscribe({
                next: claimTypes => {
                    this.claimTypes = claimTypes
                }
            })
        } else {
            this.loading = true
            this.claimControllerService.getClaimMetadata(scope)
                .subscribe({
                    next: (response) => {
                        this.claimTypes = response
                        this.dataService.publish(scope + 'claimTypes', response)
                        this.loading = false
                    }
                });
        }
    }

    setClaimType(event: any) {
        const claimType = event.target.value
        this.resolveClaimTypeField(claimType)
        this.selectedClaimType = claimType
        this.selectedClaimTypeString = claimType.toString()
    }

    updateDescription($event: any) {
        this.claimPayload.claimDescription = $event.target.value
    }


}
