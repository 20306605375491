import {Component, EventEmitter, Input, Output} from '@angular/core';
import {HttpErrorResponse} from "@angular/common/http";
import {ClaimControllerService, ClaimReferenceView, ClaimView} from "../services";
import {CryptographyService} from "../services/cryptography.service";
import {DataService} from "../services/data-storage.service";
import {CacheService} from "../services/cache-service";
import {VisibilityScopeEnum} from "../services/model/visibility-scope";
import {ActionButtonComponent} from "../action-button/action-button.component";
import {ToVisibilityScopeIconPipe} from "../pipes/ToVisibilityScopeIconPipe";
import {ToOppositeVisibilityScopeIconPipe} from "../pipes/ToOppositeClaimAspectIconPipe";
import {FactComponent} from "../fact/fact.component";
import {ToReadableStringPipe} from "../pipes/ToReadableStringPipe";
import {NgForOf, NgIf} from "@angular/common";
import {CryptoWalletService} from "../services/crypto-wallet.service";
import {ErrorMessageService} from "../services/error-message-service";

@Component({
    selector: 'app-claim',
    templateUrl: './claim.component.html',
    standalone: true,
    imports: [
        ActionButtonComponent,
        ToVisibilityScopeIconPipe,
        ToOppositeVisibilityScopeIconPipe,
        FactComponent,
        ToReadableStringPipe,
        NgForOf,
        NgIf
    ],
    styleUrls: ['./claim.component.scss']
})
export class ClaimComponent {
    @Input() claimReference?: ClaimReferenceView
    @Input() claim?: ClaimView
    @Input() myProfile: boolean = false
    @Input() inMySubscriptionsOverview: boolean = false
    @Output() deletedSubscriptionId: EventEmitter<string> = new EventEmitter<string>();
    @Output() claimChanged: EventEmitter<string> = new EventEmitter<string>();
    protected loadingMap: Map<string, boolean> = new Map<string, boolean>()
    protected deleteClaimClicked: boolean = false
    protected errorOccurred: boolean = false
    protected type: string = ''
    protected claimToDelete?: ClaimReferenceView
    protected password?: string;
    protected readonly VisibilityScopeEnum = VisibilityScopeEnum;
    protected showCopyConfirmation: boolean = false;

    constructor(private errorMessageService: ErrorMessageService, private cryptographyService: CryptographyService, private dataService: DataService, private cryptoWalletService: CryptoWalletService, private cacheService: CacheService, private claimControllerService: ClaimControllerService) {
    }

    protected tryDeleteClaim(claim?: ClaimReferenceView) {
        if (!!claim && !this.claimIsMandatory(claim)) {
            this.deleteClaimClicked = true
            this.claimToDelete = claim
            this.dataService.publish<ClaimView>('claimToDelete', claim)
        }
    }

    protected claimIsMandatory(claim?: ClaimReferenceView) {
        let isMandatory = false;
        for (const aspect of claim?.aspects!) {
            if (aspect == "mandatory") {
                isMandatory = true
            }
        }
        return isMandatory;
    }

    protected alterVisibility(id: string, visibilityScope?: VisibilityScopeEnum) {
        this.loadingMap.set(id + visibilityScope, true)
        this.cacheService.bypassCache = true
        this.claimControllerService.alterClaim(id, [{
            op: "replace",
            path: "/visibilityScope",
            value: visibilityScope?.toLowerCase()
        }]).subscribe({
            next: (response) => {
                this.claim!!.visibilityScope = visibilityScope
                this.loadingMap.set(id + visibilityScope, false)
                this.claimReference = response
            },
            error: (error) => {
                this.loadingMap.set(id + visibilityScope, false)
                if (error instanceof HttpErrorResponse) {
                    this.errorOccurred = true
                }
            }
        });
    }

    protected async decryptClaim() {
        if (this.myProfile) {
            const privateKeyString = await this.dataService.getEncryptionPrivateKey()
            await this.decryptClaimUsingKey(privateKeyString)
        } else {
            const privateKeyString = await this.claim?.encryptedPrivateKey
            await this.decryptClaimUsingKey(privateKeyString!)
        }
    }

    private async decryptClaimUsingKey(privateKeyString: string) {
        const cryptoKey = await this.cryptographyService.privateAESKeyStringToCryptoKey(privateKeyString)
        const decryptedValue = await this.cryptographyService.decryptAES(this.claim!.fact!.value!, cryptoKey)
        this.claim = {
            ...this.claim,
            fact: this.claim!.fact = {
                value: decryptedValue,
                ...this.claim!.fact
            }
        }
    }

    async supportClaim() {
        const identity = this.dataService.getIdentity()
        const did = identity?.did!
        const signatureResult = await this.cryptoWalletService.signData(JSON.stringify(this.claim))
        if (signatureResult.success) {
            const signature = signatureResult.payload.signature
            this.claimControllerService.supportClaim({claimId: this.claim!.id!, did: did, signature: signature})
        } else {
            this.errorOccurred = true;
            this.errorMessageService.setMessage("Failed to get a signature.")
        }
    }
}