import {Injectable} from '@angular/core';
import {Observable} from 'rxjs';

import {ClaimView} from './models/claim-reference-view';
import {DoaToaHttpService} from "./doa-toa-http.service";
import {JsonPatchPayload} from "./models/json-patch-payload";
import {SignedData} from "./models/signed-data";
import {ClaimPayload} from "./models/claim-payload";
import {ClaimQueryPayload} from "./models/claim-query-payload";
import {environment} from "../../environments/environment";

@Injectable({
  providedIn: 'root'
})
export class ClaimControllerService {
  
  constructor(private client: DoaToaHttpService) {
  }
  
  public viewClaims<T>(owner: string, cid?: string, scope: string = "profile"): Observable<T> {
    let headers = new Map<string, string>()
    headers.set('Content-Type', 'application/json')
    headers.set('Accept', 'application/vnd.doatoa.claims+json')
    
    let localVarPath = `/identity/claims/`
    const scopes = scope !== undefined ? [scope] : [];
    const claimQueryPayload: ClaimQueryPayload = {
      owner: owner,
      scopes: scopes,
      cid: cid,
    }
    return this.client.post<T>(`${environment.idpApiBaseUrl}${localVarPath}`, claimQueryPayload, headers)
  }
  
  public deleteClaims(cids: string[]): Observable<any> {
    let headers = new Map<string, string>()
    headers.set('Content-Type', 'application/json')
    headers.set('Accept', 'application/json')
    
    let localVarPath = `/identity/claim/`;
    return this.client.delete(`${environment.idpApiBaseUrl}${localVarPath}`,
      cids,
      headers)
  }
  
  public makeClaims(signedClaimPayloads: Array<SignedData<ClaimPayload>>): Observable<ClaimView[]> {
    let headers = new Map<string, string>()
    headers.set('Content-Type', 'application/json')
    headers.set('Accept', 'application/json')
    let localVarPath = `/identity/claim/`;
    return this.client.post<ClaimView[]>(`${environment.idpApiBaseUrl}${localVarPath}`, signedClaimPayloads, headers)
  }
  
  public alterClaim(patchPayloads: Array<JsonPatchPayload>): Observable<ClaimView> {
    let headers = new Map<string, string>()
    headers.set('Content-Type', 'application/json')
    headers.set('Accept', 'application/json')
    let localVarPath = `/identity/claim/`;
    return this.client.patch<ClaimView>(`${environment.idpApiBaseUrl}${localVarPath}`, patchPayloads, headers)
  }
  
}
