<div class="min-width-300 overflow-x-hidden">
    <div class="container" *ngIf="loading">
        <div class="h-100 mb-4">
            <lib-loading-placeholder [show]="loading"></lib-loading-placeholder>
        </div>
    </div>
    <div *ngIf="!loading">
        <app-identity-card></app-identity-card>
    </div>
    <app-error-message></app-error-message>
</div>
