import {Component, EventEmitter, Output} from '@angular/core';
import {ActivatedRoute} from "@angular/router";
import {DataService} from "../../services/data.service";
import {AuthorisedComponent} from "../common/authorised-component";
import {RedirectService} from "../../services/redirect-service";

@Component({
    selector: 'app-settings',
    templateUrl: './settings.component.html',
    styleUrls: ['./settings.component.scss']
})
export class SettingsComponent extends AuthorisedComponent {

    loading: boolean = false
    @Output() myAccountLoading: EventEmitter<boolean> = new EventEmitter<boolean>()
    errorOccurred: boolean = false
    errorMessage: string = ''

    constructor(protected activatedRoute: ActivatedRoute, dataService: DataService, redirectService: RedirectService) {
        super(dataService, redirectService)
    }
}
