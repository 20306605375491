import {Component, OnInit} from '@angular/core';

import {Router, RouterLink} from "@angular/router";

import {LogoComponent} from "../common/logo/logo.component";
import {FormsModule} from "@angular/forms";
import {GenericButtonComponent} from "../common/generic-button/generic-button.component";
import {ErrorMessageComponent} from "../common/error-message/error-message.component";
import {ErrorMessageService} from "../../services/error-message-service";
import {CryptoWalletService} from "../../services/crypto-wallet.service";
import {SplashScreen} from "@capacitor/splash-screen";


@Component({
  selector: 'app-new-license',
  templateUrl: './sign-up.component.html',
  standalone: true,
  imports: [
    LogoComponent,
    FormsModule,
    GenericButtonComponent,
    ErrorMessageComponent,
    RouterLink
  ],
  styleUrls: ['./sign-up.component.scss']
})
export class SignUpComponent implements OnInit {
  
  protected termsAndConditionsAccepted: boolean = false
  protected method?: string = undefined;
  protected email?: string = undefined;
  protected loading: boolean = false;
  protected showEmailInfo: boolean = false;
  protected didIsTaken?: boolean = undefined
  protected hide = true;
  protected showCopyConfirmation: boolean = false;
  
  toggleVisibility(): void {
    this.hide = !this.hide;
  }
  
  constructor(private cryptoWalletService: CryptoWalletService, private router: Router, private errorMessageService: ErrorMessageService) {
  }
  
  ngOnInit(): void {
    SplashScreen.hide()
    this.cryptoWalletService.getStored('email').then((email) => {
      this.email = email;
    })
  }
  
  isValidEmail = (email?: string) => {
    return String(email)
      .toLowerCase()
      .match(
        /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|.(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
      );
  };
  
  resolveClass(): string {
    if (this.email == '' || this.email == undefined)
      return 'greyed-out'
    return 'is-valid'
  }
  
  switchTermsAndConditionsAccepted() {
    this.termsAndConditionsAccepted = !this.termsAndConditionsAccepted
  }
  
  flipShowEmailInfo() {
    this.showEmailInfo = !this.showEmailInfo
  }
  
  onNext() {
    this.loading = true
    if (this.email != undefined && this.isValidEmail(this.email)) {
      this.cryptoWalletService.store('email', this.email).then(() => {
        this.router.navigate(['sign-up/authentication-methods'])
      })
    }
  }
  
  errorOccurred(): boolean {
    return this.errorMessageService.errorHasOccurred()
  }
  
}
