import {Component} from '@angular/core';
import {GenericButtonComponent} from "../../common/generic-button/generic-button.component";
import {NgForOf} from "@angular/common";
import {ToCapitalizedTextPipe} from "../../common/pipes/ToCapitalizedTextPipe";
import {LogoComponent} from "../../common/logo/logo.component";
import {environment} from "../../../environments/environment";
import {DataService} from "../../../services/data.service";
import {CryptoWalletService} from "../../../services/crypto-wallet.service";
import {Router} from "@angular/router";

@Component({
    selector: 'app-authentication-method',
    standalone: true,
    imports: [
        GenericButtonComponent,
        NgForOf,
        ToCapitalizedTextPipe,
        LogoComponent
    ],
    templateUrl: './authentication-method.component.html',
    styleUrl: './authentication-method.component.scss'
})
export class AuthenticationMethodComponent {

    protected chosenAuthenticationMethod?: string
    protected methods: string[] = environment.authenticationMethods

    constructor(private dataService: DataService, private cryptoWalletService: CryptoWalletService, private router: Router) {
    }

    flipMethod(method: string) {
        if (this.chosenAuthenticationMethod == method) {
            this.chosenAuthenticationMethod = undefined
        } else {
            this.chosenAuthenticationMethod = method
        }
    }

    async initialiseKeyStore() {
        this.dataService.publish('authenticationMethod', this.chosenAuthenticationMethod);
        this.dataService.last<string>('email').subscribe({
            next: async email => {
                const mnemonic = await this.cryptoWalletService.generateKeys(email!, this.chosenAuthenticationMethod!)
                this.dataService.publish('mnemonic', mnemonic)
                this.router.navigate(['sign-up/mnemonic-phrase'])
            }
        })

    }
}
