<div class="card p-3 mb-3">
    <h3>For the curious</h3>
    <div class="row justify-content-center mb-3">
        <app-generic-button
                routerLink="/developers-console"
                [btnClass]="'btn-regular'"
                [buttonText]="'Developers console'"
                [primaryIcon]="'fa-solid fa-code'">
        </app-generic-button>
    </div>
    <div class="row justify-content-center">
        <app-generic-button
                (click)="window.location.href = 'https://dev.app.doatoa.io/docs/index.html'"
                [btnClass]="'btn-regular'"
                [buttonText]="'Documentation'"
                [primaryIcon]="'fa-solid fa-book'">
        </app-generic-button>
    </div>
</div>