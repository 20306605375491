<div class="mb-3">
    <div *ngIf="myProfile" class="row align-items-center justify-content-between">
        <div class="d-flex justify-content-end p-0">
            <div class="d-flex border rounded-5 p-1 secondary-blue">
                <app-action-button *ngFor="let visibilityScope of VisibilityScopeEnum.values(); let first = first; let last = last;"
                                   (click)="alterVisibility(claimReference?.id!, visibilityScope)"
                                   [btnClass]="claim?.visibilityScope == visibilityScope ? 'btn-basic-info' : 'btn-basic-disabled'"
                                   [loading]="loadingMap.get(claimReference?.id!+visibilityScope) || false"
                                   [showPrimary]="claimReference?.visibilityScope == visibilityScope"
                                   [showSecondary]="claimReference?.visibilityScope != visibilityScope"
                                   [primaryIconClass]="visibilityScope | toVisibilityScopeIcon"
                                   [secondaryIconClass]="visibilityScope | toOppositeVisibilityScopeIcon"
                                   class="{{last ? 'ms-1' : first ? '' : 'ms-1'}} ">
                </app-action-button>
            </div>
            <div class="d-flex border rounded-5 p-1 secondary-blue">
                <app-action-button
                        (click)="decryptClaim()"
                        [btnClass]="'btn-basic-info'"
                        [primaryIconClass]="'fa fa-key'"
                        [disabled]="!myProfile && claim?.encryptedPrivateKey == undefined">
                </app-action-button>
                <app-action-button
                        (click)="supportClaim()"
                        [btnClass]="'btn-basic-info'"
                        [primaryIconClass]="'fa fa-checkmark'"
                        [disabled]="myProfile">
                </app-action-button>
                <app-action-button (click)="tryDeleteClaim(claimReference)"
                                   [useSpinner]="false"
                                   [disabled]="claimIsMandatory(claimReference)"
                                   [btnClass]="'btn-primary-danger'"
                                   [primaryIconClass]="'fa fa-trash'"
                                   [attr.data-bs-toggle]="!claimIsMandatory(claimReference) ? 'modal' : null"
                                   [attr.data-bs-target]="!claimIsMandatory(claimReference) ? '#deleteModal' : null"
                                   class="ms-1">
                </app-action-button>
            </div>
        </div>
    </div>
    <div class="text-center mt-3">{{ claimReference?.type |  toReadableString }}:</div>
    <app-fact [fact]="claim?.fact!" (showCopyConfirmation)="showCopyConfirmation = $event"></app-fact>
    <div class="text-center">Description:</div>
    <div class="text-center secondary-blue">{{ claimReference?.description }}</div>
    <div class="text-center primary-blue" *ngIf="showCopyConfirmation">(Copied to clipboard)</div>
</div>
