import {Component, Input, OnInit} from '@angular/core';
import * as uuid from "uuid";
import {DataSubscriptionService} from "../../services/data-subscription.service";
import {ClaimView} from "../../services/api/models/claim-reference-view";
import {IdentityView} from "../../services/api/models/identity-view";
import {SubscriptionView} from "../../services/api/models/subscription-view";
import {ActionButtonComponent} from "../common/action-button/action-button.component";
import {NgClass, NgForOf, NgIf} from "@angular/common";
import {ToReadableStringPipe} from "../common/pipes/ToReadableStringPipe";
import {ToVisibilityScopeIconPipe} from "../common/pipes/ToVisibilityScopeIconPipe";
import {ClaimComponent} from "../common/claim/claim.component";
import {GenericButtonComponent} from "../common/generic-button/generic-button.component";
import {VisibilityScopeEnum} from "../../services/api/models/visibility-scope";
import {SettingsComponent} from "../common/settings/settings.component";
import {AuthorisedComponent} from "../common/authorised-component";
import {SignOutService} from "../../services/signout-service";
import {CryptoWalletService} from "../../services/crypto-wallet.service";
import {NavigatorService} from "../common/navigator.service";
import {DataStorageService} from "../../services/data-storage.service";

@Component({
  selector: 'app-my-claims',
  templateUrl: './my-claims.component.html',
  standalone: true,
  imports: [
    ActionButtonComponent,
    NgForOf,
    NgClass,
    ToReadableStringPipe,
    ToVisibilityScopeIconPipe,
    ClaimComponent,
    NgIf,
    GenericButtonComponent,
    SettingsComponent
  ],
  styleUrls: ['./my-claims.component.scss']
})
export class MyClaimsComponent extends AuthorisedComponent implements OnInit {
  
  @Input() scopes: Array<string> = [];
  protected identity?: IdentityView;
  protected claims?: Array<ClaimView> = [];
  protected newSubscriptionId?: string = 'new-subscription-' + uuid.v4()
  protected exportId?: string = 'export-' + uuid.v4()
  protected newClaimId?: string = 'new-claim-' + uuid.v4()
  protected deleteClaimClicked: boolean = false
  protected loadingClaims: Map<string, boolean> = new Map<string, boolean>()
  protected open: Map<string, boolean> = new Map<string, boolean>()
  protected loading: boolean = false
  protected errorOccurred: boolean = false
  protected claimType: string = ''
  protected claimToDelete: ClaimView = {}
  protected requestedClaims: Map<string, ClaimView> = new Map<string, ClaimView>()
  protected errorMessage: string = ''
  protected scope?: string = undefined
  protected showMessage: boolean = false;
  protected message: string = '';
  protected readonly sessionStorage = sessionStorage;
  protected readonly VisibilityScopeEnum = VisibilityScopeEnum;
  
  constructor(protected navigator: NavigatorService,
              dataSubscriptionService: DataSubscriptionService,
              signOutService: SignOutService,
              cryptoWalletService: CryptoWalletService,
              dataStorageService: DataStorageService) {
    super(dataSubscriptionService, signOutService, cryptoWalletService, navigator, dataStorageService)
  }
  
  ngOnInit() {
    this.shouldAuthenticate = true
    this.dataSubscriptionService.onNext<boolean>('dataLoading').subscribe(dataLoading => {
      this.loading = dataLoading
    })
    this.dataSubscriptionService.onChanges<IdentityView>('identity').subscribe(identity => {
      this.identity = identity
      this.claims = identity.claims;
    })
  }
  
  protected tryDeleteClaim(claim: ClaimView) {
    this.deleteClaimClicked = true
    this.claimToDelete = claim
  }
  
  protected openCloseClaim(claimView: ClaimView) {
    this.open.set(claimView.cid!, !this.open.get(claimView.cid!) || false)
  }
  
  protected isLoading(claimId: string): boolean {
    return this.loadingClaims.has(claimId) && this.loadingClaims.get(claimId)!
  }
  
  protected removeClaimById(deletedClaimId: any) {
    this.dataSubscriptionService.onFirst<IdentityView>('identity')?.subscribe({
      next: identity => {
        identity.claims?.forEach((element, index) => {
          if (identity?.claims![index].cid == deletedClaimId) identity?.claims?.splice(index, 1);
          this.message = 'Claim deleted!'
          this.showMessage = true
          this.identity = identity
          this.dataSubscriptionService.publish<IdentityView>('identity', identity)
          this.loading = false
        });
      }
    })
  }
  
  protected deletionInitiated($event: any) {
    this.loading = true
  }
  
  protected updateClaimClass($event: string) {
    document.getElementById('claim-row-' + $event)
    this.dataSubscriptionService.onFirst<IdentityView>('identity')?.subscribe({
      next: identity => {
        // const element = this.elementRef.nativeElement.querySelector('#claim-row-' + $event);
        // const claimView = identity?.claims?.find((c, i) => c.cid == $event)
        // const index = identity?.claims?.indexOf(claimView!)
        // if (index == undefined)
        //   return
        // this.renderer.addClass(element, index % 2 == 0 ? 'btn-regular-dark' : 'btn-regular');
      }
    })
  }
  
  protected addSubscription($event: SubscriptionView) {
    this.dataSubscriptionService.onFirst<IdentityView>('identity')?.subscribe({
      next: identity => {
        identity.subscriptions!.push($event)
        this.dataSubscriptionService.publish<IdentityView>('identity', identity)
      }
    })
  }
  
  shareClaims() {
    this.navigator.navigateTo('/new-subscription', false)
  }
}
