import {Component, OnInit} from '@angular/core';
import {GenericButtonComponent} from "../../common/generic-button/generic-button.component";
import {NgForOf, NgIf} from "@angular/common";
import {LogoComponent} from "../../common/logo/logo.component";
import {ErrorMessageService} from "../../../services/error-message-service";
import {DataService} from "../../../services/data.service";
import {IdentityControllerService, IdentityPayload} from "../../../services/";
import {CryptoWalletService} from "../../../services/crypto-wallet.service";
import {Router} from "@angular/router";
// import TrezorConnect from "@trezor/connect-web";
import {DIDDocument, DIDDocumentPayload} from "../../../services/model/did-document";
import {environment} from "../../../environments/environment";
import {ErrorModalComponent} from "../../common/error-modal/error-modal.component";


@Component({
  selector: 'app-mnemonic-phrase',
  standalone: true,
  imports: [
    GenericButtonComponent,
    NgForOf,
    NgIf,
    LogoComponent,
    ErrorModalComponent
  ],
  templateUrl: './mnemonic-phrase.component.html',
  styleUrl: './mnemonic-phrase.component.scss'
})
export class MnemonicPhraseComponent implements OnInit {
  
  protected termsAndConditionsAccepted: boolean = false
  protected email?: string = undefined;
  protected loading: boolean = false;
  protected noEmail: boolean = false;
  protected errorOccurred: boolean = false
  protected authenticationMethod: string = '';
  protected mnemonic: string = '';
  protected mnemonicParts: Array<string> = [];
  protected showCopyConfirmation: boolean = false;
  
  constructor(private errorMessageService: ErrorMessageService, private dataService: DataService, private identityControllerService: IdentityControllerService, private cryptoWalletService: CryptoWalletService, private router: Router) {
  }
  
  ngOnInit(): void {
    this.dataService.last<string>('authenticationMethod').subscribe({
      next: async authenticationMethod => {
        this.authenticationMethod = authenticationMethod;
        if (this.authenticationMethod == 'doatoa') {
          this.dataService.last<string>('mnemonic').subscribe({
            next: mnemonic => {
              this.mnemonic = mnemonic;
              this.mnemonicParts = mnemonic.split(" ")
            }
          })
        }
      }
    })
  }
  
  copyToClipboard = async () => {
    try {
      await navigator.clipboard.writeText(this.mnemonic);
      this.showCopyConfirmation = true
      setTimeout(() => {
        this.showCopyConfirmation = false
      }, 1000);
    } catch (error) {
      console.error("Failed to copy to clipboard:", error);
    }
  };
  
  switchTermsAndConditionsAccepted() {
    this.termsAndConditionsAccepted = !this.termsAndConditionsAccepted
  }
  
  async createIdentity() {
    this.loading = true
    this.dataService.last<string>('email').subscribe({
      next: async email => {
        const publicKey: string = await this.resolvePublicKey(this.authenticationMethod)
        const did: string = `did:key:${publicKey!}`
        this.dataService.publish('did', did, true)
        let identityPayload: IdentityPayload = {
          did: did!,
          emailAddress: email,
          locale: navigator.languages[0]
        }
        this.identityControllerService.register(identityPayload)
          .subscribe({
            next: (response) => {
              this.router.navigate(['/sign-in'])
              this.loading = false
              this.email == undefined;
              this.noEmail = this.email == undefined;
            },
            error: (error) => {
              this.loading = false
              this.email == undefined;
              this.noEmail = this.email == undefined;
              this.router.navigate(['/sign-up'])
            }
          });
      }
    })
  }
  
  private createDIDDocumentPayload(did: string, publicKey: string): DIDDocumentPayload {
    return {
      context: "https://www.w3.org/ns/did/v1",
      assertionMethod: [did],
      authentication: [did + '#keys-1'],
      id: did,
      service: [],
      verificationMethod: [
        {
          id: did + '#keys-1',
          type: 'Ed25519VerificationKey2018',
          controller: did,
          publicKey: publicKey,
        }
      ]
    }
  }
  
  private createDIDDocument(didDocumentPayload: DIDDocumentPayload, signature: string): DIDDocument {
    return {
      context: didDocumentPayload.context,
      id: didDocumentPayload.id,
      controller: didDocumentPayload.id,
      verificationMethod: [{
        id: didDocumentPayload.verificationMethod[0].id,
        type: didDocumentPayload.verificationMethod[0].type,
        controller: didDocumentPayload.verificationMethod[0].controller,
        publicKey: didDocumentPayload.verificationMethod[0].publicKey
      }],
      authentication: didDocumentPayload.authentication,
      proof: {
        type: didDocumentPayload.verificationMethod[0].type,
        proofPurpose: 'assertionMethod',
        created: new Date().toISOString(),
        verificationMethod: didDocumentPayload.verificationMethod[0].id,
        signatureValue: signature
      }
    }
  }
  
  private async resolvePublicKey(method: string): Promise<string> {
    switch (method) {
      // case 'trezor': {
      //   const publicKeyRetrievalResponse = await TrezorConnect.getPublicKey({
      //     path: environment.cryptoPath
      //   })
      //   if (publicKeyRetrievalResponse.success) {
      //     return publicKeyRetrievalResponse.payload.publicKey
      //   }
      //   throw new Error(publicKeyRetrievalResponse.payload.error)
      // }
      case 'doatoa' :
        return this.dataService.getSigningPublicKey()
      default:
        throw new Error(`Unknown method: ${method}`)
    }
  }
}
