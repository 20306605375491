// src/app/services/crypto.service.ts

import {Injectable} from '@angular/core';
// import TrezorConnect, {Success, Unsuccessful} from '@trezor/connect-web';
import CryptoJS from 'crypto-js';
import {SigningResult} from "./model/signing-result";
import bs58 from 'bs58'
import {DataService} from "./data.service";
import {CryptographyService} from "./cryptography.service";
import {wordlist} from "@scure/bip39/wordlists/english";
import * as bip39 from "@scure/bip39";
import {HDKey} from "@scure/bip32";
import {KeyStore} from "./key-store";
import {environment} from "../environments/environment";

@Injectable({
  providedIn: 'root'
})
export class CryptoWalletService {
  
  constructor(private dataService: DataService, private cryptographyService: CryptographyService) {
    // TrezorConnect.init({
    //   lazyLoad: false,
    //   manifest: {
    //     email: 'development@compilit.com',
    //     appUrl: 'https://app.doatoa.io',
    //   },
    // })
  }
  
  //todo: encrypt mnemonic phrase aes and rsa with user password
  async generateKeys(emailAddress: string, method: string, providedMnemonic?: string): Promise<string> {
    const mnemonic = providedMnemonic || bip39.generateMnemonic(wordlist, 256) //used for (HDKey and) AES private key deterministic derivation
    
    let base58CheckEncodedSigningPrivateKey = ''
    let base58CheckEncodedSigningPublicKey = ''
    
    if (method == 'doatoa') {
      //ECDSA
      
      const seed = await bip39.mnemonicToSeed(mnemonic);
      const masterKeypair = HDKey.fromMasterSeed(seed);
      const firstChild = masterKeypair.deriveChild(1) //todo: find out how the index is determined
      
      //encode for sharing purposes and for DID:key determination
      base58CheckEncodedSigningPrivateKey = await this.cryptographyService.baseEncode(firstChild.privateKey!, 'z')
      base58CheckEncodedSigningPublicKey = await this.cryptographyService.baseEncode(firstChild.publicKey!, 'z')
      
      //decode when received
      const rawSigningPrivateKey = await this.cryptographyService.baseDecode(base58CheckEncodedSigningPrivateKey)
      const rawSigningPublicKey = await this.cryptographyService.baseDecode(base58CheckEncodedSigningPublicKey)
      
      //actual signing test
      const message = "test"
      const signature = await this.cryptographyService.signMessage(message, rawSigningPrivateKey)
      const isValid = await this.cryptographyService.verifySignature(signature, rawSigningPublicKey, message)
      console.log(`Wallet successfully initialised: ${isValid}`)
    }
    //AES
    const encryptionArrayBuffer = await this.cryptographyService.deriveAESKeyFromMnemonicPhrase(mnemonic)
    const encryptionKey = await this.cryptographyService.baseEncode(encryptionArrayBuffer)
    
    //RSA
    const exchangeKeypair = await this.cryptographyService.generateRSAKeyPair()
    const exchangeExportedKeyBuffer = await crypto.subtle.exportKey('pkcs8', exchangeKeypair.privateKey)
    const exchangePrivateKey = await this.cryptographyService.baseEncode(exchangeExportedKeyBuffer)
    
    const keyStore: KeyStore = {
      type: method,
      signingPrivateKey: base58CheckEncodedSigningPrivateKey,
      signingPublicKey: base58CheckEncodedSigningPublicKey,
      exchangePrivateKey: exchangePrivateKey,
      encryptionPrivateKey: encryptionKey,
    }
    this.dataService.publish(emailAddress, JSON.stringify(keyStore), true)
    return mnemonic
  }
  
  async encrypt(data: string): Promise<string> {
    const privateKey = await this.dataService.getEncryptionPrivateKey()
    console.log(privateKey)
    const cryptoKey = await this.cryptographyService.privateAESKeyStringToCryptoKey(privateKey)

    return await this.cryptographyService.encryptAES(data, cryptoKey)
  }
  
  async signData(data: string, method?: string): Promise<SigningResult> {
    const preferredMethod = method || await this.dataService.getPreferredMethod()
    switch (preferredMethod) {
      case 'doatoa': {
        const signingKey = await this.dataService.getSigningPrivateKey()
        const rawSigningKey = await this.cryptographyService.baseDecode(signingKey)
        const signature = await this.cryptographyService.signMessage(data, rawSigningKey)
        return new Promise((resolve, reject) => {
          resolve({
              address: "",
              signature: signature,
          })
        });
      }
      default: throw new Error()
      // case 'trezor':
      //   return await TrezorConnect.signMessage({path: environment.cryptoPath, message: data});
      // default:
      //   return new Promise((resolve, reject) => {
      //     resolve({
      //       success: false,
      //       payload: {
      //         error: `Unknown method: ${method}`
      //       }
      //     })
      //   });
    }
    
  }
  
  // verify(signature: string, data: string) {
  //   this.dataService.last<string>('did').subscribe({
  //     next: did => {
  //       const publicKey = did.substring(7)
  //       TrezorConnect.verifyMessage({
  //         message: data,
  //         signature: signature,
  //         hex: false,
  //         coin: 'Ethereum', //todo: check
  //         address: this.publicKeyToAddress(publicKey, environment.cryptoNet)
  //       })
  //     }
  //   })
  // }
  
  sha256(buffer: Uint8Array): Uint8Array {
    const wordArray = CryptoJS.lib.WordArray.create(buffer as any);
    const hash = CryptoJS.SHA256(wordArray);
    return new Uint8Array(CryptoJS.enc.Hex.parse(hash.toString()).words);
  }
  
  ripemd160(buffer: Uint8Array): Uint8Array {
    const wordArray = CryptoJS.lib.WordArray.create(buffer as any);
    const hash = CryptoJS.RIPEMD160(wordArray);
    return new Uint8Array(CryptoJS.enc.Hex.parse(hash.toString()).words);
  }
  
  base58CheckEncode(buffer: Uint8Array): string {
    const checksum = this.sha256(this.sha256(buffer)).slice(0, 4);
    const payload = new Uint8Array(buffer.length + checksum.length);
    payload.set(buffer);
    payload.set(checksum, buffer.length);
    return this.base58Encode(payload);
  }
  
  publicKeyToAddress(publicKeyBase64: string, networkByte: number = 0x00): string {
    const publicKey = Uint8Array.from(Buffer.from(publicKeyBase64, 'base64'));
    const sha256Hash = this.sha256(publicKey);
    const ripemd160Hash = this.ripemd160(sha256Hash);
    const networkAndPubkeyHash = new Uint8Array(1 + ripemd160Hash.length);
    networkAndPubkeyHash[0] = networkByte;
    networkAndPubkeyHash.set(ripemd160Hash, 1);
    return this.base58CheckEncode(networkAndPubkeyHash);
  }
  
  base58Encode(bytes: Uint8Array): string {
    return bs58.encode(bytes)
  }
}