import {Injectable} from '@angular/core';
import {RedirectService} from "./redirect-service";
import {DataSubscriptionService} from "./data-subscription.service";
import {DataStorageService} from "./data-storage.service";
import {BootstrapService} from "./bootstrap.service";
import {CryptoWalletService} from "./crypto-wallet.service";

@Injectable({
  providedIn: 'root'
})
export class SignOutService {
  
  constructor(private redirectService: RedirectService, private cryptoWalletService: CryptoWalletService, private bootstrapService: BootstrapService, private dataSubscriptionService: DataSubscriptionService) {
  }
  
  signOut() {
    this.cryptoWalletService.clear().then(() =>
      this.bootstrapService.destroy().then(() => this.redirectService.redirectToSignInPage()))
    this.dataSubscriptionService.publish('showMenu', false)
  }
  
}
