<div class="d-flex flex-column align-items-center justify-content-start">
    <div class="d-flex flex-column align-items-center justify-content-center max-width-300 mt-5">
        <app-logo class="m-3"></app-logo>
        <div class="col-auto text-center max-width-300 p-3">
            <div class="container justify-content-center">
                <app-spinner [loading]="true"
                             class="m-auto mt-5"
                             [style]="'width: 183px; height: 183px;'"></app-spinner>
                <div class="mt-3 text-center">Signing out...</div>
            </div>
        </div>
    </div>
</div>