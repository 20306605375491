import {Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges} from '@angular/core';
import * as uuid from 'uuid';

import {HttpErrorResponse} from "@angular/common/http";

import {
  ClaimControllerService,
  ClaimView,
  IdentityControllerService,
  IdentityView,
  LicenseDetailsView,
  SubscriptionView
} from "../../../services/";
import {IdentityReferenceView} from "../../../services/model/identity-reference-view";
import {DataService} from "../../../services/data.service";
import {IpfsService} from "../../../services/api/ipfs.service";

@Component({
  selector: 'app-identity-reference',
  templateUrl: './identity-reference.component.html',
  styleUrls: ['./identity-reference.component.scss']
})
export class IdentityReferenceComponent implements OnInit, OnChanges {
  
  @Output() reloadCurrentIdentity: EventEmitter<any> = new EventEmitter();
  @Input() scopes: Array<string> = []
  @Input() identityReference: IdentityReferenceView = {}
  @Input() classList: string = '';
  protected identity?: IdentityView
  protected loadingAvatar: boolean = false
  protected errorOccurred: boolean = false
  protected claimLoading: Map<string, boolean> = new Map<string, boolean>()
  protected open: Map<string, boolean> = new Map<string, boolean>()
  protected shortSummary: string = ''
  protected identityIdentifier?: string = 'my-identity-reference-' + uuid.v4()
  protected newClaimIdentifier?: string = 'new-claim-' + uuid.v4()
  protected newRequestIdentifier?: string = 'new-subscription-' + uuid.v4()
  protected exportIdentifier?: string = 'export-' + uuid.v4()
  protected filePath: string = '';
  protected fileName: string = '';
  protected loading: boolean = false;
  protected imageChangedEvent: any = '';
  protected base64Avatar: string = '';
  protected showEditAvatar: boolean = true;
  protected dataLoaded: boolean = false
  
  constructor(protected dataService: DataService,
              private factsService: IpfsService,
              private claimControllerService: ClaimControllerService,
              private identityControllerService: IdentityControllerService) {
  }
  
  getIdentity() {
    this.loading = true
    this.identityControllerService.viewIdentity(this.identityReference.did!)
      .subscribe({
        next: (identityView) => {
          this.identity = identityView;
          this.newClaimIdentifier = 'new-claim-' + identityView!!.did!!.toLowerCase()
          this.newRequestIdentifier = 'new-subscription-' + identityView!!.did!!.toLowerCase()
          this.exportIdentifier = 'export-' + identityView!!.did!!.toLowerCase()
          this.dataLoaded = true
          this.loading = false
        }
      });
  }
  
  ngOnChanges(changes: SimpleChanges): void {
    this.getImage()
    if (this.identityReference?.introduction != undefined && this.identityReference?.introduction!.length > 50) {
      this.shortSummary = this.identityReference?.introduction?.substring(0, 50) + '...'
    } else {
      this.shortSummary = this.identityReference?.introduction || ''
    }
    
  }
  
  ngOnInit(): void {
    if (this.base64Avatar == undefined) {
      this.getImage()
    }
    this.dataService.last<Array<string>>('scopeAggregates').subscribe({
      next: scopes => {
        this.scopes = scopes
      }
    })
  }
  
  getImage() {
    if (!!this.identityReference?.avatarId) {
      this.loadingAvatar = true
      this.claimControllerService.viewClaims<Array<ClaimView>>(undefined, this.identityReference?.avatarId, undefined, undefined, undefined, undefined, 'application/vnd.doatoa.claims+json')
        .subscribe({
          next: (response) => {
            const claim = response[0]
            if (!!claim.cid) {
              this.factsService.getFact(claim.cid).subscribe({
                next: fact => {
                  this.base64Avatar = fact?.value!
                  // this.imagePath = this.sanitizer.bypassSecurityTrustResourceUrl('data:image/jpg;base64,' + this.base64Avatar);
                  this.base64ToFile()
                  this.loadingAvatar = false
                }
              })
            } else {
              this.loadingAvatar = false
            }
          },
          error: (error) => {
            if (error instanceof HttpErrorResponse) {
              this.errorOccurred = true
            }
          }
        });
      
    }
  }
  
  isLoading(claimId: string): boolean {
    return this.claimLoading.has(claimId) && this.claimLoading.get(claimId)!
  }
  
  private base64ToFile() {
    const timestamp = new Date()
    const imageName = 'avatar-' + timestamp.toISOString() + '.jpg';
    const imageBlob = this.dataURItoBlob(this.base64Avatar!!);
    const newFile = new File([imageBlob], imageName, {type: 'image/*'});
    this.fileName = newFile.name
    this.filePath = URL.createObjectURL(newFile);
  }
  
  
  dataURItoBlob(dataURI: string) {
    const byteString = window.atob(dataURI);
    const arrayBuffer = new ArrayBuffer(byteString.length);
    const int8Array = new Uint8Array(arrayBuffer);
    for (let i = 0; i < byteString.length; i++) {
      int8Array[i] = byteString.charCodeAt(i);
    }
    return new Blob([int8Array], {type: 'image/jpeg'});
  }
  
  protected readonly sessionStorage = sessionStorage;
  protected readonly LicenseDetailsView = LicenseDetailsView;
  @Input() index!: number;
  
  resolveClass() {
    return this.index % 2 == 0 ? 'btn-basic-secondary' : 'btn-basic-secondary-dark';
  }
  
  resolveSource() {
    return this.index % 2 == 0 ? "..//person-circle-light.svg" : "..//person-circle-dark.svg";
  }
  
  addRequest($event: SubscriptionView) {
    this.dataService.last<IdentityView>('identity').subscribe({
      next: identity => {
        identity?.subscriptions?.push($event)
        this.identity = identity
        this.dataService.publish('identity', identity)
      }
    })
  }
  
  addClaim($event: ClaimView) {
    this.dataService.last<IdentityView>('identity').subscribe({
      next: identity => {
        identity?.claims?.push($event)
        this.identity = identity
        this.dataService.publish('identity', identity)
      }
    })
  }
  
  noClaimsMade() {
    return this.identity?.claims?.length == 0;
  }
}
