import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';

import {FormsModule} from "@angular/forms";
import {ToReadableStringPipe} from "../pipes/ToReadableStringPipe";
import {NgForOf, NgIf} from "@angular/common";
import {ErrorModalComponent} from "../error-modal/error-modal.component";
import {GenericMessageComponent} from "../generic-message/generic-message.component";
import {GenericButtonComponent} from "../generic-button/generic-button.component";
import {SubscriptionControllerService, Subscriptions, SubscriptionView} from "../../../services";
import {DataService} from "../../../services/data.service";
import {CryptographyService} from "../../../services/cryptography.service";

@Component({
    selector: 'protected app-new-subscription-modal',
    templateUrl: './new-subscription-modal.component.html',
    standalone: true,
    imports: [
        FormsModule,
        ToReadableStringPipe,
        NgForOf,
        NgIf,
        ErrorModalComponent,
        GenericMessageComponent,
        GenericButtonComponent
    ],
    styleUrls: ['./new-subscription-modal.component.scss']
})
export class NewSubscriptionModalComponent implements OnInit {

    @Output() submittedRequest: EventEmitter<SubscriptionView> = new EventEmitter<SubscriptionView>()
    @Input() appClaimClass: string = ''
    @Input() title: string = ''
    @Input() customId?: string;
    @Input() subscriptionRequest?: SubscriptionView = undefined
    @Input() shareModal: boolean = false
    @Input() subject?: string;
    protected scopes: Array<string> = []
    protected password?: string
    protected selectedClaimScope?: string
    protected errorOccurred: boolean = false
    protected showMessage: boolean = false
    protected loading: boolean = false
    protected success: boolean = false
    protected value?: string
    protected message?: string
    protected subscriber?: string
    protected defaultScopeMessage: string = "Select a scope..."
    protected selectedClaimScopeString: string = this.defaultScopeMessage
    protected todayDate: Date = new Date()
    protected startingMoment?: string;
    protected duration: number = 1
    protected durationUnit: Subscriptions.DurationUnitEnum = Subscriptions.DurationUnitEnum.Years
    protected durations: Subscriptions.DurationUnitEnum[] = [
        Subscriptions.DurationUnitEnum.Hours,
        Subscriptions.DurationUnitEnum.Days,
        Subscriptions.DurationUnitEnum.Months,
        Subscriptions.DurationUnitEnum.Years
    ]
    protected description: string = ''
    protected DurationUnitEnum = Subscriptions.DurationUnitEnum;
    protected readonly Date = Date;
    protected readonly parent = parent;

    constructor(protected dataService: DataService, private cryptographyService: CryptographyService, private requestCommandControllerService: SubscriptionControllerService) {
    }

    ngOnInit(): void {
        this.dataService.last<string>('did').subscribe({
            next: did => {
                if (this.subject == undefined) {
                    this.subject = did
                }
            }
        })
        this.dataService.last<Array<string>>('scopes').subscribe({
            next: scopes => {
               this.scopes = scopes
            }
        })
    }

    async requestSubscription() {
        this.loading = true
        const requestee = this.subject!
        const privateSigningKey = await this.dataService.getExchangePrivateKey()
        const publicKey = await this.cryptographyService.deriveRSAPublicKeyFrom(privateSigningKey!)
        this.requestCommandControllerService.requestSubscription(requestee,
            {
                subscriber: this.subscriber,
                scopes: [this.selectedClaimScope!],
                startingMoment: this.startingMoment,
                description: this.description,
                duration: this.duration,
                durationUnit: this.durationUnit,
                publicKey: publicKey
            }
        )
            .subscribe({
                next: (response) => {
                    this.loading = false
                    this.message = 'Successfully submitted the subscription'
                    this.showMessage = true
                    this.submittedRequest.emit(response)
                },
                error: (error) => {
                    this.loading = false
                    this.errorOccurred = true
                }
            });
    }

    setUnit(event: any) {
        this.durationUnit = event.target.value as Subscriptions.DurationUnitEnum
    }

    setScope(event: any) {
        const scope = event.target.value
        this.selectedClaimScope = scope
        this.selectedClaimScopeString = scope.toString()
    }
}
