"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.TRANSPORT = exports.ACTION_TIMEOUT = exports.TREZOR_USB_DESCRIPTORS = exports.WEBUSB_BOOTLOADER_PRODUCT = exports.T1_HID_VENDOR = exports.ENDPOINT_ID = exports.INTERFACE_ID = exports.CONFIGURATION_ID = void 0;
exports.CONFIGURATION_ID = 1;
exports.INTERFACE_ID = 0;
exports.ENDPOINT_ID = 1;
exports.T1_HID_VENDOR = 0x534c;
const T1_HID_PRODUCT = 0x0001;
const WEBUSB_FIRMWARE_PRODUCT = 0x53c1;
exports.WEBUSB_BOOTLOADER_PRODUCT = 0x53c0;
exports.TREZOR_USB_DESCRIPTORS = [
    { vendorId: 0x534c, productId: T1_HID_PRODUCT },
    { vendorId: 0x1209, productId: exports.WEBUSB_BOOTLOADER_PRODUCT },
    { vendorId: 0x1209, productId: WEBUSB_FIRMWARE_PRODUCT },
];
exports.ACTION_TIMEOUT = 10000;
exports.TRANSPORT = {
    START: 'transport-start',
    ERROR: 'transport-error',
    UPDATE: 'transport-update',
    DISABLE_WEBUSB: 'transport-disable_webusb',
    REQUEST_DEVICE: 'transport-request_device',
};
