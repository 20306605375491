import {Pipe, PipeTransform} from '@angular/core';

@Pipe({
    name: 'toReadableString',
    standalone: true
})
export class ToReadableStringPipe implements PipeTransform {
    constructor() {
    }

    public transform(value?: string | undefined): string {
        if (value == undefined)
            return ''
        let newValue = value.toString().toLocaleLowerCase()
        while (newValue.indexOf('_') != -1) {
            newValue = newValue.replace('_', ' ')
        }
        let firstCharacter = newValue.charAt(0).toLocaleUpperCase()
        newValue = newValue.substring(1, newValue.length)
        return firstCharacter + newValue
    }
}