<div class="m-3 card p-3">
    <div class="">
        <h5>Add formal claims</h5>
        <button aria-label="Close"
                class="btn-close float-end m-1"
                (click)="navigator.navigateTo('/my-claims', true)">
        </button>
    </div>
    <div class="" *ngIf="!componentLoading()">
        <div class="p-3">A formal claim is a verified claim with a signature of either the verifying party or DoaToa on behalf of that party.</div>
        <div class="p-3">Choose a verification method:</div>
        <div *ngFor="let method of verificationMethods" class="form-check rounded-5 m-3">
            <input (change)="flipMethod(method.name!)"
                   [checked]="chosenVerificationMethod == method.name"
                   class="form-check-input"
                   id="verificationMethodSwitch-{{method}}"
                   type="checkbox">
            <label for="verificationMethodSwitch-{{method}}">{{ method.name | toCapitalizedText }}: {{ method.name | ToScopeExplanation }}
            </label>
            <ul *ngIf="chosenVerificationMethod == method.name">
                <li *ngFor="let scope of method.scopes" class="m-1">
                    {{ scope | toCapitalizedText }}
                </li>
            </ul>
        </div>
        <app-spinner [loading]="componentLoading()" [style]="'width: 5em; height: 5em;'"></app-spinner>
    </div>
    <div class="d-flex justify-content-center">
        <app-generic-button
                (click)="startVerificationProcess()"
                [disabled]="componentLoading() || chosenVerificationMethod == undefined"
                [loading]="componentLoading()"
                [buttonText]="'Proceed'"
                [primaryIcon]="'fa-solid fa-check-double'">
        </app-generic-button>
    </div>
</div>