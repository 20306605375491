"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.getSynchronize = void 0;
const getSynchronize = () => {
    let lock;
    return (action) => {
        const newLock = (lock !== null && lock !== void 0 ? lock : Promise.resolve())
            .catch(() => { })
            .then(action)
            .finally(() => {
            if (lock === newLock) {
                lock = undefined;
            }
        });
        lock = newLock;
        return lock;
    };
};
exports.getSynchronize = getSynchronize;
