"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.getLog = exports.enableLogByPrefix = exports.enableLog = exports.setLogWriter = exports.initLog = void 0;
const utils_1 = require("@trezor/utils");
const green = '#bada55';
const blue = '#20abd8';
const orange = '#f4a744';
const yellow = '#fbd948';
const colors = {
    '@trezor/connect': `color: ${blue}; background: #000;`,
    '@trezor/connect-web': `color: ${blue}; background: #000;`,
    '@trezor/connect-webextension': `color: ${blue}; background: #000;`,
    IFrame: `color: ${orange}; background: #000;`,
    Core: `color: ${orange}; background: #000;`,
    DeviceList: `color: ${green}; background: #000;`,
    Device: `color: ${green}; background: #000;`,
    DeviceCommands: `color: ${green}; background: #000;`,
    '@trezor/transport': `color: ${green}; background: #000;`,
    InteractionTimeout: `color: ${green}; background: #000;`,
    '@trezor/connect-popup': `color: ${yellow}; background: #000;`,
};
const logsManager = new utils_1.LogsManager({ colors });
exports.initLog = logsManager.initLog.bind(logsManager);
exports.setLogWriter = logsManager.setLogWriter.bind(logsManager);
exports.enableLog = logsManager.enableLog.bind(logsManager);
exports.enableLogByPrefix = logsManager.enableLogByPrefix.bind(logsManager);
exports.getLog = logsManager.getLog.bind(logsManager);
