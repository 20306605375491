import {Component} from '@angular/core';
import {ApiStatusControllerService} from "../../services/api/api-status-controller.service";
import {environment} from "../../environments/environment";
import {IpfsService} from "../../services/api/ipfs.service";

@Component({
  selector: 'app-developer-console',
  templateUrl: './developer-console.component.html',
  styleUrl: './developer-console.component.scss'
})
export class DeveloperConsoleComponent {
  
  loading: boolean = false
  errorOccurred: boolean = false
  errorMessage: string = ''
  //
  // apiStatus: string = ''
  // databaseStatus: string = ''
  // ipfsGatewayStatus: string = ''
  // apiLiveness: string = ''
  // apiReadiness: string = ''
  //
  // constructor(private apiStatusQueryControllerService: ApiStatusControllerService, private factsService: FactsService) {
  // }
  //
  // ngOnInit(): void {
  //   this.refreshApiStatus()
  // }
  //
  redirectToSwaggerUI() {
    window.location.href = environment.idpApiBaseUrl + '/public/api-docs'
  }
  //
  // refreshApiStatus() {
  //   this.loading = true
  //     this.factsService.getStatus().subscribe(status => {
  //         if (status.status.indicator == 'none') {
  //             this.ipfsGatewayStatus = 'UP'
  //         } else {
  //             this.ipfsGatewayStatus = 'DOWN'
  //         }
  //     })
  //   this.apiStatusQueryControllerService.checkApiStatus()
  //     .subscribe({
  //         next: (value) => {
  //           this.apiStatus = value.status
  //           this.databaseStatus = value.components.neo4j.status
  //           this.apiLiveness = value.components.livenessState.status
  //           this.apiReadiness = value.components.readinessState.status
  //           this.loading = false
  //         },
  //         error: (value) => {
  //           this.apiStatus = "DOWN"
  //           this.databaseStatus = "DOWN"
  //           this.apiLiveness = "DOWN"
  //           this.apiReadiness = "DOWN"
  //           this.loading = false
  //         }
  //       }
  //     );
  // }
}
