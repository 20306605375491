import {Component, OnInit} from '@angular/core';
import {DataService} from "../../services/data.service";
import {SignOutService} from "../../services/signout-service";
import {RedirectService} from "../../services/redirect-service";
import {LogoComponent} from "../common/logo/logo.component";
import {SpinnerComponent} from "../common/spinner/spinner.component";

@Component({
  selector: 'app-sign-out',
  templateUrl: './sign-out.component.html',
  standalone: true,
  imports: [
    LogoComponent,
    SpinnerComponent
  ],
  styleUrl: './sign-out.component.scss'
})
export class SignOutComponent implements OnInit {
  
  
  constructor(private signoutService: SignOutService) {
  }
  
  ngOnInit(): void {
    this.signoutService.signOut()
  }
  
}
