import {ApplicationConfig, importProvidersFrom, inject, provideZoneChangeDetection} from '@angular/core';
import {provideRouter, withHashLocation} from '@angular/router';

import {routes} from './app.routes';
import {
    HttpErrorResponse,
    HttpInterceptorFn,
    HttpResponse,
    provideHttpClient,
    withInterceptors,
    withJsonpSupport
} from '@angular/common/http';
import {BrowserModule} from "@angular/platform-browser";
import {provideZxvbnServiceForPSM, ZxvbnConfigType} from "angular-password-strength-meter/zxcvbn";
import {DataService} from "./components/common/services/data-storage.service";
import {ErrorMessageService} from "./components/common/services/error-message-service";
import {catchError, map} from "rxjs";
import {RedirectService} from "./components/common/services/redirect-service";
import {translations} from '@zxcvbn-ts/language-en';

export const authenticationInterceptor: HttpInterceptorFn = (req, next) => {
    const dataService = inject(DataService)
    if (dataService.has('accessToken')) {
        const token = dataService.last<string>('accessToken');
        const clonedRequest = req.clone({
            setHeaders: {
                Authorization: `Bearer ${token}`
            }
        });
        return next(clonedRequest);
    }
    return next(req);
};

export const errorResponseInterceptor: HttpInterceptorFn = (req, next) => {
    const errorMessageService = inject(ErrorMessageService)
    const dataService = inject(DataService)
    const redirectService = inject(RedirectService)
    return next(req).pipe(
        map((event: any) => {
            if (event instanceof HttpResponse) {
                return event
            }
            return event
        }),
        catchError((response: any) => {
            if (response instanceof HttpErrorResponse) {
                if (response.status === 401) {
                    dataService.remove('did');
                    dataService.remove('accessToken');
                    dataService.remove('refreshToken');
                    dataService.remove('idToken')
                    dataService.remove('idTokenHint')
                    redirectService.redirectToSignInPage()
                }
                if (response.status === 403) {
                    errorMessageService.setMessage(response?.error?.message || "You are not allowed to take this action.")
                } else {
                    errorMessageService.setMessage(response?.error?.message)
                }
            }
            throw response
        })
    );
};

const zxvbnConfig: ZxvbnConfigType = {
    translations: translations,
};

export const appConfig: ApplicationConfig = {
    providers: [
        provideHttpClient(withJsonpSupport(), withInterceptors([authenticationInterceptor, errorResponseInterceptor])),
        provideZoneChangeDetection({eventCoalescing: true}),
        importProvidersFrom([BrowserModule]),
        provideRouter(routes, withHashLocation()),
        provideZxvbnServiceForPSM(zxvbnConfig)
    ]
};
