<div class="btn rounded-circle position-relative width-36 height-36 {{btnClass}} {{disabled ? 'disabled' : ''}}">
    <div *ngIf="!loading && !inert"
         class="position-absolute top-50 start-50 translate-middle pt-1"
         style="z-index: 1;">
        <i *ngIf="showPrimary && !iconStack" class="{{ primaryIconClass }}"></i>
        <i *ngIf="showSecondary && !iconStack" class="{{ secondaryIconClass }}"></i>
        <span *ngIf="iconStack && showPrimary" class="fa-stack ">
                <i class="{{stackedPrimaryFirstIconClass}} fa-stack-2x"></i>
                <i class="{{stackedPrimarySecondIconClass}} fa-stack-1x fa-inverse"></i>
        </span>
        <span *ngIf="iconStack && showSecondary" class="fa-stack ">
                <i class="{{stackedSecondaryFirstIconClass}} fa-stack-2x"></i>
                <i class="{{stackedSecondarySecondIconClass}} fa-stack-1x fa-inverse"></i>
        </span>
    </div>
    <div *ngIf="loading || inert">
        <app-spinner class="position-absolute top-50 start-50 translate-middle"
                     [spinnerClasses]="'position-absolute top-50 start-50 translate-middle'"
                     [loading]="loading"></app-spinner>
    </div>
</div>