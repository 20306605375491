import {Component, Input, OnInit} from '@angular/core';
import {ClaimView, IdentityView, SubscriptionView} from "../../../services/";
import {DataService} from "../../../services/data.service";

@Component({
  selector: 'app-subscriptions',
  templateUrl: './subscriptions.component.html',
  styleUrl: './subscriptions.component.scss'
})
export class SubscriptionsComponent implements OnInit {
  @Input() identity?: IdentityView;
  protected loading: Map<string, boolean> = new Map<string, boolean>()
  protected errorOccurred: boolean = false
  protected requestToEdit: SubscriptionView = {}
  protected open: Map<string, boolean> = new Map<string, boolean>()
  protected showMessage: boolean = false;
  protected message: string = '';
  
  constructor(protected dataService: DataService) {
  }
  
  ngOnInit(): void {
      if (!this.identity) {
          this.dataService.last<IdentityView>('identity').subscribe({next: identity => this.identity = identity})
      }
  }
  
  protected resolveClass(index: number, claim: ClaimView): string {
    if (this.identity?.subscriptions?.some((x, y) => x.status == 'approved' || x.status == 'denied')) {
      return 'btn-basic-notify'
    }
    return index % 2 == 0 ? 'btn-basic-secondary-dark' : 'btn-basic-secondary';
  }
  
  protected readonly sessionStorage = sessionStorage;
  
  protected openOrClose(id: string) {
    this.open.set(id, !this.open.get(id) || false)
  }
  
  protected removeRequest($event: SubscriptionView) {
    this.dataService.last<IdentityView>('identity').subscribe({
      next: identity => {
        identity!.subscriptions!.forEach((element, index) => {
          if (identity!.subscriptions![index].id == $event.id) identity!.subscriptions!.splice(index, 1);
          this.message = 'Request deleted!'
          this.showMessage = true
          this.identity = identity;
          this.dataService.publish('identity', identity)
        });
      }
    })
  }
}
