"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.createIFrameMessage = exports.IFRAME = void 0;
const ui_request_1 = require("./ui-request");
exports.IFRAME = {
    BOOTSTRAP: 'iframe-bootstrap',
    LOADED: 'iframe-loaded',
    INIT: 'iframe-init',
    ERROR: 'iframe-error',
    CALL: 'iframe-call',
    LOG: 'iframe-log',
};
const createIFrameMessage = (type, payload) => ({
    event: ui_request_1.UI_EVENT,
    type,
    payload,
});
exports.createIFrameMessage = createIFrameMessage;
