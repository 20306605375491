import {Component, OnInit} from '@angular/core';
import {FormsModule, ReactiveFormsModule} from "@angular/forms";
import {NgForOf, NgIf, NgOptimizedImage} from "@angular/common";
import {RouterLink} from "@angular/router";
import {GenericButtonComponent} from "../common/generic-button/generic-button.component";
import {LogoComponent} from "../common/logo/logo.component";
import {environment} from "../../environments/environment";
import {CryptoWalletService} from "../../services/crypto-wallet.service";
import {RedirectService} from "../../services/redirect-service";

@Component({
    selector: 'app-restore-keys',
    standalone: true,
  imports: [
    FormsModule,
    GenericButtonComponent,
    NgIf,
    NgOptimizedImage,
    ReactiveFormsModule,
    RouterLink,
    NgForOf,
    LogoComponent
  ],
    templateUrl: './restore-keys.component.html',
    styleUrl: './restore-keys.component.scss'
})
export class RestoreKeysComponent implements OnInit {

    protected loading: boolean = false;
    protected mnemonicPartIndices: number[] = []
    protected mnemonicParts: string[] = []
    protected methods: string[] = environment.authenticationMethods
    protected chosenMethod?: string
    protected email?: string
    protected showEmailInfo: boolean = false
    protected stepOne: boolean = true
    protected stepTwo: boolean = false
    protected stepThree: boolean = false
    protected mnemonicPhrase?: string

    constructor(private cryptoWalletService: CryptoWalletService, private redirectService: RedirectService) {
    }

    ngOnInit(): void {
    }

    async recoverKeys(method: string) {
        this.loading = true
        if (this.chosenMethod != undefined) {
            await this.cryptoWalletService.generateKeys(this.email!, method, this.mnemonicPhrase).then(() => {
                this.redirectService.redirectToSignInPage()
            })
        }
        this.loading = false
    }

    flipMethod(method: string) {
        if (this.chosenMethod == method) {
            this.chosenMethod = undefined
        } else {
            this.chosenMethod = method
        }
    }

    isValidEmail = (email?: string) => {
        return String(email)
            .toLowerCase()
            .match(
                /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|.(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
            );
    };

    resolveClass(): string {
        if (this.email == '' || this.email == undefined)
            return 'greyed-out'
        return 'is-valid'
    }

    flipShowEmailInfo() {
        this.showEmailInfo = !this.showEmailInfo
    }

    selectEmail() {
        if (this.email != undefined && this.isValidEmail(this.email)) {
            this.stepOne = false
            this.stepTwo = true
        }
    }

    addMnemonic() {
        if (this.mnemonicParts.length == 24) {
            this.stepTwo = false
            this.stepThree = true
        }
    }

    tryDivide() {
        this.mnemonicParts = this.mnemonicPhrase!.split(" ")
    }
}
