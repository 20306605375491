import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {FactView} from "../../../services";
import {NgIf} from "@angular/common";

@Component({
    selector: 'app-fact',
    templateUrl: './fact.component.html',
    standalone: true,
    imports: [
        NgIf
    ],
    styleUrls: ['./fact.component.scss']
})
export class FactComponent implements OnInit {

    @Input() fact!: FactView
    @Input() style: string = 'min-width: 300px; max-width: 100%;'
    @Output() showCopyConfirmation: EventEmitter<boolean> = new EventEmitter<boolean>()

    constructor() {
    }

    ngOnInit(): void {
    }

    copyToClipboard = async () => {
        try {
            const element = document.getElementById('fact-value-'+this.fact?.id);
            await navigator.clipboard.writeText(element!.textContent!);
            this.showCopyConfirmation.emit(true)
            setTimeout(() => {
                this.showCopyConfirmation.emit(false)
            }, 1000);
        } catch (error) {
            console.error("Failed to copy to clipboard:", error);
        }
    };
}
