import {SplashScreen} from "@capacitor/splash-screen";
import {App} from "@capacitor/app";
import {Router} from "@angular/router";
import {DataSubscriptionService} from "./services/data-subscription.service";
import {SafeArea} from "capacitor-plugin-safe-area";
import {Keyboard} from "@capacitor/keyboard";
import {Haptics, ImpactStyle} from "@capacitor/haptics";

//splash screen
export const showSplashScreen = () => SplashScreen.show()
export const hideSplashScreen = () => SplashScreen.hide()

//App
export async function onResume(router: Router, fn: () => void) {
  await App.addListener("resume", () => {
    if (!router.url.includes('sign-in')) {
      fn()
    }
  })
}
export async function onAppUrlOpen(fn: (data: any) => void) {
  App.addListener('appUrlOpen', (data: any) => {
    if (data) {
      fn(data)
    }
  });
}

//Safe area inset
export const getSafeAreaInsets = () => SafeArea.getSafeAreaInsets()
export const getStatusBarHeight = () => SafeArea.getStatusBarHeight()

//Keyboard
export async function onKeyboardWillShow(fn: (info: any) => void) {
  Keyboard.addListener('keyboardWillShow', fn)
}

export async function onKeyboardWillHide(fn: () => void) {
  Keyboard.addListener('keyboardWillHide', fn)
}

//Haptics
export const hapticsImpactMedium = async () => {
  await Haptics.impact({ style: ImpactStyle.Medium });
};
export const hapticsImpactLight = async () => {
  await Haptics.impact({ style: ImpactStyle.Light });
};
export const hapticsVibrate = async () => {
  await Haptics.vibrate();
};
export const hapticsSelectionStart = async () => {
  await Haptics.selectionStart();
};
export const hapticsSelectionChanged = async () => {
  await Haptics.selectionChanged();
};
export const hapticsSelectionEnd = async () => {
  await Haptics.selectionEnd();
};
