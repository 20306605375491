import {Component, Input} from '@angular/core';
import {
    ClaimControllerService,
    ClaimPayload,
    IdentityControllerService,
    IdentityView
} from "../../common/services";
import {VisibilityScopeEnum} from  "../../common/services/model/visibility-scope";
import {HttpErrorResponse} from "@angular/common/http";
import {DataService} from "../../common/services/data-storage.service";
import {CacheService} from "../../common/services/cache-service";
import {CryptoWalletService} from "../../common/services/crypto-wallet.service";

@Component({
    selector: 'app-identity-summary',
    templateUrl: './profile-introduction.component.html',
    styleUrl: './profile-introduction.component.scss'
})
export class ProfileIntroductionComponent {

    @Input() loading: boolean = false
    protected loadingMap: Map<VisibilityScopeEnum, boolean> = new Map<VisibilityScopeEnum, boolean>()
    protected introductionUpdateLoading: boolean = false
    protected errorOccurred: boolean = false
    protected errorMessage: string = ''
    protected introduction: string = ''
    protected maxCharacters: number = 100
    protected remainingCharacters: number = 100

    constructor(protected dataService: DataService, private profileCommandControllerService: IdentityControllerService, private claimControllerService: ClaimControllerService, private cacheService: CacheService, private cryptoWalletService: CryptoWalletService) {
    }

    alterVisibility(visibilityScope: VisibilityScopeEnum) {
        this.loadingMap.set(visibilityScope, true)
        this.cacheService.bypassCache = true
        this.profileCommandControllerService.patchIdentity([{
            op: "replace",
            path: "/visibilityScope",
            value: visibilityScope.toLowerCase()
        }]).subscribe({
            next: (response) => {
                this.loadingMap.set(visibilityScope, false)
                const identity = this.dataService.getIdentity() as IdentityView;
                identity!.visibilityScope = visibilityScope
                this.dataService.publish('identity', identity)
            },
            error: (error) => {
                this.loadingMap.set(visibilityScope, false)
                if (error instanceof HttpErrorResponse) {
                    this.errorOccurred = true
                }
            }
        })
    }

    submitIntroduction() {
        this.cacheService.bypassCache = true
        this.introductionUpdateLoading = true
        const factClaimPayload: ClaimPayload = {
            factValue: this.introduction,
            claimType: "introduction",
            claimVisibilityScope: VisibilityScopeEnum.Public,
            factAspects: [],
            claimAspects: []
        }
        this.cryptoWalletService.signData(factClaimPayload.factValue!).then(signature => {
            if (signature.success) {
                this.claimControllerService.makeClaim({
                    data: factClaimPayload,
                    signature: signature.payload.signature
                }).subscribe({
                    next: (response) => {
                        const identity = this.dataService.getIdentity() as IdentityView;
                        identity!.claims?.push(response)
                        identity!.introduction = this.introduction
                        this.introductionUpdateLoading = false
                        this.dataService.publish('identity', identity)
                    },
                    error: (error) => {
                        this.introductionUpdateLoading = false
                        if (error instanceof HttpErrorResponse) {
                            this.errorOccurred = true
                        }
                    }
                })
            }
        })
    }

    onIntroductionInput(event: any) {
        const enteredText = event.target.value;
        this.remainingCharacters = this.maxCharacters - enteredText.length;
    }

    protected readonly VisibilityScopeEnum = VisibilityScopeEnum;

}