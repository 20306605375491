<div class="container">
    <div *ngFor="let subscription of identity?.subscriptions; index as i" class="container justify-content-center">
        <div class="row text-dark">
            <div (click)="openOrClose(subscription.id!)"
                 [attr.aria-controls]="'subscription-'+subscription.id"
                 [attr.data-bs-target]="'#subscription-'+subscription.id"
                 aria-expanded="false"
                 data-bs-toggle="collapse"
                 class="mb-3 p-0 m-0 btn {{i % 2 == 0 ? 'btn-basic-secondary-dark' : 'btn-basic-secondary'}}">
                <div class="pe-0 ps-3 container d-flex align-items-center justify-content-between">
                    <div class="d-flex align-items-center">
                       <span class="col-lg-auto">Subscription request by <span class="secondary-blue">{{ subscription.initiator }}</span> on:
                    <span *ngFor="let scope of subscription.scopes"
                          class="secondary-blue">{{ scope | toReadableString }}</span>
                    </span>
                    </div>
                    <app-action-button [showPrimary]="open.get(subscription.id!) || false"
                                       [showSecondary]="!open.get(subscription.id!) || false"
                                       [btnClass]="'btn-basic-info'"
                                       [primaryIconClass]="'fa fa-chevron-up'"
                                       [secondaryIconClass]="'fa fa-chevron-down'">
                    </app-action-button>
                </div>
            </div>
        </div>
        <div class="collapse"
             id="request-{{subscription.id}}">
            <app-subscription [subscription]="subscription"
                              (requestDeleted)="removeRequest($event)"
                              [addressedToMe]="false"></app-subscription>
        </div>
    </div>
    <div *ngIf="identity?.subscriptions?.length == 0">
        <div class="mt-3 text-center greyed-out">No subscriptions sent by you</div>
    </div>
</div>
