/**
 * DoaToa
 * Manage your data and your digital identities, your data your choice.
 *
 * The version of the OpenAPI document: 1.0.0-alpha
 * Contact: info@compilit.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
/* tslint:disable:no-unused-variable member-ordering */

import {Injectable} from '@angular/core';
import {HttpContext, HttpEvent, HttpParams, HttpResponse} from '@angular/common/http';
import {Observable} from 'rxjs';

// @ts-ignore
import {BASE_PATH} from '../variables';
import {DecisionPayload} from "../model/decision-payload-ns";
import {FactView} from "../model/fact-view";
import {SubscriptionView} from "../model/subscription-view";
import {Subscriptions} from "../model/request-payload";
import {AbstractHttpService} from "./abstract-http-service";
import {addToHttpParams} from "./common-http-operations";


@Injectable({
    providedIn: 'root'
})
export class SubscriptionControllerService extends AbstractHttpService {

    public requestSubscription(did: string, subscriptionPayload?: Subscriptions.SubscriptionPayload): Observable<SubscriptionView> {

        let localVarQueryParameters = new HttpParams({encoder: this.encoder});

        let localVarHeaders = this.defaultHeaders;
        localVarHeaders = localVarHeaders.append('Content-Type', 'application/json')
        localVarHeaders = localVarHeaders.append('Accept', 'application/json')

        let localVarPath = `/identities/subscriptions/`;
        return this.httpClient.request<SubscriptionView>('post', `${this.configuration.basePath}${localVarPath}`,
            {
                body: subscriptionPayload,
                params: localVarQueryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: localVarHeaders
            }
        );
    }


    public processRequest(subscriptionId: string, decisionPayload: DecisionPayload): Observable<SubscriptionView> {
        if (subscriptionId === null || subscriptionId === undefined) {
            throw new Error('Required parameter requestId was null or undefined when calling processRequest.');
        }

        let localVarHeaders = this.defaultHeaders;
        localVarHeaders = localVarHeaders.append('Content-Type', 'application/vnd.doatoa.decision+json')
        localVarHeaders = localVarHeaders.append('Accept', 'application/json')


        let localVarPath = `/identities/subscriptions/${subscriptionId}`;
        return this.httpClient.request<FactView>('patch', `${this.configuration.basePath}${localVarPath}`,
            {
                body: decisionPayload,
                withCredentials: this.configuration.withCredentials,
                headers: localVarHeaders
            }
        );
    }

    /**
     * Delete the subscription matching the given subscriptionId, can only be done by the owner of the topic or the subscriber.
     * @param requestId The id of the subscription you wish to remove.
     * @param observe set whether to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report subscription and response progress.
     */
    public delete(requestId: string): Observable<any>;

    public delete(requestId: string): Observable<HttpResponse<any>>;

    public delete(requestId: string): Observable<HttpEvent<any>>;

    public delete(requestId: string): Observable<any> {
        if (requestId === null || requestId === undefined) {
            throw new Error('Required parameter requestId was null or undefined when calling unsubscribeFrom.');
        }

        let localVarHeaders = this.defaultHeaders;
        localVarHeaders = localVarHeaders.append('Content-Type', 'application/json')
        localVarHeaders = localVarHeaders.append('Accept', 'application/json')
        
        let localVarPath = `/identities/subscriptions/${requestId}`;
        return this.httpClient.request<any>('delete', `${this.configuration.basePath}${localVarPath}`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: localVarHeaders
            }
        );
    }


}
