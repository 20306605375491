"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.normalizeVersion = exports.isNewerOrEqual = exports.isEqual = exports.isNewer = exports.isVersionArray = void 0;
const isVersionArray = (arr) => {
    if (!Array.isArray(arr)) {
        return false;
    }
    if (arr.length !== 3) {
        return false;
    }
    for (let i = 0; i < arr.length; i++) {
        const versionNumber = arr[i];
        if (typeof versionNumber !== 'number' || versionNumber < 0) {
            return false;
        }
    }
    return true;
};
exports.isVersionArray = isVersionArray;
const parse = (versionArr) => ({
    major: versionArr[0],
    minor: versionArr[1],
    patch: versionArr[2],
});
const split = (version) => {
    const arr = version.split('.').map(v => Number(v));
    if (!(0, exports.isVersionArray)(arr)) {
        throw new Error(`version string is in wrong format: ${version}`);
    }
    return arr;
};
const versionToString = (arr) => `${arr[0]}.${arr[1]}.${arr[2]}`;
const isNewer = (versionX, versionY) => {
    const parsedX = parse(typeof versionX === 'string' ? split(versionX) : versionX);
    const parsedY = parse(typeof versionY === 'string' ? split(versionY) : versionY);
    if (parsedX.major - parsedY.major !== 0) {
        return parsedX.major > parsedY.major;
    }
    if (parsedX.minor - parsedY.minor !== 0) {
        return parsedX.minor > parsedY.minor;
    }
    if (parsedX.patch - parsedY.patch !== 0) {
        return parsedX.patch > parsedY.patch;
    }
    return false;
};
exports.isNewer = isNewer;
const isEqual = (versionX, versionY) => {
    const strX = typeof versionX === 'string' ? versionX : versionToString(versionX);
    const strY = typeof versionY === 'string' ? versionY : versionToString(versionY);
    return strX === strY;
};
exports.isEqual = isEqual;
const isNewerOrEqual = (versionX, versionY) => (0, exports.isNewer)(versionX, versionY) || (0, exports.isEqual)(versionX, versionY);
exports.isNewerOrEqual = isNewerOrEqual;
const normalizeVersion = (version) => version.replace(/\b0+(\d)/g, '$1');
exports.normalizeVersion = normalizeVersion;
