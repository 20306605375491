import {Component, OnInit} from '@angular/core';
import {FormsModule} from "@angular/forms";
import {GenericButtonComponent} from "../../common/generic-button/generic-button.component";
import {CacheService} from "../../../services/cache-service";
import {DataSubscriptionService} from "../../../services/data-subscription.service";
import {ClaimView} from "../../../services/api/models/claim-reference-view";
import {ClaimControllerService} from "../../../services/api/claim-controller.service";
import {NavigatorService} from "../../common/navigator.service";
import {IdentityView} from "../../../services/api/models/identity-view";

@Component({
  selector: 'app-delete-claim',
  templateUrl: './delete-claim.component.html',
  standalone: true,
  imports: [
    FormsModule,
    GenericButtonComponent
  ],
  styleUrl: './delete-claim.component.scss'
})
export class DeleteClaimComponent implements OnInit {
  
  protected claimToDelete?: ClaimView
  protected deleted: boolean = false
  protected copyClicked: boolean = false
  protected deleteClaimClicked: boolean = false
  protected loading: Map<string, boolean> = new Map<string, boolean>()
  protected claimType: string = ''
  protected classes: string = ''
  protected errorMessage: string = ''
  protected scopes: Array<string> = []
  protected scope?: string = undefined
  
  constructor(protected navigator: NavigatorService, private claimControllerService: ClaimControllerService, private cacheService: CacheService, private dataSubscriptionService: DataSubscriptionService) {
  }
  
  ngOnInit(): void {
    this.loading.set('component', true)
    this.dataSubscriptionService.onChanges<ClaimView>('claimToDelete')
      .subscribe({
        next: claimToDelete => {
          this.claimToDelete = claimToDelete
          this.loading.set('component', false)
        }
      })
  }
  
  deleteClaim() {
    this.cacheService.bypassCache = true
    this.loading.set('component', true)
    if (this.claimToDelete != undefined && this.claimType == this.claimToDelete.type) {
      this.dataSubscriptionService.publish('dataLoading', true)
      this.loading.set(this.claimToDelete.cid!!, true)
      this.claimControllerService.deleteClaims([this.claimToDelete.cid!])
        .subscribe({
          next: (response) => {
            this.cacheService.clear()
            this.loading.set(this.claimToDelete!.cid!!, false)
            this.deleteClaimClicked = false
            this.claimType = ''
            this.deleted = true;
            this.loading.set('component', false)
            this.dataSubscriptionService.onFirst<IdentityView>('identity').subscribe({
              next: identity => {
                identity.claims = identity.claims?.filter(c => c.cid === this.claimToDelete!.cid)
                this.dataSubscriptionService.publish('identity', identity)
              }
            })
            this.navigator.navigateTo('/my-claims', true)
          },
          error: (error) => {
            this.loading.set(this.claimToDelete!.cid!!, false)
            this.deleteClaimClicked = false
            this.loading.set('component', false)
            this.claimType = ''
            this.navigator.navigateTo('/my-claims', true)
          }
        });
    }
  }
  
  nameMatches() {
    return this.claimToDelete?.type === this.claimType;
  }
  
  copyText() {
    this.copyClicked = true
  }
  
  componentLoading() {
    return this.loading.get('component') || false;
  }
}
